import React, { useContext, useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import { DrawerProps } from "@material-ui/core/Drawer";
import SplitPane from "react-split-pane";
import ToolHeader from "../../Components/Tools/ToolHeader";
import { useImageEditor } from "./useImageEditor";
import ActionIcons from "../../Components/Tools/ActionIcons";
import ToolsAction from "../../Components/Tools/ToolsAction";
import ToolsImage from "../../Components/Tools/ToolsImage";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import ReactJson from "react-json-view";
import { useTranslation } from "react-i18next";
import Context from "../../Context/Context";
import { getDatabase, ref, update } from "firebase/database";
import DialogContent from "@material-ui/core/DialogContent";
import BriaDialogTitle from "../../Components/UI/BriaDialogTitle";
import Typography from "@material-ui/core/Typography";
import RegisterDetails from "../../Components/UI/RegisterDetails";

function ImageEditor() {
	const { t } = useTranslation();

	const {
		imageRef,
		visual_hash,
		loading,
		proccessing,
		selectedPoint,
		setSelectedVideo,
		selectedVideo,
		selectedImageUrl,
		selectedImageUrlNoBg,
		backgroundString,
		onBackgroundStringChange,
		pointOfInterests,
		imageDetails,
		setSelectedPoint,

		personalizerValues,
		onChangePersonalizer,

		//Live Faces
		liveFaces,
		closeVideo,
		onCreateLiveFace,

		//Personalizer
		toDisplayThumbnails,
		onAddSemanticCombination,
		onThumbnailClick,

		//Extreme Expressions
		createExtremeExpression,
		createExtremeExpressionMutationLoading,
		createExtremeExpressionMutationError,
		selectedExpressionValue,
		selectedExpressionName,
		resetExtremeExpression,
		resetLastExtremeExpression,
		validExpressions,

		openExpressionsAlert,
		setOpenExpressionsAlert,
		resetExpressionsAlert,

		canUndo,
		canRedo,
		onRedo,
		onUndo,

		layersURL,

		selectedEthnicity,
		selectedEthnicityValue,
		onChangeEthnicity,
		handleResetActions,
		loadingLiveFaces,
		pipelineSettings,
		handleChangePipelineSettings,
		generatingImageMetric,
		fullscreenMode,
		onFullscreenChange,

		openVideoAlert,
		setOpenVideoAlert,
		addSemanticCombinationError,
		liveBackgroundsList,
		onCreateLiveBackground,

		preparingSemantics,

		//Config
		toolsLoading,
		toolsConfigTabs,

		toolsConfigSuggestions,
		toolsConfigSliders,
		toolsConfigThumbnails,
		setImageScore,
		originalImage,
	} = useImageEditor();

	const context = useContext(Context);

	const [showExpressionWarning, setShowExpressionWarning] =
		useState<boolean | null>(null);

	const [drawerAnchor, setDrawerAnchor] = useState<DrawerProps["anchor"]>(
		// context?.preferences?.toolsActionDirections ??
		"left"
	);

	const [isCompareMode, setIsCompareMode] = useState<boolean>(false);
	const setDrawerAnchorHander = (
		value: DrawerProps["anchor"] | undefined
	) => {
		setDrawerAnchor(value);
		const db = getDatabase();
		const settingsRef = ref(db, `users/${context.user?.uid}/settings`);
		update(settingsRef, { toolsActionDirections: value });
	};

	const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
	const [showPopup, setShowPopup] = React.useState(false);
	const closeHandler = () => {
		setShowPopup(false);
	};

	const numberOfChanges = localStorage.getItem("numberOfChanges") || 0;

	useEffect(() => {
		if (numberOfChanges === "3") {
			// setShowPopup(true);
		}
	}, [numberOfChanges]);

	const children = [
		<ToolsImage
			key="tools-image-key"
			loading={loading}
			imageRef={imageRef}
			originalImage={originalImage}
			proccessing={proccessing}
			imageUrl={selectedImageUrl}
			imageUrlNoBg={selectedImageUrlNoBg}
			backgroundString={backgroundString}
			onBackgroundStringChange={onBackgroundStringChange}
			imageVH={visual_hash}
			videoUrl={selectedVideo}
			drawerDirection={drawerAnchor}
			closeVideo={closeVideo}
			pointOfInterests={pointOfInterests}
			setSelectedPoint={setSelectedPoint}
			generatingImageMetric={generatingImageMetric}
			fullscreen={fullscreenMode}
			onFullscreenChange={onFullscreenChange}
			setImageScore={setImageScore}
			isCompareMode={isCompareMode}
			layersURL={layersURL}
		/>,
		<ToolsAction
			key={`tools-action-key-${selectedPoint?.index}`}
			disabled={proccessing}
			selectedPoint={selectedPoint}
			selectedEthnicity={selectedEthnicity}
			selectedEthnicityValue={selectedEthnicityValue}
			liveBackgroundsList={liveBackgroundsList}
			onChangeEthnicity={onChangeEthnicity}
			onCreateLiveBackground={onCreateLiveBackground}
			personalizerValues={personalizerValues}
			onChangePersonalizer={onChangePersonalizer}
			onAddSemanticCombination={onAddSemanticCombination}
			toDisplayThumbnails={toDisplayThumbnails}
			onThumbnailClick={onThumbnailClick}
			liveFaces={liveFaces}
			onCreateLiveFace={onCreateLiveFace}
			loadingLiveFaces={loadingLiveFaces}
			drawerAnchor={drawerAnchor}
			fullscreen={fullscreenMode}
			selectedVideo={selectedVideo}
			setSelectedVideo={setSelectedVideo}
			openVideoAlert={openVideoAlert}
			setOpenVideoAlert={setOpenVideoAlert}
			handleResetActions={handleResetActions}
			addSemanticCombinationError={addSemanticCombinationError}
			configLoading={toolsLoading || preparingSemantics}
			createExtremeExpression={createExtremeExpression}
			createExtremeExpressionMutationLoading={
				createExtremeExpressionMutationLoading
			}
			createExtremeExpressionMutationError={
				createExtremeExpressionMutationError
			}
			selectedExpressionValue={selectedExpressionValue}
			selectedExpressionName={selectedExpressionName}
			resetExtremeExpression={resetExtremeExpression}
			resetLastExtremeExpression={resetLastExtremeExpression}
			validExpressions={validExpressions}
			openExpressionsAlert={openExpressionsAlert}
			setOpenExpressionsAlert={setOpenExpressionsAlert}
			resetExpressionsAlert={resetExpressionsAlert}
			toolConfigurations={toolsConfigTabs}
			currentSliders={toolsConfigSliders}
			toolsConfigThumbnails={toolsConfigThumbnails}
			toolsConfigSuggestions={toolsConfigSuggestions}
			setShowExpressionWarning={(value) => {
				if (showExpressionWarning === null)
					setShowExpressionWarning(value);
			}}
		>
			<ActionIcons
				canUndo={canUndo}
				canRedo={canRedo}
				onRedo={onRedo}
				onUndo={onUndo}
				isCompareMode={isCompareMode}
				setIsCompareMode={setIsCompareMode}
				layersURL={layersURL}
				selectedVideo={selectedVideo}
				selectedImageUrl={selectedImageUrl}
				selectedImageUrlNoBg={selectedImageUrlNoBg}
				backgroundString={backgroundString}
				onBackgroundStringChange={onBackgroundStringChange}
				setSelectedVideo={setSelectedVideo}
				onResetActions={handleResetActions}
				drawerAnchor={drawerAnchor}
				setDrawerAnchor={setDrawerAnchorHander}
				onOpenInfoDialog={() => setOpenInfoDialog(true)}
			/>
		</ToolsAction>,
	];

	return (
		<>
			{showPopup && <RegisterDetails onClick={closeHandler} />}
			{!fullscreenMode && (
				<Box>
					<ToolHeader
						showSettings
						imageName={visual_hash}
						pipelineSettings={pipelineSettings}
						handleChangePipelineSettings={
							handleChangePipelineSettings
						}
					/>
				</Box>
			)}

			<Box
				width="100%"
				height={
					fullscreenMode ? "calc(100vh - 20px)" : "calc(100vh - 80px)"
				}
				position="relative"
			>
				<SplitPane
					split={
						drawerAnchor !== "bottom" ? "vertical" : "horizontal"
					}
					key={drawerAnchor === "left" ? "first" : "second"}
					size={
						selectedPoint === null || fullscreenMode
							? 0
							: drawerAnchor === "bottom"
							? 280
							: 550
					}
					primary={drawerAnchor === "left" ? "first" : "second"}
					allowResize={
						selectedPoint !== null && drawerAnchor !== "bottom"
					}
				>
					{drawerAnchor === "left" ? children.reverse() : children}
				</SplitPane>
			</Box>

			<Dialog
				onClose={() => setShowExpressionWarning(false)}
				open={false}
			>
				<BriaDialogTitle
					id="expression-dialog"
					onClose={() => setShowExpressionWarning(false)}
				>
					<Typography
						color="primary"
						variant="h5"
						style={{
							fontWeight: 800,
						}}
					>
						Please note
					</Typography>
				</BriaDialogTitle>
				<DialogContent>
					<Typography
						gutterBottom
						paragraph
						style={{
							width: 440,
						}}
					>
						Expression modifications should be implemented only
						after finalising all other modifications.
					</Typography>
					<br />
				</DialogContent>
			</Dialog>

			<Dialog
				onClose={() => setOpenInfoDialog(false)}
				open={openInfoDialog}
			>
				<DialogTitle id="simple-dialog-title">
					{t<string>("info")}
				</DialogTitle>
				<Box
					style={{
						height: 400,
						width: 600,
						overflow: "scroll",
					}}
				>
					<Divider />
					<ReactJson
						src={imageDetails?.vdrObject ?? {}}
						style={{ margin: 10 }}
					/>
				</Box>
			</Dialog>
		</>
	);
}

export default ImageEditor;
