import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import firebase from "../Config/Firebase";
import RouterConstants from "../Constants/RouterConstants";
import {
	getAuth,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	confirmPasswordReset,
	sendEmailVerification,
	checkActionCode,
	applyActionCode,
} from "firebase/auth";
import Context from "../Context/Context";

export const useLoginService = () => {
	const [errorMessage, setErrorMessage] =
		React.useState<boolean | string>(false);
	const history = useHistory();
	const { user } = useContext(Context);

	const loginWithEmail = async (email: string, password: string) => {
		signInWithEmailAndPassword(getAuth(), email, password)
			.then((userCredential) => {
				var user = userCredential.user;
			})
			.catch((error) => {
				setErrorMessage(error.message);
				// var errorCode = error.code;
				// var errorMessage = error.message;
			});
	};

	const resetPassword = async (email: string) => {
		return sendPasswordResetEmail(getAuth(), email)
			.then(() => {
				setErrorMessage(false);
			})
			.catch((error) => {
				setErrorMessage(error.message);
				// var errorCode = error.code;
				// var errorMessage = error.message;
			});
	};

	const confirmPasswordResetFunction = (
		code: string,
		newPassword: string
	) => {
		return confirmPasswordReset(getAuth(), code, newPassword)
			.then((res) => res)
			.catch((error) => {
				setErrorMessage(error.code);
				// var errorCode = error.code;
				// var errorMessage = error.message;
			});
	};

	const sendEmailVerificationFunction = () => {
		sendEmailVerification(getAuth().currentUser!)
			// 	{
			// 	handleCodeInApp: true,
			// 	url: "http://localhost:3000/tools",
			// }
			.then(() => {})
			.catch((error) => {
				setErrorMessage(error.message);
			});
	};

	const verifyUser = (code: string) => {
		return checkActionCode(getAuth(), code)
			.then((res) => {
				return applyActionCode(getAuth(), code);
			})
			.then(() => {
				user?.isViewer()
					? history.push(RouterConstants.GALLERY)
					: history.push(RouterConstants.TOOLS);
			});
	};

	return {
		loginWithEmail,
		resetPassword,
		confirmPasswordResetFunction,
		sendEmailVerificationFunction,
		verifyUser,
		errorMessage,
	};
};
