import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function UndoIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 14 16" {...props}>
			<path
				d="M5.93343 2.66515L5.5799 2.31157L5.22627 2.66515L5.5799 3.01873L5.93343 2.66515ZM7.0001 2.16523H6.5001V3.16523H7.0001V2.16523ZM1.1001 9.06106V8.56106H0.100098V9.06106H1.1001ZM7.71217 0.179623L5.5799 2.31157L6.28696 3.01873L8.41922 0.886783L7.71217 0.179623ZM5.5799 3.01873L7.71217 5.15067L8.41922 4.44351L6.28696 2.31157L5.5799 3.01873ZM7.0001 3.16523C10.2581 3.16523 12.9001 5.80595 12.9001 9.06106H13.9001C13.9001 5.25305 10.8098 2.16523 7.0001 2.16523V3.16523ZM12.9001 9.06106C12.9001 12.3162 10.2581 14.9569 7.0001 14.9569V15.9569C10.8098 15.9569 13.9001 12.8691 13.9001 9.06106H12.9001ZM7.0001 14.9569C3.74207 14.9569 1.1001 12.3162 1.1001 9.06106H0.100098C0.100098 12.8691 3.1904 15.9569 7.0001 15.9569V14.9569Z"
				// fill="#5F5F5F"
			/>
		</SvgIcon>
	);
}

export default UndoIcon;
