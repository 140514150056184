import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

function ToolsEmptyState() {
	return (
		<Box
			p={2}
			display="flex"
			alignItems="center"
			height="100%"
			justifyContent="center"
		>
			<Typography align="center">
				No valid operations available
			</Typography>
		</Box>
	);
}

export default ToolsEmptyState;
