import React, { useContext, useState } from "react";
import { DrawerProps } from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
// import Link from "@material-ui/core/Link";
// import Menu from "../UI/Menu";
// import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import UndoIcon from "../../assets/icons/UndoIcon";
import RedoIcon from "../../assets/icons/RedoIcon";
// import InfoIcon from "../../assets/icons/InfoIcon";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import AddIcon from "@material-ui/icons/Add";
import Divider from "@material-ui/core/Divider";
// import Button from "@material-ui/core/Button";
// import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
// import ActionsOnLeft from "../../assets/icons/ActionsOnLeft";
// import ActionsOnRight from "../../assets/icons/ActionsOnRight";
// import ActionsOnBottom from "../../assets/icons/ActionsOnBottom";
import Tooltip from "../UI/Tooltip";
// import IconsDropdown from "../UI/IconsDropdown";
import ResetIcon from "../../assets/icons/ResetIcon";
import Context from "../../Context/Context";
import ThumbnailSliderSwitch from "../UI/ThumbnailSliderSwitch";
import { ToolsBackGroundMode, ToolsViewerMode } from "./utilities";
import Typography from "@material-ui/core/Typography";
import { RemoveFromQueue } from "@material-ui/icons";
import RemoveBackGroundSwitch from "../UI/RemoveBackGroundSwitch";
// import SlidersIcon from "../../assets/icons/SlidersIcon";
// import ThumbnailsAndSlidersIcon from "../../assets/icons/ThumbnailsAndSlidersIcon";
// import ThumbnailsIcon from "../../assets/icons/ThumbnailsIcon";
// import { ToolsViewerMode } from "./utilities";
// import { downloadImage } from "../../Helpers/images";
// import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// import ToggleButton from "@material-ui/lab/ToggleButton";
import TwoStateSwitch from "../UI/TwoStateSwitch";
import CompareIcon from "@material-ui/icons/Compare";
import FaceIcon from "@material-ui/icons/Face";
import OneImage from "../../assets/icons/OneImage";
import BothImagesSlider from "../../assets/icons/BothImagesSlider";
type ActionIconsProps = {
	backgroundString: ToolsBackGroundMode;
	onBackgroundStringChange: (backgroundString: ToolsBackGroundMode) => void;
	selectedImageUrl: string;
	selectedImageUrlNoBg: string;
	canUndo: boolean;
	canRedo: boolean;
	disableToggle?: boolean;
	layersURL: string | null;
	selectedVideo: string | null | undefined;
	onUndo: () => void;
	onRedo: () => void;
	onResetActions: () => void;
	onOpenInfoDialog: () => void;
	setSelectedVideo: React.Dispatch<
		React.SetStateAction<string | null | undefined>
	>;
	drawerAnchor: DrawerProps["anchor"];
	setDrawerAnchor: (value: DrawerProps["anchor"]) => void;
	isCompareMode: boolean;
	setIsCompareMode: (value: boolean) => void;
};

// const DirectionMapper = {
// 	left: 1,
// 	right: 2,
// 	bottom: 0,
// 	top: -1,
// };

// const StylesMapper = {
// 	[ToolsViewerMode.both]: 0,
// 	[ToolsViewerMode.thumbnails]: 1,
// 	[ToolsViewerMode.sliders]: 2,
// };

function ActionIcons({
	backgroundString,
	onBackgroundStringChange,
	drawerAnchor,
	setDrawerAnchor,
	selectedImageUrl,
	selectedImageUrlNoBg,
	onUndo,
	onRedo,
	canUndo,
	canRedo,
	layersURL,
	selectedVideo,
	setSelectedVideo,
	onResetActions,
	onOpenInfoDialog,
	disableToggle = false,
	isCompareMode,
	setIsCompareMode,
}: ActionIconsProps) {
	const { t } = useTranslation();
	const classes = useStyles();
	// const [downloadMenuAnchor, setDownloadMenuAnchor] =
	// 	useState<null | HTMLElement>(null);
	const context = useContext(Context);
	// const [selectedIndex, setSelectedIndex] = useState(
	// 	context?.preferences?.toolsActionDirections
	// 		? DirectionMapper[context?.preferences?.toolsActionDirections]
	// 		: 0
	// );

	// const handleDownloadMenuClose = async () => {
	// 	setDownloadMenuAnchor(null);
	// 	await downloadImage(selectedImageUrl);
	// };

	// const handleDownloadVideo = () => {
	// 	setDownloadMenuAnchor(null);
	// 	setSelectedVideo(null);
	// 	onResetActions();
	// };

	// const handleDrawerAnchor = (side: DrawerProps["anchor"]) => {
	// 	setDrawerAnchor(side);
	// };

	// const onDirectionChange = (index: number) => {
	// 	if (index === 0) {
	// 		handleDrawerAnchor("bottom");
	// 	} else if (index === 1) {
	// 		handleDrawerAnchor("left");
	// 	} else {
	// 		handleDrawerAnchor("right");
	// 	}

	// 	setSelectedIndex(index);
	// };

	const onStyleChange = (style: ToolsViewerMode) => {
		context.user?.updateThumbnailsSettings(style);
	};

	// const viewOptions = [
	// 	<ActionsOnBottom className={classes.icon} />,
	// 	<ActionsOnLeft className={classes.icon} />,
	// 	<ActionsOnRight className={classes.icon} />,
	// ];

	// const styleOptions = [
	// 	<ThumbnailsAndSlidersIcon className={classes.icon} />,
	// 	<ThumbnailsIcon className={classes.icon} />,
	// 	<SlidersIcon className={classes.icon} />,
	// ];

	return (
		<Grid
			container
			alignItems="center"
			style={{ justifyContent: "flex-end" }}
		>
			{context.user?.isAdmin() && (
				<Grid item>
					<TwoStateSwitch
						selectedValue={isCompareMode ? 1 : 2}
						onChange={(value) => setIsCompareMode(value === 1)}
						rightIcon={<BothImagesSlider />}
						leftIcon={<OneImage />}
					/>
				</Grid>
			)}
			{context.user?.isAdmin() && (
				<Grid item>
					<RemoveBackGroundSwitch
						selectedStyle={backgroundString}
						onStyleChange={onBackgroundStringChange}
						disabled={selectedImageUrlNoBg ? false : true}
					/>
					{/* <Tooltip title={t<string>("information")} arrow>
					<IconButton
						onClick={onOpenInfoDialog}
						disabled
						className={classes.disabledButton}
					>
						<InfoIcon className={classes.iconItem2} />
					</IconButton>
				</Tooltip> */}
				</Grid>
			)}
			<Divider orientation="vertical" className={classes.dividerHeight} />
			<Grid item className={classes.grid}>
				{/* <Tooltip title={t<string>("undo")} arrow> */}
				<IconButton
					disabled={!canUndo}
					onClick={() => {
						onUndo();
					}}
					className={classes.padding}
				>
					<UndoIcon
						className={clsx({
							[classes.iconItem]: true,
							[classes.disabledIconItem]: !canUndo,
						})}
					/>
				</IconButton>
				<Typography
					className={clsx({
						[classes.text]: true,
						[classes.disabledIconItem]: !canUndo,
					})}
				>
					{t<string>("undo")}
				</Typography>
				{/* </Tooltip> */}
			</Grid>
			<Grid item className={classes.grid}>
				{/* <Tooltip title={t<string>("redo")} arrow> */}
				<IconButton
					disabled={!canRedo}
					onClick={() => {
						onRedo();
					}}
					className={classes.padding}
				>
					<RedoIcon
						className={clsx({
							[classes.iconItem]: true,
							[classes.disabledIconItem]: !canRedo,
						})}
					/>
				</IconButton>
				<Typography
					className={clsx({
						[classes.text]: true,
						[classes.disabledIconItem]: !canRedo,
					})}
				>
					{t<string>("redo")}
				</Typography>
				{/* </Tooltip> */}
			</Grid>

			<Grid item className={classes.grid}>
				{/* <Tooltip title={t<string>("reset")} arrow> */}
				<IconButton
					onClick={onResetActions}
					className={classes.padding}
				>
					<ResetIcon className={classes.iconItem2} />
				</IconButton>
				<Typography className={classes.text}>
					{t<string>("reset")}
				</Typography>
				{/* </Tooltip> */}
			</Grid>

			<Divider orientation="vertical" className={classes.dividerHeight} />

			<Grid item>
				<ThumbnailSliderSwitch
					selectedStyle={
						context.preferences?.style ?? ToolsViewerMode.thumbnails
					}
					onStyleChange={onStyleChange}
					disabled={disableToggle}
				/>
			</Grid>

			{/* TODO: Improve styling */}
			{/* <Divider orientation="vertical" className={classes.dividerHeight} />

			<Grid item>
				<ToggleButtonGroup size="small" exclusive>
					<ToggleButton value="left">
						<ThumbnailsIcon
							// className={classes.icon}
							fontSize="small"
						/>
					</ToggleButton>
					<ToggleButton value="center">
						<SlidersIcon
							// className={classes.icon}
							fontSize="small"
						/>
					</ToggleButton>
				</ToggleButtonGroup>
			</Grid> */}
			{/* 
			<Grid item>
				<FormControl>
					<IconsDropdown
						value={selectedIndex}
						options={viewOptions}
						onChange={onDirectionChange}
						tooltipTitle="view"
					></IconsDropdown>
				</FormControl>
			</Grid>

			<Grid item>
				<FormControl>
					<IconsDropdown
						value={selectedStyleIndex}
						options={styleOptions}
						onChange={onStyleChange}
						tooltipTitle="style"
						disabled={drawerAnchor === "bottom"}
					></IconsDropdown>
				</FormControl>
			</Grid> */}

			{/* <Grid item>
				<Tooltip title={t<string>("addFileToPersonalGallery")} arrow>
					<Button
						variant="outlined"
						color="secondary"
						className={clsx(classes.button, classes.addButton)}
						startIcon={<AddIcon />}
					>
						{t("addToGallery")}
					</Button>
				</Tooltip>
			</Grid> */}

			{/* <Grid item>
				<Tooltip title={t<string>("downloadFileForPersonalUse")} arrow>
					<Button
						variant="contained"
						color="secondary"
						className={clsx(classes.button, classes.downloadButton)}
						endIcon={<ExpandMoreIcon />}
						onClick={(event: React.MouseEvent<HTMLElement>) => {
							setDownloadMenuAnchor(event.currentTarget);
						}}
					>
						{t("download")}
					</Button>
				</Tooltip>
			</Grid> */}

			{/* <Menu
				id="lock-menu"
				anchorEl={downloadMenuAnchor}
				keepMounted
				open={Boolean(downloadMenuAnchor)}
				onClose={handleDownloadMenuClose}
				style={{ top: 45 }}
			>
				<MenuItem disabled>
					<Typography
						style={{
							fontSize: 10,
							textAlign: "left",
						}}
					>
						{t("download")}
					</Typography>
				</MenuItem>
				<MenuItem
					// component={Link}
					// href={selectedImageUrl}
					// download
					// target="_blank"
					onClick={handleDownloadMenuClose}
					className={classes.downloadItem}
				>
					{t("downloadAsJPG")}
				</MenuItem>
				<MenuItem
					// onClick={handleDownloadMenuClose}
					className={classes.downloadItem}
				>
					{t("downloadAsPNG")}
				</MenuItem>
				<MenuItem
					component={Link}
					href={layersURL ?? ""}
					target="_blank"
					onClick={handleDownloadMenuClose}
					disabled={layersURL === "" || !layersURL}
					className={classes.downloadItem}
				>
					{t("downloadAsPSD")}
				</MenuItem>
				{selectedVideo && (
					<MenuItem
						component={Link}
						href={selectedVideo || ""}
						target="_blank"
						onClick={handleDownloadVideo}
						className={classes.downloadItem}
						download
						disabled={!selectedVideo}
					>
						{t("downloadAsMP4")}
					</MenuItem>
				)}
			</Menu> */}
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dividerHeight: {
			height: 20,
			margin: 8,
		},
		button: {
			borderRadius: 20,
			textTransform: "capitalize",
			margin: 6,
		},
		addButton: {
			width: 155,
			height: 40,
			textTransform: "none",
		},
		downloadButton: {
			width: 125,
			height: 40,
		},

		downloadItem: {
			fontSize: 10,
			color: theme.palette.text.primary,
			"&:hover": {
				backgroundColor: theme.palette.menuItemBackground.main,
				opacity: 0.5,
				color: theme.palette.primary.main,
			},
			"&:active": {
				backgroundColor: theme.palette.primary.dark,
				opacity: 1,
				color: theme.palette.common.white,
			},
		},
		disabledButton: {
			"&:disabled": {
				opacity: 0.3,
			},
		},
		icon: {
			fill: "transparent",
			fontSize: 16,
			margin: `0px auto`,
		},
		iconItem: {
			// fill: theme.palette.text.primary,
			fill: theme.palette.cardTextSecondary.main,
			fontSize: 16,
		},
		iconItem2: {
			// stroke: theme.palette.text.primary,
			stroke: theme.palette.cardTextSecondary.main,
			fill: "transparent",
			fontSize: 16,
		},
		disabledIconItem: {
			opacity: "0.3 !important",
		},
		selectedIcon: {
			opacity: 1,
			backgroundColor: theme.palette.primary.main,
			"&:hover": {
				opacity: 1,
				backgroundColor: theme.palette.primary.main,
			},
		},
		text: {
			color: "#A1A1A1",
			opacity: 0.97,
			fontSize: 9,
		},
		grid: { display: "grid", justifyItems: "center", paddingBottom: 9 },
		padding: {
			paddingBottom: 3,
			backgroundColor: "transparent !important",
		},
	})
);

export default ActionIcons;
