import client from "../GraphQL/client";

import React from "react";
import clsx from "clsx";
import {
	createStyles,
	withStyles,
	Theme,
	fade,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import AppContext from "../Context/Context";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import FormControl from "@material-ui/core/FormControl";
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import FolderTree from "./Dataset/FolderTree";
import SearchForm from "./Dataset/SearchForm";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
	getImageRepository,
	getFolders,
	getImagesReturnValues,
	getLabelsByMongoId,
} from "../GraphQL/queries";
import {
	deleteMongoImage,
	changeFoldername,
	moveImagesToFolder,
	addLabelsToImages,
	deleteFolder,
} from "../GraphQL/mutations";
import { images_images_images as Image } from "../GraphQL/types/images";
import { folders_folders as GQLFolder } from "../GraphQL/types/folders";
import Pagination from "@material-ui/lab/Pagination";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Filters from "./Dataset/Filters";

import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { DBName } from "../types/graphql-global-types";
import UploadForm from "../Components/UploadForms/UploadForm";
import Dialog from "../Components/Dialog";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import DeleteForever from "@material-ui/icons/DeleteForever";
import DoubleArrow from "@material-ui/icons/DoubleArrow";
import LocalOffer from "@material-ui/icons/LocalOffer";
import PermMedia from "@material-ui/icons/PermMedia";
import Settings from "@material-ui/icons/Settings";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import { labelsByPathPrefix_labelsByPathPrefix as Label } from "../GraphQL/types/labelsByPathPrefix";
import copy from "copy-to-clipboard";
import RefreshIcon from "@material-ui/icons/Refresh";
import MuiDialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withTranslation } from "react-i18next";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { RouteComponentProps, withRouter } from "react-router-dom";
import queryString from "query-string";
import RouterConstants from "../Constants/RouterConstants";
import { FormGroup, Menu, MenuItem, Modal, Switch, Typography } from "@material-ui/core";
import {
	ArrowForward,
	DeleteSweep,
	NoteAdd,
	Create,
	SelectAll,
	Publish,
	DoneAll,
	HighlightOff,
	GetApp,
	Send,
} from "@material-ui/icons";
import ThumbnailSlider from "../Components/UI/ThumbnailSlider";
import { downloadImage } from "../Helpers/images";
import { toast } from "react-toastify";

interface IFolder {
	path: string;
	name: string;
	children: IFolder[];
	isExpanded: boolean;
	parent: IFolder | null;
	isChecked: boolean;
}

export const DBnameRouteMapper = {
	[DBName.DATASETS]: RouterConstants.DATASETS,
	[DBName.RESEARCH]: RouterConstants.RESEARCH,
	[DBName.VDR]: RouterConstants.VDR,
};

export class Folder implements IFolder {
	path: string;
	name: string;
	children: IFolder[];
	isExpanded: boolean;
	parent: IFolder | null;
	isChecked: boolean;

	constructor(
		path: string,
		name: string,
		children: IFolder[],
		parent: IFolder | null
	) {
		this.path = path;
		this.name = name;
		this.children = children;
		this.parent = parent;
		this.isExpanded = false;
		this.isChecked = false;
	}
}
class FOO {
	mockStatsObject = {
		"Label 1": "95%",
		"Label 2": "97%",
		"Label 3": "80%",
		"Label 4": "76%",
		"Label 5": "88%",
	};
}

interface ISelectedImage {
	mediumUrl: string,
	mongoId: string,
	visualHash: string
}

const foo = new FOO();

const moveRootFolder = new Folder("", "moveRoot", [], null);
const globalRootFolder = new Folder("", "Root", [], null);
interface IProps extends RouteComponentProps {
	dbName: DBName;
}
interface IState {
	openDataloopModal: boolean;
	dataloopProject: string;
	mslToggle: boolean;
	validFacesToggle: boolean;
	showSpinnerForImages: boolean;
	shouldOpenDrawer: boolean;
	selectedPage: number;
	totalPages: number;
	totalImages: number;
	selectedTags: any[];
	selectedProjects: any[];
	visualHashToSearch: string;
	images: Image[];
	selectedImageIndex: number | null;
	nextImageIndex: number | null;
	activeIndex: number | null;
	customQueryStr: string | null;
	rootFolder: Folder;
	curFolder: Folder;
	moveRootFolder: Folder;
	imagesPerPage: number;
	isPaginateEnable: boolean;
	imageHeight: number;
	numOfCol: number;
	selectedImages: ISelectedImage[];
	settingsMenuIsOpen: boolean;
	selectedLabels: string[];
	folderFilterToggle: string;
	selectedText: string | null;
	actionsMenuSelection: string;
	shouldOpenDeleteWarningDialog: boolean;
	selectionMode: boolean;
	hoveredImageId: string;
	iconHovered: string;
	displayUploadForm: boolean;
	displayRenameFolder: boolean;
	curNewFolderName: string;
	curMoveChosenFolderPath: string;
	showMoveDialog: boolean;
	showAddLabelsDialog: boolean;
	curNewLabels: string[];
	triedAddingNewLabelsWithoutChangingCur: boolean;
	shouldNotifyAddLabelsCancel: boolean;
	curExpanded: string[] | [];
	curImageLabels: Label[];
	showImageLabels: boolean;
	curQueryStr: string;
	refetchFolders: boolean;
	gridSettingsDisplay: boolean;
	isResizingDrawer: boolean;
	lastDownXDrawer: number;
	newDrawerWidth: number;
	pageMenuWidth: number | string;
	bulkEdit: boolean;
	checkedFoldersExist: boolean;
}

class ImageViewer extends React.Component<IProps, IState> {
	state: IState = {
		openDataloopModal: false,
		dataloopProject: "",
		mslToggle: this.context.mslToggle,
		validFacesToggle: this.context.validFacesToggle,
		showSpinnerForImages: false,
		shouldOpenDrawer: false,
		selectedPage:
			Number(queryString.parse(this.props.location.search).page) ||
			this.context.previouslySelectedPage,
		totalPages: 0,
		totalImages: 0,
		selectedTags: [],
		selectedProjects: [],
		visualHashToSearch: "",
		images: [],
		selectedImageIndex: null,
		nextImageIndex: Number.MAX_SAFE_INTEGER + 1,
		activeIndex: Number.MAX_SAFE_INTEGER + 1,
		customQueryStr: JSON.stringify({
			$and: [
				{
					$or: [
						{ score: { $gt: 0 } },
						{ score: { $exists: false } },
						{ score: null },
					],
				},
			],
		}),
		rootFolder: globalRootFolder,
		curFolder: this.context.curFolder,
		moveRootFolder: moveRootFolder,
		imagesPerPage: this.context.imagesPerPage,
		isPaginateEnable: false,
		imageHeight: this.context.imageHeight,
		numOfCol: 6,
		selectedImages: [],
		settingsMenuIsOpen: false,
		selectedLabels: this.context.previouslySelectedLabels,
		folderFilterToggle: "folders",
		selectedText: this.context.selectedText,
		actionsMenuSelection: "",
		shouldOpenDeleteWarningDialog: false,
		selectionMode: false,
		hoveredImageId: "",
		iconHovered: "",
		displayUploadForm: false,
		displayRenameFolder: false,
		curNewFolderName: "",
		curMoveChosenFolderPath: "",
		showMoveDialog: false,
		showAddLabelsDialog: false,
		curNewLabels: [""],
		triedAddingNewLabelsWithoutChangingCur: false,
		shouldNotifyAddLabelsCancel: false,
		curExpanded: this.context.currExpandedFolders,
		curImageLabels: [],
		showImageLabels: false,
		curQueryStr: "",
		refetchFolders: true,
		gridSettingsDisplay: false,
		isResizingDrawer: false,
		lastDownXDrawer: 0,
		newDrawerWidth: 0,
		pageMenuWidth: 0,
		bulkEdit: false,
		checkedFoldersExist: false,
	};

	static contextType = AppContext;

	onMouseEnterHandler = (position: any) => {
		this.setState({ activeIndex: position });
	};

	handleDrawerOpen() {
		this.setState({ shouldOpenDrawer: true });
	}

	handleDrawerClose() {
		this.setState({ shouldOpenDrawer: false, newDrawerWidth: 0 });
	}

	debounceFunction(callback: any, delay: number) {
		let timer: any;
		return (...args: any) => {
			clearTimeout(timer);
			timer = setTimeout(() => callback(...args), delay);
		};
	}

	debouncedFetchImages = this.debounceFunction(
		async (pageNumber: number) => await this.fetchImages(pageNumber),
		300
	);

	pageControll(): JSX.Element {
		const { location }: any = this.props;
		const folder: string = location.search.split("=")[1];
		return (
			<div style={{ marginBottom: "0.5%" }}>
				{this.state.isPaginateEnable && (
					<AppContext.Consumer>
						{(context) => {
							return (
								<Pagination
									size="large"
									boundaryCount={3}
									onChange={(event: any, page: number) => {
										this.setState(
											{
												selectedPage: page,
												activeIndex: null,
											},
											async () => {
												context.updateSelectedPage(
													page
												);
												console.log(folder);
												await this.fetchImages(
													this.state.selectedPage,
													folder
												);
											}
										);
									}}
									count={this.state.totalPages}
									variant="outlined"
									shape="rounded"
									color="secondary"
									page={this.state.selectedPage}
								/>
							);
						}}
					</AppContext.Consumer>
				)}
			</div>
		);
	}

	handleSelectedImages = (mongoId: string) => {
		const { classes }: any = this.props;
		if (this.state.selectedImages.some(selectedImage => selectedImage.mongoId === mongoId)) {
			return classes.activeImgCls;
		} else {
			return classes.activeImgClsRmv;
		}
	};

	handleImageSelection = (mongoId: string, mediumUrl: string, visualHash: string) => {
		if (this.state.selectedImages.some(selectedImage => selectedImage.mongoId === mongoId)) {
			this.setState(
				{
					selectedImages: this.state.selectedImages.filter(
						(selectedImage) => selectedImage.mongoId !== mongoId
					),
				},
				() => {
					this.handleSelectionMode();
				}
			);
		} else
			this.setState(
				{
					selectedImages: [
						...this.state.selectedImages,
						{ mongoId, mediumUrl, visualHash },
					],
				},
				() => {
					this.handleSelectionMode();
				}
			);
	};

	async fetchImages(selectedPage: number, initialPath?: string) {
		this.setState({ showSpinnerForImages: true });
		const getImagesReturnValues: getImagesReturnValues =
			await getImageRepository(
				this.state.validFacesToggle,
				this.state.mslToggle,
				this.state.selectedTags,
				this.state.selectedProjects,
				selectedPage,
				this.state.imagesPerPage ? this.state.imagesPerPage : 50,
				this.state.visualHashToSearch,
				this.props.dbName,
				!initialPath ? this.state.curFolder.path : initialPath,
				this.state.customQueryStr,
				this.state.selectedLabels,
				this.state.selectedText,
				client
			);
		this.setState({
			images: getImagesReturnValues.images,
			totalPages: getImagesReturnValues.totalPages,
			totalImages: getImagesReturnValues.totalImages,
			curQueryStr: getImagesReturnValues.queryStr,
			isPaginateEnable: getImagesReturnValues.totalPages > 1,
			selectedPage: selectedPage,
			selectedImages: [],
			showSpinnerForImages: false
		});
	}

	handleClickSettingsDialog = () => {
		this.setState({ settingsMenuIsOpen: !this.state.settingsMenuIsOpen });
	};

	handleCloseSettingsDialog = () => {
		this.setState({ settingsMenuIsOpen: false });
	};

	handleUpdateGridSettingsOpen = () => {
		this.setState({ gridSettingsDisplay: true });
	};

	handleUpdateGridSettingsClose = () => {
		this.setState({ gridSettingsDisplay: false });
	};

	updateGridSettings(): JSX.Element {
		const { classes }: any = this.props;
		return (
			<AppContext.Consumer>
				{(context) => {
					return (
						<MuiDialog
							open={this.state.gridSettingsDisplay}
							onClose={this.handleUpdateGridSettingsClose}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogContent>
								<Toolbar className={classes.appBar}>
									<div className={classes.grow} />
									<TextField
										style={{ width: "6rem" }}
										variant="filled"
										value={this.state.imagesPerPage}
										onChange={(event: any) => {
											let value = event.target
												.value as unknown as number;
											let conditionVal =
												value && value > 0
													? true
													: false;
											if (
												value > this.state.images.length
											) {
												conditionVal = false;
											}
											this.setState(
												{
													imagesPerPage: event.target
														.value as unknown as number,
												},
												async () => {
													context.updateImagesPerPage(
														this.state.imagesPerPage
													);
													this.debouncedFetchImages(
														1
													);
												}
											);
										}}
										id="standard-basic"
										label="Images/page"
									/>
									<TextField
										style={{ width: "6rem" }}
										variant="filled"
										value={this.state.imageHeight}
										onChange={(event: any) => {
											this.setState(
												{
													imageHeight: event.target
														.value as unknown as number,
												},
												() => {
													context.updateImageHeight(
														this.state.imageHeight
													);
												}
											);
										}}
										id="standard-basic"
										label="Image height"
									/>
								</Toolbar>
								<FormControlLabel
									control={
										<Checkbox
											color="primary"
											name="Bulk Edit Mode"
											checked={this.state.bulkEdit}
										/>
									}
									label="Bulk Edit Mode"
									onChange={() => {
										this.setState({
											bulkEdit: !this.state.bulkEdit,
										});
									}}
								/>
							</DialogContent>
						</MuiDialog>
					);
				}}
			</AppContext.Consumer>
		);
	}

	async componentDidMount() {
		const folder = queryString.parse(this.props.location.search)
			.folder as string;

		if (folder) {
			console.log("mounted with folder");
			this.setState({ shouldOpenDrawer: true });
			await this.fetchImages(this.state.selectedPage, folder);
			let path = folder.split("/");
			path.pop();
			let pathString = path.join("/");
			await this.initializeRootFolders(pathString);
		} else {
			await this.fetchImages(this.state.selectedPage);
			await this.initializeRootFolders();
		}
		this.handleMousemove.bind(this);
		document.addEventListener("mousemove", (e) => this.handleMousemove(e));
		document.addEventListener("mouseup", (e) => this.handleMouseup(e));
		this.setState({ refetchFolders: true });
	}

	async initializeRootFolders(initialPath?: string) {
		const returnFolders: void | GQLFolder[] = await getFolders(
			this.props.dbName,
			!initialPath ? this.state.rootFolder.path : initialPath,
			client
		);
		let localRootFolder: Folder = this.state.rootFolder;
		let rootFolderChildren: Folder[] = [];

		let localMoveRootFolder: Folder = this.state.moveRootFolder;
		let moveFolderChildren: Folder[] = [];

		returnFolders.forEach((folder: GQLFolder) => {
			rootFolderChildren.push(
				new Folder(
					folder.fullPath,
					folder.folderName,
					[],
					localRootFolder
				)
			);
			moveFolderChildren.push(
				new Folder(
					folder.fullPath,
					folder.folderName,
					[],
					localMoveRootFolder
				)
			);
		});

		localRootFolder.children = rootFolderChildren;
		localRootFolder.isExpanded = true;
		localMoveRootFolder.children = moveFolderChildren;
		localMoveRootFolder.isExpanded = true;

		this.setState({
			rootFolder: localRootFolder,
			moveRootFolder: localMoveRootFolder,
		});
	}

	handleSelectionMode() {
		if (this.state.selectedImages.length > 0) {
			this.setState({ selectionMode: true });
		} else {
			this.setState({ selectionMode: false });
		}
	}

	componentDidUpdate(prevProps: IProps, prevState: IState) {
		if (this.props.dbName !== prevProps.dbName) {
			client.stop();
			this.setState(
				{
					curFolder: globalRootFolder,
					selectedImageIndex: null,
					nextImageIndex: null,
					selectedLabels: [],
					selectedPage: 1,
					selectedText: "",
				},
				() => {
					this.context.updateSelectedText("");
					this.context.updateCurrExpandedFolders([]);
					this.context.updateSelectedLabels(this.state.selectedLabels);
					this.componentDidMount();
				}
			);
		} else if (this.props.location !== prevProps.location) {
			this.setState(
				{ selectedLabels: [], selectedPage: 1, selectedText: "" },
				() => {
					this.context.updateSelectedPage(this.state.selectedPage);
					this.context.updateSelectedLabels(
						this.state.selectedLabels
					);
					this.context.updateSelectedText(this.state.selectedText);
				}
			);
		}
	}

	componentWillUnmount() {
		client.stop();
	}

	handleActionsMenuSelection(selection: string) {
		this.setState({ actionsMenuSelection: selection });
	}

	handleGridSettingsSelection() {
		this.setState({ settingsMenuIsOpen: true });
	}

	async deleteSelectedImages() {
		this.state.selectedImages.forEach(async (image: ISelectedImage) => {
			await deleteMongoImage(this.props.dbName, image.mongoId, client);
		});
		const length = this.state.selectedImages.length;
		const imagesAfterDelete: Image[] = this.state.images.filter(
			(image) => !this.state.selectedImages.some(selectedImage => selectedImage.mongoId === image.mongoId)
		);
		let localRefetchFolder: boolean = false;
		let refetchImages: boolean = false;
		let localCurFolder: Folder = this.state.curFolder;
		if (imagesAfterDelete.length === 0) {
			localRefetchFolder = true;
			localCurFolder = localCurFolder.parent ?? globalRootFolder;
			refetchImages = true;
		}
		this.setState(
			{
				images: imagesAfterDelete,
				selectedImages: [],
				shouldOpenDeleteWarningDialog: false,
				totalImages: this.state.totalImages - length,
				refetchFolders: localRefetchFolder,
				curFolder: localCurFolder,
			},
			async () => {
				if (refetchImages) {
					await this.fetchImages(1);
				}
			}
		);
	}

	handleToggleChange() {
		const isFilterToggled: boolean =
			this.state.folderFilterToggle === "folders";
		this.setState({
			folderFilterToggle: isFilterToggled ? "labels" : "folders",
		});
	}

	handleRenameFolder() {
		this.setState({ displayRenameFolder: false }, async () => {
			const localCurFolder: Folder = this.state.curFolder;
			const newFolderPath: string = await changeFoldername(
				this.props.dbName,
				this.state.curFolder.path,
				this.state.curNewFolderName,
				client
			);
			localCurFolder.name = this.state.curNewFolderName;
			localCurFolder.path = newFolderPath;
			this.setState({ curFolder: localCurFolder, curNewFolderName: "" });
		});
	}

	async handleMoveImages() {
		if (this.state.curMoveChosenFolderPath === this.state.curFolder.path) {
			alert("Please choose a different path than the current one!");
			return;
		}
		await moveImagesToFolder(
			this.props.dbName,
			this.state.selectedImages.map((selectedImage: ISelectedImage) => selectedImage.mongoId),
			this.state.curMoveChosenFolderPath,
			client
		);
		this.setState(
			{
				showMoveDialog: false,
				curMoveChosenFolderPath: "",
				selectionMode: false,
			},
			async () => {
				const timeout: number = 209; //necessary timeout for mongo to update, apparently
				await new Promise((r) => setTimeout(r, timeout));
				await this.refreshPage();
			}
		);
	}

	handleBackFromImageReview = () => {
		this.setState(
			{ selectedImageIndex: null, nextImageIndex: null },
			async () => {
				await this.fetchImages(this.state.selectedPage);
				await this.handleScrollPosition();
			}
		);
	};

	handleScrollPosition = () => {
		const scrollPosition = sessionStorage.getItem(
			"scrollPositionRepository"
		);
		if (scrollPosition) {
			window.scrollTo(0, parseInt(scrollPosition));
			sessionStorage.removeItem("scrollPositionRepository");
		}
	};

	deleteWarning(): JSX.Element {
		return (
			<Dialog
				shouldOpen={this.state.shouldOpenDeleteWarningDialog}
				onClose={() => { }}
				dialogTitle="Once you delete the images, they will no longer exist in the database. Are you sure you want to delete them?"
				dialogContentText=""
				renderDialogContent={() => { }}
				onClickAffirmative={async () => {
					await this.deleteSelectedImages();
				}}
				affirmativeButtonText="Delete"
				onClickNegative={() => {
					this.setState({ shouldOpenDeleteWarningDialog: false });
				}}
				negativeButtonText="Cancel"
			/>
		);
	}
	handleMousemove(event: MouseEvent) {
		if (!this.state.isResizingDrawer) {
			return;
		}
		let localPageMenuWidth: number;
		if (typeof this.state.pageMenuWidth === "string") {
			localPageMenuWidth =
				parseInt(this.state.pageMenuWidth.split("r")[0]) * 16;
		} else {
			localPageMenuWidth = this.state.pageMenuWidth;
		}
		let offsetLeft: number = event.clientX - localPageMenuWidth;
		let minWidth = 200;
		let maxWidth = 600;
		if (offsetLeft > minWidth && offsetLeft < maxWidth) {
			this.setState({ newDrawerWidth: offsetLeft });
		}
	}

	handleMouseup(event: MouseEvent) {
		this.setState({ isResizingDrawer: false });
	}

	public render() {
		const { classes, history }: any = this.props;

		let localShouldOpenDrawer: boolean = false;
		if (this.state.shouldOpenDrawer && !this.state.showMoveDialog) {
			localShouldOpenDrawer = true;
		}
		return (
			<div
				className={classes.root}
				style={{ overflow: "hidden", alignItems: "flex-start" }}
			>
				<AppContext.Consumer>
					{(context) => {
						return (
							<Drawer
								variant="permanent"
								classes={{
									paper: clsx(
										classes.drawerPaper,
										!localShouldOpenDrawer &&
										classes.drawerPaperClose
									),
									root: classes.drawerRoot,
								}}
								PaperProps={{
									style: {
										width: !localShouldOpenDrawer
											? "0"
											: this.state.newDrawerWidth > 0
												? this.state.newDrawerWidth
												: "200px",
									},
								}}
								open={localShouldOpenDrawer}
							>
								<Toolbar className={classes.toolbar}>
									<IconButton
										edge="start"
										color="inherit"
										aria-label="open drawer"
										onClick={this.handleDrawerOpen.bind(
											this
										)}
										className={clsx(
											classes.toolbarIcon,
											localShouldOpenDrawer &&
											classes.menuButtonHidden
										)}
									>
										<MenuIcon />
									</IconButton>
									<div className={classes.toolbarIcon}>
										<IconButton
											onClick={this.handleDrawerClose.bind(
												this
											)}
										>
											<ChevronLeftIcon
												color="secondary"
												className={classes.drawerPaper}
											/>
										</IconButton>
									</div>
								</Toolbar>
								<Divider />
								<div
									style={{
										height: "100%",
										visibility: localShouldOpenDrawer
											? "visible"
											: "hidden",
									}}
								>
									<Grid container direction="column">
										<Grid>
											<ToggleButtonGroup
												size="small"
												value={
													this.state
														.folderFilterToggle
												}
												exclusive
												onChange={this.handleToggleChange.bind(
													this
												)}
												style={{ width: "100%" }}
											>
												<ToggleButton
													value="folders"
													style={{ width: "50%" }}
												>
													<PermMedia fontSize="small" />
												</ToggleButton>
												<ToggleButton
													value="labels"
													style={{ width: "50%" }}
												>
													<LocalOffer fontSize="small" />
												</ToggleButton>
											</ToggleButtonGroup>
										</Grid>
									</Grid>
									{this.renderFoldersLabels()}
									<Box marginTop="15rem">
										<Divider />
										<Button
											color="primary"
											style={{ textTransform: "none" }}
											onClick={() => {
												copy(this.state.curQueryStr);
											}}
										>
											{"Get current Mongo query"}
										</Button>
									</Box>
								</div>
								<div
									id="dragger"
									onMouseDown={(event: any) => {
										this.setState({
											isResizingDrawer: true,
											lastDownXDrawer: event.clientX,
											pageMenuWidth:
												context.pageMenuWidth,
										});
									}}
									className={classes.dragger}
								/>
							</Drawer>
						);
					}}
				</AppContext.Consumer>
				<div
					className={classes.container}
					style={{
						width: this.state.shouldOpenDrawer ? "90%" : "100%",
					}}
				>
					{this.deleteWarning()}
					<div className={classes.root} style={{ width: "100%" }}>
						{this.renderMenu()}
						{this.sendToDataloopModal()}
					</div>
					<Divider />
					<div className={classes.root} style={{ height: "2rem" }}>
						<Box component="span" m={1}>
							Total Images: {this.state.totalImages}
						</Box>
						<Box component="span" m={1}>
							Selected Images:{" "}
							{this.state.selectedImages.length}
						</Box>
					</div>

					<Divider />
					<AppContext.Consumer>
						{(context) => {
							if (this.state.showSpinnerForImages) {
								return (
									<Typography align="center" style={{ margin: "2rem" }}>
										<CircularProgress />
									</Typography>
								)
							} else {
								return (
									<Box
										display="flex"
										flexWrap="wrap"
										overflow="scroll"
										marginBottom="10px"
									>
										{this.state.images.map(
											(image: Image, index: number) => {
												let subtitle: string =
													image.displayName === null ||
														image.displayName === undefined
														? this.props.dbName ===
															DBName.VDR
															? image.visualHash
															: image.mongoId
														: image.displayName;
												return (
													<div
														key={image.mongoId}
														onMouseEnter={() =>
															this.setState({
																hoveredImageId:
																	image.mongoId,
															})
														}
														onMouseLeave={() =>
															this.setState({
																hoveredImageId: "",
															})
														}
													>
														<GridListTile
															key={index}
															classes={{
																root: classes.root2,
																tile: classes.tile,
															}}
															className={this.handleSelectedImages(
																image.mongoId
															)}
															onClick={() => {
																this.onMouseEnterHandler(
																	index
																);
																let pageYOffset: any =
																	window.pageYOffset;
																sessionStorage.setItem(
																	"scrollPositionRepository",
																	pageYOffset
																);
																if (
																	this.state
																		.selectionMode
																) {
																	this.handleImageSelection(
																		image.mongoId, image.mediumUrl, image.visualHash
																	);
																} else {
																	this.setState({
																		selectedImageIndex:
																			index,
																		nextImageIndex:
																			index +
																			1,
																	});

																	const folder =
																		queryString.parse(
																			this
																				.props
																				.location
																				.search
																		).folder;

																	history.push(
																		`${DBnameRouteMapper[
																		this
																			.props
																			.dbName
																		]
																		}${image.mongoId
																		}${folder
																			? "?folder=" +
																			folder
																			: ""
																		}`
																	);
																}
															}}
														>
															{this.state.selectedImages.some(selectedImage => selectedImage.mongoId === image.mongoId) ? (
																<Fade
																	in={
																		this.state
																			.hoveredImageId ===
																		image.mongoId ||
																		this.state.selectedImages.some(selectedImage => selectedImage.mongoId === image.mongoId)
																	}
																>
																	<div
																		onMouseEnter={() =>
																			this.setState(
																				{
																					iconHovered:
																						image.mongoId,
																				}
																			)
																		}
																		onMouseLeave={() =>
																			this.setState(
																				{
																					iconHovered:
																						"",
																				}
																			)
																		}
																	>
																		<CheckCircleIcon
																			fontSize="small"
																			style={{
																				position:
																					"absolute",
																				left: "2px",
																				top: "2px",
																				zIndex: 100,
																				opacity:
																					this
																						.state
																						.iconHovered ===
																						image.mongoId
																						? "1.0"
																						: "0.5",
																			}}
																			onClick={(
																				event: React.MouseEvent<
																					SVGSVGElement,
																					MouseEvent
																				>
																			) => {
																				event.stopPropagation();
																				this.handleImageSelection(
																					image.mongoId, image.mediumUrl, image.visualHash
																				);
																			}}
																		/>
																	</div>
																</Fade>
															) : (
																	<Fade
																		in={
																			this.state
																				.hoveredImageId ===
																			image.mongoId ||
																			this.state.selectedImages.some(selectedImage => selectedImage.mongoId === image.mongoId)
																		}
																	>
																		<div
																			onMouseEnter={(
																				event: React.MouseEvent<
																					HTMLImageElement,
																					MouseEvent
																				>
																			) =>
																				this.setState(
																					{
																						iconHovered:
																							image.mongoId,
																					}
																				)
																			}
																			onMouseLeave={(
																				event: React.MouseEvent<
																					HTMLImageElement,
																					MouseEvent
																				>
																			) =>
																				this.setState(
																					{
																						iconHovered:
																							"",
																					}
																				)
																			}
																		>
																			<RadioButtonUncheckedIcon
																				fontSize="small"
																				style={{
																					position:
																						"absolute",
																					left: "2px",
																					top: "2px",
																					zIndex: 100,
																					opacity:
																						this
																							.state
																							.iconHovered ===
																							image.mongoId
																							? "1.0"
																							: "0.5",
																				}}
																				onClick={(
																					event: React.MouseEvent<
																						SVGSVGElement,
																						MouseEvent
																					>
																				) => {
																					event.stopPropagation();
																					this.handleImageSelection(
																						image.mongoId, image.mediumUrl, image.visualHash
																					);
																				}}
																			/>
																		</div>
																	</Fade>
																)}
															<LazyLoadImage
																alt={"format_url"}
																placeholderSrc={
																	"https://img.freepik.com/free-photo/abstract-surface-textures-white-concrete-stone-wall_74190-8184.jpg?size=626&ext=jpg"
																}
																onClick={() => {
																	let pageYOffset: any =
																		window.pageYOffset;
																	sessionStorage.setItem(
																		"scrollPositionRepository",
																		pageYOffset
																	);
																}}
																effect="blur"
																width={
																	(image.width *
																		this.state
																			.imageHeight) /
																	image.height
																}
																threshold={150}
																useIntersectionObserver={
																	false
																}
																height={`${this.state.imageHeight}px`}
																src={image.smallUrl} // use normal <img> attributes as props
																className={
																	classes.imgSize
																}
															/>
															<Fade
																in={
																	this.state
																		.hoveredImageId ===
																	image.mongoId ||
																	this.state.selectedImages.some(selectedImage => selectedImage.mongoId === image.mongoId)
																}
															>
																<GridListTileBar
																	classes={{
																		root: classes.tileBarRootTop,
																		titleWrap:
																			classes.titleWrap,
																	}}
																	titlePosition="top"
																/>
															</Fade>
															<Fade
																in={
																	this.state
																		.hoveredImageId ===
																	image.mongoId
																}
															>
																<GridListTileBar
																	classes={{
																		root: classes.tileBarRootBottom,
																		titleWrap:
																			classes.titleWrap,
																	}}
																	subtitle={
																		subtitle
																	}
																	titlePosition="bottom"
																/>
															</Fade>
														</GridListTile>
													</div>
												);
											}
										)}
									</Box>
								);
							}
						}}
					</AppContext.Consumer>
					<div className={classes.root}>{this.pageControll()}</div>
				</div>
			</div>
		);
	}

	renderFoldersLabels() {
		const { history } = this.props;
		let localRefetchFolders: boolean = false;
		if (this.state.refetchFolders) {
			this.setState({ refetchFolders: false });
			localRefetchFolders = true;
		}
		return (
			<AppContext.Consumer>
				{(context) => {
					return (
						<List
							style={{
								minHeight: "500px",
								maxHeight: "500px",
								overflow: "scroll",
							}}
						>
							{this.state.folderFilterToggle === "folders" ? (
								<FolderTree
									curFolder={this.state.curFolder.path}
									dbName={this.props.dbName}
									rootFolder={this.state.rootFolder}
									refetchFolders={localRefetchFolders}
									expanded={this.state.curExpanded}
									parentCurFolderCallback={(
										chosenFolder: Folder,
										expanded: string[]
									) => {
										history.push(
											`?folder=${chosenFolder.path}`
										);
										this.setState(
											{
												curFolder: chosenFolder,
												curExpanded: expanded,
											},
											() => {
												context.updateCurrExpandedFolders(
													this.state.curExpanded
												);
												context.updateCurFolder(this.state.curFolder)
												this.fetchImages(1);
											}
										);
									}}
									parentAfterRefetchCallback={(
										localRootFolder: Folder
									) => {
										this.setState({
											rootFolder: localRootFolder,
										});
									}}
									parentCheckCallback={(
										localCheckedFolderExist: boolean
									) => {
										this.setState({
											checkedFoldersExist:
												localCheckedFolderExist,
										});
									}}
									bulkEdit={this.state.bulkEdit}
								/>
							) : (
									<Filters
										dbName={this.props.dbName}
										pathPrefix={this.state.curFolder.path}
										parentSelectedLabelsCallback={(
											selectedLabels: string[]
										) => {
											this.setState(
												{ selectedLabels: selectedLabels },
												async () => {
													context.updateSelectedLabels(
														selectedLabels
													);
													context.updateSelectedPage(1);
													await this.fetchImages(1);
												}
											);
										}}
									/>
								)}
						</List>
					);
				}}
			</AppContext.Consumer>
		);
	}

	renameFolderDialog() {
		return (
			<Dialog
				shouldOpen={this.state.displayRenameFolder}
				onClose={() => { }}
				dialogTitle="Please choose a new name for the current folder"
				dialogContentText=""
				renderDialogContent={() => {
					return (
						<TextField
							id="changeFolderName"
							name="changeFolderName"
							label="New Foldername"
							fullWidth
							defaultValue={this.state.curNewFolderName}
							onChange={(event: any) => {
								this.setState({
									curNewFolderName: event.target.value,
								});
							}}
						/>
					);
				}}
				onClickAffirmative={this.handleRenameFolder.bind(this)}
				affirmativeButtonText="Rename"
				onClickNegative={() => {
					this.setState({
						displayRenameFolder: false,
						curNewFolderName: "",
					});
				}}
				negativeButtonText="Cancel"
			/>
		);
	}

	openMoveDialog() {
		let localRefetchFolders: boolean = false;
		if (this.state.refetchFolders) {
			this.setState({ refetchFolders: false });
			localRefetchFolders = true;
		}
		return (
			<Dialog
				shouldOpen={this.state.showMoveDialog}
				onClose={() => {
					this.setState({
						curMoveChosenFolderPath: "",
						selectionMode: false,
					});
				}}
				dialogTitle="Please choose where to move the images to"
				dialogContentText=""
				renderDialogContent={() => {
					return (
						<FolderTree
							curFolder={this.state.curFolder.path}
							dbName={this.props.dbName}
							rootFolder={this.state.moveRootFolder}
							refetchFolders={localRefetchFolders}
							expanded={[]}
							parentCurFolderCallback={(
								moveChosenFolder: Folder,
								expanded: string[]
							) => {
								this.setState({
									curMoveChosenFolderPath:
										moveChosenFolder.path,
								});
							}}
							parentAfterRefetchCallback={(
								localRootFolder: Folder
							) => {
								this.setState({ rootFolder: localRootFolder });
							}}
							parentCheckCallback={(
								localCheckedFolderExist: boolean
							) => {
								this.setState({
									checkedFoldersExist:
										localCheckedFolderExist,
								});
							}}
							bulkEdit={false}
						/>
					);
				}}
				onClickAffirmative={this.handleMoveImages.bind(this)}
				affirmativeButtonText="Move"
				onClickNegative={() => {
					this.setState({
						showMoveDialog: false,
						curMoveChosenFolderPath: "",
					});
				}}
				negativeButtonText="Cancel"
			/>
		);
	}

	addLabelsTextField(index: number): JSX.Element {
		const curValue: string = this.state.curNewLabels[index];
		const shouldShowError: boolean =
			this.state.triedAddingNewLabelsWithoutChangingCur &&
			curValue === "";
		const indexString: string = index.toString();
		return (
			<Grid xs={12} container spacing={1} item>
				<FormControl fullWidth margin="dense">
					<TextField
						variant="outlined"
						required
						type="string"
						id={indexString}
						label="New Label"
						name={indexString}
						size="small"
						error={shouldShowError}
						helperText={
							shouldShowError ? "You didn't add a label!" : ""
						}
						value={curValue}
						onChange={(event: any) => {
							let localNewLabels: string[] =
								this.state.curNewLabels;
							localNewLabels[index] = event.target.value;
							this.setState({
								curNewLabels: localNewLabels,
							});
						}}
						className="new label"
						InputProps={{
							endAdornment:
								index + 1 === this.state.curNewLabels.length ? (
									<InputAdornment position="end">
										<Tooltip title="Add Another Label">
											<Fab
												color="primary"
												size="small"
												onClick={() => {
													let localNewLabels: string[] =
														this.state.curNewLabels;
													if (
														localNewLabels[
														index
														] === ""
													) {
														this.setState({
															triedAddingNewLabelsWithoutChangingCur:
																true,
														});
														return;
													}
													localNewLabels.push("");
													this.setState({
														curNewLabels:
															localNewLabels,
														triedAddingNewLabelsWithoutChangingCur:
															false,
													});
												}}
											>
												<AddIcon />
											</Fab>
										</Tooltip>
									</InputAdornment>
								) : (
										<InputAdornment position="end">
											<Tooltip title="Remove Label">
												<Fab
													// type="submit"
													color="primary"
													size="small"
													onClick={() => {
														let localNewLabels: string[] =
															this.state.curNewLabels;
														localNewLabels.splice(
															index,
															1
														);
														this.setState({
															curNewLabels:
																localNewLabels,
															triedAddingNewLabelsWithoutChangingCur:
																false,
														});
													}}
												>
													<RemoveIcon />
												</Fab>
											</Tooltip>
										</InputAdornment>
									),
						}}
					/>
				</FormControl>
			</Grid>
		);
	}

	notifyAddLabelsCancel(): JSX.Element {
		return (
			<Dialog
				shouldOpen={this.state.shouldNotifyAddLabelsCancel}
				onClose={() => { }}
				dialogTitle={
					'You\'ve started adding the labels, but haven\'t finished yes. Click on "Go Back" to stop adding labels or "Cancel" to return.'
				}
				dialogContentText=""
				renderDialogContent={() => { }}
				onClickAffirmative={() => {
					this.setState({ shouldNotifyAddLabelsCancel: false });
				}}
				affirmativeButtonText="Go Back"
				onClickNegative={async () => {
					this.setState({ showAddLabelsDialog: false }, () => {
						this.setState({
							shouldNotifyAddLabelsCancel: false,
							curNewLabels: [""],
							showImageLabels: false,
						});
					});
				}}
				negativeButtonText="Cancel"
			/>
		);
	}

	renderImageLabelsInDialog(): JSX.Element {
		const { classes }: any = this.props;
		return this.state.selectedImages.length === 1 ? (
			!this.state.showImageLabels ? (
				<Grid container justify="center">
					<Button
						onClick={async () => {
							const localCurImageLabels: Label[] =
								await getLabelsByMongoId(
									this.props.dbName,
									this.state.selectedImages[0].mongoId,
									client
								);
							this.setState({
								showImageLabels: true,
								curImageLabels: localCurImageLabels,
							});
						}}
						color="primary"
						style={{ textTransform: "none" }}
					>
						{"Show Labels"}
					</Button>
				</Grid>
			) : (
					<Grid className={classes.chipsContainer} container>
						{this.state.curImageLabels.length > 0 ? (
							this.state.curImageLabels.map(
								(label: Label, index: number) =>
									label.name !== "" && (
										<Chip
											key={`${index}${label.name}`}
											label={label.name}
											size="small"
											style={{ margin: "2px" }}
											clickable
											color={
												this.state.selectedLabels.includes(
													label.name
												)
													? "secondary"
													: "primary"
											}
										/>
									)
							)
						) : (
								<div>{"No labels for this image"}</div>
							)}
					</Grid>
				)
		) : (
				<div />
			);
	}

	openAddLabelsDialog() {
		//TODO extract to a dialog, check forms
		return (
			<div>
				{this.state.shouldNotifyAddLabelsCancel ? (
					this.notifyAddLabelsCancel()
				) : (
						<Dialog
							shouldOpen={this.state.showAddLabelsDialog}
							onClose={() => {
								this.setState({
									curNewLabels: [""],
									showImageLabels: false,
								});
							}}
							dialogTitle="Please write the labels you want to add to the selected images"
							dialogContentText=""
							renderDialogContent={() => {
								return (
									<div>
										{this.renderImageLabelsInDialog()}
										{this.state.curNewLabels.map(
											(input, index) => {
												return this.addLabelsTextField(
													index
												);
											}
										)}
									</div>
								);
							}}
							onClickAffirmative={async () => {
								await addLabelsToImages(
									this.props.dbName,
									this.state.selectedImages.map((selectedImage: ISelectedImage) => selectedImage.mongoId),
									this.state.curNewLabels,
									client
								);
								this.setState({
									showAddLabelsDialog: false,
									curNewLabels: [""],
									showImageLabels: false,
								});
							}}
							affirmativeButtonText="Add"
							onClickNegative={() => {
								if (this.state.curNewLabels[0] !== "") {
									this.setState({
										shouldNotifyAddLabelsCancel: true,
									});
								} else {
									this.setState({
										showAddLabelsDialog: false,
										showImageLabels: false,
									});
								}
							}}
							negativeButtonText="Cancel"
						/>
					)}
			</div>
		);
	}

	async deleteFolders(folder: Folder) {
		folder.children.forEach(async (child: Folder) => {
			if (child.isChecked) {
				await deleteFolder(this.props.dbName, child.path, client);
			} else {
				await this.deleteFolders(child);
			}
		});
	}

	renderMenuMutations(): JSX.Element {
		const { classes }: any = this.props;
		const shouldShowDeleteFolders: boolean =
			this.state.bulkEdit && this.state.checkedFoldersExist;
		return (
			<div style={{ display: "flex" }}>
				<Tooltip
					TransitionComponent={Fade}
					TransitionProps={{ timeout: 200 }}
					title="Move to"
				>
					<IconButton
						color="secondary"
						aria-label="Move to"
						component="span"
						disabled={!this.state.selectionMode}
						onClick={() => {
							this.setState({ showMoveDialog: true });
						}}
					>
						<ArrowForward />
					</IconButton>
				</Tooltip>
				{this.openMoveDialog()}
				<Tooltip
					TransitionComponent={Fade}
					TransitionProps={{ timeout: 200 }}
					title="Delete Images"
				>
					<IconButton
						color="secondary"
						aria-label="Delete Images"
						component="span"
						style={{
							visibility: this.state.selectedImages.length
								? "visible"
								: "hidden",
						}}
						onClick={() =>
							this.setState({
								shouldOpenDeleteWarningDialog: true,
							})
						}
					>
						<DeleteForever />
					</IconButton>
				</Tooltip>
				<Tooltip
					TransitionComponent={Fade}
					TransitionProps={{ timeout: 200 }}
					title="Delete Folders"
				>
					<IconButton
						color="secondary"
						aria-label="Delete Images"
						component="span"
						style={{
							visibility: shouldShowDeleteFolders
								? "visible"
								: "hidden",
						}}
						onClick={async () => {
							await this.deleteFolders(this.state.rootFolder);
							await this.setState(
								{ curFolder: this.state.rootFolder },
								async () => {
									await this.refreshPage();
								}
							);
						}}
					>
						<DeleteSweep />
					</IconButton>
				</Tooltip>
				<Tooltip
					TransitionComponent={Fade}
					TransitionProps={{ timeout: 200 }}
					title="Add Labels"
				>
					<IconButton
						color="secondary"
						aria-label="Add Labels"
						component="span"
						disabled={!this.state.selectionMode}
						onClick={() => {
							this.setState({ showAddLabelsDialog: true });
						}}
					>
						<NoteAdd />
					</IconButton>
				</Tooltip>
				{this.openAddLabelsDialog()}
				<Tooltip
					TransitionComponent={Fade}
					TransitionProps={{ timeout: 200 }}
					title="Rename Folder"
				>
					<IconButton
						color="secondary"
						aria-label="Rename Folder"
						component="span"
						onClick={() => {
							if (
								this.state.curFolder !== this.state.rootFolder
							) {
								this.setState({ displayRenameFolder: true });
							}
						}}
					>
						<Create />
					</IconButton>
				</Tooltip>
				{this.renameFolderDialog()}
			</div>
		);
	}

	async refreshPage() {
		await this.fetchImages(this.state.selectedPage);
		if (this.state.images.length === 0) {
			this.setState(
				{
					curFolder:
						this.state.curFolder.parent || this.state.rootFolder,
				},
				async () => {
					await this.fetchImages(1);
				}
			);
		}
		this.setState({ refetchFolders: true });
	}

	handleMSLToggleChange() {
		this.setState({ mslToggle: !this.state.mslToggle }, () => {
			this.context.updateMslToggle(this.state.mslToggle)
			this.fetchImages(1)
		})
	}

	handleValidFacesToggleChange() {
		this.setState({ validFacesToggle: !this.state.validFacesToggle }, () => {
			this.context.updateValidFacesToggle(this.state.validFacesToggle)
			this.fetchImages(1)
		})
	}

	setDataloopProject(event: any) {
		console.log(event.target.value)
		this.setState({ dataloopProject: event.target.value })
	}

	sendToDataloopModal() {
		const { classes, t }: any = this.props;
		return (
			<Modal
				className={classes.dataloopModal}
				open={this.state.openDataloopModal}
				onClose={() => { this.setState({ openDataloopModal: false }) }}
				aria-labelledby="simple-modal-title"
				aria-describedby="simple-modal-description"
			>
				{
					<div className={classes.dataloopModalBody}>
						<TextField
							label="Which project should these images be uploaded to?"
							fullWidth
							onKeyUp={this.setDataloopProject.bind(
								this
							)}
						>
							{this.state.dataloopProject}
						</TextField>
						<Button
							variant="contained"
							color="primary"
							onClick={this.sendImageToDataloop}
							className={classes.dataloopButton}
							style={{ textTransform: "none" }}
						>
							Send
						</Button>
					</div>
				}
			</Modal>
		)
	}

	sendImageToDataloop() {
	}

	renderMenu() {
		const { classes, t }: any = this.props;
		const dbNameDisplay: string = this.props.dbName.toLowerCase();
		const allowMutations: boolean = this.props.dbName !== DBName.VDR;
		return (
			<AppContext.Consumer>
				{(context) => {
					return (
						<div
							className={classes.root}
							style={{
								justifyContent: "flex-start",
								alignItems: "center",
								height: "4rem",
								width: "100%",
							}}
						>
							<IconButton
								aria-label="openDrawer"
								onClick={() => this.handleDrawerOpen()}
								style={{
									visibility: this.state.shouldOpenDrawer
										? "hidden"
										: "visible",
								}}
							>
								<DoubleArrow />
							</IconButton>
							<SearchForm
								parentSearchTextCallback={(
									newText: string | null
								) => {
									this.setState(
										{ selectedText: newText },
										async () => {
											context.updateSelectedText(
												newText || ""
											);
											this.debouncedFetchImages(1);
										}
									);
								}}
								apparentText={this.state.selectedText}
							/>
							<Tooltip
								TransitionComponent={Fade}
								TransitionProps={{ timeout: 200 }}
								title={t("selectAll")}
							>
								<IconButton
									color="secondary"
									aria-label="Select All"
									component="span"
									onClick={() => {
										const imageSelection: ISelectedImage[] = [];
										this.state.images.map((image) => {
											if (
												this.state.selectedImages.some(selectedImage => selectedImage.mongoId === image.mongoId)
											) {
											} else {
												const { mongoId, mediumUrl, visualHash } = image
												imageSelection.push(
													{ mongoId, mediumUrl, visualHash }
												);
											}
										});
										const newSelectedImages =
											this.state.selectedImages.concat(
												imageSelection
											);
										this.setState(
											{
												selectedImages:
													newSelectedImages,
											},
											() => {
												this.handleSelectionMode();
											}
										);
									}}
								>
									<SelectAll />
								</IconButton>
							</Tooltip>
							<Tooltip
								TransitionComponent={Fade}
								TransitionProps={{ timeout: 200 }}
								title={t("unselectAll")}
							>
								<IconButton
									color="secondary"
									aria-label="Unselect All"
									component="span"
									onClick={() => {
										this.setState(
											{
												selectedImages: [],
												selectionMode: false,
											},
											() => {
												this.handleSelectionMode();
											}
										);
									}}
								>
									<Typography
										style={{
											position: "absolute",
											top: "5px",
											left: "18px",
										}}
										variant="h4"
									>
										/
									</Typography>
									<SelectAll />
								</IconButton>
							</Tooltip>
							{allowMutations && this.renderMenuMutations()}
							<Tooltip
								TransitionComponent={Fade}
								TransitionProps={{ timeout: 200 }}
								title="Refresh"
							>
								<IconButton
									color="secondary"
									aria-label="Refresh"
									component="span"
								>
									<RefreshIcon
										onClick={() => {
											this.refreshPage();
										}}
									/>
								</IconButton>
							</Tooltip>
							{this.props.dbName !== DBName.DATASETS && (
								<Tooltip
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 200 }}
									title="Upload Image"
								>
									<IconButton
										color="secondary"
										aria-label="Upload Image"
										component="span"
										onClick={() => {
											this.setState({
												displayUploadForm: true,
											});
										}}
									>
										<Publish />
									</IconButton>
								</Tooltip>
							)}
							{this.state.selectedImages.length > 0 &&
								<div>
									<Tooltip
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 200 }}
										title="Download Selected Images"
									>
										<IconButton
											color="secondary"
											aria-label="Download Selected Images"
											component="span"
											onClick={() => {
												this.state.selectedImages.map(async (selectedImage: ISelectedImage) => {
													await downloadImage(selectedImage.mediumUrl, `${selectedImage.visualHash}.jpg`);
													toast.dark(
														<Typography>
															Image was <strong>downloaded</strong>
														</Typography>,
														{
															position: "bottom-center",
															autoClose: 3000,
															hideProgressBar: true,
															closeOnClick: true,
															pauseOnHover: false,
															draggable: false,
															progress: undefined,
														}
													);
												})
											}}
										>
											<GetApp />
										</IconButton>
									</Tooltip>
									{/* <Tooltip
										TransitionComponent={Fade}
										TransitionProps={{ timeout: 200 }}
										title="Send Selected Images to Dataloop"
									>
										<IconButton
											color="secondary"
											aria-label="Send Selected Images to Dataloop"
											component="span"
											onClick={() => {
												this.setState({ openDataloopModal: true })
											}}
										>
											<Send />
										</IconButton>
									</Tooltip> */}
								</div>
							}
							{this.state.displayUploadForm && (
								<UploadForm
									parentCallback={() => {
										this.setState({
											displayUploadForm: false,
										});
									}}
									dbName={this.props.dbName}
								/>
							)}
							<FormGroup row style={{ marginLeft: "auto" }}>
								<FormControlLabel
									control={<Switch checked={this.state.mslToggle} onChange={this.handleMSLToggleChange.bind(this)} />}
									label="MSL"
								/>
								<FormControlLabel
									control={<Switch checked={this.state.validFacesToggle} onChange={this.handleValidFacesToggleChange.bind(this)} />}
									label="Valid Faces"
								/>
							</FormGroup>
							{!this.state.validFacesToggle && !this.state.mslToggle &&
								<Tooltip
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 200 }}
									title="Activate toggles"
								>
									<IconButton
										color="secondary"
										aria-label="Activate toggles"
										component="span"
										onClick={() => {
											this.setState({
												validFacesToggle: !this.state.validFacesToggle,
												mslToggle: !this.state.validFacesToggle
											}, () => {
												this.fetchImages(1)
											});
										}}
									>
										<DoneAll />
									</IconButton>
								</Tooltip>
							}
							{this.state.validFacesToggle && this.state.mslToggle &&
								<Tooltip
									TransitionComponent={Fade}
									TransitionProps={{ timeout: 200 }}
									title="Deactivate toggles"
								>
									<IconButton
										color="secondary"
										aria-label="Deactivate toggles"
										component="span"
										onClick={() => {
											this.setState({
												validFacesToggle: !this.state.validFacesToggle,
												mslToggle: !this.state.validFacesToggle
											}, () => {
												this.fetchImages(1)
											});
										}}
									>
										<HighlightOff />
									</IconButton>
								</Tooltip>
							}
							<IconButton
								aria-label="settings"
								style={{ marginLeft: "auto" }}
								onClick={this.handleUpdateGridSettingsOpen}
							>
								<Settings color="secondary" />
							</IconButton>
							{this.updateGridSettings()}
						</div>
					);
				}}
			</AppContext.Consumer>
		);
	}
}

const drawerWidth = 240;
const styles = (theme: Theme) =>
	createStyles({
		dataloopModal: {

		},
		dataloopModalBody: {
			top: "50%",
			left: "50%",
			transform: "translate(-50%, -50%)",
			width: "500px",
			height: "500px",
			position: "absolute",
			padding: theme.spacing(2),
			backgroundColor: "white"
		},
		hoverParent: {
			"&:hover": {
				"& $showOnHover": {
					visibility: "visible",
				},
			},
		},
		showOnHover: {
			visibility: "hidden",
		},
		block: {
			display: "block",
		},
		root: {
			display: "flex",
			alignItems: "center",
		},
		drawerRoot: {
			height: "890px",
		},
		toolbar: {
			paddingRight: 24, // keep right padding when drawer closed
			justifyContent: "flex-end",
		},
		logo: {
			paddingTop: theme.spacing(2),
			cursor: "pointer",
		},
		toolbarIcon: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			padding: "0 8px",
			...theme.mixins.toolbar,
		},
		menueIcon: {},
		appBar: {
			color: theme.palette.background.paper,
			background: theme.palette.background.paper,
			top: "auto",
			// bottom: 0,
			width: "fit-content",
			position: "relative",
		},
		appBarShift: {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(["width", "margin"], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		menuButton: {
			marginRight: 36,
		},
		menuButtonHidden: {
			display: "none",
		},
		title: {
			flexGrow: 1,
		},
		gridListRoot: {
			alignItems: "center",
			justifyContent: "center",
			display: "flex",
			flexWrap: "wrap",
			overflow: "scroll",
			backgroundColor: theme.palette.background.paper,
		},
		gridList: {
			width: "100%",
			height: "100%",
		},
		drawerPaper: {
			background: theme.palette.background.default,
			position: "relative",
			whiteSpace: "nowrap",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerPaperClose: {
			overflowX: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: "0",
			maxWidth: "0",
			[theme.breakpoints.up("sm")]: {
				width: "0",
			},
		},
		appBarSpacer: theme.mixins.toolbar,
		content: {
			flexGrow: 1,
			//height: '100vh',
			overflow: "auto",
		},
		container: {
			// paddingTop: theme.spacing(4),
			padding: "0 20px 16px 20px",
			// width: '97%',
		},
		paper: {
			padding: theme.spacing(2),
			display: "flex",
			overflow: "auto",
			flexDirection: "column",
		},
		fixedHeight: {
			height: 240,
		},
		searchContainer: {
			// paddingTop: theme.spacing(2),
			width: "100%",
			[theme.breakpoints.up("sm")]: {
				width: "auto",
			},
		},
		search: {
			position: "relative",
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.white, 0.15),
			"&:hover": {
				backgroundColor: fade(theme.palette.common.white, 0.25),
			},
			marginRight: theme.spacing(2),
			marginLeft: 0,
		},
		searchIcon: {
			width: theme.spacing(7),
			height: "100%",
			position: "absolute",
			pointerEvents: "none",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		inputRoot: {
			color: "inherit",
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 7),
			transition: theme.transitions.create("width"),
			width: "100%",
			[theme.breakpoints.up("md")]: {
				width: 200,
			},
		},
		chipsContainer: {
			display: "flex",
			justifyContent: "center",
			flexWrap: "wrap",
			marginTop: "5px",
			// '& > *': {
			//     margin: theme.spacing(0.5),
			// }
		},
		button: {
			textTransform: "none",
			fontWeight: "bold",
			margin: "0 1rem",
		},
		appBarCstm: {
			display: "none",
		},
		drawerPaperCstm: {
			display: "none",
			borderRadius: 3,
		},
		appBarSpacerCstm: {
			minHeight: "initial",
		},
		sitelogoCstm: {
			position: "absolute",
			top: 2,
			left: 26,
			zIndex: 999999,
		},
		activeImgCls: {
			border: "2px solid white",
			cursor: "pointer",
			// borderRadius: "6px"
		},
		activeImgClsRmv: {
			border: "2px solid transparent",
			cursor: "pointer",
		},
		root2: {
			boxSizing: "border-box",
			// flexGrow: 1,
			flexShrink: 0,
			listStyle: "none",
			margin: "1px",
		},
		/* Styles applied to the `div` element that wraps the children. */
		tile: {
			position: "relative",
			display: "block", // In case it's not rendered with a div.
		},
		/* Styles applied to an `img` element child, if needed to ensure it covers the tile. */
		imgFullHeight: {
			height: "100%",
			transform: "translateX(-50%)",
			position: "relative",
			left: "50%",
		},
		/* Styles applied to an `img` element child, if needed to ensure it covers the tile. */
		imgFullWidth: {
			width: "100%",
			position: "relative",
			transform: "none",
			// top: "0",
		},
		titleWrap: {
			overflow: "hidden",
			marginLeft: "5px",
			marginBottom: "5px",
			alignSelf: "flex-end",
		},
		tileBarRootTop: {
			left: "0",
			right: "0",
			height: "24px",
			display: "flex",
			position: "absolute",
			backgroundColor:
				"transparent" /* For browsers that do not support gradients */,
			backgroundImage: "linear-gradient(#282828, transparent)",
			alignItems: "center",
		},
		tileBarRootBottom: {
			left: "0",
			right: "0",
			height: "24px",
			display: "flex",
			position: "absolute",
			backgroundColor:
				"transparent" /* For browsers that do not support gradients */,
			backgroundImage: "linear-gradient(transparent, #282828)",
			alignItems: "center",
		},
		dragger: {
			width: "5px",
			cursor: "ew-resize",
			padding: "4px 0 0",
			borderTop: "1px solid #ddd",
			position: "absolute",
			top: 0,
			right: 0,
			bottom: 0,
			zIndex: 100,
			backgroundColor: "#f4f7f9",
		},
		imgSize: {
			objectFit: "contain",
		},
		dataloopButton: {
			marginTop: theme.spacing(3),
			marginLeft: theme.spacing(1),
			textTransform: "none",
		}
	});

export default withTranslation()(withStyles(styles)(withRouter(ImageViewer)));
