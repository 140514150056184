import React from "react";
import { IconButton } from "@material-ui/core";
import PreviousIcon from "../../assets/icons/PreviousIcon";

const CustomPrevArrow = (props: any) => {
	const { onClick, className } = props;
	let hide = className.split(" ").includes("slick-disabled");
	return hide ? null : (
		<IconButton
			onClick={onClick}
			style={{
				position: "absolute",
				top: "50%",
				left: "-25px",
				transform: "translate(0, -50%)",
				cursor: "pointer",
				border: "none",
				outline: "none",
				height: "30px",
				width: "30px",
			}}
		>
			<PreviousIcon />
		</IconButton>
	);
};

export default CustomPrevArrow;
