import { useEffect, useRef, useState, useContext } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { buildImageUrl } from "../../Config/Env";
import useLiveFaces from "../../Components/Tools/useLiveFaces";
import useLocalizer from "../../Components/Tools/useLocalizer";
import usePersonalizer from "../../Components/Tools/usePersonalizer";
import { GET_RESIZED_IMAGE, IMAGE_DETAILS } from "../../GraphQL/queries";
import {
	ImageEditorData,
	ImageEditorDataVariables,
	ImageEditorData_metaData_settings,
	ImageEditorData_vdrObject_vdrAwsFaceDetection_faces,
	ImageEditorData_vdrObject_vdrEthnicityValidFaces_faces,
} from "../../GraphQL/types/imageEditorData";
import { useUndoRedu } from "../../hooks/useUndoRedo";
import {
	ThumbnailData,
	VideoInfoParameters,
} from "../../types/graphql-global-types";
import { AddSemanticCombinationVariables } from "../../GraphQL/types/addSemanticCombination";
import {
	BriaObjectType,
	SelectedPointInterface,
	ToolsBackGroundMode,
} from "../../Components/Tools/utilities";
import useGrafanaMetric from "../../hooks/useGrafanaMetrics";
import useLiveBackgrounds from "../../Components/Tools/useLiveBackgrounds";
import { camelToSnakeCase } from "../../Helpers/string";
import { useExtremeExpression } from "../../Components/Tools/useExtremeExpressions";
import { ToolConfigTabsTypes, ToolsSlider } from "../../Models/ToolsConfig";
import { useToolConfig } from "../../hooks/useToolConfig";
import Context from "../../Context/Context";
import {
	GetResizedImage,
	GetResizedImageVariables,
} from "../../GraphQL/types/GetResizedImage";
import useAskAlan from "../../hooks/useAskAlan";
import { getAuth } from "firebase/auth";
import Analytics, { ANALYTICS_EVENTS } from "../../Models/Analytics";
import { ToolsViewerMode } from "../../Components/Tools/utilities";

interface ParamTypes {
	visual_hash: string;
}

export const useImageEditor = () => {
	const { visual_hash } = useParams<ParamTypes>();
	const metrics = useGrafanaMetric(visual_hash);
	const imageRef = useRef<HTMLImageElement>(null);
	const { isTesting, user } = useContext(Context);
	const askAlanScoreRef = useRef<string[]>([]);

	const { canUndo, canRedo, set, reset, redo, undo } = useUndoRedu();
	const [selectedPoint, setSelectedPoint] =
		useState<SelectedPointInterface | null>(null);

	const [fullscreenMode, setFullscreenMode] = useState<boolean>(false);

	const [openVideoAlert, setOpenVideoAlert] = useState<boolean>(false);
	const [originalImage, setOriginalImage] = useState<string>(
		buildImageUrl(visual_hash)
	);
	const [selectedImageUrl, setSelectedImageUrlState] = useState<string>("");
	const [imageUrlWithNoBg, setimageUrlWithNoBg] = useState<string>("");
	const [backgroundString, setBackgroundString] =
		useState<ToolsBackGroundMode>(ToolsBackGroundMode.withBg);
	const [
		selectedImageUrlWithoutExpression,
		setSelectedImageUrlWithoutExpression,
	] = useState<string>("");
	const [selectedVideo, setSelectedVideo] =
		useState<string | null | undefined>(null);

	const setSelectedImageUrl = (url: string, imageNoBgUrl: string) => {
		setSelectedImageUrlState(url);
		setSelectedImageUrlWithoutExpression(url);
		setimageUrlWithNoBg(imageNoBgUrl);
	};

	const [layersURL, setLayersURL] = useState<string | null>(null);

	const [pipelineSettings, setPipelineSettings] = useState<
		ImageEditorData_metaData_settings[]
	>([]);

	const [scaleDownFactor, setScaleDownFactor] = useState<number>(1);

	const [openExpressionsAlert, setOpenExpressionsAlert] =
		useState<boolean>(false);

	const [resetExpressionsAlert, setResetExpressionsAlert] =
		useState<boolean>(false);

	const [numberOfChanges, setNumberOfChanges] = useState<number>(0);
	const { setAskAlanScoreMutation } = useAskAlan();

	const {
		selectedEthnicity,
		selectedEthnicityValue,
		changeEthnicityMutationLoading,
		changeEthnicityHandler,
		resetEthnicity,
		changeEthnicityMutation,
	} = useLocalizer();

	const [facesConfigurations, setFacesConfigurations] = useState<{
		[key: string]: {
			ethnicity: string;
			ethnicityValue: number;
			selectedExpressionName: string;
			selectedExpressionValue: number;
			semantics: { [key: string]: any };
		};
	}>({});

	const {
		loading: imageDetailsLoading,
		data: imageDetails,
		error: imageDetailsError,
		refetch,
	} = useQuery<ImageEditorData, ImageEditorDataVariables>(IMAGE_DETAILS, {
		variables: {
			visualHash: visual_hash,
		},
	});

	const { data: resizedImage } = useQuery<
		GetResizedImage,
		GetResizedImageVariables
	>(GET_RESIZED_IMAGE, {
		variables: {
			visualHash: visual_hash,
		},
	});

	const facesData: ImageEditorData_vdrObject_vdrAwsFaceDetection_faces[] =
		imageDetails?.vdrObject?.vdrAwsFaceDetection?.faces ?? [];

	const {
		getAskAlanScores,
		toolsLoading,
		toolsConfigTabs,
		toolsConfigSliders,
		toolsConfigThumbnails,
		toolsConfigSuggestions,
	} = useToolConfig(
		isTesting,
		selectedPoint?.objectType,
		facesData[selectedPoint?.index ?? 0],
		selectedPoint?.index ?? 0
	);

	const {
		createExtremeExpressionMutation,
		setExtremeExpression,
		resetExtremeExpression: resetExtremeExpressionHandler,
		selectedExpressionName,
		selectedExpressionValue,
		createExtremeExpressionMutationLoading,
		createExtremeExpressionMutationError,
		getValidExpressions,
		validExpressions,
	} = useExtremeExpression();

	const resetExtremeExpression = () => {
		resetExtremeExpressionHandler();
		toolsConfigSliders.forEach((slider) => {
			if (
				slider.level1Id === ToolConfigTabsTypes.Emotions &&
				slider.level2Id === ToolConfigTabsTypes.Expression &&
				personalizerValues[slider.id] !== slider.defaultInitialValue
			) {
				changePersonalizerSlider(slider.id, slider.defaultInitialValue);
			}
		});
	};

	useEffect(() => {
		if (selectedPoint) {
			getValidExpressions({
				variables: {
					inputImage: selectedImageUrl,
					visualHash: visual_hash,
					ethnicity: selectedEthnicity,
					rects: [selectedPoint?.rect],
				},
			});
		}
	}, [selectedPoint]);

	const context = useContext(Context);

	useEffect(() => {
		if (imageDetails) {
			setPipelineSettings(imageDetails.metaData?.settings ?? []);
		}
	}, [imageDetails]);

	useEffect(() => {
		if (resizedImage?.resizedImage) {
			setOriginalImage(resizedImage.resizedImage);
		}
	}, [resizedImage]);

	useEffect(() => {
		if ((imageRef?.current?.naturalHeight ?? 0) > 0) {
			setScaleDownFactor(
				(imageDetails?.vdrObject?.height ?? 1) /
					(imageRef?.current?.naturalHeight || 1)
			);
		}
	}, [imageRef?.current?.naturalHeight]);

	useEffect(() => {
		refetch();
	}, [visual_hash, refetch]);

	const validVDRFaces: ImageEditorData_vdrObject_vdrEthnicityValidFaces_faces[] =
		imageDetails?.vdrObject?.vdrEthnicityValidFaces?.faces ?? [];

	useEffect(() => {
		if (selectedPoint?.rect) {
			getAskAlanScores({
				ethnicity: selectedEthnicity,
				objectType: selectedPoint?.objectType,
				visualHash: visual_hash,
				rect: [selectedPoint?.rect],
			});
		}
	}, [
		getAskAlanScores,
		selectedEthnicity,
		selectedPoint?.objectType,
		selectedPoint?.rect,
		visual_hash,
	]);

	const currentSliders: ToolsSlider[] = [];

	const increaseNumberOfChanges = () => {
		setNumberOfChanges(numberOfChanges + 1);
	};

	useEffect(() => {
		localStorage.setItem("numberOfChanges", numberOfChanges.toString());
	}, [numberOfChanges]);

	const {
		personalizerValues,
		addObjectTypeSemanticsMutationLoading,
		addSemanticsMutationLoading,
		toDisplayThumbnails,
		setSemanticsValues,
		resetSemantics,
		changePersonalizerSlider,
		addSemanticsMutation,
		addSemanticCombinationMutation,
		addObjectTypeSemanticsMutation,
		addSemanticCombinationError,
		setPersonalizerInitialValues,
	} = usePersonalizer(toolsConfigSliders);

	const getPipelineVariables = () => {
		const currentSemantics = toolsConfigSliders
			.filter(
				(slider) =>
					slider.defaultInitialValue !==
						personalizerValues[slider.id] &&
					slider.level1Id === "personalizer"
			)
			.map(
				({
					maxLayer,
					maxName,
					maxValue,
					minLayer,
					minName,
					minValue,
					name,
					pca,
					reversed,
				}) => ({
					maxLayer,
					maxName,
					maxValue,
					minLayer,
					minName,
					minValue,
					name,
					pca,
					reversed,
					value: personalizerValues[name],
				})
			);

		if (selectedPoint?.rect) {
			return {
				ethnicity: selectedEthnicity,
				ethnicityValue: selectedEthnicityValue,
				inputImage: selectedImageUrl,
				pipelineSettings: pipelineSettings,
				rects: [selectedPoint?.rect],
				semantics: currentSemantics,
				uid: "38f7e0b0-195c-4140-8e35-6569f08b04c5",
				visualHash: visual_hash,
				objectType: selectedPoint.objectType,
			};
		}
		return {
			ethnicity: selectedEthnicity,
			ethnicityValue: selectedEthnicityValue,
			inputImage: selectedImageUrl,
			pipelineSettings: pipelineSettings,
			rects: [],
			semantics: currentSemantics,
			uid: "38f7e0b0-195c-4140-8e35-6569f08b04c5",
			visualHash: visual_hash,
			objectType: "",
		};
	};

	const onChangeEthnicity = async (ethnicity: string, value: number) => {
		const previousselectedEthnicity = selectedEthnicity;
		const previousselectedEthnicityValue = selectedEthnicityValue;
		const oldImageURL = selectedImageUrl;
		await changeEthnicityHandler(ethnicity, value);

		try {
			metrics.setStartTime(performance.now());
			const res = await changeEthnicityMutation({
				variables: {
					...getPipelineVariables(),
					ethnicity: ethnicity,
					ethnicityValue: value,
				},
			});
			setLayersURL(res?.data?.chnageEthnicity?.layersUrl ?? "");
			setSelectedImageUrl(
				res?.data?.chnageEthnicity?.newImage?.url ?? "",
				res?.data?.chnageEthnicity?.newImage?.imageNoBgUrl ?? ""
			);
			askAlanScoreRef.current = [
				getAuth().currentUser?.email ??
					user?.userName ??
					"Invalid Email",
				res?.data?.chnageEthnicity?.newImage?.url ?? "",
				`ethnicity_change_${ethnicity}`,
				visual_hash,
				value.toString(),
				`${selectedPoint?.index}`,
			];

			metrics.addNewMetric("change_ethnicity_api_time");

			set({
				undo: () => {
					changeEthnicityHandler(
						previousselectedEthnicity,
						previousselectedEthnicityValue
					);
					setSelectedImageUrl(oldImageURL, "");
				},
				redo: () => {
					changeEthnicityHandler(ethnicity, value);
					setSelectedImageUrl(
						res?.data?.chnageEthnicity?.newImage?.url ?? "",
						res?.data?.chnageEthnicity?.newImage?.imageNoBgUrl ?? ""
					);
				},
			});
		} catch (e) {
			changeEthnicityHandler(
				previousselectedEthnicity,
				previousselectedEthnicityValue
			);
		} finally {
			increaseNumberOfChanges();
			await Analytics.logToolsEvent(ANALYTICS_EVENTS.CHANGE_ETHNICITY, {
				source:
					context.preferences?.style ?? ToolsViewerMode.thumbnails,
				changeName: ethnicity,
				value,
			});
		}
	};

	const slidersMapper = ({
		maxLayer,
		maxName,
		maxValue,
		minLayer,
		minName,
		minValue,
		name,
		pca,
		reversed,
		value,
	}: ToolsSlider) => ({
		maxLayer,
		maxName,
		maxValue,
		minLayer,
		minName,
		minValue,
		name,
		pca,
		reversed,
		value,
	});

	const isHairChange = (text: string) => {
		return text.startsWith("dark_hair") || text.startsWith("light_hair");
	};

	const resetHairChanges = async (field: string) => {
		if (isHairChange(field)) {
			const newPersonalizerValues = JSON.parse(
				JSON.stringify(personalizerValues)
			);
			Object.keys(newPersonalizerValues).forEach((key) => {
				if (isHairChange(key)) {
					const slider = toolsConfigSliders.find(
						({ id }) => id === key
					);
					if (slider) {
						newPersonalizerValues[key] = slider.defaultInitialValue;
					}
				}
			});
			await setSemanticsValues(newPersonalizerValues);
		}
	};

	const onChangePersonalizer = async (field: string, value: number) => {
		const previousValue = personalizerValues[field];
		const oldImageURL = selectedImageUrl;

		try {
			await resetHairChanges(field);
			await changePersonalizerSlider(field, value);
			const pipelineVariables = getPipelineVariables();
			let semantic = pipelineVariables.semantics?.find(
				(slider) => slider.name === field
			);

			if (isHairChange(field)) {
				pipelineVariables.semantics =
					pipelineVariables.semantics.filter(
						({ name }) => !isHairChange(name)
					);
			}

			if (semantic) {
				semantic.value = value;
			} else {
				let fullSlider = toolsConfigSliders.find(
					(slider) => slider.id === field
				);

				if (fullSlider) {
					let subset = slidersMapper(fullSlider);

					pipelineVariables.semantics.push({
						...subset,
						value,
					});
				}
			}

			if (selectedPoint?.objectType === BriaObjectType.human) {
				const sliderConfig = toolsConfigSliders.find(
					(el) => el.name === field
				);
				const isExpression =
					sliderConfig &&
					sliderConfig.level1Id ===
						ToolConfigTabsTypes.Personalizer &&
					sliderConfig.level2Id === ToolConfigTabsTypes.Expression;

				isExpression && resetExtremeExpression();
				metrics.setStartTime(performance.now());
				const res = await addSemanticsMutation({
					variables: pipelineVariables,
				});
				setLayersURL(res?.data?.addSemantics?.layersUrl ?? "");

				if (isExpression) {
					setSelectedImageUrlState(
						res?.data?.addSemantics?.newImage?.url ?? ""
					);
				} else {
					setSelectedImageUrl(
						res?.data?.addSemantics?.newImage?.url ?? "",
						res?.data?.addSemantics?.newImage?.imageNoBgUrl ?? ""
					);
				}
				metrics.addNewMetric("add_semantics_api_time");
				askAlanScoreRef.current = [
					getAuth().currentUser?.email ??
						user?.userName ??
						"Invalid Email",
					res?.data?.addSemantics?.newImage?.url ?? "",
					pipelineVariables.semantics.map((el) => el.name).join("_"),
					visual_hash,
					value.toString(),
					`${selectedPoint?.index}`,
				];
				set({
					undo: () => {
						changePersonalizerSlider(field, previousValue);
						setSelectedImageUrl(oldImageURL, "");
					},
					redo: () => {
						changePersonalizerSlider(field, value);
						setSelectedImageUrl(
							res?.data?.addSemantics?.newImage?.url ?? "",
							res?.data?.addSemantics?.newImage?.imageNoBgUrl ??
								""
						);
					},
				});
			} else {
				pipelineVariables.rects = [];
				const res = await addObjectTypeSemanticsMutation({
					variables: pipelineVariables,
				});
				setLayersURL(res?.data?.objectTypeSemantics?.layersUrl ?? "");
				setSelectedImageUrl(
					res?.data?.objectTypeSemantics?.newImage?.url ?? "",
					""
				);
				set({
					undo: () => {
						changePersonalizerSlider(field, previousValue);
						setSelectedImageUrl(oldImageURL, "");
					},
					redo: () => {
						changePersonalizerSlider(field, previousValue);
						setSelectedImageUrl(
							res?.data?.objectTypeSemantics?.newImage?.url ?? "",
							""
						);
					},
				});
			}
		} catch (e) {
			console.log(e);
			changePersonalizerSlider(field, previousValue);
		} finally {
			increaseNumberOfChanges();
			await Analytics.logEvent(ANALYTICS_EVENTS.CHANGE_APPEARANCE, {
				source: context.preferences?.style,
				personalizer: field,
				value,
			});
		}
	};

	const onThumbnailClick = async ({
		semanticsList,
		ethnicity = selectedEthnicity,
		ethnicityValue = selectedEthnicityValue,
	}: {
		semanticsList: ToolsSlider[];
		ethnicity: string;
		ethnicityValue: number;
	}) => {
		const previousselectedEthnicity = selectedEthnicity;
		const previousselectedEthnicityValue = selectedEthnicityValue;
		const previousSematicsValues = JSON.parse(
			JSON.stringify(personalizerValues)
		);
		const oldImageURL = selectedImageUrl;

		const currentSemantics = semanticsList.map((el) => slidersMapper(el));
		const pipelineVariables = getPipelineVariables();
		pipelineVariables.ethnicity = ethnicity;
		pipelineVariables.ethnicityValue = ethnicityValue;
		pipelineVariables.semantics = currentSemantics;

		const newPersonaolizerValues: { [key: string]: number } = {};
		Object.keys(personalizerValues).forEach((key) => {
			newPersonaolizerValues[key] =
				currentSemantics.find((el) => el.name === key)?.value ??
				toolsConfigSliders.find((el) => el.name === key)
					?.defaultInitialValue ??
				personalizerValues[key];
		});
		await changeEthnicityHandler(ethnicity, ethnicityValue);
		await setSemanticsValues(newPersonaolizerValues);
		metrics.setStartTime(performance.now());
		const res = await addSemanticsMutation({
			variables: pipelineVariables,
		});
		metrics.addNewMetric("add_semantics_api_time");
		setLayersURL(res?.data?.addSemantics?.layersUrl ?? "");
		setSelectedImageUrl(
			res?.data?.addSemantics?.newImage?.url ?? "",
			res?.data?.addSemantics?.newImage?.imageNoBgUrl ?? ""
		);

		set({
			undo: () => {
				changeEthnicityHandler(
					previousselectedEthnicity,
					previousselectedEthnicityValue
				);
				setSemanticsValues(newPersonaolizerValues);
				setSelectedImageUrl(oldImageURL, "");
			},
			redo: () => {
				changeEthnicityHandler(ethnicity, ethnicityValue);
				setSemanticsValues(previousSematicsValues);
				setSelectedImageUrl(
					res?.data?.addSemantics?.newImage?.url ?? "",
					res?.data?.addSemantics?.newImage?.imageNoBgUrl ?? ""
				);
			},
		});
	};

	const createExtremeExpression = async (emotion: string, value: number) => {
		const previousSelectedExpressionName = selectedExpressionName;
		const previousSelectedExpressionValue = selectedExpressionValue;
		const oldImageURL = selectedImageUrl;
		setExtremeExpression(emotion, value);

		await changePersonalizerSlider(
			previousSelectedExpressionName,
			previousSelectedExpressionName === emotion
				? previousSelectedExpressionValue
				: 0
		);
		await changePersonalizerSlider(emotion, value);
		const pipelineVariable = await getPipelineVariables();
		pipelineVariable.semantics = pipelineVariable.semantics.filter(
			(sematic) => {
				const slider = toolsConfigSliders.find(
					(el) => el.name === sematic.name
				);
				if (
					!!slider &&
					slider.level1Id === ToolConfigTabsTypes.Personalizer &&
					slider.level2Id === ToolConfigTabsTypes.Expression
				) {
					changePersonalizerSlider(
						slider.id,
						slider.defaultInitialValue
					);
					sematic.value = slider.defaultInitialValue;
					return false;
				}
				return true;
			}
		);

		try {
			const res = await createExtremeExpressionMutation({
				variables: {
					...pipelineVariable,
					inputImage: selectedImageUrlWithoutExpression,
					sliderScale: value,
					emotion: emotion,
				},
			});
			setLayersURL(res?.data?.addExtremeExpression?.layersUrl ?? "");
			setSelectedImageUrlState(
				res?.data?.addExtremeExpression?.newImage?.url ?? ""
			);
			// setSelectedImageUrl(
			// 	res?.data?.addExtremeExpression?.layersUrl ?? "",
			// 	res?.data?.addExtremeExpression?.newImage?.imageNoBgUrl ?? ""
			// );
			setResetExpressionsAlert(false);
			askAlanScoreRef.current = [
				getAuth().currentUser?.email ??
					user?.userName ??
					"Invalid Email",
				res.data?.addExtremeExpression?.newImage?.url ?? "",
				`expression_change_${emotion}`,
				visual_hash,
				value.toString(),
				`${selectedPoint?.index}`,
			];
			set({
				undo: () => {
					changePersonalizerSlider(
						previousSelectedExpressionName,
						previousSelectedExpressionValue
					);
					changePersonalizerSlider(
						emotion,
						previousSelectedExpressionName === emotion
							? previousSelectedExpressionValue
							: 0
					);
					setExtremeExpression(
						previousSelectedExpressionName,
						previousSelectedExpressionValue
					);
					setSelectedImageUrlState(oldImageURL);
				},
				redo: () => {
					setExtremeExpression(emotion, value);
					changePersonalizerSlider(emotion, value);
					changePersonalizerSlider(previousSelectedExpressionName, 0);
					setSelectedImageUrlState(
						res.data?.addExtremeExpression?.newImage?.url ?? ""
					);
				},
			});
		} catch (e) {
			changePersonalizerSlider(
				previousSelectedExpressionName,
				previousSelectedExpressionValue
			);
			changePersonalizerSlider(
				emotion,
				previousSelectedExpressionName === emotion
					? previousSelectedExpressionValue
					: 0
			);
			setExtremeExpression(
				previousSelectedExpressionName,
				previousSelectedExpressionValue
			);
		} finally {
			await Analytics.logEvent(ANALYTICS_EVENTS.CHANGE_EXPRESSION, {
				source: context.preferences?.style,
				expression: emotion,
				value,
			});
		}
	};

	const resetLastExtremeExpression = () => {
		setSelectedImageUrlState(selectedImageUrlWithoutExpression);
		resetExtremeExpression();
		changePersonalizerSlider(selectedExpressionName, 0);
	};

	async function handleResetActions() {
		setPersonalizerInitialValues(toolsConfigSliders);
		reset();
		resetEthnicity();
		resetSemantics();
		resetExtremeExpression();
		setFacesConfigurations({});
		setSelectedImageUrl(originalImage, "");
		setLayersURL(null);
		await Analytics.logEvent(ANALYTICS_EVENTS.RESET);
		setimageUrlWithNoBg("");
	}

	const onAddSemanticCombination = async (
		thumbnail: ThumbnailData,
		original: boolean = false,
		controller: any
	): Promise<string> => {
		const currentSemantics = !original
			? toolsConfigSliders
					.filter(
						(slider) =>
							slider.defaultInitialValue !==
								personalizerValues[slider.id] &&
							personalizerValues[slider.id] !== undefined &&
							slider.level1Id === ToolConfigTabsTypes.Personalizer
					)
					.filter(
						(slider) =>
							thumbnail.semantics.length === 0 ||
							thumbnail.semantics.find(
								({ name }) => name !== slider.id
							)
					)
					.map((slider) => ({
						...slider,
						value: personalizerValues[slider.id],
					}))
			: [];

		const currentThumbnails: ThumbnailData = {
			ethnicity: thumbnail.ethnicity,
			ethnicityValue: thumbnail.ethnicityValue,
			thumbnailIndex: 0,
			semantics: [...currentSemantics, ...thumbnail.semantics]
				.map((el) => slidersMapper(el as ToolsSlider))
				.filter((el) => el.value !== undefined || el.value !== null),
		};

		const pipelineSettings: AddSemanticCombinationVariables = {
			...getPipelineVariables(),
			scaleDownFactor: scaleDownFactor,
			thumbnails: [currentThumbnails],
		};

		try {
			const res = await addSemanticCombinationMutation({
				variables: pipelineSettings,
				...controller,
			});
			let entries: string[] =
				Object.values(
					JSON.parse(
						res?.data?.addSemanticCombination?.thumbnailImages ??
							"{}"
					)
				) || [];
			let arr: { url: string }[] = entries.map((e) => {
				return {
					url: e,
				};
			});

			return arr[0].url;
		} catch (err) {
			console.log(err);
		}

		return "";
	};

	const handleChangePipelineSettings = (index: number, value: boolean) => {
		let newSettingsList = [...pipelineSettings];
		newSettingsList[index].isOn = value;
		setPipelineSettings([...newSettingsList]);
	};

	const {
		liveFaces,
		loadingLiveFaces,
		createLiveFaceMutationLoading,
		createLiveFaceMutation,
	} = useLiveFaces(visual_hash, selectedPoint);

	const {
		liveBackgroundsList,
		loadLiveBackgroung,
		createLiveBackgroundLoading,
		createLiveBackgroundMutation,
	} = useLiveBackgrounds({
		selectedPoint,
		visualHash: visual_hash,
		selectedImageUrl,
	});

	const onCreateLiveFace = async (videoInfo: VideoInfoParameters) => {
		delete videoInfo.errDataDict;
		try {
			const res = await createLiveFaceMutation({
				variables: {
					visualHash: visual_hash,
					videoInfo: videoInfo,
					face: selectedPoint!.rect,
					imageURL: selectedImageUrl || originalImage,
				},
			});
			setSelectedVideo(res.data?.createLiveliness?.videoUrl);
		} catch (e) {
			console.error(e);
		}
	};

	const onCreateLiveBackground = async ({
		speed,
		direction,
	}: {
		speed: number;
		direction: string;
	}) => {
		try {
			const res = await createLiveBackgroundMutation({
				variables: {
					vhash: visual_hash,
					imgUrl: selectedImageUrl || originalImage,
					fileNameInBucket: `liveliness/live_bg_res/${visual_hash}.json`,
					config: {
						speed: speed || 1,
						direction: camelToSnakeCase(direction),
					},
				},
			});
			setSelectedVideo(res.data?.createLiveBackground?.videoUrl);
		} catch (e) {
			console.error(e);
		}
	};

	const closeVideo = () => {
		setSelectedVideo(null);
	};

	const onFullscreenChange = (value: boolean) => {
		setFullscreenMode(value);
	};

	useEffect(() => {
		if (
			selectedPoint?.id &&
			facesConfigurations[selectedPoint.id] === undefined
		) {
			setPersonalizerInitialValues(toolsConfigSliders);
		}
	}, [toolsConfigSliders, selectedPoint?.id]);

	const onBackgroundStringChange = (
		backgroundString: ToolsBackGroundMode
	) => {
		setBackgroundString(backgroundString);
	};

	const onSetSelectedPoint = (point: SelectedPointInterface | null) => {
		if (selectedPoint?.id !== point?.id) {
			setSelectedImageUrlWithoutExpression(selectedImageUrl);
			setSelectedPoint(point);
			if (selectedPoint === null || point === null) return;
			//save old configuration
			const faceConfig = {
				...facesConfigurations,
				[selectedPoint.id]: {
					ethnicity: selectedEthnicity,
					ethnicityValue: selectedEthnicityValue,
					semantics: personalizerValues,
					selectedExpressionName: selectedExpressionName,
					selectedExpressionValue: selectedExpressionValue,
				},
			};
			if (facesConfigurations[point.id]) {
				changeEthnicityHandler(
					facesConfigurations[point.id].ethnicity,
					facesConfigurations[point.id].ethnicityValue
				);
				setExtremeExpression(
					facesConfigurations[point.id].selectedExpressionName,
					facesConfigurations[point.id].selectedExpressionValue
				);
				setSemanticsValues(facesConfigurations[point.id].semantics);
			} else {
				resetEthnicity();
				resetSemantics();
			}

			setFacesConfigurations(faceConfig);
			setResetExpressionsAlert(true);
		}
	};

	async function onUndo() {
		await Analytics.logEvent(ANALYTICS_EVENTS.UNDO);
		undo();
	}

	async function onRedo() {
		await Analytics.logEvent(ANALYTICS_EVENTS.REDO);
		redo();
	}

	const setImageScore = (score: string) => {
		setAskAlanScoreMutation({
			variables: {
				list: [...askAlanScoreRef.current, score],
			},
		});
		askAlanScoreRef.current = [];
	};

	const loading = imageDetailsLoading || loadLiveBackgroung;
	const proccessing =
		changeEthnicityMutationLoading ||
		addSemanticsMutationLoading ||
		createLiveBackgroundLoading ||
		addObjectTypeSemanticsMutationLoading ||
		createLiveFaceMutationLoading ||
		createExtremeExpressionMutationLoading;

	const imageEditError = null;

	const facesList = validVDRFaces.map((el) => {
		const pointId = `human-${el.rect.height}-${el.rect.width}-${el.rect.top}-${el.rect.left}`;
		return {
			id: pointId,
			objectType: BriaObjectType.human,
			originalImageHieght: imageDetails?.vdrObject?.height,
			originalImageWidth: imageDetails?.vdrObject?.width,
			active: selectedPoint?.id === pointId,
			...el,
		};
	});

	//Live backgrounds
	const backgroundList =
		imageDetails?.vdrObject?.vdrPointsOfInterest?.points?.map((el) => {
			const id = `background-${el.x}-${el.y}`;
			return {
				id: id,
				objectType: BriaObjectType.background,
				originalImageHieght: imageDetails?.vdrObject?.height,
				originalImageWidth: imageDetails?.vdrObject?.width,
				active: selectedPoint?.id === id,
				rect: {
					left: el.x,
					top: el.y,
					height: 500,
					width: 500,
				},
			};
		}) ?? [];

	//TODO: Fix logic
	const carsList =
		imageDetails?.vdrObject?.vdrAwsLabelDetection?.labels?.some(
			({ name }) => name === "Car"
		)
			? [
					{
						id: "Car",
						objectType: BriaObjectType.car,
						originalImageHieght: imageDetails?.vdrObject?.height,
						originalImageWidth: imageDetails?.vdrObject?.width,
						active: selectedPoint?.id === "Car",
						rect: {
							left: 250,
							top: 350,
							height: 500,
							width: 500,
						},
					},
			  ]
			: [];
	const horseList =
		imageDetails?.vdrObject?.vdrAwsLabelDetection?.labels?.some(
			({ name }) => name === "Horse"
		)
			? [
					{
						id: "Horse",
						objectType: BriaObjectType.horse,
						originalImageHieght: imageDetails?.vdrObject?.height,
						originalImageWidth: imageDetails?.vdrObject?.width,
						active: selectedPoint?.id === "Horse",
						rect: {
							left: 200,
							top: 100,
							height: 50,
							width: 50,
						},
					},
			  ]
			: [];

	const churchList =
		imageDetails?.vdrObject?.vdrAwsLabelDetection?.labels?.some(
			({ name }) => name === "Church"
		)
			? [
					{
						id: "Church",
						objectType: BriaObjectType.church,
						originalImageHieght: imageDetails?.vdrObject?.height,
						originalImageWidth: imageDetails?.vdrObject?.width,
						active: selectedPoint?.id === "Church",
						rect: {
							left: 90,
							top: 150,
							height: 50,
							width: 50,
						},
					},
			  ]
			: [];

	const pointOfInterests = [
		...facesList,
		...backgroundList,
		...carsList,
		...horseList,
		...churchList,
	];

	useEffect(() => {
		if (
			pointOfInterests &&
			pointOfInterests.length !== 0 &&
			selectedPoint === null
		) {
			if (pointOfInterests.length >= 1) {
				setSelectedPoint({
					index: 0,
					rect: {
						x: pointOfInterests[0].rect.left,
						y: pointOfInterests[0].rect.top,
						w: pointOfInterests[0].rect.width,
						h: pointOfInterests[0].rect.height,
					},
					id: pointOfInterests[0].id,
					objectType: pointOfInterests[0].objectType,
				});
			}
		}
	}, [pointOfInterests]);

	return {
		imageRef,
		loading,
		proccessing,
		selectedVideo,
		setSelectedVideo,
		closeVideo,
		pointOfInterests,
		visual_hash,
		imageDetailsError,
		validVDRFaces,
		selectedImageUrl: selectedImageUrl || originalImage,
		originalImage: originalImage,
		selectedImageUrlNoBg: imageUrlWithNoBg,
		backgroundString,
		onBackgroundStringChange,
		imageDetails,
		imageEditError,
		selectedPoint,
		setSelectedPoint: onSetSelectedPoint,
		personalizerValues,
		onChangePersonalizer,
		onAddSemanticCombination,
		toDisplayThumbnails,
		onThumbnailClick,
		setSemanticsValues,

		layersURL,

		//Tools Configurations
		toolsLoading,
		toolsConfigTabs,
		toolsConfigSliders,
		toolsConfigThumbnails,
		toolsConfigSuggestions,
		currentTabs: [],

		toolConfigurations: [],
		currentSliders,

		// Undo/Redo
		canUndo,
		canRedo,
		onRedo,
		onUndo,

		// Ethnicicy
		selectedEthnicity,
		selectedEthnicityValue,
		onChangeEthnicity,
		handleResetActions,

		//Live Faces
		loadingLiveFaces,
		liveFaces,
		onCreateLiveFace,

		//live background
		liveBackgroundsList,
		onCreateLiveBackground,

		//Extreme Expressions
		createExtremeExpression,
		createExtremeExpressionMutationLoading,
		createExtremeExpressionMutationError,
		selectedExpressionValue,
		selectedExpressionName,
		resetExtremeExpression,
		resetLastExtremeExpression,
		validExpressions,

		openExpressionsAlert,
		setOpenExpressionsAlert,
		resetExpressionsAlert,

		//pipeline
		pipelineSettings,
		handleChangePipelineSettings,

		generatingImageMetric: metrics.generatingImageMetric,

		fullscreenMode,
		onFullscreenChange,

		openVideoAlert,
		setOpenVideoAlert,
		addSemanticCombinationError,

		preparingSemantics: Object.keys(personalizerValues).length === 0,
		setImageScore,
	};
};
