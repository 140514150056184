import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { CHANGE_ETHNICITY } from "../../GraphQL/mutations";
import {
	ChangeEthnicity,
	ChangeEthnicityVariables,
} from "../../GraphQL/types/ChangeEthnicity";

const useLocalizer = () => {
	const [
		changeEthnicityMutation,
		{ loading: changeEthnicityMutationLoading },
	] =
		useMutation<ChangeEthnicity, ChangeEthnicityVariables>(
			CHANGE_ETHNICITY
		);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			ethnicity: "original",
			ethnicityValue: 0,
		},
		validationSchema: Yup.object({
			ethnicity: Yup.string().nullable(),
		}),
		onSubmit: () => {
			// handleChangeEthnicity();
		},
	});

	return {
		selectedEthnicity: formik.values.ethnicity,
		selectedEthnicityValue: formik.values.ethnicityValue,
		changeEthnicityMutationLoading,
		changeEthnicityHandler: (ethnicity: string, ethnicityValue: number) => {
			formik.setFieldValue("ethnicity", ethnicity);
			formik.setFieldValue("ethnicityValue", ethnicityValue);
		},
		resetEthnicity: formik.resetForm,
		changeEthnicityMutation,
	};
};

export default useLocalizer;
