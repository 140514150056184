import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function RedoIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 16 16" {...props}>
			<circle cx="8" cy="8" r="8" fill="white" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8ZM7.46667 8.53333V4.26667H8.53333V8.53333H7.46667ZM8.53333 10.6667V11.744H7.46667V10.6667H8.53333Z"
				fill="#D80067"
			/>
		</SvgIcon>
	);
}

export default RedoIcon;
