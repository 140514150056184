import React, { useState } from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { useLoginService } from "../hooks/useLoginService";
import Layout from "../Components/Login/Layout";
import { useLocation } from "react-router-dom";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

interface IProps {}

const VerifyingPage: React.FC<IProps> = () => {
	const { t } = useTranslation();
	const [error, setError] = useState<boolean>(false);
	const { verifyUser } = useLoginService();
	const search = useLocation().search;

	React.useEffect(() => {
		try {
			const oobCode = new URLSearchParams(search).get("oobCode");
			if (!oobCode) throw new Error("Invalid Code");
			verifyUser(oobCode)
				.then(() => {
					console.log("success");
				})
				.catch((e) => {
					console.log("error", e);
					setError(true);
				});
		} catch (err) {
			setError(true);
		}
	}, []);

	return (
		<Layout>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				padding={3}
				maxWidth={380}
			>
				<Typography align="center" variant="h4" gutterBottom>
					{!error ? t("verifying...") : t("somethingWhenWrong")}
				</Typography>
				{!error && <CircularProgress />}
			</Box>
		</Layout>
	);
};

export default VerifyingPage;
