import {
	Button,
	CircularProgress,
	createStyles,
	IconButton,
	LinearProgress,
	makeStyles,
	Theme,
	Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import { useTranslation } from "react-i18next";
import CloudIcon from "../assets/icons/CloudIcon";
import upload from "../assets/Upload.svg";
import useUploadFile from "../hooks/useUploadFile";
import CloseIcon from "../assets/icons/CloseIcon";
import BriaButton from "./UI/BriaButton";
import clsx from "clsx";

type UploadFileProps = {};

export default function UploadFile({}: UploadFileProps) {
	const { t } = useTranslation();
	const classes = useStyles();
	const {
		getRootProps,
		getInputProps,
		isDragActive,
		files,
		error,
		displayUploadForm,
		setDisplayUploadForm,
		onUploadImage,
		uploadingDone,
		uploading,
		setFiles,
	} = useUploadFile({});

	const handleCloseUploadForm = () => {
		setDisplayUploadForm(false);
	};

	const previewImage = () => (
		<Box>
			<Box position="absolute" top={25} left={15}>
				<Typography>
					<Box component="span" fontWeight="fontWeightBold">
						{t("weLoveYourImage")}
					</Box>
				</Typography>
			</Box>
			<Box>
				<Box position="relative">
					{uploading && (
						<Box position="absolute" top={-2} left={0} width="100%">
							<LinearProgress />
						</Box>
					)}
					<img
						src={files[0].preview}
						className={clsx({
							[classes.previewImage]: true,
							[classes.blur]: uploading,
						})}
						width="100%"
						alt="image-to-upload"
					/>
				</Box>
				<Box display="flex" justifyContent="space-between" mt={3}>
					<Box>
						<BriaButton
							disabled={uploading}
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								setFiles([]);
							}}
							color="default"
							variant="text"
						>
							{t("cancel")}
						</BriaButton>
					</Box>
					<Box>
						<Box mr={2} display="inline-block">
							<BriaButton
								disabled={uploading}
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
								}}
								color="secondary"
								variant="text"
							>
								{t("+addToGallery")}
							</BriaButton>
						</Box>
						<Box mr={1} display="inline-block">
							<BriaButton
								disabled={uploading}
								onClick={(e) => {
									e.stopPropagation();
									e.preventDefault();
									onUploadImage();
								}}
								color="secondary"
								variant="contained"
							>
								{t("start")}
							</BriaButton>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);

	return (
		<Dialog
			onClose={handleCloseUploadForm}
			aria-labelledby="simple-dialog-title"
			open={displayUploadForm}
			maxWidth={false}
		>
			<div className={classes.root} {...getRootProps()}>
				<input {...getInputProps()} />
				<IconButton
					style={{
						position: "absolute",
						top: 10,
						right: 10,
					}}
					color="inherit"
					aria-label="close"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						handleCloseUploadForm();
					}}
				>
					<CloseIcon style={{ fontSize: 17 }} />
				</IconButton>
				{!(isDragActive || error || files.length === 0) ? (
					previewImage()
				) : (
					<Box className={classes.container}>
						{isDragActive ? (
							<Typography style={{ margin: "auto" }}>
								{t("dropImageHere")}
							</Typography>
						) : error ? (
							<Box
								display="flex"
								flexDirection="column"
								justifyContent="center"
								alignItems="center"
							>
								<Typography color="secondary" variant="h6">
									<Box
										component="span"
										fontWeight="fontWeightBold"
									>
										{t("sorry")}
									</Box>
								</Typography>
								<Typography color="textPrimary" variant="h6">
									<Box
										component="span"
										fontWeight="fontWeightBold"
									>
										{t("couldNotUpload")}
									</Box>
								</Typography>
								<Box mt={3}>
									<Typography
										color="textSecondary"
										variant="caption"
									>
										<Box
											component="span"
											fontWeight="fontWeightLight"
										>
											{t("onlySupportJPGandPNG")}
										</Box>
									</Typography>
								</Box>
							</Box>
						) : files.length === 0 ? (
							<Box
								justifyContent="center"
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<Box className={classes.uploadImage}>
									<CloudIcon style={{ fontSize: 90 }} />
								</Box>
								<Box width="65%" mt={2}>
									<Typography
										align="center"
										color="textPrimary"
									>
										<Box
											component="span"
											fontWeight="fontWeightBold"
										>
											{t("dragAndDropOr")}
											<span
												className={classes.markedText}
											>
												{t("browse")}
											</span>
											{t("toUpload")}
										</Box>
									</Typography>
								</Box>
								<Box width="65%" mt={2}>
									<Typography
										align="center"
										variant="subtitle2"
										color="textSecondary"
									>
										<Box
											component="span"
											fontWeight="fontWeightLight"
										>
											{t("supportedFiles")}
										</Box>
									</Typography>
									<Typography
										align="center"
										variant="subtitle2"
										color="textSecondary"
									>
										<Box
											component="span"
											fontWeight="fontWeightLight"
										>
											JPG, PNG
										</Box>
									</Typography>
								</Box>
							</Box>
						) : null}
					</Box>
				)}
			</div>
		</Dialog>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			position: "relative",
			background: theme.palette.background.paper,
			padding: theme.spacing(10, 4, 8, 4),
		},
		container: {
			background: theme.palette.background.paper,
			border: `1px dashed ${theme.palette.text.primary}`,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			width: 800,
			position: "relative",
			padding: 20,
			paddingBottom: 40,
			cursor: "pointer",
			height: 400,
			justifyContent: "center",
		},
		uploadImage: {
			marginTop: "10%",
			filter: "drop-shadow(0 0 2px white) contrast(1.25)",
		},
		markedText: {
			color: theme.palette.secondary.main,
		},
		previewImage: {
			maxWidth: "100%",
			maxHeight: "100%",
		},
		uploadButton: {
			marginRight: theme.spacing(1),
			marginLeft: theme.spacing(1),
		},
		blur: {
			filter: "blur(4px)",
		},
	})
);
