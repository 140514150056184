import { useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/client";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PaginationControl from "../../Components/UI/PaginationControl";
import RouterConstants from "../../Constants/RouterConstants";
import { GET_IMAGES_WITHOUT_TAGS } from "../../GraphQL/queries";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import empty from "../../assets/empty.svg";
import errorImg from "../../assets/error.svg";
import { useTranslation } from "react-i18next";
import { DBName } from "../../types/graphql-global-types";
import clsx from "clsx";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
	showroomImages,
	showroomImagesVariables,
} from "../../GraphQL/types/showroomImages";
import useWindowSize from "../../hooks/useWindowSize";

const imageWidth = 296;
const numberOfImagesInPage = 30;

function ToolsPage() {
	let numberOfColumns = 0;
	const classes = useStyles();
	const params = useLocation();
	const history = useHistory();
	const { t } = useTranslation();
	const imageContainerRef = useRef<HTMLDivElement>(null);

	useWindowSize();

	const pageNumber = Number(queryString.parse(params.search).page) || 1;

	const [refetch, { loading, error, data }] = useLazyQuery<
		showroomImages,
		showroomImagesVariables
	>(GET_IMAGES_WITHOUT_TAGS, {
		variables: {
			pageNumber: pageNumber,
			imagesPerPage: numberOfImagesInPage,
		},
	});

	useEffect(() => {
		refetch();
	}, [pageNumber, refetch]);

	if (imageContainerRef.current) {
		numberOfColumns = Math.max(
			Math.floor(
				imageContainerRef.current!.clientWidth / (imageWidth + 16)
			),
			1
		);
	}

	const imageGallary = new Array(numberOfColumns).fill(0);

	return (
		<Grid container className={classes.root}>
			<Grid item className={classes.container}>
				{!loading && data?.showroomImages?.images?.length === 0 ? (
					<>
						<img
							src={empty}
							alt="No images found."
							className={classes.emptyState}
							style={{
								filter: "drop-shadow(0 0 2px white) contrast(1.25)",
							}}
						/>
						<Box className={classes.noImagesText}>
							{t("noImagesFound")}
						</Box>
					</>
				) : (
					<>
						<Box p={1}>
							{loading && (
								<Box
									className={
										classes.circularProgressContainer
									}
								>
									<CircularProgress size={80} />
								</Box>
							)}
							{error && (
								<>
									<img
										src={errorImg}
										alt="Something went wrong"
										className={classes.errorState}
									/>
									<Box className={classes.errorText}>
										{error.message}
									</Box>
								</>
							)}

							<Container
								ref={imageContainerRef}
								disableGutters
								maxWidth={false}
								className={classes.gallery}
							>
								{imageGallary.map((el, index) => {
									return (
										<Box key={index} marginRight={3}>
											{data?.showroomImages?.images
												?.filter(
													(_, i) =>
														i % numberOfColumns ===
														index
												)
												.map((image) => {
													return (
														<Box
															id={
																image.visualHash
															}
															key={
																image.visualHash
															}
															className={clsx(
																classes.imageContainer
															)}
															width={imageWidth}
															height={
																(image.height *
																	imageWidth) /
																image.width
															}
														>
															<LazyLoadImage
																alt={
																	image.displayName ||
																	image.visualHash
																}
																placeholderSrc="https://img.freepik.com/free-photo/abstract-surface-textures-white-concrete-stone-wall_74190-8184.jpg?size=626&ext=jpg"
																onClick={() => {
																	history.push(
																		`${RouterConstants.GALLERY}${image.visualHash}`
																	);
																}}
																effect="blur"
																threshold={100}
																useIntersectionObserver={
																	false
																}
																width={
																	imageWidth
																}
																height={
																	(image.height *
																		imageWidth) /
																	image.width
																}
																src={
																	image.mediumUrl
																} // use normal <img> attributes as props
															/>
															<Box
																className={
																	classes.imageNameContainer
																}
															>
																<Typography
																	className={
																		classes.imageName
																	}
																>
																	{image.displayName ||
																		image.visualHash}
																</Typography>
															</Box>
														</Box>
													);
												})}
										</Box>
									);
								})}
							</Container>
							<PaginationControl
								count={data?.showroomImages?.totalPages}
								page={pageNumber}
								onChange={(_, page) => {
									window.scrollTo({
										top: 0,
										left: 0,
										behavior: "smooth",
									});
									history.push(
										`${RouterConstants.GALLERY}?page=${page}`
									);
								}}
							/>
						</Box>
					</>
				)}
			</Grid>
		</Grid>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: theme.spacing(3, 8, 8, 8),
			flexWrap: "nowrap",
		},
		container: {
			flexGrow: 1,
		},
		projectsTagsContainer: {
			width: "400px",
			padding: theme.spacing(2),
		},
		paper: {
			padding: theme.spacing(3),
			textAlign: "center",
			width: "100%",
			height: "100%",
			marginBottom: theme.spacing(1),
		},
		imageContainer: {
			overflow: "hidden",
			position: "relative",
			cursor: "pointer",
			border: "3px solid transparent",
			borderRadius: theme.shape.borderRadius,
			marginBottom: theme.spacing(3),
			"&:hover": {
				border: `3px solid ${theme.palette.common.white}`,
				boxShadow: theme.shadows[8],
			},
		},
		biggerNumber: {
			position: "absolute",
			bottom: 0,
			right: 0,
			width: 74,
			height: 74,

			"&:hover $imageText": {
				fontSize: 75,
			},

			"&:hover $imageTextContainer": {
				width: 101,
				height: 101,
			},
		},
		imageTextContainer: {
			position: "absolute",
			bottom: 0,
			right: 0,
			width: 74,
			height: 74,
			zIndex: 100,
		},
		shadowContainer: {
			background: `linear-gradient(180deg, rgba(73, 80, 87, 0.75) 50%, rgba(255, 255, 255, 0) 100%)`,
			filter: "blur(16px)",
			borderRadius: 70,
		},
		imageText: {
			fontWeight: theme.typography.fontWeightRegular,
			color: theme.palette.common.white,
			fontSize: 48,
		},
		imageNameContainer: {
			position: "absolute",
			top: 0,
			width: "100%",
			background:
				"linear-gradient(180deg, rgba(95, 95, 95, 0.75) 0%, rgba(196, 196, 196, 0) 100%)",
		},
		imageName: {
			color: theme.palette.common.white,
			fontSize: 12,
			fontWeight: 600,
			width: "fit-content",
			margin: "auto",
			marginTop: 11,
		},
		filteringLabels: {
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
			width: 400,
		},
		circularProgressContainer: {
			width: "100%",
			display: "flex",
			justifyContent: "center",
		},
		emptyState: {
			width: 300,
			height: 300,
			marginTop: "10%",
		},
		noImagesText: {
			fontSize: 25,
			paddingTop: 10,
			color: theme.palette.text.primary,
		},
		errorState: {
			width: 300,
			height: 300,
			marginTop: "10%",
		},
		errorText: {
			fontSize: 25,
			paddingTop: 10,
			color: theme.palette.text.primary,
		},
		button: {
			textTransform: "none",
			fontSize: 17,
			whiteSpace: "nowrap",
			width: 176,
			height: 44,
			borderRadius: 30,
			color: theme.palette.primary.light,
		},
		sec: {
			display: "flex",
			alignItems: "center",
			marginBottom: 30,
		},
		imgSize: {
			objectFit: "contain",
		},
		text: {
			color: theme.palette.text.secondary,
			marginBottom: 30,
			textAlign: "left",
			fontSize: 24,
			fontWeight: theme.typography.fontWeightMedium,
		},
		root2: {
			height: "100%",
			boxSizing: "border-box",
			flexShrink: 0,
			listStyle: "none",
		},
		/* Styles applied to the `div` element that wraps the children. */
		tile: {
			position: "relative",
			display: "block", // In case it's not rendered with a div.
		},
		searchInput: {
			width: 852,
			height: 43,
			borderRadius: 20,
			background: theme.palette.common.white,
			fontSize: 17,
			color: theme.palette.common.black,
		},
		searchIcon: {
			stroke: theme.palette.common.black,
			fontSize: 16,
		},
		addIcon: {
			fontSize: 44,
		},
		gallery: {
			display: "flex",
			justifyContent: "center",
		},
	})
);

export default ToolsPage;
