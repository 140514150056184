import i18next from "i18next";
import { ImageEditorData_vdrObject_vdrAwsFaceDetection_faces } from "../../GraphQL/types/imageEditorData";

import { Rect } from "../../types/graphql-global-types";

export enum AnalysisStage {
	NONE,
	A,
	B,
	C,
}

export function getTitleForAnalysisStage(analysisStage: AnalysisStage): string {
	switch (analysisStage) {
		case AnalysisStage.NONE:
			return i18next.t("toolsAnalysisStageNone");
		case AnalysisStage.A:
			return i18next.t("toolsAnalysisStageA");
		case AnalysisStage.B:
			return i18next.t("toolsAnalysisStageB");
		case AnalysisStage.C:
			return i18next.t("toolsAnalysisStageC");
	}
}

export enum BriaObjectType {
	car = "car",
	human = "human",
	horse = "horse",
	church = "church",
	background = "background",
}

export enum ToolsViewerMode {
	both = "both",
	sliders = "sliders",
	thumbnails = "thumbnails",
}

export enum ToolsBackGroundMode {
	noBg = "noBg",
	withBg = "withBg",
}

export interface SelectedPointInterface {
	id: string;
	index: number;
	objectType: string;
	rect: Rect;
	active?: boolean;
}

export const getFaceData = (
	face: ImageEditorData_vdrObject_vdrAwsFaceDetection_faces
) => {
	let highAge = face.ageRange.high || Infinity;
	let lowAge = face.ageRange.low || 0;
	let age = (highAge + lowAge) / 2;
	let smile =
		face.faceAttributes.find((s: any) => s.name.toLowerCase() === "smile")
			?.value ?? false;
	let glasses =
		face.faceAttributes.find(
			(a: any) => a.name.toLowerCase() === "eyeglasses"
		)?.value ?? false;

	return {
		gender: face.gender.value,
		age,
		smile,
		glasses,
	};
};

export function titleCase(s: string) {
	return s
		.replace("_", " ")
		.replace("-", " ")
		.replace(/\w\S*/g, function (t) {
			return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
		});
}
