import { useCallback, useEffect, useState } from "react";
import { isDevelopment } from "../Config/Env";
import { sendMetricsToGrafana } from "../GraphQL/mutations";
import Metric from "../Models/Metric";

let startTime: number = 0;
const useGrafanaMetric = (visualHash: string) => {
	const [grafanaMetrics, setGrafanaMetrics] = useState<Metric[]>([]);
	const [sendToGrafana, setSendToGrafana] = useState<boolean>(false);

	const setStartTime = (time: number) => {
		startTime = time;
	};

	useEffect(() => {
		if (sendToGrafana === true) {
			sendMetricsToGrafana(grafanaMetrics);
			setGrafanaMetrics([]);
			setSendToGrafana(false);
		}
	}, [grafanaMetrics, sendToGrafana]);

	const getTagsForMetric = useCallback((): string => {
		return JSON.stringify({
			imageVisualHash: visualHash,
			env: isDevelopment ? "dev" : "prod",
		});
	}, [visualHash]);

	const addNewMetric = (operationName: string) => {
		setGrafanaMetrics([
			...grafanaMetrics,
			new Metric(
				performance.now() - startTime,
				operationName,
				getTagsForMetric()
			),
		]);
	};

	const generatingImageMetric = () => {
		if (grafanaMetrics.length === 0) return;
		setGrafanaMetrics([
			...grafanaMetrics,
			new Metric(
				performance.now() - startTime,
				"gen_completed",
				getTagsForMetric()
			),
		]);
		setSendToGrafana(true);
	};

	return {
		setStartTime,
		addNewMetric,
		generatingImageMetric,
	};
};
export default useGrafanaMetric;
