import React, { useContext } from "react";
import { useDropzone } from "react-dropzone";
import Context from "../Context/Context";
import useOutsideClick from "./useOutsideClick";
import { getPostUrls } from "../GraphQL/queries";
import { useMutation } from "@apollo/client";
import { UPLOAD_IMAGE_TO_GALLERY } from "../GraphQL/mutations";
import {
	uploadImageToGallery,
	uploadImageToGalleryVariables,
} from "../GraphQL/types/uploadImageToGallery";
import axios from "axios";

export interface FileWithPreview extends File {
	preview?: string;
}

interface IProps {
	numbOfFiles?: number;
}

function useUploadFile({ numbOfFiles }: IProps) {
	const [files, setFiles] = React.useState<FileWithPreview[]>([]);
	const context = useContext(Context);
	const [uploading, setUploading] = React.useState(false);
	const [uploadingDone, setUploadingDone] = React.useState(false);

	const [uploadImageToVdrMutation, { loading }] = useMutation<
		uploadImageToGallery,
		uploadImageToGalleryVariables
	>(UPLOAD_IMAGE_TO_GALLERY);

	const displayUploadForm = context.displayUpload;

	const setDisplayUploadForm = (value: boolean) => {
		context.setUploadState(value);
	};

	const onDrop = (acceptedFiles: any) => {
		setFiles(
			acceptedFiles.map((file: any) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				})
			)
		);
	};

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		rootRef,
		fileRejections,
	} = useDropzone({
		onDrop,
		maxFiles: numbOfFiles ?? 1,
		accept: "image/jpeg, image/png",
	});

	const error: boolean = fileRejections.length > 0;

	const onUploadImage = async () => {
		setUploading(true);
		// setUploadingDone(false);

		try {
			let res = await getPostUrls(files.map((file) => file.name));
			let fields = JSON.parse(res[0].fields);
			let url = res[0].url;
			const formData = new FormData();

			// append the fields in presignedPostData in formData
			Object.keys(fields).forEach((key: string) => {
				formData.append(key, fields[key]);
			});

			formData.append("file", files[0]);

			await axios.post(url, formData);

			await uploadImageToVdrMutation({
				variables: {
					filePath: fields.key,
					uploadParameters: {
						projectId: "image-to-vdr-upload",
						sendToSemSeg: true,
						validForTraining: false,
						description: "123-22-88-00009",
						imageRefUrl: url,
						internalUse: false,
						query: "",
						imageKey: fields.key,
					},
				},
			});

			setUploading(false);
			setUploadingDone(true);
		} catch (e) {
			console.log(e);
		}
	};

	useOutsideClick(rootRef, () => {
		setDisplayUploadForm(false);
	});
	return {
		getRootProps,
		getInputProps,
		isDragActive,
		files,
		error,
		displayUploadForm,
		setDisplayUploadForm,
		onUploadImage,
		uploading,
		uploadingDone,
		setFiles,
	};
}

export default useUploadFile;
