import { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Context from "../../Context/Context";
import { Divider } from "@material-ui/core";
import { DrawerProps } from "@material-ui/core/Drawer";
import clsx from "clsx";
import ThumbnailSlider from "../UI/ThumbnailSlider";
import { ToolsSlider, ToolsThumbnails } from "../../Models/ToolsConfig";
import ThumbnailCardNew from "../UI/ThumbnailCardNew";
import { ToolsViewerMode } from "./utilities";

import { useTranslation } from "react-i18next";
import BriaButton from "../UI/BriaButton";
import { getValueFromNormalizedRangeBaseTen } from "../../Helpers/math";
import ToolsEmptyState from "../UI/ToolsEmptyState";
interface IProps {
	disabled?: boolean;
	selectedEthnicity: string;
	ethnicityValue?: number;
	onChangeEthnicity: (ethnicty: string, value: number) => void;
	thumbnails: ToolsThumbnails[];
	onAddSemanticCombination: (
		ethnicity: any,
		ethnicityValue: any,
		controller: any
	) => Promise<string>;
	drawerAnchor: DrawerProps["anchor"];
	sliders: ToolsSlider[];
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			height: "100%",
		},
		thumbnailsContainer: {
			height: "100%",
			overflow: "auto",
			paddingLeft: 0,
			paddingRight: 0,
		},
		bottomThumbnailsView: {
			height: "100%",
			paddingLeft: theme.spacing(5),
			paddingRight: theme.spacing(5),
		},
	})
);

export default function Localizer({
	disabled,
	ethnicityValue = 0,
	selectedEthnicity,
	onChangeEthnicity,
	thumbnails,
	drawerAnchor,
	sliders,
	onAddSemanticCombination,
}: IProps) {
	const classes = useStyles();
	const { preferences, user } = useContext(Context);
	const [count, setCount] = useState(6);
	const { t } = useTranslation();
	const currentStyle = preferences?.style;

	const mapSliders = (value: number, el: ToolsSlider) => {
		return (
			<Box maxWidth={250} width="100%" p={2} key={el.id}>
				<ThumbnailSlider
					key={el.id}
					disabled={disabled}
					defaultValue={el.defaultInitialValue}
					maxValue={el.maxValue}
					minValue={el.minValue}
					leftSubtitle={el.minName}
					rightSubtitle={el.maxName}
					value={value}
					reversed={el.reversed}
					title={el.displayName}
					warrning={el.askAlan?.warning}
					error={el.askAlan?.disabled}
					minNameRiskArea={el.minNameRiskArea}
					maxNameRiskArea={el.maxNameRiskArea}
					sliderDefaultValue={el.defaultInitialValue}
					score={user?.isAdmin() ? el.askAlan?.score : ""}
					handleValueChange={(value) => {
						onChangeEthnicity(el.id, value);
					}}
				/>
			</Box>
		);
	};

	const thumbnailsList = (
		<Box
			flex={1}
			className={clsx({
				[classes.thumbnailsContainer]: drawerAnchor !== "bottom",
				[classes.bottomThumbnailsView]: drawerAnchor === "bottom",
			})}
		>
			{sliders && sliders.length > 0 ? (
				<Box display="flex" flexWrap="wrap">
					{thumbnails
						.slice(
							0,
							count < thumbnails.length
								? count
								: thumbnails.length
						)
						.map((el) => {
							return (
								<ThumbnailCardNew
									key={el.key}
									onAddSemanticCombination={(controller) => {
										return onAddSemanticCombination(
											el.sliders[0].id,
											getValueFromNormalizedRangeBaseTen(
												el.sliders[0].minValue,
												el.sliders[0].maxValue,
												el.sliders[0].value
											),
											controller
										);
									}}
									thumbnail={el}
									onClick={() => {
										if (!disabled) {
											onChangeEthnicity(
												el.sliders[0].id,
												getValueFromNormalizedRangeBaseTen(
													el.sliders[0].minValue,
													el.sliders[0].maxValue,
													el.sliders[0].value
												)
											);
										}
									}}
									warrning={el.sliders.some(
										(el) =>
											el.askAlan?.warning ||
											el.askAlan?.disabled
									)}
									error={el.sliders.some(
										(el) => el.askAlan?.disabled
									)}
								>
									<>
										{el.sliders.map((slider) => {
											const value =
												getValueFromNormalizedRangeBaseTen(
													slider.minValue,
													slider.maxValue,
													el.sliders[0].value
												);
											return mapSliders(value, slider);
										})}
									</>
								</ThumbnailCardNew>
							);
						})}
					{count < thumbnails.length && (
						<Box
							width="100%"
							display="flex"
							justifyContent="center"
						>
							<BriaButton
								color="default"
								style={{ width: "80%" }}
								onClick={() => {
									setCount(count + 6);
								}}
							>
								{t("loadMore")}
							</BriaButton>
						</Box>
					)}
				</Box>
			) : (
				<ToolsEmptyState />
			)}
		</Box>
	);

	let validSliders = sliders?.filter((el) => !el.hideSlider);
	console.log(validSliders, validSliders && validSliders.length > 0);

	const ethnicityRadioList = (
		<Box flex={1} overflow="auto">
			{validSliders && validSliders.length > 0 ? (
				validSliders.map((slider) => {
					const value =
						slider.id === selectedEthnicity
							? ethnicityValue
							: slider.defaultInitialValue;
					return mapSliders(value, slider);
				})
			) : (
				<ToolsEmptyState />
			)}
		</Box>
	);

	return (
		<>
			<Box
				className={classes.root}
				visibility={
					currentStyle === ToolsViewerMode.sliders
						? "visible"
						: "hidden"
				}
				maxHeight={
					currentStyle === ToolsViewerMode.sliders ? "inherit" : 0
				}
			>
				{ethnicityRadioList}
			</Box>
			{currentStyle === ToolsViewerMode.both && <Divider />}
			<Box
				className={classes.root}
				visibility={
					currentStyle === ToolsViewerMode.thumbnails
						? "visible"
						: "hidden"
				}
			>
				{thumbnailsList}
			</Box>
		</>
	);
}
