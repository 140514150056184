import client from "../GraphQL/client";
import { ApolloClient } from "@apollo/client";
import { NormalizedCacheObject } from "@apollo/client/cache";
import {
	repositoryProjects,
	repositoryProjectsVariables,
	repositoryProjects_repositoryProjects as Project,
} from "./types/repositoryProjects";
import {
	repositoryTags,
	repositoryTagsVariables,
	repositoryTags_repositoryTags as Tag,
} from "./types/repositoryTags";
import {
	addItemsToDataset,
	addItemsToDatasetVariables,
	addItemsToDataset_addItemsToDataset as DataloopAddedItem,
} from "./types/addItemsToDataset";
import {
	images,
	imagesVariables,
	images_images_images as Image,
} from "./types/images";
import {
	folders,
	foldersVariables,
	folders_folders as Folder,
} from "./types/folders";
import {
	awsInstanceMetadata,
	awsInstanceMetadata_awsInstanceMetadata as InstanceMetadata,
} from "./types/awsInstanceMetadata";
import { gcpInstanceMetadata } from "./types/gcpInstanceMetadata";
import {
	labelsByPathPrefix,
	labelsByPathPrefixVariables,
	labelsByPathPrefix_labelsByPathPrefix as Label,
} from "./types/labelsByPathPrefix";
import {
	labelsByMongoId,
	labelsByMongoIdVariables,
} from "./types/labelsByMongoId";
import { DBName } from "../types/graphql-global-types";
import gql from "graphql-tag";
import {
	vdrObjectRawJson,
	vdrObjectRawJsonVariables,
} from "./types/vdrObjectRawJson";
import {
	getPostUrlsForUpload,
	getPostUrlsForUploadVariables,
	getPostUrlsForUpload_getPostUrlsForUpload as PresignedPostUrl,
} from "./types/getPostUrlsForUpload";

export async function getLiveVideos(imagesUrls: string[]): Promise<any> {
	try {
		const res = await client.query<string>({
			query: gql`
				query liveVideos($imagesUrls: [String!]!) {
					liveVideos(imagesUrls: $imagesUrls) {
						videos {
							imageUrl
							videoUrl
						}
					}
				}
			`,
			variables: {
				imagesUrls: imagesUrls,
			},
		});
		return Promise.resolve(res.data);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function vdrObjectRawJsonString(
	mongoId: string,
	dbName: DBName,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<string> {
	try {
		const res = await localClient.query<
			vdrObjectRawJson,
			vdrObjectRawJsonVariables
		>({
			query: gql`
				query vdrObjectRawJson($mongoId: String!, $dbName: DBName!) {
					vdrObjectRawJson(mongoId: $mongoId, dbName: $dbName) {
						jsonString
					}
				}
			`,
			variables: {
				mongoId: mongoId,
				dbName: dbName,
			},
		});
		return Promise.resolve(res.data.vdrObjectRawJson.jsonString);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getRepositoryProjects(
	dbName: DBName = DBName.VDR,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<Project[]> {
	try {
		const res = await localClient.query<
			repositoryProjects,
			repositoryProjectsVariables
		>({
			query: gql`
				query repositoryProjects($dbName: DBName!) {
					repositoryProjects(dbName: $dbName) {
						name
					}
				}
			`,
			variables: {
				dbName: dbName,
			},
		});
		return Promise.resolve(res.data.repositoryProjects ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getAddItemsToDataset(
	dataset: string,
	project: string,
	visualHashesToUpload: string[],
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<DataloopAddedItem[]> {
	try {
		const res = await localClient.query<
			addItemsToDataset,
			addItemsToDatasetVariables
		>({
			query: gql`
				query addItemsToDataset(
					$dataset: String!
					$project: String!
					$visualHashesToUpload: [String!]!
				) {
					addItemsToDataset(
						dataset: $dataset
						project: $project
						visualHashesToUpload: $visualHashesToUpload
					) {
						visualHash
					}
				}
			`,
			variables: {
				dataset: dataset,
				project: project,
				visualHashesToUpload: visualHashesToUpload,
			},
		});
		return Promise.resolve(res.data.addItemsToDataset ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getRepositoryTags(
	dbName: DBName = DBName.VDR,
	projectList: string[],
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<Tag[]> {
	try {
		const res = await localClient.query<
			repositoryTags,
			repositoryTagsVariables
		>({
			query: gql`
				query repositoryTags(
					$dbName: DBName!
					$projectList: [String!]!
				) {
					repositoryTags(dbName: $dbName, projectList: $projectList) {
						name
					}
				}
			`,
			variables: {
				dbName: dbName,
				projectList: projectList,
			},
		});
		return Promise.resolve(res.data.repositoryTags ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getLabelsByPathPrefix(
	dbName: DBName,
	pathPrefix: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<Label[]> {
	try {
		const res = await localClient.query<
			labelsByPathPrefix,
			labelsByPathPrefixVariables
		>({
			query: gql`
				query labelsByPathPrefix(
					$dbName: DBName!
					$pathPrefix: String!
				) {
					labelsByPathPrefix(
						dbName: $dbName
						pathPrefix: $pathPrefix
					) {
						name
					}
				}
			`,
			variables: {
				dbName: dbName,
				pathPrefix: pathPrefix,
			},
		});
		return Promise.resolve(res.data.labelsByPathPrefix ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getLabelsByMongoId(
	dbName: DBName,
	mongoId: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<Label[]> {
	try {
		const res = await localClient.query<
			labelsByMongoId,
			labelsByMongoIdVariables
		>({
			query: gql`
				query labelsByMongoId($dbName: DBName!, $mongoId: String!) {
					labelsByMongoId(dbName: $dbName, mongoId: $mongoId) {
						name
					}
				}
			`,
			variables: {
				dbName: dbName,
				mongoId: mongoId,
			},
		});
		return Promise.resolve(res.data.labelsByMongoId ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export class getImagesReturnValues {
	images: Image[];
	totalPages: number;
	totalImages: number;
	queryStr: string;

	constructor(
		images: Image[],
		totalPages: number,
		totalImages: number,
		queryStr: string
	) {
		this.images = images;
		this.totalPages = totalPages;
		this.totalImages = totalImages;
		this.queryStr = queryStr;
	}
}

export const GET_IMAGES = gql`
	query images_with_tags(
		$dbName: DBName!
		$tags: [String!]!
		$projects: [String!]!
		$pageNumber: Int!
		$imagesPerPage: Int!
		$searchText: String
		$visualHashToSearch: String
		$labels: [String!]
		$pathPrefix: String
		$customQueryStr: String
	) {
		images(
			dbName: $dbName
			tags: $tags
			projects: $projects
			pageNumber: $pageNumber
			imagesPerPage: $imagesPerPage
			searchText: $searchText
			visualHashToSearch: $visualHashToSearch
			labels: $labels
			pathPrefix: $pathPrefix
			customQueryStr: $customQueryStr
			mslToggle: false
			validFacesToggle: false
		) {
			images {
				url
				smallUrl
				mediumUrl
				mongoId
				width
				height
				score
				displayName
				visualHash
			}
			totalPages
			totalImages
			queryStr
		}
		repositoryProjects(dbName: $dbName) {
			name
		}
		repositoryTags(dbName: $dbName, projectList: $projects) {
			name
		}
	}
`;

export const GET_IMAGES_WITHOUT_TAGS = gql`
	query showroomImages($pageNumber: Int!, $imagesPerPage: Int!) {
		showroomImages(pageNumber: $pageNumber, imagesPerPage: $imagesPerPage) {
			images {
				url
				smallUrl
				mediumUrl
				width
				height
				score
				displayName
				visualHash
			}
			totalPages
			totalImages
		}
	}
`;

export async function getImageRepository(
	validFacesToggle: boolean,
	mslToggle: boolean,
	selectedTags: string[],
	selectedProjects: string[],
	pageNumber: number,
	imagesPerPage: number,
	visualHashToSearch: string,
	dbName: DBName = DBName.VDR,
	pathPrefix: string | null = null,
	customQueryStr: string | null = null,
	selectedLabels: string[] = [],
	selectedText: string | null = null,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<getImagesReturnValues> {
	try {
		const res = await localClient.query<images, imagesVariables>({
			query: GET_IMAGES_LIST,
			variables: {
				validFacesToggle: validFacesToggle,
				mslToggle: mslToggle,
				dbName: dbName,
				tags: selectedTags,
				projects: selectedProjects,
				pageNumber: pageNumber,
				imagesPerPage: imagesPerPage,
				labels: selectedLabels,
				visualHashToSearch: visualHashToSearch,
				pathPrefix: pathPrefix,
				customQueryStr: customQueryStr,
				searchText: selectedText,
			},
		});
		const returnValues: getImagesReturnValues = new getImagesReturnValues(
			res.data.images?.images ?? [],
			res.data.images?.totalPages ?? 0,
			res.data.images?.totalImages ?? 0,
			res.data.images?.queryStr ?? ""
		);
		return Promise.resolve(returnValues);
	} catch (error) {
		return Promise.reject(error);
	}
}

export const GET_VALID_EXPRESSIONS = gql`
	query getValidExtremeExpression(
		$inputImage: String
		$visualHash: String
		$ethnicity: String
		$rects: [Rect]
	) {
		getValidExtremeExpression(
			inputImage: $inputImage
			visualHash: $visualHash
			ethnicity: $ethnicity
			rects: $rects
		) {
			data
		}
	}
`;

export const GET_IMAGES_LIST = gql`
	query images(
		$validFacesToggle: Boolean!
		$mslToggle: Boolean!
		$dbName: DBName!
		$tags: [String!]!
		$projects: [String!]!
		$pageNumber: Int!
		$imagesPerPage: Int!
		$searchText: String
		$visualHashToSearch: String
		$labels: [String!]
		$pathPrefix: String
		$customQueryStr: String
	) {
		images(
			validFacesToggle: $validFacesToggle
			mslToggle: $mslToggle
			dbName: $dbName
			tags: $tags
			projects: $projects
			pageNumber: $pageNumber
			imagesPerPage: $imagesPerPage
			searchText: $searchText
			visualHashToSearch: $visualHashToSearch
			labels: $labels
			pathPrefix: $pathPrefix
			customQueryStr: $customQueryStr
		) {
			images {
				url
				smallUrl
				mediumUrl
				mongoId
				width
				height
				displayName
				visualHash
			}
			totalPages
			totalImages
			queryStr
		}
	}
`;

export async function getFolders(
	dbName: DBName = DBName.DATASETS,
	pathPrefix: string = "",
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<Folder[]> {
	try {
		const res = await localClient.query<folders, foldersVariables>({
			query: gql`
				query folders($dbName: DBName!, $pathPrefix: String!) {
					folders(dbName: $dbName, pathPrefix: $pathPrefix) {
						fullPath
						folderName
					}
				}
			`,
			variables: {
				dbName: dbName,
				pathPrefix: pathPrefix,
			},
		});
		return Promise.resolve(res.data.folders ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getGCPInstanceMetadata(
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<InstanceMetadata[]> {
	try {
		const res = await localClient.query<gcpInstanceMetadata>({
			query: gql`
				query gcpInstanceMetadata {
					gcpInstanceMetadata {
						platformName
						name
						zone
						status
						vcpuNum
						lastStartTimestampUnix
						instanceId
						instanceType
						gpuType
					}
				}
			`,
		});
		return Promise.resolve(res.data.gcpInstanceMetadata ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getAWSInstanceMetadata(
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<InstanceMetadata[]> {
	try {
		const res = await localClient.query<awsInstanceMetadata>({
			query: gql`
				query awsInstanceMetadata {
					awsInstanceMetadata {
						platformName
						name
						zone
						status
						vcpuNum
						lastStartTimestampUnix
						instanceId
						instanceType
						gpuType
					}
				}
			`,
		});
		return Promise.resolve(res.data.awsInstanceMetadata ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function getPostUrls(
	imageNames: string[],
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<PresignedPostUrl[]> {
	try {
		const res = await localClient.query<
			getPostUrlsForUpload,
			getPostUrlsForUploadVariables
		>({
			query: gql`
				query getPostUrlsForUpload($imageNames: [String!]!) {
					getPostUrlsForUpload(imageNames: $imageNames) {
						url
						fields
					}
				}
			`,
			variables: {
				imageNames: imageNames,
			},
		});
		return Promise.resolve(res.data.getPostUrlsForUpload ?? []);
	} catch (error) {
		return Promise.reject(error);
	}
}

export const GET_LIVELINESS_FACES_LIST = gql`
	query GetLivelinessFacesList($visualHash: String!, $face: Rect!) {
		imageLiveliness(imageVisualHash: $visualHash, face: $face) {
			liveFaces {
				propertyName
				videoInfo {
					bestFrameIndex
					frames
					labels
					videoPath
					errDataDict {
						avgPixelErr
					}
				}
				videoUrl
				id
			}
		}
	}
`;

export const GET_LIVELINESS_BACKGROUNDS_LIST = gql`
	query GetBackgroundLivenessList($vHash: String!, $imgUrl: String!) {
		backgroundThumbnails(vHash: $vHash, imgUrl: $imgUrl) {
			leftToRight
			rightToLeft
			towardsLeftCamera
			towardsRightCamera
			awayLeft
		}
	}
`;

export const GET_ETHNICITIES_LIST = gql`
	query EthnictyList {
		metaData {
			ethnicities {
				name
				displayName
				isDefault
			}
		}
	}
`;
export const GET_SEMANTICS_LIST = gql`
	query senamticsList {
		metaData {
			semantics {
				maxLayer
				maxName
				maxValue
				minLayer
				minName
				minValue
				name
				pca
				value
			}
		}
	}
`;

export const GET_RESIZED_IMAGE = gql`
	query GetResizedImage($visualHash: String) {
		resizedImage(visualHash: $visualHash)
	}
`;

export const IMAGE_DETAILS = gql`
	query ImageEditorData($visualHash: String) {
		vdrObject(visualHash: $visualHash) {
			url
			vdrEthnicityValidFaces {
				faces {
					rect {
						width
						height
						left
						top
					}
				}
			}
			vdrAwsLabelDetection {
				labels {
					name
				}
			}
			vdrPointsOfInterest {
				points {
					x
					y
				}
			}
			vdrAwsFaceDetection {
				faces {
					gender {
						value
					}
					faceAttributes {
						value
						name
					}
					faceSentiments {
						name
					}
					ageRange {
						low
						high
					}
				}
			}
			width
			height
		}
		metaData {
			pipelineVersion
			settings {
				name
				isOn
				description
			}
		}
	}
`;

export const TOOL_CONFIG = gql`
	query ToolConfig($facesData: [FaceData!]!, $isTesting: Boolean) {
		toolConfig(facesData: $facesData, isTesting: $isTesting) {
			objectTypesData {
				objectType
				data {
					level1Id
					level1Name
					data {
						level2Id
						level2Name
						faceThumbnails {
							faceIndex
							thumbnails {
								title
								baseImageUrl
								imageUrl
								ethnicity
								ethnicityValue
								semantics {
									value
									name
								}
							}
						}
						sliders {
							value
							name
							minName
							maxName
							minValue
							maxValue
							pca
							minLayer
							maxLayer
							reversed
						}
					}
				}
			}
		}
	}
`;

export const GET_SETTINGS_LIST = gql`
	query getSettingsList {
		metaData {
			settings {
				name
				isOn
				description
			}
		}
	}
`;

export const GET_ASK_ALAN_SCORES = gql`
	query GetAskAlanScores(
		$ethnicity: String!
		$objectType: String!
		$crowdScoring: Boolean!
		$automaticScoring: Boolean!
		$rects: [Rect]
		$visualHash: String!
	) {
		getAskAlanScores(
			visualHash: $visualHash
			objectType: $objectType
			crowdScoring: $crowdScoring
			automaticScoring: $automaticScoring
			rects: $rects
			ethnicity: $ethnicity
		) {
			sliderId
			score
		}
	}
`;

export const GET_GPU_MACHINE_STATUS = gql`
	query GetGPUMachineStatus {
		getGpuMachineStatus
	}
`;
