import React from 'react';
import { Theme, createStyles, withStyles } from '@material-ui/core/styles';
import {
    Grid, Button, Fab,
    FormControl,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from '@material-ui/icons/Remove';
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import Autocomplete from '@material-ui/lab/Autocomplete';

interface IProps {
    finishedForm: (project: string, source: string, folder: string, subfolder: string, displayName: string | null, labels: string[] | null) => void
    formNotEmpty: (formEmpty: boolean) => void
    activeStepCallback: (activeStep: number) => void
    numberOfImages: number
}

interface IState {
    projectName: string
    sourceName: string
    folder: string
    subfolder: string
    displayName: string | null
    labels: string[]
    activeStep: number
    steps: string[]
    formEmpty: boolean
    triedAddingNewLabelsWithoutChangingCur: boolean
    shouldShowErrorProject: boolean
    shouldShowErrorSource: boolean
    shouldShowErrorFolder: boolean
    shouldShowErrorSubfolder: boolean
}


class ResearchUploadForm extends React.Component<IProps, IState> {
    state: IState = {
        projectName: "",
        sourceName: "",
        folder: "",
        subfolder: "",
        displayName: null,
        labels: [""],
        activeStep: 0,
        steps: ['Metadata', 'Review your upload request'],
        formEmpty: true,
        triedAddingNewLabelsWithoutChangingCur: false,
        shouldShowErrorProject: false,
        shouldShowErrorSource: false,
        shouldShowErrorFolder: false,
        shouldShowErrorSubfolder: false,
    }

    async componentDidMount() {
        this.props.formNotEmpty.bind(this)
        this.handleFormUpdating = this.handleFormUpdating.bind(this)
    }

    async isFormEmpty() {
        let localFormEmpty: boolean = (this.state.projectName === "") && (this.state.sourceName === "") && (this.state.folder === "") &&
            (this.state.subfolder === "") && (this.state.displayName === null) && (this.state.labels === [""])
        this.setState({ formEmpty: localFormEmpty })
    }

    getStepContent(step: number) {
        switch (step) {
            case 0:
                return this.renderMetadata()
            case 1:
                return this.renderReview()
            default:
                throw new Error('Unknown step');
        }
    }

    changeActiveStep() {
        this.setState({ activeStep: this.state.activeStep - 1 }, () => { this.props.activeStepCallback(this.state.activeStep) })
    };

    checkForEmptyFields(): boolean {
        let shouldGoNext: boolean = true
        if (this.state.projectName === "") {
            this.setState({ shouldShowErrorProject: true })
            shouldGoNext = false;
        }
        else {
            this.setState({ shouldShowErrorProject: false })
        }
        if (this.state.sourceName === "") {
            this.setState({ shouldShowErrorSource: true })
            shouldGoNext = false;
        }
        else {
            this.setState({ shouldShowErrorSource: false })
        }
        if (this.state.folder === "") {
            this.setState({ shouldShowErrorFolder: true })
            shouldGoNext = false;
        }
        else {
            this.setState({ shouldShowErrorFolder: false })
        }
        if (this.state.subfolder === "") {
            this.setState({ shouldShowErrorSubfolder: true })
            shouldGoNext = false;
        }
        else {
            this.setState({ shouldShowErrorSubfolder: false })
        }
        return shouldGoNext
    }

    handleNext() {
        const shouldGoNext: boolean = this.checkForEmptyFields()
        if (!shouldGoNext) {
            return
        }
        if (this.state.activeStep + 1 === this.state.steps.length) {
            let imageLabels: string[] | null = this.state.labels
            if (imageLabels === [""]) {
                imageLabels = null
            }
            this.props.finishedForm(this.state.projectName, this.state.sourceName, this.state.folder, this.state.subfolder, this.state.displayName, imageLabels)
        }
        else {
            this.setState({ activeStep: this.state.activeStep + 1 }, () => { this.props.activeStepCallback(this.state.activeStep) })
        }
    };


    getUploadImageText(): string {
        const uploadImagetext: string = this.props.numberOfImages === 1 ? "Upload 1 image" : "Upload " + this.props.numberOfImages + " images"
        return uploadImagetext
    }

    render() {
        const { classes }: any = this.props;
        return <React.Fragment>
            {this.getStepContent(this.state.activeStep)}
            <div className={classes.buttons}>
                {this.state.activeStep !== 0 && (
                    <Button onClick={this.changeActiveStep.bind(this)} className={classes.button}>
                        {" Back"}
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleNext.bind(this)}
                    className={classes.button}
                    style={{ textTransform: "none" }}
                >
                    {this.state.activeStep === this.state.steps.length - 1 ? this.getUploadImageText() : 'Next'}
                </Button>
            </div>
        </React.Fragment>
    }

    async handleFormUpdating() {
        await this.isFormEmpty()
        this.props.formNotEmpty(this.state.formEmpty)
    }

    renderMetadata(): JSX.Element {
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    {this.props.numberOfImages === 1 ? "Tell us about the image" : "Tell us about the images"}
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="projectName"
                            name="projectName"
                            label="Project Name"
                            fullWidth
                            error={this.state.shouldShowErrorProject && this.state.projectName === ""}
                            defaultValue={this.state.projectName}
                            onChange={(event: any) => { this.setState({ projectName: event.target.value }, async () => { await this.handleFormUpdating() }) }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="sourceName"
                            name="sourceName"
                            label="Source Name"
                            fullWidth
                            error={this.state.shouldShowErrorSource && this.state.sourceName === ""}
                            defaultValue={this.state.sourceName}
                            onChange={(event: any) => { this.setState({ sourceName: event.target.value }, async () => { await this.handleFormUpdating() }) }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="folder"
                            name="folder"
                            label="Folder"
                            fullWidth
                            error={this.state.shouldShowErrorFolder && this.state.folder === ""}
                            defaultValue={this.state.folder}
                            onChange={(event: any) => { this.setState({ folder: event.target.value }, async () => { await this.handleFormUpdating() }) }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="subfolder"
                            name="subfolder"
                            label="Subfolder"
                            fullWidth
                            error={this.state.shouldShowErrorSubfolder && this.state.subfolder === ""}
                            defaultValue={this.state.subfolder}
                            onChange={(event: any) => { this.setState({ subfolder: event.target.value }, async () => { await this.handleFormUpdating() }) }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="displayName"
                            name="displayName"
                            label="Display Name"
                            fullWidth
                            defaultValue={this.state.subfolder ?? ""}
                            onChange={(event: any) => {
                                let newDisplayName: string | null = event.target.value;
                                if (newDisplayName === "") {
                                    newDisplayName = null
                                }
                                this.setState({ displayName: newDisplayName }, async () => { await this.handleFormUpdating() })
                            }}
                        />
                    </Grid>
                    {this.state.labels.map((input, index) => {
                        return this.addLabelsTextField(index)
                    })}
                </Grid>
            </React.Fragment>
        );
    }

    addLabelsTextField(index: number): JSX.Element {
        const curValue: string = this.state.labels[index]
        const shouldShowError: boolean = this.state.triedAddingNewLabelsWithoutChangingCur && (curValue === "")
        const indexString: string = index.toString()
        return <Grid xs={12} container spacing={1} item>
            {/* <form onSubmit={(event: React.SyntheticEvent) => {
        event.preventDefault()
        let localNewLabels: string[] = this.state.labels
        if (localNewLabels[index] === "") {
          this.setState({ triedAddingNewLabelsWithoutChangingCur: true })
          return
        }
        localNewLabels.push("")
        this.setState({
          labels: localNewLabels, triedAddingNewLabelsWithoutChangingCur: false
        })
      }}> */}
            <FormControl fullWidth margin="dense">
                <TextField
                    variant="outlined"
                    type="string"
                    id={indexString}
                    label="New Label"
                    name={indexString}
                    size="small"
                    error={shouldShowError}
                    helperText={shouldShowError ? "You didn't add a label!" : ""}
                    value={curValue}
                    onChange={(event: any) => {
                        let localNewLabels: string[] = this.state.labels
                        localNewLabels[index] = event.target.value
                        this.setState({
                            labels: localNewLabels,
                        })
                    }}
                    className="new label"
                    InputProps={{
                        endAdornment: index + 1 ===
                            this.state.labels.length ? (
                            <InputAdornment position="end">
                                <Tooltip title="Add Another Label">
                                    <Fab
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            let localNewLabels: string[] = this.state.labels
                                            if (localNewLabels[index] === "") {
                                                this.setState({ triedAddingNewLabelsWithoutChangingCur: true })
                                                return
                                            }
                                            localNewLabels.push("")
                                            this.setState({
                                                labels: localNewLabels, triedAddingNewLabelsWithoutChangingCur: false
                                            })
                                        }}
                                    >
                                        <AddIcon />
                                    </Fab>
                                </Tooltip>
                            </InputAdornment>
                        ) : <InputAdornment position="end">
                            <Tooltip title="Remove Label">
                                <Fab
                                    // type="submit"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        let localNewLabels: string[] = this.state.labels
                                        localNewLabels.splice(index, 1)
                                        this.setState({
                                            labels: localNewLabels, triedAddingNewLabelsWithoutChangingCur: false
                                        })
                                    }}
                                // onSubmit={(event: React.SyntheticEvent) => {
                                //   event.preventDefault()
                                //   let localNewLabels: string[] = this.state.labels
                                //   if (localNewLabels[index] === "") {
                                //     this.setState({ triedAddingNewLabelsWithoutChangingCur: true })
                                //     return
                                //   }
                                //   localNewLabels.push("")
                                //   this.setState({
                                //     labels: localNewLabels, triedAddingNewLabelsWithoutChangingCur: false
                                //   })
                                // }}
                                >
                                    <RemoveIcon />
                                </Fab>
                            </Tooltip>
                        </InputAdornment>
                    }}
                />
            </FormControl>
        </Grid>
    }

    renderReview() {
        const { classes }: any = this.props;
        const labels: string[] = this.state.labels
        let labelString: string = labels[0]
        for (let i = 1; i < labels.length; i++) {
            const curLabel: string = labels[i]
            if (curLabel === "") {
                continue
            }
            labelString += `, ${curLabel}`
        }
        if (labelString === "") {
            labelString = "No labels"
        }
        return (
            <React.Fragment>
                <Typography variant="h6" gutterBottom>
                    {"Upload summary"}
                </Typography>
                <List disablePadding>
                    <ListItem className={classes.listItem}>
                        <ListItemText primary="Project Name" />
                        <Typography variant="subtitle1" className={classes.total}>
                            {this.state.projectName}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText primary="Source Name" />
                        <Typography variant="subtitle1" className={classes.total}>
                            {this.state.sourceName}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText primary="Folder" />
                        <Typography variant="subtitle1" className={classes.total}>
                            {this.state.folder}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText primary="Subfolder" />
                        <Typography variant="subtitle1" className={classes.total}>
                            {this.state.subfolder}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText primary="Display Name" />
                        <Typography variant="subtitle1" className={classes.total}>
                            {this.state.displayName ?? "No Display Name chosen"}
                        </Typography>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <ListItemText primary="Labels" />
                        <Typography variant="subtitle1" className={classes.total}>
                            {labelString}
                        </Typography>
                    </ListItem>
                </List>
            </React.Fragment>
        );
    }

}
const styles = (theme: Theme) => createStyles({
    modalPaper: {
        position: 'absolute',
        width: 800,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    listItem: {
        padding: theme.spacing(1, 0),
    },
    total: {
        fontWeight: 2048,
    },
    title: {
        marginTop: theme.spacing(2),
    },
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    formPaper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
        textTransform: "none"
    },
});

export default withStyles(styles)(ResearchUploadForm)
