import React from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import MoreIcon from '@material-ui/icons/More';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { Button } from '@material-ui/core';
import gql from 'graphql-tag'
import client from '../GraphQL/client'
import { trainingSessions as TrainingSessions, trainingSessions_trainingSessions as TrainingSession } from './types/trainingSessions';
import { UpdateTensorboardSessionsVariables } from './types/UpdateTensorboardSessions';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isDevelopment } from '../Config/Env'
import { deleteAlgoExperimentVariables } from './types/deleteAlgoExperiment';
import { archiveAlgoExperimentVariables } from './types/archiveAlgoExperiment';
import AppContextProvider from '../Context/AppContextProvider'
import AppContext from '../Context/Context'
import Dialog from "../Components/Dialog"
interface Istate {
  anchorEl: any
  sessions: TrainingSession[]
  selectedSessions: Map<string, boolean>
  isLoadingSessions: boolean
  isArchavingFilderNamed: string | null
  shouldNotifyDelete: boolean
  sessionDeleteCandidate: TrainingSession | null
}

class TensorBoardPage extends React.Component<any, Istate> {

  state: Istate = {
    anchorEl: null,
    sessions: [],
    selectedSessions: new Map<string, boolean>(),
    isLoadingSessions: false,
    isArchavingFilderNamed: null,
    shouldNotifyDelete: false,
    sessionDeleteCandidate: null
  }

  componentDidMount() {
    this.getTrainingSessions()
  }

  componentWillUnmount() {
    client.stop()
  }

  // server
  async getTrainingSessions() {
    try {
      const res = await client.query<TrainingSessions>({
        query: gql`
          query trainingSessions {
            trainingSessions {
              s3Key
            }
          }
        `})
      this.setState({ sessions: res.data?.trainingSessions ?? [] })
    } catch (error) {
      // alert(error.message)
    }
  }

  async deleteAlgoExperiment(s3FolderKey: string) {
    try {
      const res = await client.query<any, deleteAlgoExperimentVariables>({
        query: gql`
          mutation deleteAlgoExperiment($s3FolderKey:String){
            deleteAlgoExperiment(s3FolderKey:$s3FolderKey) {
              ok
            }
          }
        `, variables: {
          s3FolderKey: s3FolderKey
        }
      })
      this.getTrainingSessions()
    } catch (error) {
      // alert(error.message)
    }
  }

  async aechiveAlgoExperiment(s3FolderKey: string) {
    try {
      const res = await client.query<any, archiveAlgoExperimentVariables>({
        query: gql`
          mutation archiveAlgoExperiment($s3FolderKey:String){
            archiveAlgoExperiment(s3FolderKey:$s3FolderKey) {
              ok
            }
          }
        `, variables: {
          s3FolderKey: s3FolderKey
        }
      })
      this.handleClose()
      this.getTrainingSessions()
    } catch (error) {
      // alert(error.message)
    }
  }

  async applyChanges(username: string) {
    const usernameArray: string[] = username.split(" ")
    const username_joined = usernameArray.join("_").toLocaleLowerCase()
    this.setState({ isLoadingSessions: true })
    const s3FilesKeys: string[] = []
    this.state.selectedSessions.forEach(function (value, key) {
      if (value === true) {
        s3FilesKeys.push(key)
      }
    })
    try {
      await client.mutate<any, UpdateTensorboardSessionsVariables>({
        mutation: gql`
          mutation UpdateTensorboardSessions($s3FilesKeys: [String], $username: String!) {
            updateTensorboardSessions(s3FilesKeys: $s3FilesKeys, username: $username) {
              ok
            }
          }
        `, variables: {
          s3FilesKeys: s3FilesKeys,
          username: username_joined
        }
      })
      // this.refreshIframe()
    } catch (error) {
      // alert(error.message)
    }
    this.setState({ isLoadingSessions: false })
  }

  // functions
  refreshIframe() {
    var iframe: any = document.getElementById('tf_iframe');
    // eslint-disable-next-line
    iframe.src = iframe.src;
  }

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  notifyDelete(): JSX.Element {
    return <Dialog
      shouldOpen={this.state.shouldNotifyDelete}
      onClose={() => { }}
      dialogTitle="Are you sure you want to delete this experiment?"
      dialogContentText={""}
      renderDialogContent={() => { }}
      onClickAffirmative={() => {
        this.setState({ anchorEl: null, shouldNotifyDelete: false }, () => {
          if (this.state.sessionDeleteCandidate) {
            this.deleteAlgoExperiment(this.state.sessionDeleteCandidate.s3Key)
          }
        })
      }}
      affirmativeButtonText="Yes"
      onClickNegative={() => { this.setState({ shouldNotifyDelete: false }, () => this.handleClose()) }}
      negativeButtonText="No"
    />
  }

  // UI
  public render() {
    const { classes } = this.props
    return (
      <div>
        {this.state.shouldNotifyDelete ? this.notifyDelete() : <div />}
        <div className={classes.root}>
          <iframe
            title={"tf_iframe"}
            id={"tf_iframe"}
            width="100%"
            height="100%"
            src={isDevelopment ? "http://localhost:6006/" : "https://labs.bria.ai:6006/"}
          />
          <div>
            {
              this.state.isLoadingSessions ?
                <CircularProgress className={classes.fabButton} color="secondary" /> :
                <Fab
                  color="secondary"
                  aria-label="add"
                  className={classes.fabButton}
                  onClick={this.state.sessions.length === 0 ? undefined : this.handleClick}
                >
                  <MoreIcon />
                </Fab>
            }
            <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
            >
              {this.state.sessions.map(session => {
                const fileName = session.s3Key.slice(0, -1).split("/").pop() ?? ""
                return (
                  <MenuItem >
                    <Checkbox checked={this.state.selectedSessions.get(fileName)}
                      onChange={(_, checked) => {
                        if (checked) {
                          this.state.selectedSessions.set(fileName, true)
                        } else {
                          this.state.selectedSessions.delete(fileName)
                        }
                      }}
                    />
                    {fileName}
                    <div className={classes.flexGrow}></div>
                    {this.state.isArchavingFilderNamed === session.s3Key ? <CircularProgress /> :
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={this.state.isArchavingFilderNamed === session.s3Key}
                        onClick={_ => {
                          this.setState({ isArchavingFilderNamed: session.s3Key })
                          this.aechiveAlgoExperiment(session.s3Key)
                        }}
                        className={classes.experimentActionButton}
                      >
                        {"ARCHIVE"}
                      </Button>
                    }

                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => this.setState({ sessionDeleteCandidate: session, shouldNotifyDelete: true })}
                      className={classes.experimentActionButton}
                    >
                      {"DELETE"}
                    </Button>
                  </MenuItem>
                )
              })}
              <AppContextProvider>
                <AppContext.Consumer>
                  {(context) => {
                    return <Button
                      variant="contained"
                      color="primary"
                      onClick={_ => {
                        this.handleClose()
                        if (context.user === null) {
                          alert("Unable to complete task - user hasn't been authenticated")
                        }
                        else { this.applyChanges(context.user.userName) }
                      }}
                      className={classes.button}
                    >
                      {"APPLY CHANGES"}
                    </Button>
                  }
                  }
                </AppContext.Consumer>
              </AppContextProvider>
            </Menu>
          </div>
        </div>
      </div>
    )
  }
}

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: "100vh",
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: '90%',
    left: '92%',
    right: 0,
    margin: '0 auto',
  },
  button: {
    width: "98%",
    height: 60,
    margin: theme.spacing(1),
  },
  flexGrow: {
    flexGrow: 1,
  },
  experimentActionButton: {
    width: 120,
    height: 40,
    margin: theme.spacing(1),
  }
})

export default withStyles(styles)(TensorBoardPage)
