import React from 'react';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  circularProgressContainer: {
    background: 'rgba(0,0,0,0.7)',
    position: 'fixed',
    width: '100%',
    height: '100%',
    zIndex: 2,
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
  circularProgress: {
    position: 'relative',
    top: 'calc(50% - 50px)',
    left: 'calc(50% - 50px)',
  },
}));

const LoadingOverlay = () => {
  const classes = useStyles();

  return (
    <Box className={classes.circularProgressContainer}>
      <CircularProgress size={100} className={classes.circularProgress} />
    </Box>
  );
};

export default LoadingOverlay;
