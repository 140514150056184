/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AWSInstanceAction {
  START = "START",
  STOP = "STOP",
}

export enum DBName {
  DATASETS = "DATASETS",
  RESEARCH = "RESEARCH",
  VDR = "VDR",
}

export enum GCPInstanceAction {
  LIST = "LIST",
  START = "START",
  STOP = "STOP",
}

export enum PlatformName {
  AWS = "AWS",
  GCP = "GCP",
}

export interface Config {
  direction: string;
  speed: number;
}

export interface ErrDataDict {
  avgPixelErr?: number | null;
}

export interface FaceData {
  gender: string;
  age?: number | null;
  smile: boolean;
  glasses: boolean;
}

export interface ImageSemantic {
  name: string;
  value: number;
  minName?: string | null;
  maxName?: string | null;
  minValue?: number | null;
  maxValue?: number | null;
  minLayer?: number | null;
  maxLayer?: number | null;
  pca?: number | null;
  reversed?: number | null;
}

export interface PipelineSetting {
  name: string;
  description: string;
  isOn: boolean;
}

export interface Rect {
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface ThumbnailData {
  thumbnailIndex: number;
  semantics: ImageSemantic[];
  ethnicity?: string | null;
  ethnicityValue?: number | null;
}

export interface VDRUploadParameters {
  query: string;
  description?: string | null;
  projectId: string;
  sendToSemSeg: boolean;
  validForTraining: boolean;
  internalUse: boolean;
  imageRefUrl?: string | null;
  imageKey?: string | null;
}

export interface VideoInfoParameters {
  bestFrameIndex?: number | null;
  frames?: (number | null)[] | null;
  labels?: string | null;
  videoPath: string;
  errDataDict?: ErrDataDict | null;
}

export interface WebOperations {
  duration: number;
  operationName: string;
  tags?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
