import {
	getAnalytics,
	logEvent,
	setUserProperties,
	setAnalyticsCollectionEnabled,
	setCurrentScreen,
} from "firebase/analytics";
import { isDevelopment } from "../Config/Env";
import Cookies from "js-cookie";

export enum ANALYTICS_EVENTS {
	CHANGE_ETHNICITY = "CHANGE_ETHNICITY",
	CHANGE_EXPRESSION = "CHANGE_EXPRESSION",
	CHANGE_APPEARANCE = "CHANGE_APPEARANCE",
	DOWNLOAD_IMAGE_MESSAGE = "IMAGE_DOWNLOADED_SUCCESSFULLY",
	DOWNLOAD_LAYERS_MESSAGE = "LAYERS_DOWNLOADED_SUCCESSFULLY",
	DOWNLOAD_IMAGE = "DOWNLOAD_IMAGE",
	DOWNLOAD_LAYERS = "DOWNLOAD_LAYERS",
	UNDO = "UNDO",
	REDO = "REDO",
	RESET = "RESET",
	createLivelinessFace = "VIDEO CREATED",
	uploadImageToVdr = "Images uploaded to VDR",
	uploadImageToResearch = "Images uploaded to Research",
}

class Analytics {
	analytics = getAnalytics();

	constructor() {
		this.initialize();
	}

	initialize() {
		try {
			setAnalyticsCollectionEnabled(
				this.analytics,
				isDevelopment === false
			);
			console.log(Cookies.get("_g1"));
		} catch (e) {}
	}

	post(event: string, params: Map<string, any> = new Map<string, any>()) {
		logEvent(this.analytics, event.toUpperCase(), params);
	}

	setUserProperties(role: string) {
		setUserProperties(this.analytics, {
			role: role,
		});
	}

	logEvent(
		eventName: ANALYTICS_EVENTS,
		eventParams?: { [key: string]: any }
	) {
		logEvent(this.analytics, eventName, eventParams);
	}

	logToolsEvent(
		eventName: ANALYTICS_EVENTS,
		{
			changeName,
			value,
			source,
		}: { changeName: string; value: number; source: String }
	) {
		logEvent(this.analytics, eventName, { changeName, value, source });
	}

	setCurrentScreen(location: string) {
		setCurrentScreen(this.analytics, location);
	}
}

export default new Analytics();
