export const normalizeSliderValue = (a0: number, a1: number,  b0: number,  b1: number,  a: number): number => {
	return b0 + (b1 - b0) * ((a-a0)/(a1-a0));
}

export const normalizeSliderValueToBaseTen = (a0: number, a1: number, a: number): number => {
	return normalizeSliderValue(a0, a1, -10, 10, a)
}

export const getValueFromNormalizedRange = (a0: number, a1: number,  b0: number,  b1: number,  b: number): number => {
	return a0 + (((b-b0) * (a1 - a0)) / (b1-b0));
}

export const getValueFromNormalizedRangeBaseTen = (a0: number, a1: number,  b: number): number => {
	return getValueFromNormalizedRange(a0, a1, -10, 10, b);
}
