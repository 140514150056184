import { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import ThumbnailSlider from "../UI/ThumbnailSlider";
import { DrawerProps } from "@material-ui/core/Drawer";
import Context from "../../Context/Context";
import { BriaObjectType, ToolsViewerMode } from "./utilities";
import { Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
	ToolConfigTabsTypes,
	ToolsSlider,
	ToolsThumbnails,
} from "../../Models/ToolsConfig";
import ThumbnailCardNew from "../UI/ThumbnailCardNew";
import clsx from "clsx";
import { ImageSemantic } from "../../types/graphql-global-types";
import {
	getValueFromNormalizedRangeBaseTen,
	normalizeSliderValueToBaseTen,
} from "../../Helpers/math";
import { useTranslation } from "react-i18next";
import BriaButton from "../UI/BriaButton";
import ToolsEmptyState from "../UI/ToolsEmptyState";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		height: "100%",
	},
	slidersContainer: {
		maxHeight: "100%",
	},
	thumbnailsContainer: {
		overflow: "auto",
		height: "100%",
		paddingLeft: 0,
		paddingRight: 0,
	},
	bothContainer: {
		maxHeight: "50%",
	},
	bottomThumbnailsView: {
		height: "100%",
		paddingLeft: theme.spacing(5),
		paddingRight: theme.spacing(5),
	},
}));

interface IProps {
	disabled?: boolean;
	thumbnails: ToolsThumbnails[];
	drawerAnchor: DrawerProps["anchor"];
	sliders: ToolsSlider[] | null;
	personalizerKey: string;
	isSuggestion?: boolean;
	// children?: React.ReactChild;
	personalizerValues: { [key: string]: number };
	onChangePersonalizer: (sliders: ToolsSlider[]) => void;
	onAddSemanticCombination: (
		sematics: ToolsSlider[] | ImageSemantic[],
		controller: any
	) => Promise<string>;
	objectType: any;
}

const caluclateThubmnailValue = (
	currentValue: number,
	thumbnailsCount: number,
	index: number
): number => {
	if (thumbnailsCount === 2) {
		let values: number[] = [];
		if (-10 <= currentValue && currentValue <= -5) {
			values = [1, 6];
		}
		if (
			(-4 <= currentValue && currentValue <= 0) ||
			(1 <= currentValue && currentValue <= 5)
		) {
			values = [-6, 6];
		}
		if (6 <= currentValue && currentValue <= 10) {
			values = [-1, -6];
		}

		return values[index];
	} else {
		if (
			(-10 <= currentValue && currentValue <= -6) ||
			(6 <= currentValue && currentValue <= 10)
		) {
			return 0;
		}
		if (-5 <= currentValue && currentValue <= 0) {
			return 6;
		}
		if (1 <= currentValue && currentValue <= 5) {
			return -6;
		}
	}
	return 0;
};

export default function Personalizer({
	disabled,
	personalizerValues,
	thumbnails,
	drawerAnchor,
	sliders,
	personalizerKey,
	isSuggestion,
	onChangePersonalizer,
	onAddSemanticCombination,
	objectType,
}: IProps) {
	const { preferences, user } = useContext(Context);
	const currentStyle = preferences?.style;
	const [count, setCount] = useState(6);
	const { t } = useTranslation();

	const classes = useStyles();

	const mapSliders = (value: number, el: ToolsSlider) => {
		if (el.id === "glasses") {
			return (
				<Box maxWidth={250} width="100%" p={2} key={el.id}>
					<ThumbnailSlider
						key={el.id}
						disabled={disabled}
						defaultValue={el.defaultInitialValue}
						maxValue={el.maxValue}
						minValue={el.minValue}
						leftSubtitle={el.minName}
						rightSubtitle={el.maxName}
						value={value}
						title={el.displayName}
						reversed={el.reversed}
						stepSize={4}
						NormalizationRangeMax={el.maxValue * 4}
						NormalizationRangeMin={el.minValue * 4}
						sliderDefaultValue={el.defaultInitialValue}
						warrning={el.askAlan?.warning}
						error={el.askAlan?.disabled}
						minNameRiskArea={el.minNameRiskArea}
						maxNameRiskArea={el.maxNameRiskArea}
						score={user?.isAdmin() ? el.askAlan?.score : ""}
						handleValueChange={(value) => {
							onChangePersonalizer([{ ...el, value }]);
						}}
					/>
				</Box>
			);
		}
		return (
			<Box maxWidth={250} width="100%" p={2} key={el.id}>
				<ThumbnailSlider
					key={el.id}
					disabled={disabled}
					defaultValue={el.defaultInitialValue}
					maxValue={el.maxValue}
					minValue={el.minValue}
					leftSubtitle={el.minName}
					rightSubtitle={el.maxName}
					value={value}
					title={el.displayName}
					reversed={el.reversed}
					warrning={el.askAlan?.warning}
					error={el.askAlan?.disabled}
					minNameRiskArea={el.minNameRiskArea}
					maxNameRiskArea={el.maxNameRiskArea}
					sliderDefaultValue={el.defaultInitialValue}
					score={user?.isAdmin() ? el.askAlan?.score : ""}
					handleValueChange={(value) => {
						onChangePersonalizer([{ ...el, value }]);
					}}
				/>
			</Box>
		);
	};

	let validSliders = sliders?.filter((el) => !el.hideSlider);
	const personalizerSliderList = (
		<Box flex={1} overflow="auto">
			{validSliders && validSliders.length > 0 ? (
				validSliders.map((el) =>
					mapSliders(personalizerValues[el.id], el)
				)
			) : (
				<ToolsEmptyState />
			)}
		</Box>
	);

	const thumbnailsList = (
		<Box
			flex={1}
			className={clsx({
				[classes.thumbnailsContainer]: drawerAnchor !== "bottom",
				[classes.bottomThumbnailsView]: drawerAnchor === "bottom",
			})}
		>
			{sliders && sliders.length > 0 ? (
				<Box display="flex" flexWrap="wrap">
					{thumbnails
						.slice(
							0,
							count < thumbnails.length
								? count
								: thumbnails.length
						)
						.map((el) => {
							let sliders = el.sliders;
							if (!isSuggestion) {
								sliders = sliders.map((slider) => {
									return {
										...slider,
										value:
											caluclateThubmnailValue(
												normalizeSliderValueToBaseTen(
													slider.minValue,
													slider.maxValue,
													personalizerValues[
														slider.id
													]
												) * slider.reversed,
												el.thumbnails_number ?? 0,
												el.thumbnail_index ?? 0
											) * slider.reversed,
									};
								});
							}

							return (
								<ThumbnailCardNew
									key={el.key}
									personalizerValues={personalizerValues}
									onAddSemanticCombination={(
										operationVariables
									) => {
										return onAddSemanticCombination(
											sliders.map((el: any) => {
												const slider: ToolsSlider =
													el as ToolsSlider;
												return {
													...slider,
													value: getValueFromNormalizedRangeBaseTen(
														slider.minValue,
														slider.maxValue,
														slider.value
													),
												};
											}),
											operationVariables
										);
									}}
									thumbnail={el}
									onClick={() => {
										if (!disabled) {
											onChangePersonalizer(
												sliders.map((el: any) => {
													const slider: ToolsSlider =
														el as ToolsSlider;
													return {
														...slider,
														value: getValueFromNormalizedRangeBaseTen(
															slider.minValue,
															slider.maxValue,
															slider.value
														),
													};
												})
											);
										}
									}}
									warrning={sliders.some(
										(el) =>
											el.askAlan?.warning ||
											el.askAlan?.disabled
									)}
									error={sliders.some(
										(el) => el.askAlan?.disabled
									)}
								>
									<>
										{sliders.map((slider) => {
											const value =
												getValueFromNormalizedRangeBaseTen(
													slider.minValue,
													slider.maxValue,
													slider.value
												);
											return mapSliders(value, slider);
										})}
									</>
								</ThumbnailCardNew>
							);
						})}
					{personalizerKey === ToolConfigTabsTypes.Expression && (
						<Box padding={1}>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
								style={{
									marginBottom: 150,
								}}
							>
								<Typography
									style={{ fontSize: 14, paddingBottom: 25 }}
								>
									Want to see more options for expressions?
								</Typography>
								<BriaButton
									variant="contained"
									onClick={() => {
										user?.updateThumbnailsSettings(
											ToolsViewerMode.sliders
										);
									}}
									style={{
										fontSize: 14,
										backgroundColor: "#DADADA",
										textTransform: "none",
									}}
								>
									Change to sliders view
								</BriaButton>
							</Box>
							<Box
								borderRadius={10}
								padding={1}
								borderColor="#5300C9"
								style={{
									border: "1px solid #5300C9",
								}}
							>
								<Typography align="center" color="primary">
									<strong>Attention</strong>
								</Typography>
								<Typography align="center" color="primary">
									Expression modifications should be
									implemented only after finalising all other
									modifications.
								</Typography>
							</Box>
						</Box>
					)}
					{count < thumbnails.length && (
						<Box
							width="100%"
							display="flex"
							justifyContent="center"
						>
							<BriaButton
								color="default"
								style={{ width: "80%" }}
								onClick={() => {
									setCount(count + 6);
								}}
							>
								{t("loadMore")}
							</BriaButton>
						</Box>
					)}
				</Box>
			) : (
				<ToolsEmptyState />
			)}
		</Box>
	);

	const showSliders =
		(currentStyle === ToolsViewerMode.sliders &&
			personalizerKey !== ToolConfigTabsTypes.Suggestions) ||
		personalizerKey === ToolConfigTabsTypes.Expression ||
		objectType !== BriaObjectType.human;

	return (
		<>
			<Box
				className={classes.root}
				visibility={showSliders ? "visible" : "hidden"}
				maxHeight={showSliders ? "inherit" : 0}
			>
				{personalizerSliderList}
			</Box>
			{currentStyle === ToolsViewerMode.both && <Divider />}
			<Box
				className={classes.root}
				visibility={
					currentStyle === ToolsViewerMode.thumbnails ||
					personalizerKey === ToolConfigTabsTypes.Suggestions
						? "visible"
						: "hidden"
				}
			>
				{thumbnailsList}
			</Box>
		</>
	);
}
