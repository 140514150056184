enum SelectedPage {
	IMAGEREPOSITORY = "VDR",
	BRIATOR_V2 = "Tool",
	INSTANCE_MANAGEMENT = "Instance Management",
	BRIATOR = "Demo",
	DATASET = "Datasets",
	RESEARCH = "Research",
	TENSORBOARD = "Tensor Board",
	GRAFANA = "Graphana",
	USERMAMAGEMENT = "User Managment",
	SETTINGS = "Settings",
    GRAPHQLPLAYGROUND = ' GraphQL Playground'
}

export default SelectedPage;
