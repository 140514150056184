import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import clsx from "clsx";

interface IProps {
	selectedVideo: string | null | undefined;
	setSelectedVideo: React.Dispatch<
		React.SetStateAction<string | null | undefined>
	>;
	openVideoAlert: boolean;
	setOpenVideoAlert: React.Dispatch<React.SetStateAction<boolean>>;
	handleResetActions: () => void;
}

const CustomizedDialog = withStyles((theme) => ({
	paperWidthFalse: {
		width: 440,
		maxWidth: 440,
		height: 219,
		padding: "30px 27px 19px 2px",
		border: "1px solid",
		// borderColor:
		// 	"linear-gradient(#5300C9 at 0%, #8800FF at 15.46%, #D80067 at 49.51%, rgba(216, 0, 103, 0.08) at 94.03%)",
	},
}))(Dialog);

const CustomizedDialogTitle = withStyles((theme) => ({
	root: {
		padding: 0,
	},
}))(DialogTitle);

const VideoAlert = ({
	openVideoAlert,
	setOpenVideoAlert,
	selectedVideo,
	setSelectedVideo,
	handleResetActions,
}: IProps) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const handleDownload = () => {
		setOpenVideoAlert(false);
		setSelectedVideo(null);
		handleResetActions();
	};

	const handleClose = () => {
		setOpenVideoAlert(false);
	};

	return (
		<CustomizedDialog
			open={openVideoAlert}
			onClose={handleClose}
			maxWidth={false}
			fullWidth
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<CustomizedDialogTitle id="alert-dialog-title">
				<IconButton
					color="inherit"
					aria-label="close"
					onClick={handleClose}
					style={{ position: "relative", float: "right" }}
				>
					<CloseIcon />
				</IconButton>
			</CustomizedDialogTitle>

			<DialogContent>
				<DialogContentText
					id="alert-dialog-description"
					className={classes.text}
				>
					{t("wouldYouLikeToDownloadThisVideo?")}
				</DialogContentText>
			</DialogContent>
			<Box
				display="flex"
				justifyContent="space-between"
				paddingLeft="24px"
			>
				<Button
					onClick={() => {
						setSelectedVideo(null);
						handleClose();
					}}
					color="primary"
					className={clsx(classes.text, classes.button)}
					style={{ width: 48 }}
				>
					{t("cancel")}
				</Button>
				<Box display="flex">
					<Button
						onClick={handleClose}
						color="secondary"
						variant="outlined"
						startIcon={<AddIcon />}
						className={classes.button}
						style={{ marginRight: 9 }}
					>
						{t("addToGallery")}
					</Button>
					<Button
						onClick={handleDownload}
						color="secondary"
						autoFocus
						variant="contained"
						className={classes.button}
						href={selectedVideo || ""}
					>
						{t("download")}
					</Button>
				</Box>
			</Box>
		</CustomizedDialog>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		text: {
			color: theme.palette.text.primary,
		},
		button: {
			borderRadius: 20,
			textTransform: "unset",
		},
	})
);

export default VideoAlert;
