import { useCallback, useContext, useEffect, useState } from "react";
import { getFaceData } from "../Components/Tools/utilities";
import Context from "../Context/Context";
import { ImageEditorData_vdrObject_vdrAwsFaceDetection_faces } from "../GraphQL/types/imageEditorData";
import {
	ToolsSlider,
	ToolsStructure,
	ToolsSuggestions,
	ToolsThumbnails,
	ToolsValidatoin,
} from "../Models/ToolsConfig";
import useAskAlan from "./useAskAlan";

let displayNameCounter: { [key: string]: number } = {};

export const useToolConfig = (
	isTesting: boolean,
	objectType: string = "",
	selectedFace:
		| ImageEditorData_vdrObject_vdrAwsFaceDetection_faces
		| undefined,
	selectedFaceIndex: number
) => {
	const [loading, setloading] = useState<boolean>(true);
	const { user } = useContext(Context);
	const [askAlanConfigurations, setaskAlanConfigurations] = useState<any>({});
	const [toolsConfiguration, settoolsConfiguration] = useState<any>({});
	const [toolsConfigTabs, settoolsConfigTabs] = useState<ToolsStructure[]>(
		[]
	);
	const [toolsConfigSliders, settoolsConfigSliders] = useState<ToolsSlider[]>(
		[]
	);
	const [toolsConfigThumbnails, settoolsConfigThumbnails] = useState<
		ToolsThumbnails[]
	>([]);
	const [toolsConfigSuggestions, settoolsConfigSuggestions] = useState<
		ToolsSuggestions[]
	>([]);
	let faceData: any = {};
	if (selectedFace) {
		faceData = getFaceData(selectedFace);
	}

	const { askAlanScoresList, getAskAlanScores } = useAskAlan();

	useEffect(() => {
		const controller = new AbortController();
		const signal = controller.signal;

		fetch(
			user?.isViewer()
				? "https://bria-image-repository.s3.amazonaws.com/web-assets/ui-config-viewer.json"
				: !isTesting
				? "https://bria-image-repository.s3.amazonaws.com/web-assets/ui-config.json"
				: "https://bria-image-repository.s3.amazonaws.com/web-assets/ui-testing-config.json",
			{
				signal: signal,
			}
		)
			.then((res) => {
				return res.json();
			})
			.then((res) => {
				setloading(false);
				settoolsConfiguration(res);
				setaskAlanConfigurations(res.askAlanConfig);
			});
		return () => {
			controller.abort();
		};
	}, [isTesting]);

	const filterSliders = useCallback(
		(el: ToolsValidatoin) => {
			return el?.gender === undefined || el.gender === faceData?.gender;
		},
		[faceData?.gender]
	);

	const askAlanFilteration = (slider: ToolsSlider) => {
		let showSlider = true;
		if (
			askAlanScoresList === null ||
			askAlanScoresList[selectedFaceIndex] === null
		) {
			return true;
		}
		const askAlanFaceScore = askAlanScoresList[selectedFaceIndex] ?? [];
		const alanScore = askAlanFaceScore.find(
			(el) => el.sliderId === slider.id
		);
		if (alanScore && askAlanConfigurations) {
			if (!askAlanConfigurations["show_disabled"]) {
				showSlider =
					showSlider &&
					alanScore.score >
						askAlanConfigurations["disabled_threshold"];
			}
			if (!askAlanConfigurations["show_alerts"]) {
				showSlider =
					showSlider &&
					alanScore.score > askAlanConfigurations["alerts_threshold"];
			}
			slider.askAlan = {
				score: alanScore.score,
				disabled:
					alanScore.score <
					askAlanConfigurations["disabled_threshold"],
				warning:
					alanScore.score < askAlanConfigurations["alerts_threshold"],
			};
		}
		return showSlider;
	};

	const duplicateDispalyNameHandler = (displayName: string) => {
		if (!displayNameCounter[displayName]) {
			displayNameCounter[displayName] = 0;
		}
		displayNameCounter[displayName]++;
		return `${displayName} ${displayNameCounter[displayName]}`;
	};
	useEffect(() => {
		if (
			toolsConfiguration?.structure &&
			toolsConfiguration?.structure[objectType]
		) {
			displayNameCounter = {};
			let toolsConfigTabs: ToolsStructure[] =
				toolsConfiguration?.structure[objectType] ?? [];

			let toolsConfigSliders: ToolsSlider[] = (
				toolsConfiguration?.sliders[objectType] ?? []
			)
				.filter((el: ToolsSlider) => filterSliders(el.validation))
				.filter((el: ToolsSlider) => askAlanFilteration(el));

			let displayNameList: { [key: string]: number } = {};
			toolsConfigSliders.forEach(({ displayName }: ToolsSlider) => {
				if (!displayNameList[displayName]) {
					displayNameList[displayName] = 0;
				}
				displayNameList[displayName]++;
			});
			toolsConfigSliders = toolsConfigSliders.map((el: ToolsSlider) => {
				return {
					...el,
					displayName:
						displayNameList[el.displayName] > 1
							? duplicateDispalyNameHandler(el.displayName)
							: el.displayName,
				};
			});

			let toolsConfigThumbnails: ToolsThumbnails[] = (
				toolsConfiguration?.thumbnails[objectType] ?? []
			)
				.filter((el: ToolsThumbnails) => filterSliders(el.validation))
				.filter((el: ToolsThumbnails) =>
					el?.sliders?.every((el) => askAlanFilteration(el))
				);
			displayNameList = {};
			toolsConfigThumbnails.forEach(({ title }: ToolsThumbnails) => {
				if (!displayNameList[title]) {
					displayNameList[title] = 0;
				}
				displayNameList[title]++;
			});
			toolsConfigThumbnails = toolsConfigThumbnails.map(
				(el: ToolsThumbnails) => {
					return {
						...el,
						title:
							displayNameList[el.title] > 1
								? duplicateDispalyNameHandler(el.title)
								: el.title,
					};
				}
			);

			let toolsConfigSuggestions: ToolsSuggestions[] = (
				toolsConfiguration?.suggestions ?? []
			)
				.filter((el: ToolsSuggestions) =>
					el?.thumbnails?.every((el) => filterSliders(el.validation))
				)
				.filter((el: ToolsSuggestions) =>
					el?.thumbnails?.some((el) => askAlanFilteration(el))
				);

			const defaultInitialValues =
				toolsConfiguration?.initialValues[objectType] ?? {};

			Object.keys(defaultInitialValues).forEach((key) => {
				const conditions: any[] = defaultInitialValues[key] ?? [];
				conditions?.forEach((element) => {
					switch (key) {
						case "age":
							if (
								faceData.age >= element.min &&
								faceData.age <= element.max
							) {
								const slider = toolsConfigSliders.find(
									(el) => el.id === element?.sliderId
								);
								if (slider) {
									slider.defaultInitialValue = element.value;
									slider.value = element.value;
								}
							}
							break;
						case "gender":
							if (
								faceData.gender.toLowerCase() ===
								element.gender.toLowerCase()
							) {
								const slider = toolsConfigSliders.find(
									(el) => el.id === element?.sliderId
								);
								if (slider) {
									slider.defaultInitialValue = element.value;
									slider.value = element.value;
								}
							}

							break;
						case "glasses":
							if (faceData.glasses === element.isOn) {
								const slider = toolsConfigSliders.find(
									(el) => el.id === element?.sliderId
								);
								if (slider) {
									slider.defaultInitialValue = element.value;
									slider.value = element.value;
								}
							}
							break;

						default:
							break;
					}
				});
			});

			settoolsConfigSliders(toolsConfigSliders);
			settoolsConfigTabs(toolsConfigTabs);
			settoolsConfigThumbnails(toolsConfigThumbnails);
			settoolsConfigSuggestions(toolsConfigSuggestions);
		}
	}, [
		toolsConfiguration,
		objectType,
		selectedFace,
		filterSliders,
		faceData.age,
		faceData.gender,
		faceData.glasses,
		askAlanConfigurations,
		askAlanScoresList,
		selectedFaceIndex,
	]);

	return {
		getAskAlanScores,
		toolsLoading: loading,
		toolsConfigTabs,
		toolsConfigSliders,
		toolsConfigThumbnails,
		toolsConfigSuggestions,
	};
};
