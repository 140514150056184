import { Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { DrawerProps } from "@material-ui/core/Drawer";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import AppContextProvider from "../../Context/Context";
import CustomNextArrow from "../UI/CustomNextArrow";
import CustomPrevArrow from "../UI/CustomPrevArrow";
import LiveVideoItem from "../UI/LiveVideoItem";
import ThumbnailSlider from "../UI/ThumbnailSlider";
import ToolsLoader from "../UI/ToolsLoader";
import { LiveFaces } from "./useLiveFaces";
import VideoAlert from "./VideoAlert";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CardsCheckbox from "../UI/CardsCheckbox";

interface IProps {
	liveFaces: LiveFaces[];
	onCreateLiveFace: (index: number, videoConfig?: any) => void;
	loading?: boolean;
	disabled?: boolean;
	selectedVideo: string | null | undefined;
	setSelectedVideo: React.Dispatch<
		React.SetStateAction<string | null | undefined>
	>;
	openVideoAlert: boolean;
	setOpenVideoAlert: React.Dispatch<React.SetStateAction<boolean>>;
	handleResetActions: () => void;
	drawerAnchor: DrawerProps["anchor"];
}

const LiveFacesTab: React.FC<IProps> = ({
	liveFaces,
	loading,
	disabled,
	onCreateLiveFace,
	selectedVideo,
	setSelectedVideo,
	openVideoAlert,
	setOpenVideoAlert,
	handleResetActions,
	drawerAnchor,
}) => {
	const { t } = useTranslation();
	const context = useContext(AppContextProvider);
	const classes = useStyles();
	const [speed, setSpeed] = useState<number>(0);
	const [time, setTime] = useState<number>(0);
	const [loop, setLoop] = useState<boolean>(false);
	const [highResolution, setHighResolution] = useState<boolean>(false);
	const theme = useTheme();

	if (loading || !Boolean(liveFaces)) {
		return <ToolsLoader />;
	}

	if (liveFaces.length === 0) {
		return (
			<Typography align="center">{t("noLiveFacesAvailable")}</Typography>
		);
	}

	const settings = {
		variableWidth: liveFaces.length < 6,
		dots: false,
		infinite: false,
		draggable: false,
		initialSlide: 0,
		speed: 500,
		slidesToShow: liveFaces.length >= 8 ? 8 : liveFaces.length,
		slidesToScroll: liveFaces.length >= 8 ? 8 : liveFaces.length,
		prevArrow: <CustomPrevArrow />,
		nextArrow: <CustomNextArrow />,
		responsive: [
			{
				breakpoint: theme.breakpoints.values.xl,
				settings: {
					slidesToShow: 8,
					slidesToScroll: 8,
				},
			},
			{
				breakpoint: theme.breakpoints.values.lg,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 6,
				},
			},
			{
				breakpoint: theme.breakpoints.values.md,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
				},
			},
			{
				breakpoint: theme.breakpoints.values.sm,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
		],
	};
	return drawerAnchor === "bottom" ? (
		<Box px={6} margin="auto" maxWidth={1500}>
			<Slider {...settings}>
				{liveFaces.map((liveFace: LiveFaces, index: number) => (
					<LiveVideoItem
						key={liveFace.videoUrl}
						// showTitle={context.preferences?.withLabels}
						title={liveFace?.title || ""}
						videoURL={liveFace.videoUrl}
						error={
							context.user?.isAdmin()
								? liveFace.videoInfo?.errDataDict?.avgPixelErr
								: undefined
						}
					>
						{(toggleExpand) => (
							<Box>
								<ThumbnailSlider
									small={true}
									title={t("speed")}
									name="time"
									minValue={1}
									maxValue={5}
									stepSize={1}
									value={speed}
									disabled={disabled}
									sliderDefaultValue={1}
									handleValueChange={(value) =>
										setSpeed(value)
									}
									leftSubtitle={t("verySlow")}
									rightSubtitle={t("veryFast")}
								/>
								<ThumbnailSlider
									small={true}
									title={t("time")}
									name="time"
									minValue={1}
									maxValue={5}
									stepSize={1}
									value={time}
									sliderDefaultValue={1}
									disabled={disabled}
									handleValueChange={(value) =>
										setTime(value)
									}
									leftSubtitle={t("verySlow")}
									rightSubtitle={t("veryFast")}
								/>

								<Box display="flex" alignItems="flex-end">
									<FormGroup>
										<FormControlLabel
											label={
												<Typography
													variant="caption"
													className={classes.typo}
												>
													{t("loop")}
												</Typography>
											}
											control={
												<CardsCheckbox
													name="loop"
													disabled={disabled}
													value={loop}
													onChange={(_, checked) =>
														setLoop(checked)
													}
												/>
											}
										/>
									</FormGroup>
									<FormGroup>
										<FormControlLabel
											label={
												<Typography
													variant="caption"
													className={classes.typo}
												>
													{t("highResolution")}
												</Typography>
											}
											control={
												<CardsCheckbox
													name="highRes"
													disabled={disabled}
													value={highResolution}
													onChange={(_, checked) =>
														setHighResolution(
															checked
														)
													}
												/>
											}
										/>
									</FormGroup>
								</Box>
								<Button
									variant="contained"
									size="small"
									fullWidth
									disabled={disabled}
									onClick={() => {
										onCreateLiveFace(index, {
											speed,
											highResolution,
											loop,
											time,
										});

										toggleExpand();
									}}
									className={classes.button}
								>
									{t("createVideo")}
								</Button>
							</Box>
						)}
					</LiveVideoItem>
				))}
			</Slider>
			<VideoAlert
				selectedVideo={selectedVideo}
				setSelectedVideo={setSelectedVideo}
				openVideoAlert={openVideoAlert}
				setOpenVideoAlert={setOpenVideoAlert}
				handleResetActions={handleResetActions}
			/>
		</Box>
	) : (
		<Box
			display="flex"
			flexWrap="wrap"
			justifyContent="center"
			className={classes.root}
		>
			{liveFaces.map((liveFace: LiveFaces, index: number) => (
				<LiveVideoItem
					key={liveFace.videoUrl}
					// showTitle={context.preferences?.withLabels}
					title={liveFace?.title || ""}
					videoURL={liveFace.videoUrl}
					error={
						context.user?.isAdmin()
							? liveFace.videoInfo?.errDataDict?.avgPixelErr
							: undefined
					}
				>
					{(toggleExpand) => (
						<Box>
							<ThumbnailSlider
								title={t("speed")}
								name="time"
								minValue={1}
								maxValue={5}
								stepSize={1}
								value={speed}
								disabled={disabled}
								sliderDefaultValue={1}
								handleValueChange={(value) => setSpeed(value)}
								leftSubtitle={t("verySlow")}
								rightSubtitle={t("veryFast")}
							/>
							<ThumbnailSlider
								title={t("time")}
								name="time"
								minValue={1}
								maxValue={5}
								stepSize={1}
								value={time}
								disabled={disabled}
								sliderDefaultValue={1}
								handleValueChange={(value) => setTime(value)}
								leftSubtitle={t("verySlow")}
								rightSubtitle={t("veryFast")}
							/>

							<Box display="flex" alignItems="flex-end">
								<FormGroup>
									<FormControlLabel
										label={
											<Typography
												variant="caption"
												className={classes.typo}
											>
												{t("loop")}
											</Typography>
										}
										control={
											<CardsCheckbox
												name="loop"
												disabled={disabled}
												value={loop}
												onChange={(_, checked) =>
													setLoop(checked)
												}
											/>
										}
									/>
								</FormGroup>
								<FormGroup>
									<FormControlLabel
										label={
											<Typography
												variant="caption"
												className={classes.typo}
											>
												{t("highResolution")}
											</Typography>
										}
										control={
											<CardsCheckbox
												name="highRes"
												disabled={disabled}
												value={highResolution}
												onChange={(_, checked) =>
													setHighResolution(checked)
												}
											/>
										}
									/>
								</FormGroup>
							</Box>
							<Button
								variant="contained"
								size="small"
								fullWidth
								disabled={disabled}
								onClick={() => {
									onCreateLiveFace(index, {
										speed,
										loop,
										highResolution,
										time,
									});
									toggleExpand();
								}}
								className={classes.button}
							>
								{t("createVideo")}
							</Button>
						</Box>
					)}
				</LiveVideoItem>
			))}
			<VideoAlert
				selectedVideo={selectedVideo}
				setSelectedVideo={setSelectedVideo}
				openVideoAlert={openVideoAlert}
				setOpenVideoAlert={setOpenVideoAlert}
				handleResetActions={handleResetActions}
			/>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: "100%",
		overflow: "auto",
	},
	button: {
		background: theme.palette.cards.main,
		color: theme.palette.common.white,
		fontSize: 14,
		textTransform: "capitalize",
		marginTop: 22,
		marginBottom: 22,
	},
	typo: {
		color: theme.palette.cardTextSecondary.main,
		fontSize: 10,
	},
}));

export default LiveFacesTab;
