import React, { useContext, useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "./UI/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Avatar from "@material-ui/core/Avatar";
import Dialog from "@material-ui/core/Dialog";
import AccountSettings from "./AccountSettings";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Settings from "./Settings";
import { ImageEditorData_metaData_settings } from "../GraphQL/types/imageEditorData";
import Account from "../assets/icons/Account";
import SettingsIcon from "../assets/icons/SettingsIcon";
import Logout from "../assets/icons/Logout";
import { Divider, Link } from "@material-ui/core";
import Context from "../Context/Context";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import LinksConst from "../Constants/LinksConst";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			maxWidth: 360,
			backgroundColor: theme.palette.background.paper,
		},
		textSize: {
			fontSize: 10,
		},
		menuItem: {
			width: 120,
			color: theme.palette.text.primary,
			"&:hover": {
				backgroundColor: theme.palette.menuItemBackground.main,
				opacity: 0.5,
				color: theme.palette.primary.main,
			},
			"&:active": {
				backgroundColor: theme.palette.primary.dark,
				opacity: 1,
				color: theme.palette.common.white,
			},
		},
		listItemIcon: {
			minWidth: 16,
			marginRight: 10,
		},
		itemIcon: {
			fill: "transparent",
			stroke: theme.palette.text.primary,
			fontSize: 16,

			"&:hover": {
				stroke: theme.palette.primary.main,
				opacity: 1,
			},
			"&:active": {
				stroke: theme.palette.common.white,
			},
		},
		backDrop: {
			backdropFilter: "blur(3px)",
			backgroundColor: "rgba(0,0,30,0.4)",
			filter: "blur(3px)",
		},
	})
);
interface IProps {
	pipelineSettings?: ImageEditorData_metaData_settings[];
	handleChangePipelineSettings?: (index: number, value: boolean) => void;
	showSettings?: boolean;
}

const UserMenu: React.FC<IProps> = ({
	pipelineSettings,
	handleChangePipelineSettings,
	showSettings,
}) => {
	const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [openSettingsDialog, setOpenSettingsDialog] = React.useState(false);
	const classes = useStyles();
	const { t } = useTranslation();
	const context = useContext(Context);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleCloseSettings = () => {
		setSettingsOpen(false);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		context?.setIsTesting(event.target.checked);
	};

	return (
		<Box>
			<Button onClick={handleClick} aria-label="account of current user">
				<Avatar src={context.user?.profilePicture} />
			</Button>

			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				getContentAnchorEl={null}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
			>
				<MenuItem
					className={classes.menuItem}
					style={{ justifyContent: "center" }}
					disabled
				>
					<Typography variant="inherit" className={classes.textSize}>
						{t("Profile")}
					</Typography>
				</MenuItem>

				<MenuItem
					className={classes.menuItem}
					onClick={() => {
						setSettingsOpen(true);
					}}
				>
					<ListItemIcon className={classes.listItemIcon}>
						<Account className={classes.itemIcon} />
					</ListItemIcon>
					<Typography variant="inherit" className={classes.textSize}>
						{t("Account")}
					</Typography>
				</MenuItem>

				{showSettings && context?.user?.isAdmin() && (
					<MenuItem
						className={classes.menuItem}
						onClick={() => setOpenSettingsDialog(true)}
					>
						<ListItemIcon className={classes.listItemIcon}>
							<SettingsIcon className={classes.itemIcon} />
						</ListItemIcon>
						<Typography
							variant="inherit"
							className={classes.textSize}
						>
							{t("Settings")}
						</Typography>
					</MenuItem>
				)}

				<Settings
					pipelineSettings={pipelineSettings}
					openSettingsDialog={openSettingsDialog}
					setOpenSettingsDialog={setOpenSettingsDialog}
					handleChangePipelineSettings={handleChangePipelineSettings}
				/>

				<Box>
					<Divider style={{ width: "80%", margin: "auto" }} />

					<MenuItem disabled>
						<Typography
							color="textSecondary"
							variant="inherit"
							className={classes.textSize}
						>
							<Box component="span" fontWeight="fontWeightBold">
								{t("developerVersion")}
							</Box>
						</Typography>
					</MenuItem>
					{context?.user?.isAdmin() && (
						<>
							<MenuItem>
								<FormControlLabel
									control={
										<Switch
											checked={
												context?.isTesting || false
											}
											onChange={handleChange}
											name="testing"
											size="small"
										/>
									}
									label="Testing"
								/>
							</MenuItem>
							<MenuItem>
								<Typography
									variant="inherit"
									className={classes.textSize}
								>
									{t("sdk")}
								</Typography>
							</MenuItem>
						</>
					)}

					<MenuItem
						component={Link}
						href={LinksConst.BRIA_API}
						target="_blank"
					>
						<Typography
							variant="inherit"
							className={classes.textSize}
						>
							{t("api")}
						</Typography>
					</MenuItem>
				</Box>
			</Menu>

			<Dialog
				open={settingsOpen}
				onClose={handleCloseSettings}
				maxWidth="sm"
				fullWidth
				BackdropProps={{
					classes: {
						root: classes.backDrop,
					},
				}}
			>
				<AccountSettings
					handleClose={() => {
						setSettingsOpen(false);
					}}
				/>
			</Dialog>
		</Box>
	);
};

export default UserMenu;
