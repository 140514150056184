import React, { ChangeEvent, Fragment } from "react";

import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
interface IProps {
	show?: boolean;
	count?: number | undefined | null;
	page?: number | undefined;
	hasPrev?: boolean | null;
	hasNext?: boolean | null;
	onChange?: (
		event: ChangeEvent<HTMLElement | unknown>,
		page: number
	) => void;
}

function PaginationControl({
	count,
	onChange,
	page = 1,
	hasPrev = false,
	hasNext = false,
}: IProps) {
	if (!count && (hasPrev || hasNext)) {
		return (
			<Box display="flex" justifyContent="center">
				<Box m={1}>
					<Button
						variant="outlined"
						disabled={!hasPrev}
						startIcon={<ArrowBackIosIcon />}
						onClick={(e) => onChange && onChange(e, page - 1)}
					>
						Previous
					</Button>
				</Box>
				<Box m={1}>
					<Button
						variant="outlined"
						disabled={!hasNext}
						endIcon={<ArrowForwardIosIcon />}
						onClick={(e) => onChange && onChange(e, page + 1)}
					>
						Next
					</Button>
				</Box>
			</Box>
		);
	} else if (!count) {
		return <Fragment />;
	}
	return (
		<Box display="flex" justifyContent="center">
			<Pagination
				size="large"
				variant="outlined"
				shape="rounded"
				color="secondary"
				boundaryCount={3}
				onChange={onChange}
				count={count || 0}
				page={page || 0}
			/>
		</Box>
	);
}

export default PaginationControl;
