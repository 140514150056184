import React, { Dispatch, SetStateAction } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { images_with_tags_images_images } from "../../GraphQL/types/images_with_tags";
import UploadVersion from "../../Components/UI/UploadVersion";
import VersionCard from "./VersionCard";

interface IProps {
	openGalleryPopup: boolean;
	setOpenGalleryPopup: Dispatch<SetStateAction<boolean>>;
	selectedImage: images_with_tags_images_images | undefined;
}

const GalleryPopup = ({
	openGalleryPopup,
	setOpenGalleryPopup,
	selectedImage,
}: IProps) => {
	const { t } = useTranslation();

	let imageWidth: number;
	let containerWidth: number;
	imageWidth =
		(selectedImage?.width &&
			(selectedImage?.width * 200) / selectedImage?.height) ||
		0;
	containerWidth = (imageWidth + 30) * 3 || 0;

	const classes = useStyles(containerWidth);

	const handleCloseGalleryPopup = () => {
		setOpenGalleryPopup(false);
	};

	return (
		<Dialog
			onClose={handleCloseGalleryPopup}
			aria-labelledby="simple-dialog-title"
			open={openGalleryPopup}
			maxWidth={false}
		>
			<Box
				display="flex"
				flexDirection="column"
				className={classes.popup}
			>
				<Box display="flex" justifyContent="space-between">
					<Typography className={classes.text}>
						{selectedImage?.visualHash}
					</Typography>
					<IconButton
						edge="end"
						color="inherit"
						aria-label="close"
						onClick={handleCloseGalleryPopup}
					>
						<CloseIcon />
					</IconButton>
				</Box>

				<Typography className={classes.version}>1 Version</Typography>

				<Box className={classes.container}>
					<Box className={classes.imagesContainer}>
						<UploadVersion onClick={() => {}} />
					</Box>

					<VersionCard selectedImage={selectedImage} />

					<VersionCard selectedImage={selectedImage} />

					<VersionCard selectedImage={selectedImage} />

					<VersionCard selectedImage={selectedImage} />
				</Box>

				<Box display="flex" justifyContent="flex-end">
					<Button
						variant="outlined"
						color="secondary"
						className={classes.button}
					>
						{t("download")}
					</Button>
					<Button
						variant="contained"
						color="secondary"
						className={classes.button}
					>
						{t("downloadAll")}
					</Button>
				</Box>
			</Box>
		</Dialog>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		popup: {
			margin: theme.spacing(5),
		},
		container: {
			position: "relative",
			display: "flex",
			flexWrap: "wrap",
			overflow: "scroll",
			maxHeight: 470,
			maxWidth: (containerWidth) => (containerWidth as number) || 400,
		},
		imagesContainer: {
			width: "33%",
		},
		text: {
			fontSize: 24,
			fontWeight: 600,
			color: theme.palette.text.primary,
		},
		version: {
			fontSize: 24,
			fontWeight: 500,
			color: theme.palette.text.secondary,
		},
		button: {
			borderRadius: 20,
			textTransform: "capitalize",
			margin: 6,
		},
	})
);

export default GalleryPopup;
