import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function ConfirmIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 34 34" {...props}>
			<circle cx="17.0089" cy="17.4757" r="16.4796" fill="#5300C9" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.2498 14.8553L16.3841 21.8291C15.9177 22.3029 15.1451 22.3029 14.6641 21.8291L11.2385 18.3496C10.7721 17.8758 10.7721 17.0911 11.2385 16.6024C11.705 16.1286 12.4775 16.1286 12.9586 16.6024L15.5387 19.2232L21.5443 13.1229C22.0108 12.6491 22.7833 12.6491 23.2644 13.1229C23.7163 13.5967 23.7163 14.3815 23.2498 14.8553Z"
				fill="white"
			/>
		</SvgIcon>
	);
}

export default ConfirmIcon;
