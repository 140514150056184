import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AppContext from "../Context/Context";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import client from "../GraphQL/client";
import {
	startEngineVariables,
	startEngine_startEngine,
} from "./types/startEngine";
import gql from "graphql-tag";
import PowerIcon from "@material-ui/icons/Power";
import PowerOffIcon from "@material-ui/icons/PowerOff";
import SettingsInputComponentIcon from "@material-ui/icons/SettingsInputComponent";

async function startEngine(instanceIds: string[]) {
	try {
		await client.mutate<startEngine_startEngine, startEngineVariables>({
			mutation: gql`
				mutation startEngine($instanceIds: [String!]) {
					startEngine(instanceIds: $instanceIds) {
						ok
					}
				}
			`,
			variables: {
				instanceIds: instanceIds,
			},
		});
	} catch (error) {
		// alert(error?.message);
	}
}

export default function EngineMenue() {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<AppContext.Consumer>
			{(context) => {
				return (
					<div>
						<Button onClick={handleClick}>
							<SettingsInputComponentIcon
								color={
									context.isEngineOnline
										? "primary"
										: "secondary"
								}
							/>
						</Button>
						<StyledMenu
							id="customized-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
						>
							{context.engineMachines.map((machine) => (
								<StyledMenuItem
									onClick={() => {
										handleClose();
									}}
								>
									<Button
										style={{
											height: 40,
										}}
										onClick={() =>
											startEngine([machine.id])
										}
										variant="contained"
										color="secondary"
										startIcon={
											machine.isOn ? (
												<PowerIcon />
											) : (
												<PowerOffIcon />
											)
										}
									>
										<FormControlLabel
											// inputProps={{style: {textTransform: 'capitalize'}}}
											control={
												<Switch
													checked={machine.isOn}
													name="checkedA"
													inputProps={{
														"aria-label":
															"secondary checkbox",
													}}
												/>
											}
											label={machine.displayName}
											style={{
												textTransform: "none",
											}}
										/>
									</Button>
								</StyledMenuItem>
							))}
						</StyledMenu>
					</div>
				);
			}}
		</AppContext.Consumer>
	);
}

const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
	},
})((props: MenuProps) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "center",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "center",
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
	root: {},
}))(MenuItem);
