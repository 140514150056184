import React, { useContext } from 'react';
import { fade, InputBase, Paper } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import AppContext from "../../Context/Context";
function SearchForm(props: any) {

  const appContext = useContext(AppContext)
  
  const [searchText, setSearchText] = React.useState(appContext.selectedText);

  const classes = useStyles();

  const handleSearchTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let newText: string | null = event.target.value;
    setSearchText(newText);
    if (newText === '') {
      newText = null;
    }
    props.parentSearchTextCallback(newText);
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleSearchTextChange}
          value={appContext.selectedText}
        />
      </div>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  gridListRoot: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(5),
  },
  gridList: {
    width: '100%',
    height: '100%',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: '16px 0px',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    width: '20%',
    boxShadow: 'none',
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
  },
  activeImgCls: {
    border: '3px solid #7D26CD',
    cursor: 'pointer',
    borderRadius: '6px',
  },
  activeImgClsRmv: {
    border: '3px solid transparent',
  },
}));

export default SearchForm;
