import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const LaughingIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			{...props}
		>
			<g clipPath="url(#clip0)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M10.7578 19.3799C8.43717 19.3799 6.21157 18.458 4.57063 16.8171C2.92969 15.1761 2.00781 12.9505 2.00781 10.6299C2.00781 8.30924 2.92969 6.08364 4.57063 4.4427C6.21157 2.80175 8.43717 1.87988 10.7578 1.87988C13.0785 1.87988 15.3041 2.80175 16.945 4.4427C18.5859 6.08364 19.5078 8.30924 19.5078 10.6299C19.5078 12.9505 18.5859 15.1761 16.945 16.8171C15.3041 18.458 13.0785 19.3799 10.7578 19.3799ZM10.7578 20.6299C8.10565 20.6299 5.56211 19.5763 3.68674 17.701C1.81138 15.8256 0.757812 13.282 0.757812 10.6299C0.757812 7.97772 1.81138 5.43418 3.68674 3.55882C5.56211 1.68345 8.10565 0.629883 10.7578 0.629883C13.41 0.629883 15.9535 1.68345 17.8289 3.55882C19.7042 5.43418 20.7578 7.97772 20.7578 10.6299C20.7578 13.282 19.7042 15.8256 17.8289 17.701C15.9535 19.5763 13.41 20.6299 10.7578 20.6299Z"
					fill="#8800FF"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5.34417 12.5049C5.23446 12.6949 5.17671 12.9105 5.17671 13.1299C5.17671 13.3493 5.23446 13.5649 5.34417 13.7549C5.89262 14.7053 6.68174 15.4946 7.63211 16.0431C8.58248 16.5917 9.66058 16.8803 10.7579 16.8799C11.8551 16.8801 12.9329 16.5914 13.883 16.0428C14.8332 15.4943 15.6221 14.7051 16.1704 13.7549C16.2801 13.565 16.3378 13.3495 16.3379 13.1302C16.3379 12.9109 16.2803 12.6954 16.1707 12.5054C16.0612 12.3155 15.9036 12.1576 15.7137 12.0478C15.5239 11.938 15.3085 11.8801 15.0892 11.8799H6.42667C6.20726 11.8799 5.99171 11.9376 5.80169 12.0474C5.61167 12.1571 5.45388 12.3149 5.34417 12.5049Z"
					fill="#8800FF"
				/>
				<path
					d="M12.0078 8.75488C12.0078 9.78988 12.5678 8.75488 13.2578 8.75488C13.9478 8.75488 14.5078 9.78988 14.5078 8.75488C14.5078 7.71988 13.9478 6.87988 13.2578 6.87988C12.5678 6.87988 12.0078 7.71988 12.0078 8.75488ZM7.00781 8.75488C7.00781 9.78988 7.56781 8.75488 8.25781 8.75488C8.94781 8.75488 9.50781 9.78988 9.50781 8.75488C9.50781 7.71988 8.94781 6.87988 8.25781 6.87988C7.56781 6.87988 7.00781 7.71988 7.00781 8.75488Z"
					fill="#8800FF"
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect
						width="20"
						height="20"
						fill="white"
						transform="matrix(-1 0 0 1 20.7578 0.629883)"
					/>
				</clipPath>
			</defs>
		</SvgIcon>
	);
};

export default LaughingIcon;
