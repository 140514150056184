import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function GalleryIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 16 17" {...props}>
			<rect y="0.749023" width="7" height="5" rx="2" fill="#A4A4A4" />
			<rect x="9" y="11.749" width="7" height="5" rx="2" fill="#A4A4A4" />
			<rect y="7.74902" width="7" height="9" rx="2" fill="#A4A4A4" />
			<rect
				x="9"
				y="0.749023"
				width="7"
				height="9"
				rx="2"
				fill="#A4A4A4"
			/>
		</SvgIcon>
	);
}

export default GalleryIcon;
