import React from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import SlidersIcon from "../../assets/icons/SlidersIcon";
import ThumbnailsIcon from "../../assets/icons/ThumbnailsIcon";
import clsx from "clsx";
import { ToolsViewerMode } from "../Tools/utilities";
import Tooltip from "./Tooltip";

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.common.white,
		width: 60,
		height: 30,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(4),
		borderRadius: 25,
		overflow: "hidden",
	},
	toggleGroup: {
		height: "100%",
		width: "100%",
		padding: 1,
	},
	toggleButton: {
		height: "100%",
		width: "50%",
		stroke: "#A4A4A4",
		borderRadius: 15,
	},
	icon1: {
		marginLeft: 3,
		fontSize: 12,
	},
	icon2: {
		marginRight: 6,
		fontSize: 12,
	},
	activeToggleButton: {
		backgroundColor: `${theme.palette.primary.dark} !important`,
		stroke: "white",
		"&:disabled": {
			backgroundColor: `${theme.palette.grey[400]} !important`,
		},
	},
}));

interface IProps {
	selectedStyle: ToolsViewerMode;
	onStyleChange: (style: ToolsViewerMode) => void;
	disabled?: boolean;
}

const ThumbnailSliderSwitch: React.FC<IProps> = ({
	selectedStyle,
	onStyleChange,
	disabled = false,
}) => {
	const classes = useStyles();

	const handleStyle = (
		event: React.MouseEvent<HTMLElement>,
		newStyle: ToolsViewerMode
	) => {
		if (newStyle !== null) {
			onStyleChange(newStyle);
		}
	};

	return (
		<Box className={classes.root}>
			<ToggleButtonGroup
				className={classes.toggleGroup}
				value={selectedStyle}
				exclusive
				onChange={handleStyle}
			>
				<Tooltip title="Sliders View" arrow>
					<ToggleButton
						className={clsx({
							[classes.toggleButton]: true,
							[classes.activeToggleButton]:
								selectedStyle === ToolsViewerMode.sliders,
						})}
						disabled={disabled}
						value={ToolsViewerMode.sliders}
					>
						<SlidersIcon className={classes.icon1} />
					</ToggleButton>
				</Tooltip>
				<Tooltip title="Thumbnails View" arrow>
					<ToggleButton
						className={clsx({
							[classes.toggleButton]: true,
							[classes.activeToggleButton]:
								selectedStyle === ToolsViewerMode.thumbnails,
						})}
						value={ToolsViewerMode.thumbnails}
						disabled={disabled}
					>
						<ThumbnailsIcon className={classes.icon2} />
					</ToggleButton>
				</Tooltip>
			</ToggleButtonGroup>
		</Box>
	);
};

export default ThumbnailSliderSwitch;
