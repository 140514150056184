import React from 'react';
import client from '../../GraphQL/client'
import { Theme, createStyles } from '@material-ui/core/styles';
import { Box, Chip, fade, Grid, Typography, withStyles } from '@material-ui/core';
import { repositoryTags_repositoryTags as Tag } from '../../GraphQL/types/repositoryTags'
import { labelsByPathPrefix_labelsByPathPrefix as Label } from '../../GraphQL/types/labelsByPathPrefix'
import Link from '@material-ui/core/Link';
import { getLabelsByPathPrefix } from '../../GraphQL/queries'
import { DBName } from "../../types/graphql-global-types"
import CircularProgress from "@material-ui/core/CircularProgress";


interface IProps {
    parentSelectedLabelsCallback: (selectedLabels: string[]) => void;
    dbName: DBName
    pathPrefix: string
}
interface IState {
    showSpinnerForLabels: boolean,
    groups: Tag[],
    selectedGroups: string[]
    labels: Label[]
    selectedLabels: string[],
}

class Filters extends React.Component<IProps, IState> {

    state: IState = {
        showSpinnerForLabels: false,
        groups: [{ __typename: "Tag", name: "Group1" }, { __typename: "Tag", name: "Group2" }, { __typename: "Tag", name: "Group3" }],
        selectedGroups: [],
        labels: [],
        selectedLabels: []
    }

    removeItemOnce(arr: any[], value: any) {
        let index = arr.indexOf(value);
        if (index > -1) {
            arr.splice(index, 1);
        }
        return arr;
    }

    async componentDidMount() {
        this.setState({ showSpinnerForLabels: true })
        const labels: Label[] = await getLabelsByPathPrefix(this.props.dbName, this.props.pathPrefix, client)
        this.setState({ labels: labels, showSpinnerForLabels: false })
    }

    componentWillUnmount() {
        client.stop()
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.dbName !== prevProps.dbName) {
            this.componentDidMount()
        }
    }

    handleSelectedLabels(newSelectedLabels: string[]) {
        this.setState({ selectedLabels: newSelectedLabels }, () => { this.props.parentSelectedLabelsCallback(this.state.selectedLabels) })
    }

    render() {
        const { classes }: any = this.props
        if (this.state.showSpinnerForLabels) {
            return (
                <Typography align="center" style={{margin: "1rem"}}>
                    <CircularProgress />
                </Typography>
            )
        } else {
            return (
                <div className={classes.container}>
                    <div className={classes.root}>
                        <Grid container spacing={0} item xs={12} >
                            <Grid justify="center" container>
                                <Link
                                    component="button"
                                    style={{ padding: '0 0.5rem', color: 'white', textDecoration: 'underline' }}
                                    variant="body2"
                                    onClick={() => {
                                        const labelSelection: string[] = []
                                        this.state.labels.map(label => {
                                            if (this.state.selectedLabels.includes(label.name)) {
                                            } else {
                                                labelSelection.push(label.name)
                                            }
                                        })
                                        const newSelectedLabels = this.state.selectedLabels.concat(labelSelection)
                                        this.handleSelectedLabels(newSelectedLabels)
                                    }}
                                >
                                    Select All
                        </Link>
                                <Link
                                    component="button"
                                    style={{ padding: '0 0.5rem', color: 'white', textDecoration: 'underline' }}
                                    variant="body2"
                                    onClick={() => {
                                        this.handleSelectedLabels([])
                                    }}
                                >
                                    Unselect All
                        </Link>
                            </Grid>
                            <Grid className={classes.chipsContainer} container>
                                {this.state.labels.length > 0 ? this.state.labels.map((label: Label, index: number) => (
                                    label.name !== "" &&
                                    <Chip key={`${index}${label.name}`}
                                        // style={{ padding: '0 0.5rem' }}
                                        label={label.name}
                                        size="small"
                                        style={{ margin: "2px" }}
                                        clickable
                                        color={this.state.selectedLabels.includes(label.name) ? "secondary" : "primary"}
                                        onClick={() => {
                                            var newArray: string[] = this.state.selectedLabels
                                            if (this.state.selectedLabels.includes(label.name)) {
                                                this.removeItemOnce(newArray, label.name)
                                            } else {
                                                newArray.push(label.name)
                                            }
                                            this.handleSelectedLabels(newArray)
                                        }}
                                    />
                                )) : <div>{"No labels for this folder!"}</div>}
                            </Grid>
                        </Grid>
                    </div>
                </div>
            )
        }
    }
}


const styles = (theme: Theme) => createStyles({
    root: {
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        flexWrap: 'wrap',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'flex-start',
        margin: '1rem 0'
    },
    gridListRoot: {
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        // flexWrap: 'wrap',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        width: "100%",
        height: "100%",
    },
    mainGrid: {
        flexGrow: 1,
    },
    container: {
        width: '-webkit-fill-available',
        // padding: theme.spacing(2),
    },
    pageControll: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '90vh',
        [theme.breakpoints.up('md')]: {
            width: '90vh',
        },
    },
    activeImgCls: {
        border: "3px solid #7D26CD",
        cursor: "pointer",
        borderRadius: "6px"
    },
    activeImgClsRmv: {
        border: "3px solid transparent",
        cursor: "pointer",
    },
    chipsContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginTop: '5px'
        // '& > *': {
        //     margin: theme.spacing(0.5),
        // }
    }
});

export default withStyles(styles)(Filters)