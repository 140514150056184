import React from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { isDevelopment } from '../Config/Env'


class GrafanaDashboard extends React.Component<any, any> {

  public render() {
    const { classes } = this.props
    return (
      <div className={classes.root}>
        <iframe
          title={"grafana"}
          width="100%"
          height="100%"
          src={isDevelopment ? "http://localhost:4000/" : "https://labs.bria.ai:4000/"}
        />
      </div>
    )
  }
}

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.background.paper,
    height: "100vh",
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
  }
})

export default withStyles(styles)(GrafanaDashboard)
