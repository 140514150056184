import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme, withStyles } from "@material-ui/core";

interface IProps {
	openExpressionsAlert: boolean;
	setOpenExpressionsAlert: React.Dispatch<React.SetStateAction<boolean>>;
	resetExtremeExpression: () => void;
	onUndo: () => void;
	setSelectedTab: React.Dispatch<React.SetStateAction<number>>;
	newTabNumber: number;
}

const CustomizedDialog = withStyles((theme) => ({
	paperWidthFalse: {
		width: 577,
		maxWidth: 557,
		height: 204,
		padding: "26px 1px 22px 12px",
	},
}))(Dialog);

const CustomizedDialogTitle = withStyles((theme) => ({
	root: {
		padding: "0 0 0 24px",
	},
}))(DialogTitle);

const ExpressionsAlert = ({
	openExpressionsAlert,
	setOpenExpressionsAlert,
	resetExtremeExpression,
	onUndo,
	setSelectedTab,
	newTabNumber,
}: IProps) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const handleContinue = () => {
		onUndo();
		setOpenExpressionsAlert(false);
		resetExtremeExpression();
		setSelectedTab(newTabNumber);
	};

	const handleClose = () => {
		setOpenExpressionsAlert(false);
	};

	return (
		<CustomizedDialog
			open={openExpressionsAlert}
			onClose={handleClose}
			maxWidth={false}
			fullWidth
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<CustomizedDialogTitle id="alert-dialog-title">
				<Typography className={classes.title}>
					Keep your changes
				</Typography>
			</CustomizedDialogTitle>

			<DialogContent>
				<DialogContentText
					id="alert-dialog-description"
					className={classes.text}
				>
					Continuing to other settings will reset facial expressions.
					Make changes to sentiment the final step in the editing
					process.
				</DialogContentText>
			</DialogContent>
			<Box display="flex" justifyContent="flex-end" px="24px">
				<Box display="flex">
					<Button
						onClick={handleClose}
						color="secondary"
						variant="outlined"
						className={classes.button}
						style={{ marginRight: 9 }}
					>
						Go Back
					</Button>
					<Button
						onClick={handleContinue}
						color="secondary"
						autoFocus
						variant="contained"
						className={classes.button}
					>
						Continue
					</Button>
				</Box>
			</Box>
		</CustomizedDialog>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		title: { fontSize: 24, fontWeight: theme.typography.fontWeightBold },
		text: {
			color: theme.palette.text.primary,
		},
		button: {
			borderRadius: 20,
			textTransform: "unset",
		},
	})
);

export default ExpressionsAlert;
