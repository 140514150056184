import React from "react";
import { makeStyles, Theme, withStyles } from "@material-ui/core";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import ShowPasswordIcon from "../../assets/icons/ShowPasswordIcon";
import HidePasswordIcon from "../../assets/icons/HidePasswordIcon";
import InputAdornment from "@material-ui/core/InputAdornment";

const CustomizedInput = withStyles((theme: Theme) => ({
	root: {
		minWidth: 440,
		width: "80%",
		height: 40,

		"& input": {
			padding: "11px 19px",
		},

		"& label": {
			color: theme.palette.text.primary,
			fontSize: 14,
			fontWeight: theme.typography.fontWeightMedium,
			paddingTop: 3,
		},

		"& label.Mui-focused": {
			color: theme.palette.primary.light,
		},

		"& .MuiOutlinedInput-root": {
			borderRadius: 20,

			"& fieldset": {
				borderColor: "#C4C4C4",
			},

			"&:hover fieldset": {
				borderColor: theme.palette.primary.light,
				opacity: 0.5,
			},

			"&.Mui-focused fieldset": {
				borderColor: theme.palette.primary.light,
				opacity: 1,
				borderWidth: 1,
			},
		},

		"& .MuiFormHelperText-root.Mui-error, .MuiFormLabel-root.Mui-error": {
			color: theme.palette.primary.light,
		},

		"& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
			borderColor: theme.palette.primary.light,
		},
	},
}))(TextField);

const useStyles = makeStyles((theme) => ({
	icon: {
		cursor: "pointer",
	},
}));

interface IProps {
	type?: string;
}

const BriaInput: React.FC<IProps & TextFieldProps> = ({ type, ...rest }) => {
	const classes = useStyles();
	const [showPassword, setShowPassword] = React.useState<boolean>(false);

	const handleClick = () => {
		setShowPassword(!showPassword);
	};

	return (
		<CustomizedInput
			variant="outlined"
			size="small"
			type={
				type === "password"
					? showPassword
						? "text"
						: "password"
					: "text"
			}
			InputProps={{
				endAdornment: type === "password" && (
					<InputAdornment position="end">
						{showPassword ? (
							<ShowPasswordIcon
								onClick={handleClick}
								className={classes.icon}
							/>
						) : (
							<HidePasswordIcon
								onClick={handleClick}
								className={classes.icon}
							/>
						)}
					</InputAdornment>
				),
			}}
			{...rest}
		/>
	);
};

export default BriaInput;
