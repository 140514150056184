import React from "react";
import AppContextInterface from "./AppContextInterface";
interface IFolder {
	path: string;
	name: string;
	children: IFolder[];
	isExpanded: boolean;
	parent: IFolder | null;
	isChecked: boolean;
}
export class Folder implements IFolder {
	path: string;
	name: string;
	children: IFolder[];
	isExpanded: boolean;
	parent: IFolder | null;
	isChecked: boolean;

	constructor(
		path: string,
		name: string,
		children: IFolder[],
		parent: IFolder | null
	) {
		this.path = path;
		this.name = name;
		this.children = children;
		this.parent = parent;
		this.isExpanded = false;
		this.isChecked = false;
	}
}

const defaultContext: AppContextInterface = {
	validFacesToggle: false,
	updateValidFacesToggle: (newValidFacesToggle: boolean) => {},
	mslToggle: false,
	updateMslToggle: (newMslToggle: boolean) => {},
	curFolder: new Folder("", "Root", [], null),
	updateCurFolder: (newCurFolder: Folder) => {},
	preferences: null,
	changeallConfigPersonalizerSwitchCheck: (switchState: boolean) => {},
	allConfigPersonalizerSwitchCheck: false,
	authenticationWasVerified: false,
	isEngineOnline: false,
	engineMachines: [],
	heartbeatRefs: null,
	user: null,
	presentedImagesArray: [],
	addToPresentedImagesArray: (presentedImagesMongoIdArray: string[]) => {},
	updateSelectedText: (newText: string) => {},
	updateCurrExpandedFolders: (newExpandedFolders: string[] | []) => {},
	currExpandedFolders: [],
	selectedText: "",
	imageHeight: 150,
	updateImageHeight: (newImageHeight: number) => {},
	imagesPerPage: 50,
	updateImagesPerPage: (newImagesPerPage: number) => {},
	previouslySelectedLabels: [],
	updateSelectedLabels: (newSelectedLables: string[]) => {},
	previouslySelectedPage: 1,
	updateSelectedPage: (newSelectedPage: number) => {},
	registerViaAuthProvider: (provider: any, viewer?: boolean) =>
		Promise.resolve(),
	registerWithEmail: (
		username: string,
		email: string,
		password: string,
		viewer?: boolean
	) => Promise.resolve(),
	logOut: () => {},
	pageMenuWidth: 72,
	displayUpload: false,
	setUploadState: () => {},
	isTesting: false,
	setIsTesting: () => {},
};

export default React.createContext<AppContextInterface>(defaultContext);
