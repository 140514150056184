import React from "react";
import client from "../../GraphQL/client";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Paper, Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { addImageToResearch, addImageToVDR } from "../../GraphQL/mutations";
import CircularProgress from "@material-ui/core/CircularProgress";
import VDRUploadForm from "./VDRUploadForm";
import ResearchUploadForm from "./ResearchUploadForm";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Analytics from "../../Models/Analytics";
import { getRepositoryProjects } from "../../GraphQL/queries";
import { repositoryProjects_repositoryProjects as ProjectInterface } from "../../GraphQL/types/repositoryProjects";
import Dropzone from "react-dropzone";
import { base64ArrayBuffer } from "../../Helpers/base64buffer";
import Dialog from "../Dialog";
import { DBName, VDRUploadParameters } from "../../types/graphql-global-types";

interface IProps {
	parentCallback: () => void;
	dbName: DBName;
}

interface IState {
	curQueryName: string;
	curProjectId: string;
	curDescription: string;
	curSendToSemSeg: boolean;
	curInternalUse: boolean;
	curValidForTraining: boolean;
	showModal: boolean;
	showSpinner: boolean;
	activeStep: number;
	uploadingFile: boolean;
	vdrSteps: string[];
	researchSteps: string[];
	sucessfulUpload: boolean;
	formEmpty: boolean;
	projects: string[];
	customChosen: boolean;
	projectIdAnchorEl: null | HTMLElement;
	shouldShowErrorProjectId: boolean;
	shouldNotifyClosing: boolean;
	uploadFinished: boolean;
	createdImagesArray: boolean;
	binaryStrArray: string[];
	finishedForm: boolean;
}

class UploadForm extends React.Component<IProps, IState> {
	state: IState = {
		curQueryName: "",
		curProjectId: "",
		curDescription: "",
		curSendToSemSeg: false,
		curInternalUse: true,
		curValidForTraining: true,
		showModal: true,
		showSpinner: false,
		activeStep: 0,
		uploadingFile: false,
		vdrSteps: ["Data", "Data Pipeline", "Review your upload request"],
		researchSteps: ["Metadata", "Review your upload request"],
		sucessfulUpload: true,
		formEmpty: true,
		projects: [],
		customChosen: false,
		projectIdAnchorEl: null,
		shouldShowErrorProjectId: false,
		shouldNotifyClosing: false,
		uploadFinished: false,
		createdImagesArray: false,
		binaryStrArray: [],
		finishedForm: false,
	};

	componentDidMount() {
		this.formEmptinessCallback = this.formEmptinessCallback.bind(this);
		this.activeStepCallback = this.activeStepCallback.bind(this);
	}

	componentWillUnmount() {
		client.stop();
	}

	notifyClosing(): JSX.Element {
		return (
			<Dialog
				shouldOpen={this.state.shouldNotifyClosing}
				onClose={() => {}}
				dialogTitle="Are you sure you want to stop?"
				dialogContentText={
					'You\'ve started filling the form, but haven\'t finished. Click on "Agree" to delete the form or "Disagree" to return to the form.'
				}
				renderDialogContent={() => {}}
				onClickAffirmative={this.closeModal}
				affirmativeButtonText="Agree"
				onClickNegative={() => {
					this.setState({ shouldNotifyClosing: false });
				}}
				negativeButtonText="Disagree"
			/>
		);
	}

	handleClose = () => {
		if (!this.state.formEmpty && !this.state.uploadFinished) {
			this.setState({ shouldNotifyClosing: true });
		} else {
			this.closeModal();
		}
	};

	closeModal = () => {
		this.setState({ showModal: false }, () => {
			this.props.parentCallback();
		});
	};

	async getProjects() {
		// console.log("Beofre")
		// if (this.state.projects.length === 0) {
		//     console.log("After")
		const projects: Project[] = await getRepositoryProjects(
			this.props.dbName,
			client
		);
		const localProjects: string[] = [];
		projects.forEach((project: Project) => {
			localProjects.push(project.name);
		});
		localProjects.push("Custom");
		this.setState({ projects: localProjects });
	}

	async uploadImageToVDR(
		binaryStr: string,
		uploadParameters: VDRUploadParameters
	) {
		this.setState({ showSpinner: true, uploadFinished: false });
		try {
			const success: boolean = await addImageToVDR(
				binaryStr,
				uploadParameters,
				client
			);
			if (success) {
				this.setState({ sucessfulUpload: true });
			} else {
				alert("Unable to upload your image, try again");
				this.setState({ sucessfulUpload: false });
			}
		} catch (error) {
			alert(error);
		}
		this.setState({ showSpinner: false, uploadFinished: true });
	}

	async uploadImageToResearch(
		binaryStr: string,
		folder: string,
		subfolder: string,
		project: string,
		source: string,
		labels: string[] | null,
		displayName: string | null
	) {
		this.setState({ showSpinner: true, uploadFinished: false });
		try {
			const success: boolean = await addImageToResearch(
				binaryStr,
				folder,
				subfolder,
				project,
				source,
				labels,
				displayName,
				client
			);
			if (success) {
				this.setState({ sucessfulUpload: true });
			} else {
				alert("Unable to upload your image, try again");
				this.setState({ sucessfulUpload: false });
			}
		} catch (error) {
			alert(error);
		}
		this.setState({ showSpinner: false, uploadFinished: true });
	}

	getModalStyle() {
		const top = 50;
		const left = 50;
		return {
			top: `${top}%`,
			left: `${left}%`,
			transform: `translate(-${top}%, -${left}%)`,
		};
	}

	uploadFiles(): JSX.Element {
		const { classes }: any = this.props;
		let localBinaryStrArray: string[] = this.state.binaryStrArray;
		return (
			<div>
				<div className="files">
					<Dropzone
						onDrop={(acceptedFiles) => {
							acceptedFiles.forEach((file: any) => {
								const reader = new FileReader();
								reader.onabort = () =>
									alert("file reading was aborted");
								reader.onerror = () =>
									alert("file reading has failed");
								reader.onload = () => {
									const binaryStr: any = base64ArrayBuffer(
										reader.result
									);
									localBinaryStrArray.push(binaryStr);
								};
								reader.readAsArrayBuffer(file);
							}, this.setState({ createdImagesArray: true, binaryStrArray: localBinaryStrArray }));
						}}
					>
						{({ getRootProps, getInputProps }) => (
							<section>
								<div {...getRootProps()}>
									<input {...getInputProps()} />
									<img
										src="https://static.thenounproject.com/png/49665-200.png"
										alt="upload_image"
									/>
								</div>
							</section>
						)}
					</Dropzone>
				</div>
			</div>
		);
	}

	render() {
		const { classes }: any = this.props;
		return (
			<div>
				<Modal
					open={this.state.showModal}
					onClose={this.handleClose}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<Paper
						style={this.getModalStyle()}
						className={classes.modalPaper}
					>
						{!this.state.createdImagesArray
							? this.uploadFiles()
							: this.renderForm()}
					</Paper>
				</Modal>
			</div>
		);
	}

	activeStepCallback(localActiveStep: number) {
		this.setState({ activeStep: localActiveStep });
	}

	formEmptinessCallback(localFormEpmty: boolean) {
		this.setState({ formEmpty: localFormEpmty });
	}

	renderForm(): JSX.Element {
		const { classes }: any = this.props;
		if (this.state.projects.length === 0) {
			this.getProjects();
		}
		return (
			<div>
				{this.state.shouldNotifyClosing ? (
					this.notifyClosing()
				) : (
					<div />
				)}
				<React.Fragment>
					<main className={classes.layout}>
						<Paper className={classes.formPaper}>
							{this.state.showSpinner ? (
								<Typography
									component="h1"
									variant="h6"
									align="center"
								>
									{"Uploading!"}
								</Typography>
							) : (
								this.HeadlineRender()
							)}
							<React.Fragment>
								{this.state.finishedForm ? (
									this.UploadRender()
								) : this.props.dbName === DBName.VDR ? (
									<VDRUploadForm
										finishedForm={(
											uploadParameters: VDRUploadParameters
										) => {
											this.setState(
												{ finishedForm: true },
												() => {
													this.state.binaryStrArray.forEach(
														(binaryStr: string) => {
															this.uploadImageToVDR(
																binaryStr,
																uploadParameters
															);
														}
													);
												}
											);
										}}
										activeStepCallback={
											this.activeStepCallback
										}
										formNotEmpty={
											this.formEmptinessCallback
										}
										numberOfImages={
											this.state.binaryStrArray.length
										}
										projects={this.state.projects}
									/>
								) : (
									<ResearchUploadForm
										finishedForm={(
											project: string,
											source: string,
											folder: string,
											subfolder: string,
											displayName: string | null,
											labels: string[] | null
										) => {
											this.setState(
												{ finishedForm: true },
												() => {
													this.state.binaryStrArray.forEach(
														(binaryStr: string) => {
															this.uploadImageToResearch(
																binaryStr,
																folder,
																subfolder,
																project,
																source,
																labels,
																displayName
															);
														}
													);
												}
											);
										}}
										activeStepCallback={
											this.activeStepCallback
										}
										formNotEmpty={
											this.formEmptinessCallback
										}
										numberOfImages={
											this.state.binaryStrArray.length
										}
									/>
								)}
							</React.Fragment>
						</Paper>
					</main>
				</React.Fragment>
			</div>
		);
	}

	HeadlineRender(): JSX.Element {
		const { classes }: any = this.props;
		const text: string =
			this.state.binaryStrArray.length === 1
				? "1 image to upload"
				: this.state.binaryStrArray.length + " images to upload";
		const steps: string[] =
			this.props.dbName === DBName.VDR
				? this.state.vdrSteps
				: this.state.researchSteps;
		return (
			<div>
				<Typography component="h1" variant="h4" align="center">
					{text}
				</Typography>
				<Stepper
					activeStep={this.state.activeStep}
					className={classes.stepper}
				>
					{steps.map((label: string) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
			</div>
		);
	}

	UploadRender(): JSX.Element {
		const { classes }: any = this.props;
		if (this.state.showSpinner) {
			return (
				<Typography align="center">
					<CircularProgress />
				</Typography>
			);
		} else {
			return (
				<React.Fragment>
					{this.state.sucessfulUpload ? (
						<div>
							<Typography
								variant="h5"
								align="center"
								gutterBottom
							>
								{"Upload is finished!"}
							</Typography>
							<Typography
								variant="h5"
								align="center"
								gutterBottom
							>
								{"Click on the button to close this window."}
							</Typography>{" "}
						</div>
					) : (
						<Typography variant="h5" align="center" gutterBottom>
							{"Upload was unsuccesful. Please try again"}
						</Typography>
					)}
					<div className={classes.buttons}>
						<Button
							variant="contained"
							color="primary"
							onClick={this.handleClose}
							className={classes.button}
							style={{ textTransform: "none" }}
						>
							{"Close the window"}
						</Button>
					</div>
				</React.Fragment>
			);
		}
	}
}

class Project implements ProjectInterface {
	name: string;
	__typename: "Project" = "Project";
	constructor(name: string) {
		this.name = name;
	}
}

const styles = (theme: Theme) =>
	createStyles({
		modalPaper: {
			position: "absolute",
			width: 800,
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		listItem: {
			padding: theme.spacing(1, 0),
		},
		total: {
			fontWeight: 2048,
		},
		title: {
			marginTop: theme.spacing(2),
		},
		appBar: {
			position: "relative",
		},
		layout: {
			width: "auto",
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
			[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
				width: 600,
				marginLeft: "auto",
				marginRight: "auto",
			},
		},
		formPaper: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
			padding: theme.spacing(2),
			[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
				marginTop: theme.spacing(6),
				marginBottom: theme.spacing(6),
				padding: theme.spacing(3),
			},
		},
		stepper: {
			padding: theme.spacing(3, 0, 5),
		},
		buttons: {
			display: "flex",
			justifyContent: "flex-end",
		},
		button: {
			marginTop: theme.spacing(3),
			marginLeft: theme.spacing(1),
			textTransform: "none",
		},
	});

export default withStyles(styles)(UploadForm);
