import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	root: {
		width: 296,
		height: 220,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		background: theme.palette.uploadCardBackground.main,
		overflow: "hidden",
		position: "relative",
		cursor: "pointer",
		border: "3px solid transparent",
		borderRadius: theme.shape.borderRadius,
		marginBottom: theme.spacing(3),
	},
	innerCard: {
		width: 151,
		height: 113,
		// border: "1px dotted #495057",
		borderRadius: theme.shape.borderRadius,
		background: theme.palette.common.white,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		flexDirection: "column",
		backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='3' ry='3' stroke='%23A0A0A0FF' stroke-width='3' stroke-dasharray='2%2c 8' stroke-dashoffset='6' stroke-linecap='butt'/%3e%3c/svg%3e")`,
	},
	addIcon: {
		width: 30,
		height: 30,
		fontSize: 30,
		color: theme.palette.common.black,
	},
	typo: {
		color: theme.palette.common.black,
	},
}));

interface IProps {
	onClick: () => void;
}

const UploadCard: React.FC<IProps> = ({ onClick }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box className={classes.root} marginRight={3} onClick={onClick}>
			<Box className={classes.innerCard}>
				<Box>
					<Add className={classes.addIcon} />
				</Box>
				<Typography className={classes.typo}>
					{t("addImage")}
				</Typography>
			</Box>
		</Box>
	);
};

export default UploadCard;
