import React from 'react';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Grid, Slider, Paper, Container, LinearProgress, GridList, GridListTile, Button, Menu, MenuItem, Divider, Collapse, ListItem, List, ListItemText, Link, ListItemIcon, Checkbox, IconButton, Box } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ProgressiveImage from "react-progressive-image";
import { Animate } from 'react-simple-animate';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddCircleSharpIcon from '@material-ui/icons/AddCircleSharp';
import RemoveCircleSharpIcon from '@material-ui/icons/RemoveCircleSharp';
import { relative } from 'path';
import { createTrue } from 'typescript';
import Draggable from 'react-draggable';


class Crumb {
  img: any

  constructor(img: any) {
    this.img = img
  }
}

class Image {
  img: any
  smileResualts: SmileResult[]
  constructor(img: any, smileResualts: SmileResult[]) {
    this.img = img
    this.smileResualts = smileResualts
  }
}

class Brand {
  name: string
  constructor(name: string) {
    this.name = name
  }
}

class SmileResult {
  value: number
  outpupImage: any
  geoLocation: GeoLocation
  gender: Gender
  colors: ColorPalette
  objects: SceneObject[]
  brands: Brand[]

  constructor(
    value: number,
    outpupImage: any,
    geoLocation: GeoLocation,
    gender: Gender,
    brands: Brand[] = [],
    colors: ColorPalette = new ColorPalette(["#f6e9f2", "#fafcfb", "#352f2a"]),
    objects: SceneObject[] = []) {

    this.value = value
    this.outpupImage = outpupImage
    this.geoLocation = geoLocation
    this.gender = gender
    this.colors = colors
    this.objects = objects
    this.brands = brands
  }
}

class GeoLocation {
  name: string
  imageUrl: string

  constructor(name: string, imageUrl: string) {
    this.name = name
    this.imageUrl = imageUrl
  }
}

class Gender {
  name: string

  constructor(name: string) {
    this.name = name
  }
}

class ColorPalette {
  colors: string[]

  constructor(colors: string[]) {
    this.colors = colors
  }
}

class SceneObject {
  type: ObjectType
  name: string
  constructor(type: ObjectType, name: string) {
    this.type = type
    this.name = name
  }
}

interface Istate {
  value: number
  processing: boolean,
  nextResult: Crumb | null,
  oldImageResult: string | null,
  analysisStage: AnalysisStage,
  breadCrumbs: Crumb[],
  selectedImage: Image | null,
  showDog: boolean | null,
  showWix: boolean | null,
  selectedLocal: GeoLocation | null,
  selectedGender: Gender,
  colorPalette: ColorPalette,
  showEthnicityMenue: boolean,
  seasonsAnchorEl: HTMLElement | null,
  imageAnchorEl: HTMLElement | null,
  localizerOpen: boolean,
  personalizerOpen: boolean,
  brandOpen: boolean,
  objectsRecognition: boolean,
  shrink: boolean,
  positionTool: any 
}

const IN = new GeoLocation("IN", "https://image.flaticon.com/icons/svg/3014/3014003.svg")
const UK = new GeoLocation("UK", "https://image.flaticon.com/icons/svg/555/555417.svg")
const US = new GeoLocation("US", "https://image.flaticon.com/icons/svg/3013/3013911.svg")
const DE = new GeoLocation("DE", "https://image.flaticon.com/icons/svg/3014/3014029.svg")

const M = new Gender("Male")
const F = new Gender("Female")

enum ObjectType {
  DOG,
}

const baseURL = "https://bria-research-results.s3.amazonaws.com/Demo+1/pic_1/"
const originalImages = [
  new Image(baseURL + "original.jpeg", [

    // UK
    new SmileResult(25, baseURL + "demo_1_man2_01.jpeg", UK, M),
    new SmileResult(45, baseURL + "demo_1_man2_02.jpeg", UK, M),
    new SmileResult(85, baseURL + "demo_1_man2_03.jpeg", UK, M),
    new SmileResult(100, baseURL + "demo_1_man2_04.jpeg", UK, M),

    new SmileResult(25, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fdemo_1_man2_01_wix.jpeg", UK, M,
      [new Brand("WIX")],
    ),
    new SmileResult(45, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fdemo_1_man2_02_wix.jpeg", UK, M,
      [new Brand("WIX")],
    ),
    new SmileResult(85, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fdemo_1_man2_03_wix.jpeg", UK, M,
      [new Brand("WIX")],
    ),
    new SmileResult(100, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fdemo_1_man2_04_wix.jpeg", UK, M,
      [new Brand("WIX")],
    ),

    new SmileResult(25, baseURL + "demo_1_women1_01.jpeg", UK, F),
    new SmileResult(45, baseURL + "demo_1_women1_02.jpeg", UK, F),
    new SmileResult(85, baseURL + "demo_1_women1_03.jpeg", UK, F),
    new SmileResult(100, baseURL + "demo_1_women1_04.jpeg", UK, F),


    // DE
    new SmileResult(25, baseURL + "demo_1_man3_01.jpeg", DE, M),
    new SmileResult(45, baseURL + "demo_1_man3_02.jpeg", DE, M),
    new SmileResult(85, baseURL + "demo_1_man3_03.jpeg", DE, M),
    new SmileResult(100, baseURL + "demo_1_man3_04.jpeg", DE, M),

    new SmileResult(25, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fdemo_1_man3_01_wix.jpeg", DE, M,
      [new Brand("WIX")],
    ),
    new SmileResult(45, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fdemo_1_man3_02_wix.jpeg", DE, M,
      [new Brand("WIX")],
    ),
    new SmileResult(85, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fdemo_1_man3_03_wix.jpeg", DE, M,
      [new Brand("WIX")],
    ),
    new SmileResult(100, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fdemo_1_man3_04_wix.jpeg", DE, M,
      [new Brand("WIX")],
    ),

    new SmileResult(25, baseURL + "demo_1_women2_01.jpeg", DE, F),
    new SmileResult(45, baseURL + "demo_1_women2_02.jpeg", DE, F),
    new SmileResult(85, baseURL + "demo_1_women2_03.jpeg", DE, F),
    new SmileResult(100, baseURL + "demo_1_women2_04.jpeg", DE, F),


    // US
    new SmileResult(25, baseURL + "demo_1_man1_01.jpeg", US, M),
    new SmileResult(45, baseURL + "demo_1_man1_02.jpeg", US, M),
    new SmileResult(85, baseURL + "demo_1_man1_03.jpeg", US, M),
    new SmileResult(100, baseURL + "demo_1_man1_04.jpeg", US, M),

    new SmileResult(25, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fdemo_1_man1_01wix.png", US, M,
      [new Brand("WIX")],
    ),
    new SmileResult(45, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fdemo_1_man1_02wix.jpeg", US, M,
      [new Brand("WIX")],
    ),
    new SmileResult(85, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fdemo_1_man1_03wix.jpeg", US, M,
      [new Brand("WIX")],
    ),
    new SmileResult(100, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fdemo_1_man1_04wix.jpeg", US, M,
      [new Brand("WIX")],
    ),

    new SmileResult(25, baseURL + "demo_1_us_women_01.jpeg", US, F),
    new SmileResult(45, baseURL + "demo_1_us_women_02.jpeg", US, F),
    new SmileResult(85, baseURL + "demo_1_us_women_03.jpeg", US, F),
    new SmileResult(100, baseURL + "demo_1_us_women_04.jpeg", US, F),


    // IN
    new SmileResult(25, baseURL + "demo_1_in_man_01.jpeg", IN, M, [], new ColorPalette(["#f6e9f2", "#fafcfb", "#352f2a", "black"])),
    new SmileResult(45, baseURL + "demo_1_in_man_02.jpeg", IN, M, [], new ColorPalette(["#f6e9f2", "#fafcfb", "#352f2a", "black"])),
    new SmileResult(85, baseURL + "demo_1_in_man_03.jpeg", IN, M, [], new ColorPalette(["#f6e9f2", "#fafcfb", "#352f2a", "black"])),
    new SmileResult(100, baseURL + "demo_1_in_man_04.jpeg", IN, M, [], new ColorPalette(["#f6e9f2", "#fafcfb", "#352f2a", "black"])),

    new SmileResult(25, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fpic_1%2FDemo%2B1%2Fpic_1%2Fdemo_1_in_women_01.jpeg%2Fdemo_1_in_man_01_wix.jpeg",
      IN, M, [
      new Brand("WIX"),
    ],
      new ColorPalette(["#f6e9f2", "#fafcfb", "#352f2a", "black"])
    ),
    new SmileResult(45, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fpic_1%2FDemo%2B1%2Fpic_1%2Fdemo_1_in_women_01.jpeg%2Fdemo_1_in_man_02_wix.jpeg",
      IN, M, [
      new Brand("WIX")
    ],
      new ColorPalette(["#f6e9f2", "#fafcfb", "#352f2a", "black"])
    ),
    new SmileResult(85, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fpic_1%2FDemo%2B1%2Fpic_1%2Fdemo_1_in_women_01.jpeg%2Fdemo_1_in_man_03_wix.jpeg",
      IN, M, [
      new Brand("WIX")
    ],
      new ColorPalette(["#f6e9f2", "#fafcfb", "#352f2a", "black"])
    ),
    new SmileResult(100, "https://s3.amazonaws.com/bria-research-results/Demo%201%2Fpic_1%2FDemo%2B1%2Fpic_1%2Fdemo_1_in_women_01.jpeg%2Fdemo_1_in_man_04_wix.jpeg",
      IN, M, [
      new Brand("WIX")
    ],
      new ColorPalette(["#f6e9f2", "#fafcfb", "#352f2a", "black"])
    ),

    new SmileResult(25, baseURL + "demo_1_in_women_01.jpeg", IN, F, [], new ColorPalette(["#f6e9f2", "#fafcfb", "#352f2a", "black"])),
    new SmileResult(45, baseURL + "demo_1_in_women_02.jpeg", IN, F, [], new ColorPalette(["#f6e9f2", "#fafcfb", "#352f2a", "black"])),
    new SmileResult(85, baseURL + "demo_1_in_women_03.jpeg", IN, F, [], new ColorPalette(["#f6e9f2", "#fafcfb", "#352f2a", "black"])),
    new SmileResult(100, baseURL + "demo_1_in_women_04.jpeg", IN, F, [], new ColorPalette(["#f6e9f2", "#fafcfb", "#352f2a", "black"])),
  ]),
  new Image("https://s3.amazonaws.com/bria-research-results/Noa%2Fshutterstock_1760219624.jpg", [

    new SmileResult(25, "https://s3.amazonaws.com/bria-research-results/Noa%2Fkid%2Bbg.png", UK, F),
    new SmileResult(45, "https://s3.amazonaws.com/bria-research-results/Noa%2Fkid%2Bbg.png", UK, F),
    new SmileResult(85, "https://s3.amazonaws.com/bria-research-results/Noa%2Fkid%2Bbg.png", UK, F),
    new SmileResult(100, "https://s3.amazonaws.com/bria-research-results/Noa%2Fkid%2Bbg.png", UK, F),

    new SmileResult(25, "https://s3.amazonaws.com/bria-research-results/Noa%2Fkid%2Bdog_bg-colordgirl.png", UK, F,
      [],
      new ColorPalette([]),
      [new SceneObject(ObjectType.DOG, "Dog")]
    ),
    new SmileResult(45, "https://s3.amazonaws.com/bria-research-results/Noa%2Fkid%2Bdog_bg-colordgirl.png", UK, F,
      [],
      new ColorPalette([]),
      [new SceneObject(ObjectType.DOG, "Dog")]
    ),
    new SmileResult(85, "https://s3.amazonaws.com/bria-research-results/Noa%2Fkid%2Bdog_bg-colordgirl.png", UK, F,
      [],
      new ColorPalette([]),
      [new SceneObject(ObjectType.DOG, "Dog")]
    ),
    new SmileResult(100, "https://s3.amazonaws.com/bria-research-results/Noa%2Fkid%2Bdog_bg-colordgirl.png", UK, F,
      [],
      new ColorPalette([]),
      [new SceneObject(ObjectType.DOG, "Dog")]
    ),
  ])
]


enum AnalysisStage {
  NONE,
  A,
  B,
  C
}

class ImageFiltersDemo extends React.Component<any, Istate> {

  state: Istate = {
    value: 0,
    processing: false,
    breadCrumbs: [],
    nextResult: new Crumb(originalImages[0].img),
    oldImageResult: null,
    showWix: null,
    analysisStage: AnalysisStage.NONE,
    colorPalette: originalImages[0].smileResualts[0].colors,
    selectedImage: originalImages[0],
    selectedLocal: UK,
    selectedGender: M,
    showEthnicityMenue: false,
    seasonsAnchorEl: null,
    imageAnchorEl: null,
    localizerOpen: false,
    personalizerOpen: false,
    brandOpen: false,
    objectsRecognition: false,
    showDog: null,
    shrink: true,
    positionTool: { x:0, y:0 }
  }

  public render() {
    const { classes } = this.props
    if (this.state.selectedImage === null) {
      return (
        <Paper className={classes.select_image_paper}>
          <div>SELECT YOU IMAGE </div>
        </Paper>
      )
    }
    return <div style={{minHeight:'100vh'}}>
      <Box
        position="absolute"
        zIndex="tooltip"
      >
        {this.filters()}
      </Box>
      <Box
        position="absolute"
        zIndex="modal"
      >
        {this.results()}
      </Box>
      {this.state.nextResult !== null ? <div className={classes.approval_buttons_container} style={{
        position: "absolute",
        right: "9px",
        top: "9%",
        width: "9%"
      }}>
        <Button
          style={{ height: "40px" }}
          disabled={this.shouldDisablEditing()}
          onClick={() => {
            var newArray: Crumb[] = this.state.breadCrumbs
            if (this.state.nextResult !== null) {
              newArray.unshift(new Crumb(this.state.nextResult.img))
              this.setState({ breadCrumbs: newArray })
            }
          }}
          className={classes.approval_buttons}
          variant="contained"
          color="primary"
        >
          {"ADD"}
        </Button>


        {this.state.showEthnicityMenue && <div style={{
          position: "absolute",
          right: "20px",
          top: "30%",
        }}>
          <Paper>
            <List >
              {this.getLocals().map((local) =>
                <ListItem
                  button
                  onClick={(_) => this.handleCloseLocationMenue(local)}
                >
                  <ListItemIcon>
                    <img className={classes.local_image} width={20} src={local.imageUrl} alt={local.imageUrl} />
                  </ListItemIcon>
                  <ListItemText
                    primary={local.name}
                  />
                </ListItem>
              )}

            </List>
          </Paper>

        </div>}
      </div> : <div />}
      {this.state.breadCrumbs.length > 0 ? <Grid item lg={3}>
        <Paper className={(classes.paper, classes.addimages)}>
          {this.chnageHistory()}
        </Paper>
      </Grid> : <div />}
    </div>
  }

  // actions 
  handleCloseLocationMenue = (local: GeoLocation) => {
    this.setState({ showEthnicityMenue: false, selectedLocal: local }, () => {
      this.applyChnages()
    })
  };

  handleCloseSeasonsMenue = () => {
    this.setState({ seasonsAnchorEl: null })
  };

  handleCloseImageMenue = (image: Image) => {
    this.setState({
      imageAnchorEl: null,
      selectedImage: image,
      colorPalette: image.smileResualts[0].colors,
      nextResult: new Crumb(image.img),
      selectedGender: image.img === originalImages[1].img ? F : M,
      showDog: image.img === originalImages[1].img ? true : null,
      selectedLocal: image.img === originalImages[1].img ? UK : UK
    })
  };

  handleClickLocationMenue = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ showEthnicityMenue: true })
  }

  handleClickSeasonsMenue = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ seasonsAnchorEl: event.currentTarget })
  }

  handleClickImageMenue = (event: React.MouseEvent<HTMLDivElement>) => {
    this.setState({ imageAnchorEl: event.currentTarget })
  }

  applyChnages() {
    const newImage = this.state.selectedImage?.smileResualts
      .filter((image) => this.state.selectedLocal === null ? true : this.state.selectedLocal?.name === image.geoLocation.name)
      .filter((image) => this.state.selectedGender === null ? true : this.state.selectedGender?.name === image.gender.name)
      .filter((image) => {
        if (this.state.showDog !== null) {
          if (this.state.showDog) {
            return image.objects.map(obj => obj.type).includes(ObjectType.DOG)
          } else {
            return !image.objects.map(obj => obj.type).includes(ObjectType.DOG)
          }
        } else {
          return true
        }
      })
      .filter((image) => {
        if (this.state.showWix !== null) {
          if (this.state.showWix) {
            return image.brands.map(obj => obj.name).includes("WIX")
          } else {
            return !image.brands.map(obj => obj.name).includes("WIX")
          }
        } else {
          return true
        }
      })
      .find((image) => {
        return image.value >= this.state.value && this.state.selectedLocal === image.geoLocation
      })
    if (newImage !== undefined) {
      this.setState({ processing: true }, () => {
        this.setState({
          oldImageResult: this.state.nextResult?.img ?? "",
          nextResult: new Crumb(newImage.outpupImage),
          colorPalette: newImage.colors
        })

        this.setState({ analysisStage: AnalysisStage.A })
        setTimeout(() => {
          this.setState({ analysisStage: AnalysisStage.B })
          setTimeout(() => {
            this.setState({ analysisStage: AnalysisStage.C })
            setTimeout(() => {
              this.setState({
                analysisStage: AnalysisStage.NONE,
                processing: false,
              })
            }, 1500)
          }, 1500)
        }, 1500)
        // setTimeout(() => {
        // }, Math.floor((Math.random() * 3) + 1) * 1000)
      })
    }
  }

  handleSliderChange = (event: any, newValue: number | number[]) => {
    this.setState({ value: newValue as number }, () => {
      this.applyChnages()
    })
  };

  // helpers functions
  shouldDisablEditing(): boolean {
    return this.state.processing
  }

  isSingleGender(): Gender | null {
    return null
  }

  getTitleForAnalysisStage() {
    switch (this.state.analysisStage) {
      case AnalysisStage.NONE: return ""
      case AnalysisStage.A: return "Extracting pixels into Latent space"
      case AnalysisStage.B: return "Finding vectors that fit your query"
      case AnalysisStage.C: return "Finalizing results"
    }
  }

  getBlurForAnalysisStage() {
    switch (this.state.analysisStage) {
      case AnalysisStage.NONE: return ""
      case AnalysisStage.A: return 'blur(8px)'
      case AnalysisStage.B: return 'blur(6px)'
      case AnalysisStage.C: return 'blur(4px)'
    }
  }

  getLocals(): GeoLocation[] {
    var cash: Map<string, any> = new Map<string, any>()
    var locals: GeoLocation[] = []
    this.state.selectedImage?.smileResualts.forEach((res) => {
      cash.set(res.geoLocation.name, res.geoLocation)
    })
    cash.forEach((value: GeoLocation, key, map) => locals.push(value))
    return locals
  }

  getSceneObjects(): SceneObject[] {
    var cash: Map<string, any> = new Map<string, any>()
    var items: SceneObject[] = []
    this.state.selectedImage?.smileResualts.flatMap(res => res.objects).forEach((obj) => {
      cash.set(obj.name, obj)
    })
    cash.forEach((value: SceneObject, key, map) => items.push(value))
    return items
  }

  getBrandObjects(): Brand[] {
    var cash: Map<string, any> = new Map<string, any>()
    var items: Brand[] = []
    this.state.selectedImage?.smileResualts.flatMap(res => res.brands).forEach((obj) => {
      cash.set(obj.name, obj)
    })
    cash.forEach((value: Brand, key, map) => items.push(value))
    return items
  }

  // components
  results(): JSX.Element {
    const { classes } = this.props
    // const imageWidth = 610
    return (
      <div className={classes.container}>
        {this.state.processing ? <div className={classes.loading_container}>
          {this.state.processing ? <LinearProgress className={classes.loading} /> : <div></div>}
          <Typography variant={"h5"} id="disabled-slider" gutterBottom>
            {this.getTitleForAnalysisStage()}
          </Typography>
        </div> : <div />}

        {/* 
          <Animate
            play={true} // set play true to start the animation
            duration={4500} // how long is the animation duration
            delay={0.0} // how many delay seconds will apply before the animation start
            start={{ transform: 'blur(8px)' }}
            end={{ transform: 'blur(4px)' }}
            // complete={{ display: 'none' }}
            easeType="cubic-bezier(0.445, 0.05, 0.55, 0.95)"
          // onComplete={onCompleteCallBack} // call back function when animation is completed
          >
            <img className={classes.image} width={630} src={this.state.nextResult?.img ?? ""} alt="" /> :
          </Animate> */}
        {this.state.oldImageResult === null ?
          <img className={classes.image} src={this.state.nextResult?.img ?? ""} alt="" /> :
          <Animate
            play={this.state.processing}
            duration={2} // how long is the animation duration
            start={{ opacity: 1.0 }}
            end={{ opacity: 0.7 }}
          >
            <ProgressiveImage
              delay={4500}
              src={this.state.nextResult?.img ?? ""}
              placeholder={this.state.oldImageResult ?? ""}>
              {(src: any, loading: any) => {
                return (
                  <img
                    style={{
                      filter: loading ? this.getBlurForAnalysisStage() : '',
                    }}
                    className={classes.image}
                    src={src}
                    alt={src}
                  />
                )
              }}
            </ProgressiveImage>
          </Animate>
        }
        <Menu
          id="simple-menu"
          anchorEl={this.state.imageAnchorEl}
          keepMounted
          open={Boolean(this.state.imageAnchorEl)}
          onClose={this.handleCloseLocationMenue}
        >
          {originalImages.map((image) =>
            <MenuItem
              onClick={(_) => this.handleCloseImageMenue(image)}>
              <img className={classes.local_image} width={80} src={image.img} alt={image.img} />
            </MenuItem>
          )}
        </Menu>
      </div>
    )
  }

  chnageHistory(): JSX.Element {
    const { classes } = this.props
    return (
      <div className={classes.addImagesHistory}>
        <GridList className={classes.addUlList} style={{ flexWrap: 'nowrap' }}>
          {this.state.breadCrumbs.map((tile) => (
            <GridListTile className={classes.addLiList} style={{ flexWrap: 'nowrap' }} key={tile.img}>
              <img height={200} src={tile.img} alt={"FOO"} className={classes.imgLiList} />
            </GridListTile>
          ))}
        </GridList>
      </div>
    )
  }
  onStop(event: any, data: any) {
    // Viewport (wrapper)
    const documentElement = document.documentElement
    var wrapperHeight =  documentElement.clientHeight
    var wrapperWidth = documentElement.clientWidth
    wrapperWidth = this.state.shrink ? wrapperWidth-560 : wrapperWidth-560
    var ZeroBound = this.state.shrink ? -260 : 0
    var x = data.x >= wrapperWidth ? 50 : data.x<= ZeroBound ? 50 : data.x;
    var y = x === 50 ? 50 : data.y >= wrapperHeight-250 ? 50 : data.y<= 0? 50 : data.y;
    x = y === 50 ? 50:
    this.setState({ positionTool : {x: x , y: y}})
  }

  filters(): JSX.Element { 
    const { classes } = this.props
    return (
      <Draggable 
        // bounds={{ top: 0, left: 0, right: 900, bottom: 350 }}
        position={this.state.positionTool}
        onStop={(event, data) => this.onStop(event, data)}
      >
        <List className={classes.filters_root}>
        <ListItem button onClick={(_) => this.setState({ shrink: !this.state.shrink })}>
          <ListItemText primary={
            <Typography variant={"h5"} id="disabled-slider" gutterBottom>
            </Typography>
          } />
          {this.state.shrink ? <RemoveCircleSharpIcon className={classes.addButtonInside}/> : <AddCircleSharpIcon className={classes.addButtonInside} />}
        </ListItem>
        <Collapse in={this.state.shrink} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <div id="toolSec" style={{backgroundColor: 'rgba(52, 52, 52, 0.9)'}}>
              {this.localizerSection()}
              <Divider className={classes.divider} />
              {this.personalizerSection()}
              <Divider className={classes.divider} />
              {this.optimizerSection()}
            </div>
          </List>
        </Collapse>
      </List>
    </Draggable>
    );
  }

  // filters section
  optimizerSection(): JSX.Element {
    const { classes } = this.props
    return (
      <List className={classes.colpase_list}>
        <ListItem button >
          <ListItemText primary={
            <Typography variant={"h5"} id="disabled-slider" gutterBottom>
              {"Optimizer"}
            </Typography>
          } />
          <ExpandMore />
        </ListItem>
        <Collapse in={true} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
          </List>
        </Collapse>
      </List>
    )
  }

  personalizerSection(): JSX.Element {
    const { classes } = this.props
    return (
      <List className={classes.colpase_list}>
        <ListItem button onClick={(_) => this.setState({ personalizerOpen: !this.state.personalizerOpen })}>
          <ListItemText primary={
            <Typography variant={"h5"} id="disabled-slider" gutterBottom>
              {"Personalizer"}
            </Typography>
          } />
          {this.state.personalizerOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.personalizerOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem className={classes.zero_padding} >
              <ListItemText primary={
                <Typography variant={"h6"} id="disabled-slider" gutterBottom>
                  {"Sentiment"}
                </Typography>
              } />
            </ListItem>
            {this.traditionalInnovativeFilter()}
            {this.accessablePreimumeFilter()}
            {this.funSeriuseFilter()}
            {this.simpleSophisticatedFilter()}
            {this.relaxEnergeticFilter()}
            {this.colorFilter()}
            {this.seasonsFilter()}
            {this.genderFilter()}
            {/* {this.eyeColorFilter()} */}
            {this.ageFilter()}

            {/* {"OBJECT RECOGNITION"} */}
            <List className={classes.colpase_list}>
              <ListItem className={classes.zero_padding} button onClick={(_) => this.setState({ objectsRecognition: !this.state.objectsRecognition })}>
                <ListItemText primary={
                  <Typography variant={"h6"} id="disabled-slider" gutterBottom>
                    {"Object recognition"}
                  </Typography>
                } />
                {this.state.objectsRecognition ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.objectsRecognition} timeout="auto" unmountOnExit>
                <List>
                  {this.getSceneObjects().map((value) => {
                    const labelId = `checkbox-list-label-${value}`;
                    return (
                      <div className={classes.geo_location}>
                        <ListItem key={value.name} role={undefined} dense button onClick={(event => {
                          this.setState({ showDog: !this.state.showDog }, () => this.applyChnages())
                        })}>
                          <ListItemIcon>
                            <Checkbox
                              edge="start"
                              checked={this.state.showDog ?? false}
                              tabIndex={-1}
                              disableRipple
                              inputProps={{ 'aria-labelledby': labelId }}
                            />
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={value.name} />
                        </ListItem>
                        {this.state.showDog ?
                          <IconButton onClick={(_) => {
                            this.setState({ nextResult: new Crumb("https://s3.amazonaws.com/bria-research-results/Noa%2Fkid2%2Bdog2%2Bbg.png") })
                          }} >
                            <AutorenewIcon />
                          </IconButton >
                          : <div />}
                      </div>
                    );
                  })}
                </List>
              </Collapse>
            </List>

            {/* {"BRAND"} */}
            <List>
              <ListItem className={classes.zero_padding} button onClick={(_) => this.setState({ brandOpen: !this.state.brandOpen })}>
                <ListItemText primary={
                  <Typography variant={"h6"} id="disabled-slider" gutterBottom>
                    {"Brand"}
                  </Typography>
                } />
                {this.state.brandOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={this.state.brandOpen} timeout="auto" unmountOnExit>
                <List className={classes.zero_padding} >
                  {this.getBrandObjects().map((value) => {
                    const labelId = `checkbox-list-label-${value}`;
                    return (
                      <ListItem key={value.name} role={undefined} dense button onClick={(event => {
                        this.setState({
                          showWix: this.state.showWix === null ? true : !this.state.showWix
                        }, () => this.applyChnages())
                      })}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={this.state.showWix ?? false}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={value.name} />
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </List>

          </List>
        </Collapse>
      </List>
    )
  }

  localizerSection(): JSX.Element {
    const { classes } = this.props
    return (
      <List className={classes.colpase_list}>
        <ListItem button onClick={(_) => this.setState({ localizerOpen: !this.state.localizerOpen })}>
          <ListItemText primary={
            <Typography variant={"h5"} id="disabled-slider" gutterBottom>
              {"Localizer"}
            </Typography>
          } />
          {this.state.localizerOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={this.state.localizerOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem >
              <Button
                disabled={this.shouldDisablEditing()}
                onClick={this.handleClickLocationMenue}>
                {this.state.selectedLocal !== null ?
                  <div className={classes.geo_location}>
                    {"counrty - "}
                    <img className={classes.local_image} width={20} src={this.state.selectedLocal.imageUrl} alt={this.state.selectedLocal.imageUrl} />
                  </div>
                  : "Location"}
              </Button>
            </ListItem >
            <ListItem >
              <Button > {"State"} </Button>
            </ListItem >
            <ListItem >
              <Button > {"City"} </Button>
            </ListItem >
            <ListItem >
              <Button > {"Ethnicity"} </Button>
            </ListItem >
            <ListItem >
              <Button > {"Vertical"} </Button>
            </ListItem >
          </List>
        </Collapse>
      </List>
    )
  }

  // filters
  funSeriuseFilter() {
    return (
      <Grid container spacing={2}>
        <Grid item>
          {"Serious"}
        </Grid>
        <Grid item xs>
          <Slider
            disabled={this.shouldDisablEditing()}
            onChangeCommitted={this.handleSliderChange}
            aria-labelledby="continuous-slider"
          />
        </Grid>
        <Grid item>
          {"Fun"}
        </Grid>
      </Grid>
    )
  }

  relaxEnergeticFilter() {
    return (
      <Grid container spacing={2}>
        <Grid item>
          {"Classic"}
        </Grid>
        <Grid item xs>
          <Slider disabled onChangeCommitted={this.handleSliderChange} aria-labelledby="continuous-slider" />
        </Grid>
        <Grid item>
          {"Modern"}
        </Grid>
      </Grid>
    )
  }

  simpleSophisticatedFilter() {
    return (
      <Grid container spacing={2}>
        <Grid item>
          {"Simple"}
        </Grid>
        <Grid item xs>
          <Slider disabled onChangeCommitted={this.handleSliderChange} aria-labelledby="continuous-slider" />
        </Grid>
        <Grid item>
          {"Sophisticated"}
        </Grid>
      </Grid>
    )
  }

  accessablePreimumeFilter() {
    return (
      <Grid container spacing={2}>
        <Grid item>
          {"Accessable"}
        </Grid>
        <Grid item xs>
          <Slider disabled onChangeCommitted={this.handleSliderChange} aria-labelledby="continuous-slider" />
        </Grid>
        <Grid item>
          {"Premium"}
        </Grid>
      </Grid>
    )
  }

  traditionalInnovativeFilter() {
    return (
      <Grid container spacing={2}>
        <Grid item>
          {"Traditional"}
        </Grid>
        <Grid item xs>
          <Slider disabled onChangeCommitted={this.handleSliderChange} aria-labelledby="continuous-slider" />
        </Grid>
        <Grid item>
          {"Innovative"}
        </Grid>
      </Grid>
    )
  }

  colorFilter() {
    const { classes } = this.props
    return (
      <div className={classes.color_filter_grid}>
        <div className={classes.color_filter_name}> {"Color"} </div>
        <Grid container spacing={2}>
          <Grid container spacing={0}>
            {this.state.colorPalette?.colors.map((value) => (
              <Grid key={value} item>
                <Paper style={{ backgroundColor: value }} className={classes.color_cell} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    )
  }

  genderFilter() {
    return (
      <Grid container spacing={2}>
        <Grid item>
          {"Male"}
        </Grid>
        <Grid item xs>
          <Slider
            // value={(this.state.selectedImage?.img ?? "") === originalImages[1].img ? 100 : 0}
            disabled={this.shouldDisablEditing() || (this.state.selectedImage?.img ?? "") === originalImages[1].img}
            onChangeCommitted={(event, value) => {
              var gender = M
              if (value as number > 50) {
                gender = F
              } else {
                gender = M
              }
              this.setState({ selectedGender: gender }, () => {
                this.applyChnages()
              })
            }} aria-labelledby="continuous-slider" />
        </Grid>
        <Grid item>
          {"Female"}
        </Grid>
      </Grid>
    )
  }

  ageFilter() {
    return (
      <Grid container spacing={2}>
        <Grid item>
          {"Young"}
        </Grid>
        <Grid item xs>
          <Slider disabled onChangeCommitted={this.handleSliderChange} aria-labelledby="continuous-slider" />
        </Grid>
        <Grid item>
          {"Old"}
        </Grid>
      </Grid>
    )
  }

  seasonsFilter() {
    return (<Grid container spacing={2}>
      <Grid item>
        {"Winter"}
      </Grid>
      <Grid item xs>
        <Slider disabled aria-labelledby="continuous-slider" />
      </Grid>
      <Grid item>
        {"Summer"}
      </Grid>
    </Grid>
      // <Grid container spacing={2}>
      //   <Grid item>
      //     {"Season -"}
      //   </Grid>
      //   <Button
      //     disabled={this.shouldDisablEditing()}
      //     onClick={this.handleClickSeasonsMenue}>
      //     {"Spring"}
      //   </Button>
      //   <Menu
      //     id="simple-menu"
      //     anchorEl={this.state.seasonsAnchorEl}
      //     keepMounted
      //     open={Boolean(this.state.seasonsAnchorEl)}
      //     onClose={this.handleCloseSeasonsMenue}
      //   >
      //     {["Winter", "Spring", "Summer", "Fall"].map((season) =>
      //       <MenuItem>
      //         {season}
      //       </MenuItem>
      //     )}
      //   </Menu>
      // </Grid>
    )
  }

  eyeColorFilter() {
    return (
      <div>
        {"Eye Color"}
        <Grid container spacing={2}>
          <Grid item>
            {"R"}
          </Grid>
          <Grid item xs>
            <Slider disabled onChangeCommitted={this.handleSliderChange} aria-labelledby="continuous-slider" />
          </Grid>
          <Grid item>
            {"G"}
          </Grid>
          <Grid item xs>
            <Slider disabled onChangeCommitted={this.handleSliderChange} aria-labelledby="continuous-slider" />
          </Grid>
          <Grid item>
            {"B"}
          </Grid>
          <Grid item xs>
            <Slider disabled onChangeCommitted={this.handleSliderChange} aria-labelledby="continuous-slider" />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = (theme: Theme) => createStyles({
  approval_buttons: {
    width: "100%",
    height: 60,
    margin: theme.spacing(1),
  },
  local_image: {
    margin: theme.spacing(1),
  },
  zero_padding: {
    padding: 0
  },
  menuLocals: {
    // position: "absolute"
  },
  approval_buttons_container: {
    display: 'flex',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-around',
    // overflow: 'hidden',
    // backgroundColor: theme.palette.background.paper,
  },
  chnageHistory: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  loading: {
    width: '100%',
    '& > * + *': {
      // marginTop: theme.spacing(0),
    },
  },
  filters_root: {
    width: 260,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    transition : 'height 1s ease',
    height: '100%'
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  image: {
    alignSelf: "center",
    position: "absolute",
    maxwidth: "100%",
    maxHeight: "90vh",
    margin: "auto"
  },
  loading_container: {
    alignItems: "center",
    height: 60,
  },
  original_image: {
    alignSelf: "center",
    paddingTop: theme.spacing(33),
  },
  container: {
    paddingLeft: theme.spacing(10),
  },
  paper: {
    padding: theme.spacing(2),
    height: 800,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  geo_location: {
    // padding: theme.spacing(2),
    alignItems: "center",
    display: 'flex',
    flexDirection: 'row',
  },
  colpase_list: {

  },
  divider: {
    margin: theme.spacing(2),
  },
  color_cell: {
    height: 30,
    width: 35,
  },
  select_image_paper: {
    margin: theme.spacing(15),
    height: 700,
    alignSelf: "center"
  },
  color_filter_grid: {
    alignItems: "center",
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
  },
  color_filter_name: {
    paddingRight: theme.spacing(2),
  },
  addimages: {
    width: 'auto'
  },
  addUlList: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 999999,
    overflow: 'auto',
    margin: 'auto !important',
    width: '572px',
  },
  addButtonInside: {
    position: 'absolute',
    right: '0%',
    fontSize: '45px',
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    marginBottom: '15px'
  }
})

export default withStyles(styles)(ImageFiltersDemo)
