import React, {
	ReactElement,
	SetStateAction,
	useContext,
	useMemo,
} from "react";
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Typography from "@material-ui/core/Typography";
import Personalizer from "./Personalizer";
import { makeStyles, Theme } from "@material-ui/core/styles";
import LiveFacesTab from "./LiveFacesTab";
import Localizer from "./Diversifier";
import {
	ThumbnailData,
	VideoInfoParameters,
} from "../../types/graphql-global-types";
import { DrawerProps } from "@material-ui/core/Drawer";
import { SelectedPointInterface, ToolsViewerMode } from "./utilities";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import { ApolloError } from "@apollo/client";
import { LiveFaces } from "./useLiveFaces";
import Context from "../../Context/Context";
import { ROLES } from "../../Routes";
import BriaBadge from "../UI/BriaBadge";
import {
	ToolConfigTabsTypes,
	ToolsSlider,
	ToolsStructure,
	ToolsSuggestions,
	ToolsThumbnails,
} from "../../Models/ToolsConfig";
import ExpressionsAlert from "./ExpressionsAlert";

interface IProps {
	disabled: boolean;
	liveFaces: LiveFaces[];
	onCreateLiveFace: (videoInfo: VideoInfoParameters) => void;
	loadingLiveFaces: boolean;
	selectedPoint: SelectedPointInterface | null;
	selectedEthnicity: string;
	selectedEthnicityValue: number;
	onChangeEthnicity: (ethnicty: string, value: number) => void;
	personalizerValues: { [key: string]: number };
	onChangePersonalizer: (
		field: string,
		value: number
	) => Promise<void> | void;
	onAddSemanticCombination: (
		thumbnail: ThumbnailData,
		original: boolean,
		controller: any
	) => Promise<string>;
	toDisplayThumbnails: { url: string }[] | null;
	onThumbnailClick: (value: any) => void;
	drawerAnchor: DrawerProps["anchor"];
	fullscreen?: boolean;
	selectedVideo: string | null | undefined;
	setSelectedVideo: React.Dispatch<
		React.SetStateAction<string | null | undefined>
	>;
	openVideoAlert: boolean;
	setOpenVideoAlert: React.Dispatch<React.SetStateAction<boolean>>;
	handleResetActions: () => void;
	addSemanticCombinationError?: ApolloError | undefined;
	liveBackgroundsList: any[];
	onCreateLiveBackground: (data: any) => void;
	configLoading?: boolean;
	createExtremeExpression: (
		emotion: string,
		value: number
	) => Promise<void> | undefined;
	createExtremeExpressionMutationLoading: boolean;
	createExtremeExpressionMutationError: ApolloError | undefined;
	selectedExpressionValue: number;
	selectedExpressionName: string;
	resetExtremeExpression: () => void;
	validExpressions: [string];
	openExpressionsAlert: boolean;
	setOpenExpressionsAlert: React.Dispatch<React.SetStateAction<boolean>>;
	resetExpressionsAlert: boolean;
	children?: ReactElement;
	toolConfigurations: ToolsStructure[];
	currentSliders: ToolsSlider[];
	toolsConfigThumbnails: ToolsThumbnails[];
	toolsConfigSuggestions: ToolsSuggestions[];
	resetLastExtremeExpression: () => void;
	setShowExpressionWarning: (value: boolean) => void;
}

interface IMapper {
	[key: string]: React.PropsWithChildren<React.ReactNode>;
}

const useStyles = makeStyles((theme: Theme) => {
	return {
		tabsContainer: {
			background: "transparent",
		},
		tabContainer: {
			alignSelf: "start",
			fontSize: 18,
			fontWeight: theme.typography.fontWeightBold,
			width: "100%",
			minWidth: "auto",
			textTransform: "capitalize",
			padding: 23,
			color: theme.palette.cardTextSecondary.main,
			opacity: 1,
			textAlign: "left",
			"& .MuiTab-wrapper": {
				alignItems: "flex-start",
			},
		},
		disabled: {
			// opacity: 0.4,
			opacity: "1 !important",
			color: "rgba(164, 164, 164, 0.4)",
		},
		selecteTab: {
			fontWeight: theme.typography.fontWeightBold,
			color: theme.palette.tools.main,
		},
		hidden: {
			opacity: 0,
		},
		sideRoot: {
			opacity: (fullscreen) => (fullscreen ? 0 : 1),
			width: (fullscreen) => (fullscreen ? 0 : "100%"),
			overflow: "hidden",
			// marginTop: theme.spacing(1),
			// padding: theme.spacing(1),
			height: "100%",
		},
		sideContent: {
			height: "100%",
			background: theme.palette.background.paper,
			padding: theme.spacing(1, 2),
			// borderRadius: theme.shape.borderRadius,
		},
		title: {
			fontSize: 18,
			fontWeight: theme.typography.fontWeightBold,
		},
	};
});

function TabPanel(props: any) {
	const { children, hidden } = props;

	return hidden ? null : (
		<Typography
			role="tabpanel"
			component={"div"}
			hidden={hidden}
			style={{ height: "100%" }}
		>
			<Box height={"100%"}>{children}</Box>
		</Typography>
	);
}

const ToolsAction: React.FC<IProps> = ({
	disabled,
	selectedEthnicity,
	selectedEthnicityValue,
	personalizerValues,
	liveFaces,
	drawerAnchor,
	selectedPoint,
	onChangeEthnicity,
	onThumbnailClick,
	onAddSemanticCombination,
	onChangePersonalizer,
	onCreateLiveFace,
	fullscreen,
	selectedVideo,
	setSelectedVideo,
	openVideoAlert,
	setOpenVideoAlert,
	handleResetActions,
	liveBackgroundsList,
	onCreateLiveBackground,
	configLoading,
	children,
	createExtremeExpression,
	selectedExpressionValue,
	selectedExpressionName,
	resetExtremeExpression,
	validExpressions,
	openExpressionsAlert,
	setOpenExpressionsAlert,
	resetExpressionsAlert,
	toolConfigurations,
	currentSliders,
	toolsConfigThumbnails,
	toolsConfigSuggestions,
	resetLastExtremeExpression,
	setShowExpressionWarning,

	//Config
}) => {
	const [selectedTab, setSelectedTab] = React.useState<number>(0);
	const [newTabNumber, setNewTabNumber] = React.useState<number>(0);
	const classes = useStyles(fullscreen);
	const context = useContext(Context);

	const suggestionThumbnails = useMemo(() => {
		return toolsConfigSuggestions.map((el, index) => {
			return {
				key: `suggestions-${index + 1}`,
				title: `Option ${index + 1}`,
				level1_id: "personalizer",
				level2_id: "suggestions",
				sliders: el.thumbnails,
				validation: {},
			};
		});
	}, [toolsConfigSuggestions]);

	const diversityThubmbnails = useMemo(() => {
		return toolsConfigThumbnails.filter(
			(el) => el.level2_id === ToolConfigTabsTypes.Diversity
		);
	}, [toolsConfigThumbnails]);

	const diversitySliders = useMemo(() => {
		return currentSliders.filter(
			(el) => el.level2Id === ToolConfigTabsTypes.Diversity
		);
	}, [currentSliders]);

	const validExpressionsLowerCase = validExpressions.map((exp: string) =>
		exp.toLowerCase()
	);

	const expressionsSliders = [
		...currentSliders.filter(
			(el) =>
				el.level2Id === ToolConfigTabsTypes.Expression &&
				el.level1Id === ToolConfigTabsTypes.Personalizer
		),
		...currentSliders.filter(
			(el) =>
				el.level2Id === ToolConfigTabsTypes.Expression &&
				el.level1Id === ToolConfigTabsTypes.Emotions &&
				validExpressionsLowerCase.includes(el.id.toLowerCase())
		),
	];

	const ToolsActionMapper: IMapper = {
		[ToolConfigTabsTypes.Diversity]: (
			<Box p={1} height="100%">
				<Localizer
					disabled={disabled}
					ethnicityValue={selectedEthnicityValue}
					selectedEthnicity={selectedEthnicity}
					onChangeEthnicity={(ethnicity, value) => {
						onChangeEthnicity(ethnicity, value);
					}}
					onAddSemanticCombination={async (
						ethnicity,
						ethnicityValue,
						controller
					) => {
						return await onAddSemanticCombination(
							{
								ethnicity: ethnicity,
								ethnicityValue: ethnicityValue,
								semantics: [],
								thumbnailIndex: 0,
							},
							false,
							controller
						);
					}}
					drawerAnchor={drawerAnchor}
					thumbnails={diversityThubmbnails}
					sliders={diversitySliders}
				/>
			</Box>
		),
		[ToolConfigTabsTypes.Suggestions]: (
			<Personalizer
				key="suggestion"
				personalizerKey="suggestion"
				personalizerValues={personalizerValues}
				disabled={disabled}
				isSuggestion={true}
				onChangePersonalizer={(sliders) => {
					const semanticsList = sliders.filter((el) => {
						const slider: ToolsSlider = el as ToolsSlider;
						return slider.level1Id === "personalizer";
					});
					const mutation: any = {
						semanticsList,
					};
					const ethinicty = sliders.filter((el) => {
						const slider: ToolsSlider = el as ToolsSlider;
						return slider.level1Id === "localizer";
					});
					if (ethinicty.length === 1) {
						mutation["ethnicity"] = ethinicty[0].id;
						mutation["ethnicityValue"] = ethinicty[0].value;
					}
					onThumbnailClick(mutation);
				}}
				onAddSemanticCombination={async (semantics, controller) => {
					if (semantics.length > 0) {
						const semanticsList = semantics.filter((el) => {
							const slider: ToolsSlider = el as ToolsSlider;
							return (
								slider.level1Id ===
								ToolConfigTabsTypes.Personalizer
							);
						});
						const ethinicty = semantics.filter((el) => {
							const slider: ToolsSlider = el as ToolsSlider;
							return (
								slider.level1Id ===
								ToolConfigTabsTypes.Localizer
							);
						});
						return await onAddSemanticCombination(
							{
								ethnicity: ethinicty[0]?.name ?? "original",
								ethnicityValue: +ethinicty[0]?.value ?? 0,
								semantics: semanticsList,
								thumbnailIndex: 0,
							},
							true,
							controller
						);
					}
					return "";
				}}
				drawerAnchor={drawerAnchor}
				thumbnails={suggestionThumbnails}
				sliders={null}
				objectType={selectedPoint?.objectType}
			/>
		),
		[ToolConfigTabsTypes.Expression]: (
			<Personalizer
				key={ToolConfigTabsTypes.Expression}
				personalizerKey={ToolConfigTabsTypes.Expression}
				personalizerValues={personalizerValues}
				disabled={disabled}
				onChangePersonalizer={(sliders) => {
					if (
						sliders.length === 1 &&
						sliders[0].level1Id === ToolConfigTabsTypes.Personalizer
					)
						onChangePersonalizer(sliders[0].id, sliders[0].value);
					else if (
						sliders.length === 1 &&
						sliders[0].level1Id === ToolConfigTabsTypes.Emotions
					) {
						createExtremeExpression(
							sliders[0].id,
							sliders[0].value
						);
					}
				}}
				onAddSemanticCombination={async (semantics, controller) => {
					return await onAddSemanticCombination(
						{
							ethnicity: selectedEthnicity,
							ethnicityValue: selectedEthnicityValue,
							semantics: semantics,
							thumbnailIndex: 0,
						},
						false,
						controller
					);
				}}
				drawerAnchor={drawerAnchor}
				thumbnails={toolsConfigThumbnails.filter(
					(el) => el.level2_id === ToolConfigTabsTypes.Expression
				)}
				sliders={expressionsSliders}
				objectType={selectedPoint?.objectType}
			/>
		),
		live_faces_video: context.user?.role === ROLES.ADMIN && (
			<LiveFacesTab
				liveFaces={liveFaces}
				onCreateLiveFace={(index: number) => {
					onCreateLiveFace(liveFaces[index].videoInfo);
				}}
				disabled={disabled}
				selectedVideo={selectedVideo}
				setSelectedVideo={setSelectedVideo}
				openVideoAlert={openVideoAlert}
				setOpenVideoAlert={setOpenVideoAlert}
				handleResetActions={handleResetActions}
				drawerAnchor={drawerAnchor}
			/>
		),
		backgroundVideo: context.user?.role === ROLES.ADMIN && (
			<LiveFacesTab
				liveFaces={liveBackgroundsList}
				onCreateLiveFace={(index, videoConfig) =>
					onCreateLiveBackground({
						direction: liveBackgroundsList[index].propertyName,
						speed: videoConfig.speed ?? 1,
					})
				}
				disabled={disabled}
				selectedVideo={selectedVideo}
				setSelectedVideo={setSelectedVideo}
				openVideoAlert={openVideoAlert}
				setOpenVideoAlert={setOpenVideoAlert}
				handleResetActions={handleResetActions}
				drawerAnchor={drawerAnchor}
			/>
		),
	};

	const personalizerMapper = (key: string): ReactElement => {
		return (
			<Personalizer
				key={key}
				personalizerKey={key}
				personalizerValues={personalizerValues}
				disabled={disabled}
				onChangePersonalizer={(sliders) => {
					if (sliders.length === 1)
						onChangePersonalizer(sliders[0].id, sliders[0].value);
				}}
				onAddSemanticCombination={async (semantics, controller) => {
					return await onAddSemanticCombination(
						{
							ethnicity: selectedEthnicity,
							ethnicityValue: selectedEthnicityValue,
							semantics: semantics,
							thumbnailIndex: 0,
						},
						false,
						controller
					);
				}}
				drawerAnchor={drawerAnchor}
				thumbnails={toolsConfigThumbnails.filter(
					(el) => el.level2_id === key
				)}
				sliders={currentSliders.filter((el) => el.level2Id === key)}
				objectType={selectedPoint?.objectType}
			/>
		);
	};

	const panels = toolConfigurations.map((el, index) => {
		return (
			<TabPanel
				key={`tab-panel-${el.level2Id}`}
				value={selectedTab}
				index={index}
				hidden={selectedTab !== index}
			>
				{ToolsActionMapper[el.level2Id] === undefined
					? personalizerMapper(el.level2Id)
					: ToolsActionMapper[el.level2Id]}
			</TabPanel>
		);
	});

	const diversityTabIndex = toolConfigurations.find((el) => {
		return el.level2Id === ToolConfigTabsTypes.Diversity;
	})?.level2Location;

	const expressionTabIndex = toolConfigurations.find((el) => {
		return el.level2Id === ToolConfigTabsTypes.Expression;
	})?.level2Location;

	const handleChange = (event: any, newValue: SetStateAction<number>) => {
		setNewTabNumber(newValue);

		if (
			diversityTabIndex !== undefined &&
			newValue === diversityTabIndex - 1
		) {
			context.user?.updateThumbnailsSettings(ToolsViewerMode.thumbnails);
		}
		if (
			expressionTabIndex !== undefined &&
			newValue === expressionTabIndex - 1
		) {
			context.user?.updateThumbnailsSettings(ToolsViewerMode.sliders);
			setShowExpressionWarning(true);
		}

		if (selectedVideo) {
			setOpenVideoAlert(true);
		} else if (selectedExpressionName && !resetExpressionsAlert) {
			setOpenExpressionsAlert(true);
		} else {
			setSelectedTab(newValue);
		}
	};

	if (!selectedPoint) return null;

	if (configLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="100%"
				width="100%"
			>
				<CircularProgress />
			</Box>
		);
	}
	const disableSliderSwitch =
		toolConfigurations.find((el) => {
			return el.level2Id === ToolConfigTabsTypes.Diversity;
		})?.level2Location === selectedTab;

	const childrenWithProps = React.Children.map(children, (child) => {
		// Checking isValidElement is the safe way and avoids a typescript
		// error too.
		if (React.isValidElement(child)) {
			return React.cloneElement(child, {
				disableToggle: disableSliderSwitch,
			});
		}
		return child;
	});

	return (
		<Box className={classes.sideRoot}>
			<Box className={classes.sideContent}>
				<Box display="flex" justifyContent="flex-end">
					<Box>{childrenWithProps}</Box>
				</Box>

				<Divider />
				<Box display="flex" mt={2} height="100%">
					<Box pr={1}>
						<Tabs
							value={selectedTab}
							onChange={handleChange}
							variant="scrollable"
							orientation="vertical"
							scrollButtons="auto"
							classes={{
								indicator: classes.tabsContainer,
							}}
							style={{ width: 190 }}
						>
							{toolConfigurations.map((el) => {
								const title = (
									<Typography
										className={classes.title}
										id={el.level2DisplayName}
									>
										{el.level2DisplayName}
									</Typography>
								);
								const label =
									el.soon && !context.user?.isAdmin() ? (
										<BriaBadge
											badgeContent={
												context.user?.isViewer()
													? "Premium"
													: "Soon"
											}
											color="secondary"
										>
											{title}
										</BriaBadge>
									) : (
										title
									);

								return (
									<Tab
										key={el.level2Id}
										disableRipple
										disabled={
											el.soon && !context.user?.isAdmin()
										}
										label={label}
										className={classes.tabContainer}
										classes={{
											selected: classes.selecteTab,
											disabled: classes.disabled,
										}}
									/>
								);
							})}
						</Tabs>
					</Box>
					<Box pr={1}>
						<Divider orientation="vertical" />
					</Box>
					<Box height="calc(100% - 60px)" width={"100%"}>
						{panels}
					</Box>
				</Box>
				<ExpressionsAlert
					openExpressionsAlert={openExpressionsAlert}
					setOpenExpressionsAlert={setOpenExpressionsAlert}
					resetExtremeExpression={resetExtremeExpression}
					onUndo={resetLastExtremeExpression}
					setSelectedTab={setSelectedTab}
					newTabNumber={newTabNumber}
				/>
			</Box>
		</Box>
	);
};

export default ToolsAction;
