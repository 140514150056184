import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import { LazyLoadImage } from "react-lazy-load-image-component";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import PaginationControl from "../../Components/UI/PaginationControl";
import RouterConstants from "../../Constants/RouterConstants";
import { GET_IMAGES } from "../../GraphQL/queries";
import { repositoryTags_repositoryTags as Tag } from "../../GraphQL/types/repositoryTags";
import { repositoryProjects_repositoryProjects as Project } from "../../GraphQL/types/repositoryProjects";
import Chip from "@material-ui/core/Chip";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {
	images_with_tags,
	images_with_tags_images_images,
} from "../../GraphQL/types/images_with_tags";
import empty from "../../assets/empty.svg";
import errorImg from "../../assets/error.svg";
import { useTranslation } from "react-i18next";
import { DBName } from "../../types/graphql-global-types";
import Search from "../../assets/icons/Search";
import GalleryPopup from "../../Components/Tools/GalleryPopup";
import Switch from "@material-ui/core/Switch";
import AppContext from "../../Context/Context";
import clsx from "clsx";
import "react-lazy-load-image-component/src/effects/blur.css";
import UploadCard from "../../Components/UI/UploadCard";

const imageHeight = 222;
const imageWidth = 296;
const numberOfImagesInPage = 50;

function ToolsPage() {
	let numberOfColumns = 0;
	const classes = useStyles();
	const params = useLocation();
	const history = useHistory();
	const { t } = useTranslation();
	const abortController = useRef(new AbortController());
	const imageContainerRef = useRef<HTMLDivElement>(null);
	const context = useContext(AppContext);
	const [showAllImages, setShowAllImages] = useState<boolean>(
		JSON.parse(localStorage.getItem("showAllImages") || "false")
	);
	const [selectedTags, setSelectedTags] = useState<string[]>([]);
	const [selectedProjects, setSelectedProjects] = useState<string[]>(() => {
		let projects = localStorage.getItem("selectedProjects");
		return projects !== null ? JSON.parse(projects) : [];
	});
	const [searchText, setSearchText] = useState<string>("");
	const pageNumber = Number(queryString.parse(params.search).page) || 1;
	const [recent] = useState<images_with_tags_images_images[]>(() => {
		try {
			const items = localStorage.getItem("Recents");
			if (items === null) {
				return [];
			}
			const recent = JSON.parse(items);
			return recent?.reverse()?.slice(0, 5);
		} catch (e) {
			return [];
		}
	});
	const [openGalleryPopup, setOpenGalleryPopup] = useState(false);
	const [selectedImage, setSelectedImage] =
		useState<images_with_tags_images_images>();

	let customQueryJson: any =
		showAllImages || localStorage.getItem("selectedProjects")
			? {
					$and: [
						{
							$or: [
								{ score: { $gt: 0 } },
								{ score: { $exists: false } },
								{ score: null },
							],
						},
					],
			  }
			: null;

	if (!context.user?.isAdmin()) {
		customQueryJson = {
			$and: [{ "source.tags": { $in: ["viewer"] } }],
		};
	}
	const customQueryStr = JSON.stringify(customQueryJson);

	const [refetch, { loading, error, data }] = useLazyQuery<images_with_tags>(
		GET_IMAGES,
		{
			variables: {
				dbName: DBName.VDR,
				tags: selectedTags,
				projects: selectedProjects,
				pageNumber: pageNumber,
				imagesPerPage: numberOfImagesInPage,
				labels: [],
				visualHashToSearch: null,
				pathPrefix: null,
				customQueryStr: customQueryStr,
				searchText,
			},
			context: {
				fetchOptions: {
					signal: abortController.current.signal,
				},
			},
		}
	);

	const addToRecent = (image: images_with_tags_images_images) => {
		const recentItems = recent.filter(
			({ visualHash }) => image.visualHash !== visualHash
		);
		recentItems.unshift(image);
		localStorage.setItem("Recents", JSON.stringify(recentItems));
	};

	useEffect(() => {
		refetch();
	}, [
		selectedTags,
		selectedProjects,
		searchText,
		pageNumber,
		refetch,
		showAllImages,
	]);

	if (imageContainerRef.current) {
		numberOfColumns = Math.max(
			Math.floor(
				imageContainerRef.current!.clientWidth / (imageWidth + 16)
			),
			1
		);
	}

	const imageGallary = new Array(numberOfColumns).fill(0);

	return (
		<Grid container className={classes.root}>
			<Grid item className={classes.container}>
				<Box className={classes.sec} alignItems="center" width="100%">
					<Box
						display="flex"
						mb={1}
						width="100%"
						justifyContent="flex-end"
						alignItems="center"
					>
						{context.user?.isAdmin() && (
							<>
								<Chip
									size="medium"
									label="getty_batch_1"
									clickable
									color={
										selectedProjects.includes(
											"getty_batch_1"
										)
											? "secondary"
											: "primary"
									}
									onClick={() => {
										let newArray: string[] = [
											...selectedProjects,
										];
										if (
											selectedProjects.includes(
												"getty_batch_1"
											)
										) {
											removeItemOnce(
												newArray,
												"getty_batch_1"
											);
										} else {
											newArray.push("getty_batch_1");
										}
										localStorage.setItem(
											"selectedProjects",
											JSON.stringify(newArray)
										);
										setSelectedProjects(newArray);
									}}
									style={{ marginRight: 16 }}
								/>

								<Chip
									size="medium"
									label="getty_batch_2"
									clickable
									color={
										selectedProjects.includes(
											"getty_batch_2"
										)
											? "secondary"
											: "primary"
									}
									onClick={() => {
										let newArray: string[] = [
											...selectedProjects,
										];
										if (
											selectedProjects.includes(
												"getty_batch_2"
											)
										) {
											removeItemOnce(
												newArray,
												"getty_batch_2"
											);
										} else {
											newArray.push("getty_batch_2");
										}
										localStorage.setItem(
											"selectedProjects",
											JSON.stringify(newArray)
										);
										setSelectedProjects(newArray);
									}}
									style={{ marginRight: 16 }}
								/>
								<Chip
									size="medium"
									label="getty_batch_3"
									clickable
									color={
										selectedProjects.includes(
											"getty_batch_3"
										)
											? "secondary"
											: "primary"
									}
									onClick={() => {
										let newArray: string[] = [
											...selectedProjects,
										];
										if (
											selectedProjects.includes(
												"getty_batch_3"
											)
										) {
											removeItemOnce(
												newArray,
												"getty_batch_3"
											);
										} else {
											newArray.push("getty_batch_3");
										}
										localStorage.setItem(
											"selectedProjects",
											JSON.stringify(newArray)
										);
										setSelectedProjects(newArray);
									}}
									style={{ marginRight: 16 }}
								/>
								<Chip
									size="medium"
									label="getty_batch_4"
									clickable
									color={
										selectedProjects.includes(
											"getty_batch_4"
										)
											? "secondary"
											: "primary"
									}
									onClick={() => {
										let newArray: string[] = [
											...selectedProjects,
										];
										if (
											selectedProjects.includes(
												"getty_batch_4"
											)
										) {
											removeItemOnce(
												newArray,
												"getty_batch_4"
											);
										} else {
											newArray.push("getty_batch_4");
										}
										localStorage.setItem(
											"selectedProjects",
											JSON.stringify(newArray)
										);
										setSelectedProjects(newArray);
									}}
									style={{ marginRight: 16 }}
								/>
							</>
						)}
						<OutlinedInput
							placeholder={t("search")}
							margin="dense"
							className={classes.searchInput}
							onChange={(event) => {
								const searchText = event.target.value;
								setSearchText(searchText);
							}}
							startAdornment={
								<InputAdornment position="start">
									<Search className={classes.searchIcon} />
								</InputAdornment>
							}
						/>
						{context.user?.isAdmin() && (
							<Switch
								onChange={(
									e: ChangeEvent<HTMLInputElement>
								) => {
									setShowAllImages(e.target.checked);
									localStorage.setItem(
										"showAllImages",
										JSON.stringify(e.target.checked)
									);
								}}
								checked={showAllImages}
							/>
						)}
					</Box>
				</Box>

				{!loading && data?.images?.images?.length === 0 ? (
					<>
						<img
							src={empty}
							alt="No images found."
							className={classes.emptyState}
							style={{
								filter: "drop-shadow(0 0 2px white) contrast(1.25)",
							}}
						/>
						<Box className={classes.noImagesText}>
							{t("noImagesFound")}
						</Box>
					</>
				) : (
					<>
						<Box p={1}>
							<Typography className={classes.text}>
								{t("recent")}
							</Typography>
							<Box
								display="flex"
								flexWrap="wrap"
								height={240}
								overflow="hidden"
							>
								<UploadCard
									onClick={() => {
										context.setUploadState(true);
									}}
								/>
								{recent.map((image) => {
									return (
										<Box
											key={image.visualHash}
											className={classes.imageContainer}
											height={imageHeight}
											marginRight={3}
										>
											<LazyLoadImage
												alt={"format_url"}
												placeholderSrc={
													"https://img.freepik.com/free-photo/abstract-surface-textures-white-concrete-stone-wall_74190-8184.jpg?size=626&ext=jpg"
												}
												onClick={() => {
													addToRecent(image);
													history.push(
														`${RouterConstants.TOOLS}${image.visualHash}`
													);
												}}
												effect="blur"
												threshold={150}
												useIntersectionObserver={false}
												height={`${imageHeight}px`}
												src={image.mediumUrl}
												className={classes.imgSize}
											/>
										</Box>
									);
								})}
							</Box>

							<Typography
								className={classes.text}
								style={{ marginTop: 30 }}
							>
								{t("images")}
							</Typography>

							{loading && (
								<Box
									className={
										classes.circularProgressContainer
									}
								>
									<CircularProgress size={80} />
								</Box>
							)}
							{error && (
								<>
									<img
										src={errorImg}
										alt="Something went wrong"
										className={classes.errorState}
									/>
									<Box className={classes.errorText}>
										{error.message}
									</Box>
								</>
							)}

							<Container
								ref={imageContainerRef}
								disableGutters
								maxWidth={false}
								className={classes.gallery}
							>
								{imageGallary.map((el, index) => {
									return (
										<Box key={index} marginRight={3}>
											{data?.images?.images
												?.filter(
													(_, i) =>
														i % numberOfColumns ===
														index
												)
												.map((image) => {
													return (
														<Box
															id={image.mongoId}
															key={image.mongoId}
															className={clsx(
																classes.imageContainer
															)}
															width={imageWidth}
															height={
																(image.height *
																	imageWidth) /
																image.width
															}
														>
															<LazyLoadImage
																alt={
																	image.displayName ||
																	image.mongoId
																}
																placeholderSrc="https://img.freepik.com/free-photo/abstract-surface-textures-white-concrete-stone-wall_74190-8184.jpg?size=626&ext=jpg"
																onClick={() => {
																	history.push(
																		`${RouterConstants.TOOLS}${image.visualHash}`
																	);
																	addToRecent(
																		image
																	);
																}}
																effect="blur"
																threshold={100}
																useIntersectionObserver={
																	false
																}
																width={
																	imageWidth
																}
																height={
																	(image.height *
																		imageWidth) /
																	image.width
																}
																src={
																	image.mediumUrl
																} // use normal <img> attributes as props
															/>
															<Box
																className={
																	classes.imageNameContainer
																}
															>
																<Typography
																	className={
																		classes.imageName
																	}
																>
																	{image.displayName ||
																		image.visualHash}
																</Typography>
															</Box>
															{/* <Box
																className={
																	classes.biggerNumber
																}
															>
																<Box
																	className={clsx(
																		classes.imageTextContainer,
																		classes.shadowContainer
																	)}
																></Box>
																<Box
																	onClick={(
																		event
																	) => {
																		event.stopPropagation();
																		setOpenGalleryPopup(
																			true
																		);
																		setSelectedImage(
																			image
																		);
																	}}
																	className={
																		classes.imageTextContainer
																	}
																>
																	<Typography
																		className={
																			classes.imageText
																		}
																	>
																		+1
																	</Typography>
																</Box>
															</Box> */}
														</Box>
													);
												})}
										</Box>
									);
								})}
							</Container>
							<PaginationControl
								count={data?.images?.totalPages}
								page={pageNumber}
								onChange={(_, page) => {
									window.scrollTo({
										top: 0,
										left: 0,
										behavior: "smooth",
									});
									history.push(
										`${RouterConstants.TOOLS}?page=${page}`
									);
								}}
							/>

							<GalleryPopup
								openGalleryPopup={openGalleryPopup}
								setOpenGalleryPopup={setOpenGalleryPopup}
								selectedImage={selectedImage}
							/>
						</Box>
					</>
				)}
			</Grid>

			{showAllImages && (
				<Container className={classes.projectsTagsContainer}>
					<Typography
						variant="h4"
						color="textSecondary"
						align="center"
					>
						{t("projects")}
					</Typography>
					<Grid className={classes.filteringLabels} item xs={12}>
						<Grid container justify="center" spacing={2}>
							{data?.repositoryProjects?.map(
								(project: Project, index) =>
									project.name !== "" && (
										<Grid key={index} item>
											<Chip
												key={`${index}${project.name}`}
												size="medium"
												label={project.name}
												clickable
												color={
													selectedProjects.includes(
														project.name
													)
														? "secondary"
														: "primary"
												}
												onClick={() => {
													let newArray: string[] = [
														...selectedProjects,
													];
													if (
														selectedProjects.includes(
															project.name
														)
													) {
														removeItemOnce(
															newArray,
															project.name
														);
													} else {
														newArray.push(
															project.name
														);
													}
													localStorage.setItem(
														"selectedProjects",
														JSON.stringify(newArray)
													);
													setSelectedProjects(
														newArray
													);
												}}
											/>
										</Grid>
									)
							)}
						</Grid>
					</Grid>
					<Typography
						variant="h4"
						color="textSecondary"
						align="center"
					>
						{t("tags")}
					</Typography>
					<Grid className={classes.filteringLabels} item xs={12}>
						<Grid container justify="center" spacing={2}>
							{data?.repositoryTags?.map(
								(tag: Tag, index) =>
									tag.name !== "" && (
										<Grid key={index} item>
											<Chip
												key={`${index}${tag.name}`}
												size="medium"
												label={tag.name}
												clickable
												color={
													selectedTags.includes(
														tag.name
													)
														? "secondary"
														: "primary"
												}
												onClick={() => {
													var newArray: string[] = [
														...selectedTags,
													];
													if (
														selectedTags.includes(
															tag.name
														)
													) {
														removeItemOnce(
															newArray,
															tag.name
														);
													} else {
														newArray.push(tag.name);
													}
													setSelectedTags(newArray);
													history.push(
														`${
															RouterConstants.TOOLS
														}?page=${1}`
													);
												}}
											/>
										</Grid>
									)
							)}
						</Grid>
					</Grid>
				</Container>
			)}
		</Grid>
	);
}

function removeItemOnce(arr: any[], value: any) {
	var index = arr.indexOf(value);
	if (index > -1) {
		arr.splice(index, 1);
	}
	return arr;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			padding: theme.spacing(3, 8, 8, 8),
			flexWrap: "nowrap",
		},
		container: {
			flexGrow: 1,
		},
		projectsTagsContainer: {
			width: "400px",
			padding: theme.spacing(2),
		},
		paper: {
			padding: theme.spacing(3),
			textAlign: "center",
			width: "100%",
			height: "100%",
			marginBottom: theme.spacing(1),
		},
		imageContainer: {
			overflow: "hidden",
			position: "relative",
			cursor: "pointer",
			border: "3px solid transparent",
			borderRadius: theme.shape.borderRadius,
			marginBottom: theme.spacing(3),
			"&:hover": {
				border: `3px solid ${theme.palette.common.white}`,
				boxShadow: theme.shadows[8],
			},
		},
		biggerNumber: {
			position: "absolute",
			bottom: 0,
			right: 0,
			width: 74,
			height: 74,

			"&:hover $imageText": {
				fontSize: 75,
			},

			"&:hover $imageTextContainer": {
				width: 101,
				height: 101,
			},
		},
		imageTextContainer: {
			position: "absolute",
			bottom: 0,
			right: 0,
			width: 74,
			height: 74,
			zIndex: 100,
		},
		shadowContainer: {
			background: `linear-gradient(180deg, rgba(73, 80, 87, 0.75) 50%, rgba(255, 255, 255, 0) 100%)`,
			filter: "blur(16px)",
			borderRadius: 70,
		},
		imageText: {
			fontWeight: theme.typography.fontWeightRegular,
			color: theme.palette.common.white,
			fontSize: 48,
		},
		imageNameContainer: {
			position: "absolute",
			top: 0,
			width: "100%",
			background:
				"linear-gradient(180deg, rgba(95, 95, 95, 0.75) 0%, rgba(196, 196, 196, 0) 100%)",
		},
		imageName: {
			color: theme.palette.common.white,
			fontSize: 12,
			fontWeight: 600,
			width: "fit-content",
			margin: "auto",
			marginTop: 11,
		},
		filteringLabels: {
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
			width: 400,
		},
		circularProgressContainer: {
			width: "100%",
			display: "flex",
			justifyContent: "center",
		},
		emptyState: {
			width: 300,
			height: 300,
			marginTop: "10%",
		},
		noImagesText: {
			fontSize: 25,
			paddingTop: 10,
			color: theme.palette.text.primary,
		},
		errorState: {
			width: 300,
			height: 300,
			marginTop: "10%",
		},
		errorText: {
			fontSize: 25,
			paddingTop: 10,
			color: theme.palette.text.primary,
		},
		button: {
			textTransform: "none",
			fontSize: 17,
			whiteSpace: "nowrap",
			width: 176,
			height: 44,
			borderRadius: 30,
			color: theme.palette.primary.light,
		},
		sec: {
			display: "flex",
			alignItems: "center",
			marginBottom: 30,
		},
		imgSize: {
			objectFit: "contain",
		},
		text: {
			color: theme.palette.text.secondary,
			marginBottom: 30,
			textAlign: "left",
			fontSize: 24,
			fontWeight: theme.typography.fontWeightMedium,
		},
		root2: {
			height: "100%",
			boxSizing: "border-box",
			flexShrink: 0,
			listStyle: "none",
		},
		/* Styles applied to the `div` element that wraps the children. */
		tile: {
			position: "relative",
			display: "block", // In case it's not rendered with a div.
		},
		searchInput: {
			width: 296,
			height: 43,
			borderRadius: 20,
			background: theme.palette.common.white,
			fontSize: 17,
			color: theme.palette.common.black,
		},
		searchIcon: {
			stroke: theme.palette.common.black,
			fontSize: 16,
		},
		addIcon: {
			fontSize: 44,
		},
		gallery: {
			display: "flex",
			justifyContent: "center",
		},
	})
);

export default ToolsPage;
