import { DrawerProps, IconButton, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { RefObject, useEffect, useState, useContext } from "react";
import FullscreenIcon from "../../assets/icons/FullscreenIcon";
// import LayersIcon from "../../assets/icons/LayersIcon";
import MinimizeIcon from "../../assets/icons/MinimizeIcon";
import DropShadow from "../UI/DropShadow";
import FaceRect from "../UI/FaceRect";
import ObjectPoint from "../UI/ObjectPoint";
import FeedbackForm from "./FeedbackForm2";
import { AnalysisStage, BriaObjectType, ToolsBackGroundMode } from "./utilities";
import BriaButton from "../UI/BriaButton";
import { useTranslation } from "react-i18next";
import { downloadImage } from "../../Helpers/images";
import { toast } from "react-toastify";
import Context from "../../Context/Context";
import Analytics, { ANALYTICS_EVENTS } from "../../Models/Analytics";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useEventListener } from "../../hooks/useEventListener";
import {
	ReactCompareSlider,
	ReactCompareSliderImage,
	ReactCompareSliderHandle,
	styleFitContainer,
} from "react-compare-slider";
import BriaBadge from "../UI/BriaBadge";
import useResizeObserver from "use-resize-observer";

type ToolsImageProps = {
	imageUrl: string;
	imageUrlNoBg: string;
	imageVH: string;
	loading: boolean;
	proccessing?: boolean;
	videoUrl?: string | null;
	pointOfInterests: any[];
	drawerDirection?: DrawerProps["anchor"];
	imageRef: RefObject<HTMLImageElement>;
	setSelectedPoint: (x: any) => void;
	closeVideo: () => void;
	generatingImageMetric: () => void;
	hasPointOfInterest?: boolean;
	fullscreen?: boolean;
	onFullscreenChange: (value: boolean) => void;
	setImageScore: (value: string) => void;
	layersURL: string | null;
	backgroundString: ToolsBackGroundMode;
	onBackgroundStringChange: (backgroundString: ToolsBackGroundMode) => void;
	originalImage: string;
	isCompareMode: boolean;
};

function ToolsImage({
	backgroundString,
	onBackgroundStringChange,
	imageUrl,
	imageUrlNoBg,
	imageVH,
	videoUrl,
	loading,
	proccessing,
	pointOfInterests,
	imageRef,
	setSelectedPoint,
	generatingImageMetric,
	fullscreen,
	onFullscreenChange,
	hasPointOfInterest,
	drawerDirection,
	setImageScore,
	layersURL,
	originalImage,
	isCompareMode,
}: ToolsImageProps) {
	const classes = useStyles(fullscreen);
	const { t } = useTranslation();
	const { user } = useContext(Context);
	const [points, setPoints] = useState<any[]>([]);
	const [isImageLoading, setIsImageLoading] = useState<boolean>(true);
	const [stage, setCurrentStage] = useState<AnalysisStage>(
		AnalysisStage.NONE
	);

	useEffect(() => {
		setIsImageLoading(true);
	}, [imageUrl, backgroundString]);

	useEffect(() => {
		let stage1: NodeJS.Timeout, stage2: NodeJS.Timeout;
		if (proccessing) {
			setCurrentStage(AnalysisStage.A);
			stage1 = setTimeout(() => {
				setCurrentStage(AnalysisStage.B);
				stage2 = setTimeout(() => {
					setCurrentStage(AnalysisStage.C);
				}, 2500);
			}, 2500);
		}
		return () => {
			clearTimeout(stage1);
			clearTimeout(stage2);
		};
	}, [proccessing]);

	useOutsideClick(imageRef, () => {
		fullscreen && onFullscreenChange(false);
	});

	useEventListener({
		type: "keydown",
		listener: (event: any) => {
			if (event.key === "Escape" && fullscreen) {
				onFullscreenChange(false);
			}
		},
	});

	const OnImageIcons = () => {
		return (
			<Box
				className={classes.onImageIconsContainer}
				display="flex"
				flexDirection="column"
			>
				{fullscreen ? (
					<IconButton
						onClick={() => {
							onFullscreenChange(false);
						}}
						className={classes.iconButton}
					>
						<MinimizeIcon className={classes.icon} />
					</IconButton>
				) : (
					<>
						<IconButton
							onClick={() => {
								onFullscreenChange(true);
							}}
							className={classes.iconButton}
						>
							<FullscreenIcon className={classes.icon} />
						</IconButton>
						{/* <IconButton
							onClick={() => {
								if (!hasPointOfInterest) {
									alert(
										"No configuration available for current selection"
									);
								}
							}}
						>
							<LayersIcon />
						</IconButton> */}

						{/* <Box mt="auto">
							<IconButton>
								<HappyIcon />
							</IconButton>
							<IconButton>
								<SadIcon />
							</IconButton>
						</Box> */}
					</>
				)}
			</Box>
		);
	};
	const { width: imageWidth, height: imageHeight } =
		useResizeObserver<HTMLImageElement>({ ref: imageRef });

	useEffect(() => {
		if (imageRef.current !== null) {
			setPoints(
				pointOfInterests.map((el) => {
					const heightRatio =
						imageRef.current!.clientHeight / el.originalImageHieght;
					const widthRatio =
						imageRef.current!.clientWidth / el.originalImageWidth;
					return {
						id: el.id,
						objectType: el.objectType,
						active: el.active,
						heightRatio,
						widthRatio,
						position: {
							height: heightRatio * el.rect.height,
							width: widthRatio * el.rect.width,
							top: heightRatio * el.rect.top,
							left: widthRatio * el.rect.left,
						},
						rect: el.rect,
					};
				})
			);
		}
	}, [imageRef, loading, pointOfInterests, imageWidth, imageHeight]);

	if (videoUrl) {
		return (
			<Box className={clsx(classes.imgContainer)}>
				<Box
					position="relative"
					className={clsx(
						classes.imageWrappper,
						{
							[classes.maxDimentionsBottom]:
								drawerDirection === "bottom",
						},
						{
							[classes.maxDimentionsSide]:
								drawerDirection !== "bottom",
						}
					)}
				>
					<video
						key={videoUrl}
						muted
						loop
						autoPlay
						controls
						plays-inline
						disablePictureInPicture
						controlsList="nodownload"
						className={clsx(classes.videoContainer)}
					>
						<source src={videoUrl} type="video/mp4" />
						<p>
							Your browser doesn't support HTML5 video. Here is a
							<a href={videoUrl}>link to the video</a> instead.
						</p>
					</video>
				</Box>
			</Box>
		);
	}

	return (
		<>
			<Box className={clsx(classes.imgContainer)}>
				<Box
					position="relative"
					className={clsx(
						classes.imageWrappper,
						{
							[classes.maxDimentionsBottom]:
								drawerDirection === "bottom",
						},
						{
							[classes.maxDimentionsSide]:
								drawerDirection !== "bottom",
						},
						// {
						// 	[classes.removeBgImage]:
						// 	backgroundString === ToolsBackGroundMode.noBg,
						// }
					)}
				>
					{proccessing && (
						<Box position="absolute" width="100%" zIndex={1}>
							<LinearProgress />
						</Box>
					)}

					<ReactCompareSlider
						style={{
							overflow: "hidden",
							maxWidth: "100%",
							height: "100%",
							objectFit: "contain",
							borderRadius: 10,
							border: "1px solid #ccc",
						}}
						className={clsx({
							[classes.imageLoadingState]:
								loading || isImageLoading || proccessing,
						})}
						handle={
							<ReactCompareSliderHandle
								buttonStyle={{
									WebkitBackdropFilter: "none",
									backdropFilter: "none",
									border: 0,
									boxShadow: "none",
									display: isCompareMode ? "grid" : "none",
								}}
								linesStyle={{ opacity: 0.5 }}
							/>
						}
						position={isCompareMode ? 50 : 0}
						itemOne={
							<img
								id="root_image"
								ref={imageRef}
								style={{ ...styleFitContainer() }}
								src={originalImage}
								alt="Lets Bria it"
								// onLoad={() => {
								// 	generatingImageMetric();
								// 	setIsImageLoading(false);
								// }}
							/>
						}
						itemTwo={
							<img
								id="root_image"
								ref={imageRef}
								style={{ ...styleFitContainer() }}
								src={backgroundString === ToolsBackGroundMode.withBg ? imageUrl : imageUrlNoBg}
								alt="Lets Bria it"
								onLoad={() => {
									generatingImageMetric();
									setIsImageLoading(false);
								}}
							/>
						}
					/>

					{!isCompareMode && (
						<Box
							className={clsx(classes.hoverContianer, {
								[classes.imageBackground]: !points.some(
									(el) => el.active
								),
							})}
						>
							<FeedbackForm setImageScore={setImageScore} />
							<OnImageIcons />
							{!fullscreen &&
								points.map(
									(
										{
											position,
											rect,
											id,
											active,
											objectType,
										},
										index
									) => {
										if (
											objectType !== BriaObjectType.human
										) {
											return (
												<ObjectPoint
													active={active}
													top={position.top}
													left={position.left}
													onClick={() => {
														setSelectedPoint({
															id: id,
															index,
															objectType:
																objectType,
															rect: {
																w: rect.width,
																h: rect.height,
																x: rect.left,
																y: rect.top,
															},
														});
													}}
												/>
											);
										}
										return (
											<FaceRect
												key={id}
												active={active}
												width={position.width}
												height={position.height}
												top={position.top}
												left={position.left}
												onClick={() => {
													setSelectedPoint({
														id: id,
														objectType: objectType,
														index,
														rect: {
															w: rect.width,
															h: rect.height,
															x: rect.left,
															y: rect.top,
														},
													});
												}}
											/>
										);
									}
								)}
						</Box>
					)}
				</Box>

				{/* {!loading && !fullscreen && backgroundString === ToolsBackGroundMode.withBg && (
					<DropShadow
						mx={4}
						maxWidth={imageRef.current?.clientWidth ?? 0}
						mt="-50px"
					/>
				)} */}
			</Box>
			{!fullscreen && (
				<Box display="flex" justifyContent="center" alignItems="center">
					{user?.isAdmin() ? (
						<BriaButton
							variant="contained"
							color="secondary"
							disabled={!user?.isAdmin()}
							onClick={async () => {
								await downloadImage(imageUrl, `${imageVH}.jpg`);
								await Analytics.logEvent(
									ANALYTICS_EVENTS.DOWNLOAD_IMAGE
								);
								toast.dark(
									<Typography>
										Image was <strong>downloaded</strong>
									</Typography>
								);
							}}
							className={classes.button}
							classes={{
								disabled: classes.disabledButton,
							}}
						>
							{t("download")}
						</BriaButton>
					) : (
						<BriaBadge
							badgeContent="Premium"
							color="secondary"
							className={classes.badge}
						>
							<BriaButton
								variant="contained"
								color="secondary"
								disabled={!user?.isAdmin()}
								onClick={async () => {
									await downloadImage(
										imageUrl,
										`${imageVH}.jpg`
									);
									await Analytics.logEvent(
										ANALYTICS_EVENTS.DOWNLOAD_LAYERS
									);
									toast.dark(
										<Typography>
											Image was{" "}
											<strong>downloaded</strong>
										</Typography>
									);
								}}
								className={classes.button}
								classes={{
									disabled: classes.disabledButton,
								}}
							>
								{t("download")}
							</BriaButton>
						</BriaBadge>
					)}
					{user?.isAdmin() && layersURL && (
						<BriaButton
							variant="contained"
							color="secondary"
							onClick={async () => {
								window.open(layersURL, "_blank");
								toast.dark(
									<Typography>
										Layers was <strong>downloaded</strong>
									</Typography>
								);
							}}
							className={classes.button}
						>
							{t("downloadAsPSD")}
						</BriaButton>
					)}
				</Box>
			)}
		</>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	imgContainer: {
		paddingTop: theme.spacing(3),
		width: "100%",
		// height: "calc(100% - 130px)",
		height: (fullscreen) => (fullscreen ? "100%" : "calc(100% - 130px)"),
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "relative",
		flexDirection: "column",
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	videoContainer: {
		height: "100%",
		objectFit: "contain",
		borderRadius: theme.shape.borderRadius,
		overflow: "hidden",
	},
	maxDimentionsBottom: {
		maxWidth: (fullscreen) => (fullscreen ? "100%" : 1760),
		maxHeight: (fullscreen) =>
			fullscreen ? "calc(100vh - 150px)" : "calc(100vh - 370px)",
	},
	maxDimentionsSide: {
		// maxWidth: 1009,
		// maxHeight: 940,
		maxWidth: (fullscreen) => (fullscreen ? "100%" : 1009),
		maxHeight: (fullscreen) => (fullscreen ? "100%" : 940),
	},
	img: {
		height: "auto",
		objectFit: "contain",
		borderRadius: theme.shape.borderRadius,
		overflow: "hidden",
		maxHeight: "100%",
		maxWidth: "100%",
	},
	imageMaxDimentionsSide: {
		maxHeight: 940,
		maxWidth: 1009,
	},
	imageLoadingState: {
		filter: "blur(4px)",
	},
	invalidImage: {
		display: "none",
	},
	imageWrappper: {
		borderRadius: theme.shape.borderRadius,
		overflow: "hidden",
		position: "relative",
	},
	errorText: {
		fontSize: 25,
		paddingTop: 10,
		color: theme.palette.text.primary,
	},
	hoverContianer: {
		width: "100%",
		height: "100%",
		position: "absolute",
		top: 0,
		left: 0,
		borderRadius: theme.shape.borderRadius,
		opacity: 0,
		"&:hover": {
			opacity: 1,
		},
	},
	imageBackground: {
		background: (fullscreen) =>
			fullscreen ? "transparent" : "rgba(0,0,0,0.5)",
	},
	onImageIconsContainer: {
		height: 40,
		width: 40,
		position: "absolute",
		right: 13,
		top: 15,
		background: "transparent",
		zIndex: 9999,
	},
	button: {
		margin: theme.spacing(1),
		padding: "7px 31px",
	},
	iconButton: {
		width: 40,
		height: 40,
		boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
	},
	icon: {
		fontSize: 40,
	},
	disabledButton: {
		"&.MuiButton-contained.Mui-disabled": {
			backgroundColor: "#979797",
			color: "#FFFFFF",
		},
	},
	badge: {
		"& .MuiBadge-badge": {
			top: 8,
			right: 17,
		},
	},
}));

export default ToolsImage;
