import React, { useContext } from "react";
import AppContext from "../../Context/Context";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import { ROLES } from "../../Routes";
import RouterConstants from "../../Constants/RouterConstants";

interface IProps extends RouteProps {
	roles: ROLES[];
	guest?: boolean;
}

const PrivateRoute: React.FC<IProps> = ({
	guest,
	children,
	roles,
	...rest
}) => {
	const { user } = useContext(AppContext);
	const history = useHistory();
	let path = history.location.pathname;
	if (path === "/") {
		path =
			new URLSearchParams(window.location.search).get("redirect") ?? "/";
	}
	const redirect = path === "/" ? null : `?redirect=${path}`;

	const isEmailVerified = user?.isEmailVerified();

	return (
		<Route
			{...rest}
			render={() => {
				if (user === null) {
					return (
						<Redirect
							to={`/${RouterConstants.LOGIN.slice(1)}${
								redirect ? redirect : ""
							}`}
						/>
					);
				} else {
					if (user && !isEmailVerified) {
						return (
							<Redirect
								to={`${RouterConstants.VERIFY}${
									redirect ?? ""
								}`}
							/>
						);
					} else if (user && roles.includes(user.role as ROLES)) {
						return children;
					} else if (
						user?.role !== ROLES.ADMIN &&
						user?.role === ROLES.USER
					) {
						return <Redirect to={RouterConstants.GALLERY} />;
					} else if (user?.role !== ROLES.ADMIN) {
						return <Redirect to={RouterConstants.TOOLS} />;
					} else {
						return <Redirect to={RouterConstants.BASE} />;
					}
				}
			}}
		></Route>
	);
};

export default PrivateRoute;
