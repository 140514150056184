import { ThemeOptions } from "@material-ui/core/styles";

export const lightTheme: ThemeOptions = {
	palette: {
		type: "light",
		common: {
			white: "#FFFFFF",
			black: "#495057",
		},
		primary: {
			light: "#D80067",
			main: "#8800FF",
			dark: "#5300C9",
		},
		secondary: {
			main: "#D80067",
		},
		text: {
			primary: "#495057",
			secondary: "#ABB5BE",
		},
		error: {
			main: "#DC3545",
		},
		warning: {
			main: "#FFC107",
		},
		success: {
			main: "#28A745",
		},
		background: {
			default:
				"radial-gradient(60.83% 60.83% at 50% 39.17%, #FFFFFF 21.87%, #EDEDED 100%)",
			paper: "#FFFFFF",
		},
		buttonTextColor: {
			main: "white",
		},
		startButtonBackground: {
			main: "green",
		},
		stopButtonBackground: {
			main: "orange",
		},
		menuItemBackground: {
			main: "#F1E3FD",
		},
		tools: {
			main: "#5300C9",
		},
		cards: {
			main: "#8800FF",
		},
		cardText: {
			main: "#2C2738",
		},
		cardTextSecondary: {
			main: "#A4A4A4",
		},
		uploadCardBackground: {
			main: "#C4C4C4",
		},
		textColor: {
			main: "#FFFFFF",
		},
		shadow: {
			main: "rgba(73, 80, 87, 0.75)",
		},
	},
	typography: {
		fontFamily: "Montserrat",
	},
	shape: {
		borderRadius: 10,
	},
};
