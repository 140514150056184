import { useEffect, useRef } from "react";
import { OperationVariables, useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { useState } from "react";
import {
	ADD_OBJECT_TYPE_SEMANTICS,
	ADD_SEMANTICS,
	ADD_SEMANTIC_COMBINATION,
} from "../../GraphQL/mutations";
import {
	AddSemantics,
	AddSemanticsVariables,
} from "../../GraphQL/types/addSemantics";
import {
	ChangeObjectTypeSemantics,
	ChangeObjectTypeSemanticsVariables,
} from "../../GraphQL/types/ChangeObjectTypeSemantics";
import {
	AddSemanticCombination,
	AddSemanticCombinationVariables,
} from "../../GraphQL/types/addSemanticCombination";
import { ToolsSlider } from "../../Models/ToolsConfig";

const usePersonalizer = (personalizerSliders: ToolsSlider[]) => {
	const [initialValues, setInitialValues] = useState<{
		[key: string]: number;
	}>({});

	const [semanticsList, setSemanticsList] =
		useState<ToolsSlider[]>(personalizerSliders);

	useEffect(() => {
		if (Object.keys(initialValues).length === 0) {
			setPersonalizerInitialValues(personalizerSliders);
		}
		setSemanticsList(personalizerSliders);
	}, [personalizerSliders]);

	const formik = useFormik({
		enableReinitialize: Object.keys(initialValues).length !== 0,
		initialValues: initialValues,
		onSubmit: () => {},
	});

	const setPersonalizerInitialValues = (
		personalizerSliders: ToolsSlider[]
	) => {
		const initialValues: { [key: string]: number } = {};

		personalizerSliders.forEach(({ id, defaultInitialValue }) => {
			initialValues[id] = defaultInitialValue;
		});
		setInitialValues(initialValues);
	};

	const [toDisplayThumbnails, setToDisplayThumbnails] =
		useState<{ url: string }[] | null>(null);

	const [addSemanticsMutation, { loading: addSemanticsMutationLoading }] =
		useMutation<AddSemantics, AddSemanticsVariables>(ADD_SEMANTICS);

	const [
		addObjectTypeSemanticsMutation,
		{ loading: addObjectTypeSemanticsMutationLoading },
	] = useMutation<
		ChangeObjectTypeSemantics,
		ChangeObjectTypeSemanticsVariables
	>(ADD_OBJECT_TYPE_SEMANTICS);

	const [
		addSemanticCombinationMutation,
		{ error: addSemanticCombinationError },
	] = useMutation<AddSemanticCombination, AddSemanticCombinationVariables>(
		ADD_SEMANTIC_COMBINATION
	);

	const addSemanticCombinationMutationHandler = async (
		params: OperationVariables
	) => {
		// controllerRef.current && controllerRef.current.abort();
		// controllerRef.current = new window.AbortController();
		const operationVariables = {
			// context: {
			// 	fetchOptions: {
			// 		signal: controllerRef.current.signal,
			// 	},
			// },
			...params,
		};
		return addSemanticCombinationMutation(operationVariables);
	};

	const handleToDisplayThumbnailsChange = (
		thumbnails: { url: string }[] | null
	) => {
		setToDisplayThumbnails([...(thumbnails ?? [])]);
	};

	const semantics: ToolsSlider[] = semanticsList.map((el) => ({
		...el,
		value: formik.values[el.id],
	}));

	return {
		semantics,
		addSemanticsMutationLoading,
		addObjectTypeSemanticsMutationLoading,
		personalizerValues: formik.values,
		toDisplayThumbnails,
		setPersonalizerInitialValues,
		selectedSemantics: semantics.filter(
			(el: ToolsSlider) => el.value !== 0
		),
		setSemanticsValues: formik.setValues,
		changePersonalizerSlider: formik.setFieldValue,
		addSemanticsMutation,
		addSemanticCombinationMutation: addSemanticCombinationMutationHandler,
		addObjectTypeSemanticsMutation,
		handleToDisplayThumbnailsChange,
		resetSemantics: formik.resetForm,
		addSemanticCombinationError,
	};
};

export default usePersonalizer;
