import Typography from "@material-ui/core/Typography";

export default function Copyright() {
	return (
		<footer>
			<Typography variant="body2" align="center">
				{`© ${new Date().getFullYear()} Bria Artificial Intelligence. All Rights Reserved.`}
			</Typography>
		</footer>
	);
}
