import Button from "@material-ui/core/Button";
import { useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

interface StyleProps {
	color: string;
	fontWeight: number;
}

const DropDownMenu = (props: {
	listItems: string[];
	handleChoice(value: string | null): void;
}) => {
	const [color, setColor] = useState<string>("#afafaf");
	const [fontWeight, setFontWeight] = useState<number>(100);
	const styleProps = {
		color: color,
		fontWeight: fontWeight,
	};
	const classes = useStyles(styleProps);
	const selectedChoice: string | null = "choose main thing";
	const { listItems, handleChoice } = props;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [choice, setChoice] = useState<string | null>(selectedChoice);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(null);
		const selectedChoice = event.currentTarget.getAttribute("value");
		setColor("#495057");
		setFontWeight(600);
		handleChoice(selectedChoice);
		setChoice(selectedChoice);
	};

	return (
		<div className={classes.list}>
			<Button
				id="basic-button"
				aria-controls="basic-menu"
				aria-haspopup="true"
				aria-expanded={open ? "true" : undefined}
				className={classes.choices}
				onClick={handleClick}
			>
				{choice}
			</Button>
			<Menu
				id="basic-menu"
				key="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				PaperProps={{
					style: {
						width: 220,
					},
				}}
			>
				{listItems.map((item) => {
					return (
						<MenuItem value={item} onClick={handleClose}>
							{item}
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
	createStyles({
		list: {
			marginRight: 10,
		},
		choices: {
			borderBottom: "2px solid #495057",
			borderRadius: 0,
			fontSize: 25,
			fontWeight: ({ fontWeight }) => fontWeight,
			color: ({ color }) => color,
			font: "small-caption",
			textTransform: "none",
		},
	})
);
export default DropDownMenu;
