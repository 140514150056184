export interface ToolsStructure {
	input1: string;
	level1DisplayName?: string;
	level1Id: string;
	level1Location: number;
	level2DisplayName: string;
	level2Id: string;
	level2Location: number;
	soon: boolean;
}

export interface ToolsSlider {
	value: number;
	defaultInitialValue: number;
	displayName: string;
	id: string;
	name: string;
	level1Id: string;
	level2Id: string;
	maxLayer: number;
	maxName: string;
	maxValue: number;
	minLayer: number;
	minName: string;
	minValue: number;
	normallizedValue: number;
	order: number;
	pca: number;
	reversed: number;
	hideSlider: boolean;
	validation: ToolsValidatoin;
	minNameRiskArea: boolean;
	maxNameRiskArea: boolean;
	askAlan?: {
		score?: number;
		disabled?: boolean;
		warning?: boolean;
	};
}

export interface ToolsValidatoin {
	gender?: string;
}

export interface ToolsThumbnails {
	key: string;
	title: string;
	level1_id: string;
	level2_id: string;
	thumbnail_index?: number;
	thumbnails_number?: number;
	sliders: ToolsSlider[];
	validation: ToolsValidatoin;
}

export interface ToolsSuggestions {
	id: number;
	thumbnails: ToolsSlider[];
}

export interface ThumbnailSlider {
	key: string;
	value: number;
}

export enum ToolConfigTabsTypes {
	Personalizer = "personalizer",
	Suggestions = "suggestion",
	Diversity = "diversity",
	Localizer = "localizer",
	Emotions = "emotions",
	Expression = "expression",
}
