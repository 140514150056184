import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const CloseIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="22"
			height="21"
			viewBox="0 0 22 21"
			fill="none"
			{...props}
		>
			<circle cx="12" cy="12" r="12" fill="white" />
			<path
				d="M0.75 0.982422L20.8643 20.1824M0.75 20.1824L20.8643 0.982422"
				stroke="#495057"
				strokeLinecap="round"
				strokeLinejoin="bevel"
			/>
		</SvgIcon>
	);
};

export default CloseIcon;
