import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function RedoIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 14 16" {...props}>
			<path
				d="M13.9001 9.06094V8.56094H12.9001V9.06094H13.9001ZM7.0001 3.1651H7.5001V2.1651H7.0001V3.1651ZM8.06676 2.6651L8.42021 3.01877L8.7741 2.66509L8.4202 2.31143L8.06676 2.6651ZM12.9001 9.06094C12.9001 12.3161 10.2581 14.9568 7.0001 14.9568V15.9568C10.8098 15.9568 13.9001 12.8689 13.9001 9.06094H12.9001ZM7.0001 14.9568C3.74207 14.9568 1.1001 12.3161 1.1001 9.06094H0.100098C0.100098 12.8689 3.1904 15.9568 7.0001 15.9568V14.9568ZM1.1001 9.06094C1.1001 5.80582 3.74207 3.1651 7.0001 3.1651V2.1651C3.1904 2.1651 0.100098 5.25293 0.100098 9.06094H1.1001ZM5.58 0.886875L7.71333 3.01878L8.4202 2.31143L6.28687 0.179531L5.58 0.886875ZM7.71332 2.31144L5.57999 4.44347L6.28688 5.1508L8.42021 3.01877L7.71332 2.31144Z"
				// fill="#5F5F5F"
			/>
		</SvgIcon>
	);
}

export default RedoIcon;
