import React from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Avatar, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import client from '../GraphQL/client';
import gql from 'graphql-tag';
import AppContext from "../Context/Context";
import { withRouter } from "react-router-dom";


interface Organization {
    apiToken: string
    uid: string
    plan: string
    name: string
    logoUrl: string
}

interface IState {
    organizations: Organization[] | [Organization]
}


class Organization extends React.Component<any, any> {

    state: IState = {
        organizations: []
    }

    static contextType = AppContext

    componentDidMount() {
        const userUid: string | undefined | null = this.context.user?.uid
        if (userUid) {
            this.getOrganizations(userUid)
        }
    }

    async getOrganizations(userUid: string) {
        try {
            const res = await client.query<any, any>({
                query: gql`
                  query organizations($userUid: String) {
                    organizations(userUid: $userUid) {
                        apiToken
                        uid
                        plan
                        name
                    } 
                  }
                `,
                variables: {
                    userUid: userUid,
                },
            })
            this.setState({ organizations: res.data.organizations ?? [] })
        } catch (error) {
            // alert(error.message)
        }
    }

    public render() {
        const { classes, history, location }: any = this.props;
        const path =
            location.pathname.slice(-1) === "/"
                ? location.pathname.slice(0, -1)
                : location.pathname;
        return (
            <div className={classes.root} >
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Organization</TableCell>
                                <TableCell>Plan</TableCell>
                                <TableCell>Api Token</TableCell>
                                <TableCell>API Secret</TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.organizations.map((organization: Organization, index: number) => {
                                return (
                                    <TableRow key={index} >
                                        <TableCell>
                                            <Avatar alt={organization.name} src={organization.logoUrl} />
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {organization.name}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {organization.plan}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {organization.apiToken}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {organization.uid}
                                        </TableCell>
                                        <TableCell align="right">
                                            <Button variant="contained" onClick={() => {
                                                history.push(`${path}/${organization.uid}`)
                                            }}>See users</Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        );
    }
}

const styles = () => createStyles({

    root: {
        display: 'flex',
        margin: 40
    },
    table: {
        minWidth: 650,
    },
})

export default withStyles(styles)(withRouter(Organization))

