import React from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

function ToolsLoader(props: BoxProps) {
	return (
		<Box
			width="100%"
			height="100%"
			justifyContent="center"
			alignItems="center"
			display="flex"
			{...props}
		>
			<CircularProgress />
		</Box>
	);
}

export default ToolsLoader;
