import { withStyles } from "@material-ui/core";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";

const CardsCheckbox = withStyles((theme) => ({
	root: {
		color: "rgba(233, 236, 239, 0.5)",
		"&$checked": {
			color: theme.palette.cards.main,
		},
	},
	checked: {},
}))((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export default CardsCheckbox;
