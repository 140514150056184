import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { isDevelopment } from "./Config/Env";

if (!isDevelopment) {
	Sentry.init({
		dsn: "https://84b28882ee0d47dea87581329c5c0bc2@o417868.ingest.sentry.io/5884174",
		integrations: [new Integrations.BrowserTracing()],
		tracesSampleRate: 1.0,
	});
}

ReactDOM.render(
	<React.StrictMode>
		<Suspense
			fallback={
				<Box
					width="100vw"
					height="100vh"
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<CircularProgress />
				</Box>
			}
		>
			<App />
		</Suspense>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
