import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function OneImage(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props} >
            <path d="M17.7188 3.9375H3.28125C2.91881 3.9375 2.625 4.23131 2.625 4.59375V16.4062C2.625 16.7687 2.91881 17.0625 3.28125 17.0625H17.7188C18.0812 17.0625 18.375 16.7687 18.375 16.4062V4.59375C18.375 4.23131 18.0812 3.9375 17.7188 3.9375Z" fill="none" stroke="inherit" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.625 13.7809L6.7547 9.6512C6.81564 9.59026 6.88798 9.54192 6.9676 9.50894C7.04722 9.47596 7.13256 9.45898 7.21874 9.45898C7.30492 9.45898 7.39025 9.47596 7.46987 9.50894C7.54949 9.54192 7.62184 9.59026 7.68278 9.6512L11.3484 13.3169C11.4094 13.3778 11.4817 13.4261 11.5614 13.4591C11.641 13.4921 11.7263 13.5091 11.8125 13.5091C11.8987 13.5091 11.984 13.4921 12.0636 13.4591C12.1432 13.4261 12.2156 13.3778 12.2765 13.3169L13.9734 11.6199C14.0344 11.559 14.1067 11.5107 14.1864 11.4777C14.266 11.4447 14.3513 11.4277 14.4375 11.4277C14.5237 11.4277 14.609 11.4447 14.6886 11.4777C14.7682 11.5107 14.8406 11.559 14.9015 11.6199L18.375 15.0934" fill="none" stroke="inherit" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.7969 9.1875C13.3405 9.1875 13.7812 8.74678 13.7812 8.20312C13.7812 7.65947 13.3405 7.21875 12.7969 7.21875C12.2532 7.21875 11.8125 7.65947 11.8125 8.20312C11.8125 8.74678 12.2532 9.1875 12.7969 9.1875Z" fill="none" stroke="inherit" />
        </SvgIcon>
    );
}

export default OneImage;
