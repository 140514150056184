export const convertSnakeCaseToText = (text: string): string =>{
    return text.replaceAll('_', ' ').trim();
}


export const camelToSnakeCase = (str: string) =>
	str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const camelCaseToText = (str: string) =>
	str.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`);


export const  capitalizeFirstLetter = (string: string)  => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

export const  renameDuplicateItems = (arr: string[]) => {
    const count: {[key: string ]: number} = {};
    arr.forEach(function(x,i) {
      if ( arr.indexOf(x) !== i ) {
        const c = x in count ? count[x] = count[x] + 1 : count[x] = 1;
        let j = c + 1;
        let k = `${x} ${j}`
  
        while( arr.indexOf(k) !== -1 ) k = `${x} ${++j}` 
        arr[i] = k;
      }
    });
    return arr;
  }