import React from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		overflow: "hidden",
	},
	container: {
		borderRadius: theme.shape.borderRadius,
		mixBlendMode: "normal",
		cursor: "pointer",
		border: "1px dashed #FFFFFF",
		zIndex: (active) => (active ? 2 : 3),
	},
	active: {
		borderRadius: theme.shape.borderRadius,
		position: "absolute",
		width: "calc(100% + 2px)",
		height: "calc(100% + 2px)",
		left: -1,
		top: -1,
		boxShadow: "0 0 0 9999em rgb(0 0 0 / 50%)",
	},
}));
interface IProps extends BoxProps {
	active: boolean;
}
function FaceRect({ active, ...props }: IProps) {
	const classes = useStyles(active);
	return (
		<Box className={classes.root}>
			<Box
				className={clsx(classes.container, "hover-container")}
				position="absolute"
				zIndex={2}
				{...props}
			>
				<Box className={clsx({ [classes.active]: active })}></Box>
			</Box>
		</Box>
	);
}

export default FaceRect;
