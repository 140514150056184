import React, { Component } from "react";
import AppContext from "./Context";
import AppContextInterface from "./AppContextInterface";
import SelectedPage from "../Models/SelectedPage";
import app from "../Config/Firebase";
import {
	getAuth,
	onAuthStateChanged,
	createUserWithEmailAndPassword,
	signInWithPopup,
	setPersistence,
	signOut,
	browserLocalPersistence,
} from "firebase/auth";
import {
	getRemoteConfig,
	getString,
	fetchAndActivate,
} from "firebase/remote-config";
import { getDatabase, ref, onValue, set } from "firebase/database";
import User from "../Models/User";
import Machine from "../Models/Machine";
import { ROLES } from "../Routes";
import { ToolsViewerMode } from "../Components/Tools/utilities";
import Analytics from "../Models/Analytics";
import { isDevelopment } from "../Config/Env";

interface IFolder {
	path: string;
	name: string;
	children: IFolder[];
	isExpanded: boolean;
	parent: IFolder | null;
	isChecked: boolean;
}

export class Folder implements IFolder {
	path: string;
	name: string;
	children: IFolder[];
	isExpanded: boolean;
	parent: IFolder | null;
	isChecked: boolean;

	constructor(
		path: string,
		name: string,
		children: IFolder[],
		parent: IFolder | null
	) {
		this.path = path;
		this.name = name;
		this.children = children;
		this.parent = parent;
		this.isExpanded = false;
		this.isChecked = false;
	}
}

const firebaseHeartbeat: string = "services_hart_bit";
const auth = getAuth(app);

declare global {
	interface Window {
		defaultRemoteConfig: any;
	}
}
class AppContextProvider extends Component {
	state: AppContextInterface = {
		validFacesToggle: false,
		updateValidFacesToggle: (newValidFacesToggle: boolean) => {},
		mslToggle: false,
		updateMslToggle: (newMslToggle: boolean) => {},
		curFolder: new Folder("", "Root", [], null),
		updateCurFolder: (newCurFolder: Folder) => {},
		updateCurrExpandedFolders: (newExpandedFolders: string[] | []) => {},
		currExpandedFolders: [],
		changeallConfigPersonalizerSwitchCheck: (switchState: boolean) => {},
		addToPresentedImagesArray: () => {},
		updateSelectedText: (newText: string) => {},
		selectedText: "",
		allConfigPersonalizerSwitchCheck: false,
		authenticationWasVerified: false,
		user: null,
		isEngineOnline: false,
		engineMachines: [],
		heartbeatRefs: [],
		presentedImagesArray: [],
		imagesPerPage: 50,
		imageHeight: 150,
		previouslySelectedLabels: [],
		previouslySelectedPage: 1,
		updateSelectedPage: () => {},
		updateSelectedLabels: (newSelectedLabels: string[]) => {},
		updateImageHeight: (newImageHeight: number) => {},
		updateImagesPerPage: (newImagesPerPage: number) => {},
		registerViaAuthProvider: (provider: any, viewer?: boolean) =>
			Promise.resolve(),
		registerWithEmail: (
			username: string,
			email: string,
			password: string,
			viewer?: boolean
		) => Promise.resolve(),
		logOut: () => {},
		pageMenuWidth: 72,
		preferences: null,
		displayUpload: false,
		setUploadState: () => {},
		isTesting: isDevelopment,
		setIsTesting: () => {},
	};

	async componentDidMount() {
		await this.getMachinesFromConfig();
		this.verifyUser();
		// const database: firebase.database.Database = firebase.database();
		// const localRefs: firebase.database.Reference[] = [];
		// this.state.engineMachines.forEach((machine: Machine) => {
		// let curRef: firebase.database.Reference = database.ref(
		// 	`${firebaseHeartbeat}/${machine.heartbeatName}`
		// );
		// curRef.on("value", (snapshot: firebase.database.DataSnapshot) => {
		// 	machine.lastSeen = this.parseSnapshot(snapshot);
		// });
		// localRefs.push(curRef);
		// });
		// this.setState({ heartbeatRefs: localRefs });
		// this.observeServiceState();
	}

	componentWillUnmount() {
		this.state.heartbeatRefs?.forEach((ref: any) => {
			ref.off("value");
		});
	}

	changeallConfigPersonalizerSwitchCheck(switchState: boolean) {
		this.setState({ allConfigPersonalizerSwitchCheck: switchState });
	}
	addToPresentedImagesArray(presentedImagesMongoIdArray: string[]) {
		this.setState({ presentedImagesArray: presentedImagesMongoIdArray });
	}
	updateSelectedLabels(newSelectedLabels: string[]) {
		this.setState({ previouslySelectedLabels: newSelectedLabels });
	}
	updateCurFolder(newCurFolder: Folder) {
		this.setState({ curFolder: newCurFolder });
	}
	updateSelectedPage(newSelectedPage: number) {
		this.setState({ previouslySelectedPage: newSelectedPage });
	}
	updateImagesPerPage(newImagesPerPage: number) {
		this.setState({ imagesPerPage: newImagesPerPage });
	}
	updateImageHeight(newImageHeight: number) {
		this.setState({ imageHeight: newImageHeight });
	}
	updateSelectedText(newSelectedText: string) {
		this.setState({ selectedText: newSelectedText });
	}
	updateCurrExpandedFolders(newExpandedFolders: string[] | []) {
		this.setState({ currExpandedFolders: newExpandedFolders });
	}
	updateValidFacesToggle(newValidFacesToggle: boolean) {
		this.setState({ validFacesToggle: newValidFacesToggle });
	}
	updateMslToggle(newMslToggle: boolean) {
		this.setState({ mslToggle: newMslToggle });
	}

	render() {
		return (
			<AppContext.Provider
				value={{
					validFacesToggle: this.state.validFacesToggle,
					updateValidFacesToggle:
						this.updateValidFacesToggle.bind(this),
					mslToggle: this.state.mslToggle,
					updateMslToggle: this.updateMslToggle.bind(this),
					curFolder: this.state.curFolder,
					updateCurFolder: this.updateCurFolder.bind(this),
					updateCurrExpandedFolders:
						this.updateCurrExpandedFolders.bind(this),
					currExpandedFolders: this.state.currExpandedFolders,
					changeallConfigPersonalizerSwitchCheck:
						this.changeallConfigPersonalizerSwitchCheck.bind(this),
					allConfigPersonalizerSwitchCheck:
						this.state.allConfigPersonalizerSwitchCheck,
					isEngineOnline: this.state.isEngineOnline,
					engineMachines: this.state.engineMachines,
					heartbeatRefs: this.state.heartbeatRefs,
					user: this.state.user,
					authenticationWasVerified:
						this.state.authenticationWasVerified,
					presentedImagesArray: this.state.presentedImagesArray,
					imagesPerPage: this.state.imagesPerPage,
					imageHeight: this.state.imageHeight,
					previouslySelectedLabels:
						this.state.previouslySelectedLabels,
					previouslySelectedPage: this.state.previouslySelectedPage,
					updateImagesPerPage: this.updateImagesPerPage.bind(this),
					updateSelectedPage: this.updateSelectedPage.bind(this),
					updateImageHeight: this.updateImageHeight.bind(this),
					updateSelectedLabels: this.updateSelectedLabels.bind(this),
					addToPresentedImagesArray:
						this.addToPresentedImagesArray.bind(this),
					updateSelectedText: this.updateSelectedText.bind(this),
					selectedText: this.state.selectedText,
					registerViaAuthProvider: this.registerViaAuthProvider,
					registerWithEmail: this.registerWithEmail,
					logOut: this.logOut,
					pageMenuWidth: this.state.pageMenuWidth,
					preferences: this.state.preferences,
					displayUpload: this.state.displayUpload,
					setUploadState: this.setUploadState.bind(this),
					isTesting: this.state.isTesting,
					setIsTesting: this.setIsTesting.bind(this),
				}}
			>
				{this.props.children}
			</AppContext.Provider>
		);
	}

	async getMachinesFromConfig() {
		const defaultRemoteConfig = getRemoteConfig();
		defaultRemoteConfig.settings.minimumFetchIntervalMillis = 0;
		await fetchAndActivate(defaultRemoteConfig);
		try {
			const machinesFromRemoteConfig = JSON.parse(
				getString(defaultRemoteConfig, "prod_instances")
			);
			const localMachines: Machine[] = [];
			for (let idx in machinesFromRemoteConfig) {
				const singleConfig = machinesFromRemoteConfig[idx];
				localMachines.push(
					new Machine(
						singleConfig.id,
						singleConfig.displayName,
						singleConfig.heartbeatName
					)
				);
			}
			this.setState({ engineMachines: localMachines });
		} catch (e) {
			console.log(e);
		}
	}

	// Context Actions
	setSelectedPage = (selectedPage: SelectedPage) => {
		this.setState({ selectedPage: selectedPage });
	};

	logOut = async () => {
		try {
			signOut(auth);
			localStorage.removeItem("client_uid");
			this.setState({ user: null });
		} catch {}
	};

	registerViaAuthProvider = async (provider: any, viewer?: boolean) => {
		try {
			await setPersistence(auth, browserLocalPersistence);
			var result = await signInWithPopup(auth, provider);
			var user = await result.user;
			if (user !== null) {
				var exsistringUser: User | null = null;
				try {
					exsistringUser = await this.getUserWithUID(user.uid);
					const jwt = await user?.getIdToken();
					localStorage.setItem("authorization", jwt);
				} catch {}
				var database = getDatabase(app);
				await set(ref(database, "users/" + user.uid), {
					user_name: user.displayName ?? "",
					uid: user.uid,
					email: user.email,
					profile_picture: user.photoURL ?? "",
					role:
						user.email?.includes("@bria.ai") ||
						user.email?.includes("@exalt")
							? ROLES.ADMIN
							: exsistringUser?.role ??
							  (viewer ? ROLES.USER : ROLES.EXTERNAL),
				});
				localStorage.setItem("client_uid", user.uid);
				this.verifyUser();
				return Promise.resolve();
			}
			return Promise.reject("Invalid user");
		} catch (error) {
			return Promise.reject(error);
		}
	};

	registerWithEmail = async (
		username: string,
		email: string,
		password: string,
		viewer?: boolean
	) => {
		try {
			await setPersistence(auth, browserLocalPersistence);
			var result = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			);
			var user = await result.user;
			if (user !== null) {
				var exsistringUser: User | null = null;
				try {
					exsistringUser = await this.getUserWithUID(user.uid);
					const jwt = await user?.getIdToken();
					localStorage.setItem("authorization", jwt);
				} catch {}
				var database = getDatabase(app);
				await set(ref(database, "users/" + user.uid), {
					user_name: user.displayName ?? username,
					uid: user.uid,
					email: user.email,
					profile_picture: user.photoURL ?? "",
					role:
						exsistringUser?.role ??
						(viewer ? ROLES.USER : ROLES.EXTERNAL),
				});
				localStorage.setItem("client_uid", user.uid);
				this.verifyUser();
				return Promise.resolve();
			}
			return Promise.reject("Invalid user");
		} catch (error) {
			return Promise.reject(error);
		}
	};

	// helpers
	parseSnapshot(snapshot: any): number {
		const data = snapshot.val();
		if (data) {
			if ("last_seen" in data) {
				return data["last_seen"] as number;
			}
		}
		return 0;
	}

	observeServiceState() {
		setTimeout(() => {
			const now = Math.floor(Date.now() / 1000);
			const buffer: number = 35;
			this.state.engineMachines.forEach((machine: Machine) => {
				machine.isOn = now - machine.lastSeen < buffer;
			});
			const isEngineOnline =
				this.state.engineMachines.filter(
					(machine) => machine.isOn === false
				).length == 0;
			this.setState({
				isEngineOnline: isEngineOnline,
			});
			this.observeServiceState();
		}, 1000);
	}

	getUserWithUID(uid: string): Promise<User> {
		return new Promise<User>((resolve, reject) => {
			var starCountRef = ref(getDatabase(app), "users/" + uid);
			onValue(starCountRef, function (snapshot) {
				if (snapshot.exists()) {
					const recoed = snapshot.val();
					const user = new User(
						recoed.user_name,
						recoed.uid,
						recoed.profile_picture,
						recoed.role,
						getAuth()?.currentUser?.emailVerified ?? false
					);
					Analytics.setUserProperties(user.role);
					resolve(user);
				}
				reject("no_user");
			});
		});
	}

	async verifyUser() {
		await onAuthStateChanged(auth, async (currentUser) => {
			try {
				if (currentUser) {
					const token = await currentUser.getIdToken();
					const user = await this.getUserWithUID(currentUser.uid);
					localStorage.setItem("client_uid", currentUser.uid);
					localStorage.setItem("authorization", token);
					const settingsRef = ref(
						getDatabase(),
						`users/${user?.uid}/settings`
					);
					onValue(settingsRef, (snapshot) => {
						this.setState({
							preferences: snapshot.val(),
						});
						if (snapshot.val() === null) {
							this.setState({
								preferences: {
									withLabels: true,
									style: ToolsViewerMode.sliders,
								},
							});
						}
					});
					this.setState({ user: user }, () => {
						this.observeUserRoleChnage();
					});
				} else {
					this.setState({ user: null });
				}
			} catch {
				this.setState({ user: null });
			}
			this.setState({ authenticationWasVerified: true });
		});
	}

	observeUserRoleChnage() {
		var starCountRef = ref(
			getDatabase(),
			"users/" + this.state.user?.uid ?? "" + "/role"
		);
		onValue(starCountRef, (snapshot) => {
			this.updateUser();
		});
	}

	async updateUser() {
		const user = await this.getUserWithUID(this.state.user?.uid ?? "");
		this.setState({ user: user });
	}

	setUploadState(value: boolean) {
		this.setState({ displayUpload: value });
	}

	setIsTesting(value: boolean) {
		this.setState({ isTesting: value });
	}
}

export default AppContextProvider;
