import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const PlusIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			{...props}
		>
			<path
				d="M7.99987 4.26654V11.7332M4.26654 7.99987H11.7332M7.99987 15.4665C3.87614 15.4665 0.533203 12.1236 0.533203 7.99987C0.533203 3.87614 3.87614 0.533203 7.99987 0.533203C12.1236 0.533203 15.4665 3.87614 15.4665 7.99987C15.4665 12.1236 12.1236 15.4665 7.99987 15.4665Z"
				stroke="inherit"
				fill="none"
			/>
		</SvgIcon>
	);
};

export default PlusIcon;
