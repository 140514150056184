import {
	createStyles,
	makeStyles,
	TextField,
	Theme,
	Typography,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import LaughingIcon from "../../assets/icons/LaughingIcon";
import SendIcon from "../../assets/icons/SendIcon";
import useFeedback from "./useFeedback";
import clsx from "clsx";
import HappyIcon from "../../assets/icons/HappyIcon";
import NormalIcon from "../../assets/icons/NormalIcon";
import SadIcon from "../../assets/icons/SadIcon";
import AngryIcon from "../../assets/icons/AngryIcon";
import ConfirmIcon from "../../assets/icons/ConfirmIcon";
import { useContext } from "react";
import Context from "../../Context/Context";
import Rating from "@material-ui/lab/Rating";

interface IProps {
	setImageScore: (ratingValue: string) => void;
}

export default function FeedbackForm({ setImageScore }: IProps) {
	const { t } = useTranslation();
	const classes = useStyles();
	const [anchorEl, setAnchorEl] =
		React.useState<HTMLButtonElement | null>(null);

	const { user } = useContext(Context);

	const {
		value: feedbackValue,
		handleChange: setFeedbackValue,
		ratingValue,
		setRatingValue,
		handleSubmit: handleSubmitValue,
	} = useFeedback();

	const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setMessageConfirmation(false);
	};

	const open = Boolean(anchorEl);
	const id = open ? "feedback-popover" : undefined;

	const [messageConfirmation, setMessageConfirmation] =
		useState<boolean>(false);

	const [value, setValue] = React.useState<number | null>(5);
	const [hover, setHover] = React.useState<number>(5);

	return (
		<div>
			<Box className={classes.container} display="flex">
				<Box onClick={handleClickOpen}>
					{!open && (
						<IconButton
							className={clsx(
								classes.iconButton,
								classes.opacityIcon
							)}
						>
							<LaughingIcon />
						</IconButton>
					)}
				</Box>
			</Box>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				style={{ marginTop: -9, marginLeft: -9 }}
				className={classes.popover}
			>
				{messageConfirmation ? (
					<Box className={classes.feedBackContainer} pt={6}>
						<ConfirmIcon className={classes.confirmIcon} />
						<Typography className={classes.typo}>
							{t("thankYouForYourFeedback")}
						</Typography>
					</Box>
				) : (
					<Box className={classes.feedBackContainer}>
						<Box
							width={242}
							height={54}
							display="flex"
							justifyContent="space-around"
							alignItems="center"
							padding="9px 7px 5px 7px"
						>
							<IconButton
								className={clsx({
									[classes.iconButton]: true,
									[classes.selectedRatingIcon]:
										ratingValue === 5,
								})}
								onClick={() => {
									setRatingValue(5);
								}}
							>
								<LaughingIcon />
							</IconButton>
							<IconButton
								className={clsx({
									[classes.iconButton]: true,
									[classes.selectedRatingIcon]:
										ratingValue === 4,
								})}
								onClick={() => {
									setRatingValue(4);
								}}
							>
								<HappyIcon />
							</IconButton>
							<IconButton
								className={clsx({
									[classes.iconButton]: true,
									[classes.selectedRatingIcon]:
										ratingValue === 3,
								})}
								onClick={() => {
									setRatingValue(3);
								}}
							>
								<NormalIcon />
							</IconButton>
							<IconButton
								className={clsx({
									[classes.iconButton]: true,
									[classes.selectedRatingIcon]:
										ratingValue === 2,
								})}
								onClick={() => {
									setRatingValue(2);
								}}
							>
								<SadIcon />
							</IconButton>
							<IconButton
								className={clsx({
									[classes.iconButton]: true,
									[classes.selectedRatingIcon]:
										ratingValue === 1,
								})}
								onClick={() => {
									setRatingValue(1);
								}}
							>
								<AngryIcon />
							</IconButton>
						</Box>

						<Box width="100%" position="relative" my={1}>
							<TextField
								placeholder={t("pleaseWriteCommentsOrFeedback")}
								variant="filled"
								size="small"
								fullWidth
								multiline
								className={classes.feedbackTextfield}
								value={feedbackValue}
								onChange={setFeedbackValue}
							/>
						</Box>

						{user?.isAdmin() && (
							<Box>
								<Box
									component="fieldset"
									mb={3}
									borderColor="transparent"
								>
									<Typography component="legend">
										{value !== null &&
											(hover !== -1 ? hover : value)}{" "}
										stars
									</Typography>
									<Rating
										size="small"
										name="customized-10"
										max={10}
										value={value}
										defaultValue={5}
										onChange={(event, newValue) => {
											setValue(newValue);
										}}
										onChangeActive={(event, newHover) => {
											setHover(newHover);
										}}
									/>
								</Box>
							</Box>
						)}

						<Box className={classes.sendButton}>
							<IconButton
								onClick={() => {
									handleSubmitValue();
									setMessageConfirmation(true);
									user?.isAdmin() &&
										setImageScore(value?.toString() ?? "");
								}}
							>
								<SendIcon />
							</IconButton>
						</Box>
					</Box>
				)}
			</Popover>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			height: "100%",
			width: 40,
			position: "absolute",
			left: 18,
			top: 0,
			background: "transparent",
			zIndex: 9999,
			paddingTop: 15,
		},

		textArea: {
			width: "100%",
			padding: 16,
			fontSize: 14,
			color: "#C4C4C4",
			marginBottom: 15,
			borderRadius: 4,
			height: 120,
			minHeight: 120,
			maxWidth: "100%",
		},
		feedbackIcon: {
			height: "100%",
			width: 40,
			position: "absolute",
			left: 10,
			bottom: 0,
			background: "transparent",
			zIndex: 9999,
			paddingBottom: theme.spacing(1),
		},
		iconButton: {
			background: theme.palette.common.white,
			width: 40,
			height: 40,
			borderRadius: "50%",
			"&:hover": {
				background: theme.palette.common.white,
			},
		},
		feedBackContainer: {
			width: 242,
			minHeight: 186,
			background: theme.palette.common.white,
		},
		feedBackText: {
			color: "white",
		},
		feedBackTextArea: {
			width: "100%",
			background: "white",
			padding: theme.spacing(1),
		},
		feedBackButton: {
			marginTop: theme.spacing(1),
		},
		feedbackTextfield: {
			color: "black",
			"& .MuiFilledInput-root": {
				height: 78,
				fontSize: 12,
				backgroundColor: "#E9ECEF",
				borderRadius: 0,
				// color: "#A4A4A4",
			},

			"& .MuiFilledInput-multiline": {
				padding: "0px 8px 8px 10px",
			},

			"& .MuiFilledInput-underline:before, .MuiFilledInput-underline:after":
				{
					border: "none",
				},
		},
		sendButton: {
			position: "absolute",
			right: 10,
			bottom: 13,
			height: 26,
			width: 26,
			background: "#5300C9",
			borderRadius: 4,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		// sendIconButton: {
		// 	"& .MuiIconButton-root:hover": {
		// 		backgroundColor: "transparent",
		// 	},
		// },
		selectedRatingIcon: {
			background: "rgba(255, 255, 255, 0.7)",
			boxShadow: "0px 5px 10px -4px rgb(0 0 0 / 50%)",
		},
		popover: {
			"& .MuiPaper-rounded": {
				borderRadius: 20,
			},
		},
		typo: {
			color: theme.palette.primary.main,
			fontSize: 12,
			marginTop: theme.spacing(4),
			textAlign: "center",
		},
		confirmIcon: {
			display: "block",
			margin: "auto",
		},
		opacityIcon: {
			opacity: 0.7,
		},
	})
);
