import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function PushPinIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 905.8 594.6" {...props}>
			<circle cx="672.2" cy="505" r="64" fill="#d80067" />
			<path
				fill="#5300c9"
				d="M270.6 336.1h-43.8c-.3 0-.5.2-.5.5v40.5c0 .3.2.5.5.5h43c15 0 23.2-8.2 23.2-21.2-.1-12.4-9.1-20.3-22.4-20.3zm-43.8-31.9H267c10.9 0 18.8-5.2 21.7-13.7 4.5-13.3-6.3-27-20.3-27h-41.5c-.3 0-.5.2-.5.5v39.7c-.2.3.1.5.4.5zm230.6-38.4h-43.6c-.3 0-.5.2-.5.5v44.5c0 .3.2.5.5.5h43.6c16.7 0 27.4-9 27.4-22.9 0-13.6-10.7-22.6-27.4-22.6z"
			/>
			<path
				fill="#5300c9"
				d="M558.6 226.4c0-.3.2-.5.5-.5h15C542.2 103.4 430.8 13 298.4 13c-157.4 0-285 127.6-285 285s127.6 285 285 285c116 0 215.7-69.3 260.2-168.8V226.4zM277.9 415.2h-97.8c-.3 0-.5-.2-.5-.5V226.4c0-.3.2-.5.5-.5h97.5c34.8 0 57.4 20.6 57.4 51.7 0 26-18.4 38.4-31.1 39.8v1.1c17.5 1.4 36.5 15 36.5 43.5 0 31.5-21.2 53.2-62.5 53.2zm206.3 0c-.2 0-.3-.1-.4-.2L427 350.4c-.1-.1-.2-.2-.4-.2h-12.8c-.3 0-.5.2-.5.5v64c0 .3-.2.5-.5.5h-45.6c-.3 0-.5-.2-.5-.5V226.4c0-.3.2-.5.5-.5h95.3c41 0 68.1 24.9 68.1 61.9 0 30.8-18.9 53-48.9 60-.4.1-.5.5-.3.8l58 65.8c.3.3.1.9-.4.9h-54.8z"
			/>
			<path
				fill="#80f"
				d="M764.7 381.1c-.1-.2-.3-.3-.5-.3h-79c-.2 0-.4.1-.5.3l-8.9 23.1c19.7 8.9 41.5 13.9 64.5 13.9 12.7 0 25-1.5 36.9-4.4l-12.5-32.6z"
			/>
			<path
				fill="#80f"
				d="M740.4 104c-74.7 0-137.1 52.1-153 121.9h17.3c.3 0 .5.2.5.5v114.5c8.5 14.3 19.2 27.1 31.6 38l63.8-152.7c.1-.2.3-.3.5-.3h47.1c.2 0 .4.1.5.3l71.2 170.2c46.4-27.3 77.7-77.7 77.7-135.5-.2-86.6-70.5-156.9-157.2-156.9z"
			/>
			<path
				fill="#80f"
				d="M748.3 340.6c.4 0 .6-.4.5-.7l-23.5-60.2c-.2-.4-.8-.4-1 0L701 339.9c-.1.3.1.7.5.7h46.8z"
			/>
		</SvgIcon>
	);
}

export default PushPinIcon;
