import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const NextIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 22 42" {...props}>
			<path
				d="M1 41L21 21L0.999998 1.00001"
				stroke="#5300C9"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="bevel"
				fill="none"
			/>
		</SvgIcon>
	);
};

export default NextIcon;
