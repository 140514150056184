import React from "react";
import client from "../../GraphQL/client";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Folder } from "../ImageViewer";
import { getFolders } from "../../GraphQL/queries";
import { folders_folders as GQLFolder } from "../../GraphQL/types/folders";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import LabelIcon from "@material-ui/icons/Label";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import { DBName } from "../../types/graphql-global-types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

interface IProps {
	curFolder: string;
	rootFolder: Folder;
	refetchFolders: boolean;
	dbName: DBName;
	parentCurFolderCallback: (chosenFolder: Folder, expanded: string[]) => void;
	parentAfterRefetchCallback: (rootFolder: Folder) => void;
	parentCheckCallback: (checkedFoldersExist: boolean) => void;
	expanded: string[];
	bulkEdit: boolean;
}

interface IState {
	expanded: string[];
	rootFolder: Folder;
	checkedFoldersPaths: string[];
}

class FolderTree extends React.Component<IProps, IState> {
	state: IState = {
		expanded: this.props.expanded,
		rootFolder: this.props.rootFolder,
		checkedFoldersPaths: []
	};

	collapseChildren(folder: Folder, toRemove: string[]) {
		folder.isExpanded = false;
		toRemove.push(folder.path);
		if (folder.children.length > 0) {
			folder.children.forEach((child: Folder) => {
				this.collapseChildren(child, toRemove);
			});
		}
	}

	async getCurFolderChildren(folder: Folder) {
		if (folder.children.length === 0) {
			const returnFolders: GQLFolder[] = await getFolders(
				this.props.dbName,
				folder.path,
				client
			);
			returnFolders.forEach((gqlFolder: GQLFolder) => {
				folder.children.push(
					new Folder(
						gqlFolder.fullPath,
						gqlFolder.folderName,
						[],
						folder
					)
				);
			});
		}
		if (folder.isExpanded === true) {
			let curExpanded: string[] = this.state.expanded;
			let toRemove: string[] = [];
			this.collapseChildren(folder, toRemove);
			curExpanded = curExpanded.filter(function (existingNode) {
				return !toRemove.some(function (nodeToRemove) {
					return nodeToRemove === existingNode;
				});
			});
			this.setState({ expanded: curExpanded });
			return;
		}
		let curExpanded: string[] = this.state.expanded;
		curExpanded.push(folder.path);
		this.setState({ expanded: curExpanded });
		folder.isExpanded = true;
	}

	async expandChildren(expanded: string[], folder: Folder) {
		for (let i = 0; i < expanded.length; i++) {
			if (expanded[i] === folder.path) {
				const returnFolders: GQLFolder[] = await getFolders(
					this.props.dbName,
					folder.path,
					client
				);
				returnFolders.forEach((gqlFolder: GQLFolder) => {
					folder.children.push(
						new Folder(
							gqlFolder.fullPath,
							gqlFolder.folderName,
							[],
							folder
						)
					);
				});
				folder.isExpanded = true;
			}
		}
		folder.children.forEach((folder: Folder) => {
			this.expandChildren(expanded, folder);
		});
	}

	async reinitializeRoot() {
		const returnFolders: void | GQLFolder[] = await getFolders(
			this.props.dbName,
			this.state.rootFolder.path,
			client
		);
		let localRootFolder: Folder = new Folder("", "Root", [], null);
		let rootFolderChildren: Folder[] = [];

		returnFolders.forEach((folder: GQLFolder) => {
			rootFolderChildren.push(
				new Folder(
					folder.fullPath,
					folder.folderName,
					[],
					localRootFolder
				)
			);
		});

		localRootFolder.children = rootFolderChildren;
		localRootFolder.isExpanded = true;
		await this.expandChildren(this.state.expanded, localRootFolder);
		this.setState({ rootFolder: localRootFolder }, () => {
			this.props.parentAfterRefetchCallback(this.state.rootFolder);
		});
	}

	async componentDidUpdate(prevProps: IProps) {
		if (prevProps.dbName !== this.props.dbName) {
			this.setState({ expanded: [] });
		}
		if (this.props.refetchFolders) {
			await this.reinitializeRoot();
		}
	}

	componentWillUnmount() {
		client.stop();
	}

	render() {
		const { classes }: any = this.props;
		return (
			<TreeView
				className={classes.root}
				defaultCollapseIcon={<ArrowDropDownIcon color="secondary" />}
				defaultExpandIcon={<ArrowRightIcon color="secondary" />}
				defaultEndIcon={<div style={{ width: 24 }} />}
				expanded={this.state.expanded}
			>
				{this.renderTree(this.state.rootFolder, 0)}
			</TreeView>
		);
	}

	handleFolderCheck(folder: Folder) {
		folder.isChecked = !folder.isChecked;
		if (!folder.isChecked) {
			this.setState(
				{
					checkedFoldersPaths: this.state.checkedFoldersPaths.filter(
						function (checkedFolderPath) {
							return checkedFolderPath !== folder.path;
						}
					),
				},
				() =>
					this.props.parentCheckCallback(
						this.state.checkedFoldersPaths.length !== 0
					)
			);
		} else {
			let localCheckedFolders: string[] = this.state.checkedFoldersPaths;
			localCheckedFolders.push(folder.path);
			this.setState({ checkedFoldersPaths: localCheckedFolders }, () =>
				this.props.parentCheckCallback(
					this.state.checkedFoldersPaths.length !== 0
				)
			);
		}
	}

	renderTree(rootFolder: Folder, leftPadding: number) {
		const { classes, curFolder }: any = this.props;
		return (
			<div>
				{rootFolder.children.map((child: Folder, index: number) => {
					return (
						<TreeItem
							label={
								<div className={classes.labelRoot}>
									{this.props.bulkEdit && (
										<FormControlLabel
											control={
												<Checkbox
													color="primary"
													name="checkInstance"
													checked={child.isChecked}
												/>
											}
											label=""
											onChange={() =>
												this.handleFolderCheck(child)
											}
										/>
									)}
									<LabelIcon className={classes.labelIcon} />
									<Typography
										variant="body2"
										className={classes.labelText}
									>
										{child.name}
									</Typography>
								</div>
							}
							key={child.path}
							nodeId={child.path}
							style={{
								// backgroundColor: (child.path === curFolder) ? `rgb(82, 1, 201, 0.2)` : "none",
								color: "secondary",
								paddingLeft: leftPadding,
							}}
							classes={{
								root: classes.root,
								content: classes.content,
								expanded: classes.expanded,
								label: classes.label,
							}}
							onClick={async () => {
								await this.getCurFolderChildren(child);
								this.props.parentCurFolderCallback(
									child,
									this.state.expanded
								);
							}}
						>
							{this.renderTree(child, leftPadding + 1.5)}
						</TreeItem>
					);
				})}
			</div>
		);
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			color: theme.palette.text.primary,
		},
		content: {
			color: theme.palette.text.primary,
			borderTopRightRadius: theme.spacing(2),
			borderBottomRightRadius: theme.spacing(2),
			paddingRight: theme.spacing(1),
			fontWeight: theme.typography.fontWeightMedium,
			"$expanded > &": {
				fontWeight: theme.typography.fontWeightRegular,
			},
		},
		label: {
			fontWeight: "inherit",
			color: "secondary",
		},
		labelRoot: {
			display: "flex",
			alignItems: "center",
			padding: theme.spacing(0.5, 0),
		},
		labelIcon: {
			marginRight: theme.spacing(1),
		},
		labelText: {
			fontWeight: "inherit",
			flexGrow: 1,
		},
	});

export default withStyles(styles)(FolderTree);
