import { getDatabase, ref, update } from "firebase/database";

class User {
	userName: string;
	uid: string;
	profilePicture: string;
	role: string;
	emailVerified: boolean;

	constructor(
		userName: string,
		uid: string,
		profilePicture: string,
		role: string,
		emailVerified: boolean
	) {
		this.uid = uid;
		this.userName = userName;
		this.profilePicture = profilePicture;
		this.role = role;
		this.emailVerified = emailVerified;
	}

	isEmailVerified(): boolean {
		return this.emailVerified;
	}

	haveViewPermitions() {
		return this.role !== "non";
	}

	isAdmin() {
		return this.role === "admin";
	}

	isViewer() {
		return this.role === "view";
	}

	updateThumbnailsSettings(value: string) {
		const db = getDatabase();
		const settingsRef = ref(db, `users/${this.uid}/settings/`);
		update(settingsRef, { style: value });
	}
}

export default User;
