import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function ThumbnailsIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 16 16" {...props}>
			<path
				fill="none"
				stroke="inherit"
				d="M5.86654 0.533203H1.59987C1.01077 0.533203 0.533203 1.01077 0.533203 1.59987V5.86654C0.533203 6.45564 1.01077 6.9332 1.59987 6.9332H5.86654C6.45564 6.9332 6.9332 6.45564 6.9332 5.86654V1.59987C6.9332 1.01077 6.45564 0.533203 5.86654 0.533203Z"
			/>
			<path
				fill="none"
				stroke="inherit"
				d="M14.3999 0.533203H10.1332C9.5441 0.533203 9.06654 1.01077 9.06654 1.59987V5.86654C9.06654 6.45564 9.5441 6.9332 10.1332 6.9332H14.3999C14.989 6.9332 15.4665 6.45564 15.4665 5.86654V1.59987C15.4665 1.01077 14.989 0.533203 14.3999 0.533203Z"
			/>
			<path
				fill="none"
				stroke="inherit"
				d="M14.3999 9.06654H10.1332C9.5441 9.06654 9.06654 9.5441 9.06654 10.1332V14.3999C9.06654 14.989 9.5441 15.4665 10.1332 15.4665H14.3999C14.989 15.4665 15.4665 14.989 15.4665 14.3999V10.1332C15.4665 9.5441 14.989 9.06654 14.3999 9.06654Z"
			/>
			<path
				fill="none"
				stroke="inherit"
				d="M5.86654 9.06654H1.59987C1.01077 9.06654 0.533203 9.5441 0.533203 10.1332V14.3999C0.533203 14.989 1.01077 15.4665 1.59987 15.4665H5.86654C6.45564 15.4665 6.9332 14.989 6.9332 14.3999V10.1332C6.9332 9.5441 6.45564 9.06654 5.86654 9.06654Z"
			/>
		</SvgIcon>
	);
}

export default ThumbnailsIcon;
