import {
	ApolloClient,
	DefaultOptions,
	createHttpLink,
	from,
} from "@apollo/client";
import { getAuth, signOut, getIdToken } from "firebase/auth";
import { setContext } from "@apollo/client/link/context";
import { InMemoryCache } from "@apollo/client/cache";
import { isDevelopment } from "../Config/Env";
import { onError } from "@apollo/client/link/error";
import { ServerError } from "@apollo/client/link/utils";
import { RetryLink } from "@apollo/client/link/retry";
import app from "../Config/Firebase";

let backend_url = isDevelopment
	? "http://127.0.0.1:5000/graphql/"
	: "https://labs.bria.ai:5000/graphql/";

if (process.env.REACT_APP_IS_INT) {
	backend_url = "https://labs-int.bria.ai/graphql/";
}

const httpLink = createHttpLink({
	uri: backend_url,
});

const authLink = setContext(async (_, { headers }) => {
	const authorization: string =
		(await getIdToken(getAuth(app)?.currentUser!)) ?? "";
	const clientUid = localStorage.getItem("client_uid") || "";
	return {
		headers: {
			...headers,
			client_uid: clientUid,
			Authorization: authorization,
		},
	};
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
	if (
		networkError &&
		networkError.name === "ServerError" &&
		(networkError as ServerError).statusCode === 401
	) {
		try {
			signOut(getAuth(app));
		} catch (e) {
			console.error(e);
		}
	}
});

const retryLink = new RetryLink({
	attempts: {
		max: 5,
		retryIf: (error, _operation) => {
			if (error.statusCode === 401) {
				return false;
			}
			return !!error;
		},
	},
	delay: (count, operation, error) => {
		const delayTime: number = Math.pow(2, count - 1) * 100;
		return delayTime;
	},
});

const defaultOptions: DefaultOptions = {
	watchQuery: {
		fetchPolicy: "no-cache",
		errorPolicy: "ignore",
	},
	query: {
		fetchPolicy: "no-cache",
		errorPolicy: "all",
		context: { timeout: 120 },
	},
	mutate: {
		context: { timeout: 120 },
	},
};

export default new ApolloClient({
	link: from([errorLink, retryLink, authLink, httpLink]),
	cache: new InMemoryCache({
		addTypename: false,
	}),
	defaultOptions: defaultOptions,
});
