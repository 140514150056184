import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const SendIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			{...props}
		>
			<path
				d="M0.230528 7.86015L6.85612 10.0686L9.0647 16.6943C9.10705 16.821 9.22363 16.9082 9.3574 16.9129C9.36099 16.9131 9.36474 16.9131 9.36834 16.9131C9.49773 16.9131 9.61478 16.8351 9.66431 16.7149L15.9899 1.35286L15.9901 1.352C15.9908 1.35021 15.9907 1.34827 15.9914 1.34646C16.0046 1.31262 16.0104 1.27774 16.0116 1.24267C16.0121 1.23179 16.0107 1.22154 16.01 1.21068C16.0082 1.18329 16.0031 1.15694 15.9945 1.13092C15.9909 1.12029 15.988 1.11008 15.9833 1.0998C15.9677 1.06529 15.948 1.03247 15.9202 1.00469C15.8923 0.976797 15.8595 0.957048 15.8249 0.941402C15.8148 0.936831 15.8047 0.933959 15.7943 0.930482C15.7679 0.921672 15.7413 0.916516 15.7136 0.914758C15.703 0.914113 15.693 0.912765 15.6825 0.913156C15.6472 0.914386 15.6122 0.920188 15.5782 0.933432C15.5765 0.934116 15.5746 0.934057 15.5729 0.93478L15.572 0.934995L0.2099 7.26053C0.0862892 7.31148 0.00737224 7.43384 0.0119041 7.56745C0.0165922 7.70122 0.103792 7.8178 0.230528 7.86015ZM9.3999 15.6757L7.4753 9.90195L14.7887 2.58865L9.3999 15.6757ZM14.3361 2.13615L7.02274 9.44938L1.2491 7.52495L14.3361 2.13615Z"
				fill="white"
			/>
		</SvgIcon>
	);
};

export default SendIcon;
