import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppContext from '../Context/Context';
import SelectedPage from '../Models/SelectedPage';
import Image from '@material-ui/icons/ImageTwoTone';
import Create from '@material-ui/icons/CreateTwoTone';
import ComputerIcon from '@material-ui/icons/Computer';
import ExtensionIcon from '@material-ui/icons/ExtensionTwoTone';
import InsertChartIcon from '@material-ui/icons/InsertChartTwoTone';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternateTwoTone';
import PermIdentityIcon from '@material-ui/icons/PermIdentityTwoTone';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import Dashboard from '@material-ui/icons/DashboardTwoTone';
import RouterConstants from '../Constants/RouterConstants';
import { NavLink } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core';
import { Code } from '@material-ui/icons';

const iconColor = 'secondary';

function IconForItem({ item }: any) {
  switch (item) {
    case SelectedPage.BRIATOR_V2:
      return <AddPhotoAlternateIcon color={iconColor} />;
    case SelectedPage.BRIATOR:
      return <Create color={iconColor} />;
    case SelectedPage.GRAFANA:
      return <InsertChartIcon color={iconColor} />;
    case SelectedPage.RESEARCH:
      return <Dashboard color={iconColor} />;
    case SelectedPage.DATASET:
      return <PermMediaIcon color={iconColor} />;
    case SelectedPage.TENSORBOARD:
      return <ExtensionIcon color={iconColor} />;
    case SelectedPage.IMAGEREPOSITORY:
      return <Image color={iconColor} />;
    case SelectedPage.USERMAMAGEMENT:
      return <PermIdentityIcon color={iconColor} />;
    case SelectedPage.INSTANCE_MANAGEMENT:
      return <ComputerIcon color={iconColor} />;
    case SelectedPage.GRAPHQLPLAYGROUND:
      return <Code color={iconColor} />;
  }
  return <></>;
}

const NavigationItems = () => {
  const classes = useStyles();

  const list = Object.values(SelectedPage).map((value: SelectedPage) => {
    let dest = RouterConstants.BASE;
    if (value === SelectedPage.BRIATOR_V2) {
      dest = RouterConstants.TOOLS;
    } else if (value === SelectedPage.INSTANCE_MANAGEMENT) {
      dest = RouterConstants.INSTANCES;
    } else if (value === SelectedPage.USERMAMAGEMENT) {
      dest = RouterConstants.USERS;
    } else if (value === SelectedPage.DATASET) {
      dest = RouterConstants.DATASETS;
    } else if (value === SelectedPage.RESEARCH) {
      dest = RouterConstants.RESEARCH;
    } else if (value === SelectedPage.GRAFANA) {
      dest = RouterConstants.GRAPHANA;
    } else if (value === SelectedPage.BRIATOR) {
      dest = RouterConstants.DEMO;
    } else if (value === SelectedPage.TENSORBOARD) {
      dest = RouterConstants.BOARD;
    } else if (value === SelectedPage.GRAPHQLPLAYGROUND) {
      dest = RouterConstants.GRAPHQLPLAYGROUND;
    } else {
      dest = RouterConstants.BASE;
    }
    return (
      <div key={value}>
        <AppContext.Consumer>
          {(context) => {
            if (
              value === SelectedPage.USERMAMAGEMENT &&
              !context.user?.isAdmin()
            ) {
              return null;
            }
            return (
              <ListItem
                className={classes.buttonContainer}
                component={NavLink}
                to={dest}
                exact
              >
                <ListItemIcon>
                  <IconForItem item={value} />
                </ListItemIcon>
                <ListItemText className={classes.text} primary={value} />
              </ListItem>
            );
          }}
        </AppContext.Consumer>
      </div>
    );
  });
  return <>{list}</>;
};

const useStyles = makeStyles((theme: Theme) => ({
  buttonContainer: {
    backgroundColor: '#262626',
    '&.active': {
      backgroundColor: '#b2bec3',
    },
  },
  text: {
    textTransform: 'capitalize',
    color: theme.palette.text.primary,
  },
}));

export default NavigationItems;
