import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import BriaLogo from "../assets/icons/BriaLogo";
import Copyright from "../Components/Copyright";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: "black",
	},
	title: {
		padding: theme.spacing(1),
	},
	logo: {
		height: 55,
		width: 63,
	},
}));

function TermsOfUse() {
	const classes = useStyles();

	return (
		<>
			<Box
				display="flex"
				alignItems="center"
				width="100%"
				height="100%"
				flexDirection="column"
				style={{ backgroundColor: "white" }}
			>
				<AppBar position="fixed" className={classes.root}>
					<Toolbar>
						<BriaLogo className={classes.logo} />
						<Typography className={classes.title}>
							Terms of service
						</Typography>
					</Toolbar>
				</AppBar>
				<iframe
					style={{
						width: "100%",
						height: "4500px",
						maxWidth: "540pt",
						border: 0,
					}}
					title="Terms of Service"
					src="https://bria-image-repository.s3.amazonaws.com/web-assets/BriasTermsConditions-2.html"
				></iframe>
				<Box padding={2}>
					<Copyright />
				</Box>
			</Box>
		</>
	);
}

export default TermsOfUse;
