import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SendIcon from "@material-ui/icons/ExitToApp";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import AppContext from "../Context/Context";
import {
	Avatar,
	Box,
	CircularProgress,
	FormControlLabel,
	ListItem,
	Switch,
} from "@material-ui/core";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import { useThemeContext } from "../Context/BriaThemeProvider";
import { Router, useHistory } from "react-router-dom";
import { createBrowserHistory } from "history";
import Analytics from "../Models/Analytics";
import PrivateRoute from "../Components/Common/PrivateRoute";
import PublicRoute from "../Components/Common/PublicRoute";
import RouterConstants from "../Constants/RouterConstants";
import AppHeader from "./AppHeader";
import SelectedPage from "../Models/SelectedPage";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { Business, Description } from "@material-ui/icons";
import { NavLink } from "react-router-dom";

const StyledMenu = withStyles({
	paper: {
		border: "1px solid #d3d4d5",
	},
})((props: MenuProps) => (
	<Menu
		elevation={0}
		getContentAnchorEl={null}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "center",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "center",
		}}
		{...props}
	/>
));

const StyledMenuItem = withStyles((theme) => ({
	root: {},
}))(MenuItem);

const history = createBrowserHistory();
history.listen(() => {
	Analytics.setCurrentScreen(window.location.pathname);
});

export enum ROLES {
	ADMIN = "admin",
	USER = "view",
	EXTERNAL = "external",
	NON = "non",
}

export enum PAGE_TYPES {
	PRIVATE = "PRIVATE",
	PUBLIC = "PUBLIC",
}

const AdminRole = () => [ROLES.ADMIN, ROLES.EXTERNAL];

function PageContianer({ children }: React.PropsWithChildren<any>) {
	const classes = useStyles();
	return (
		<React.Suspense
			fallback={
				<Box
					width="100vw"
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<CircularProgress />
				</Box>
			}
		>
			<main className={classes.content}>
				<div className={clsx(classes.toolbar)}>{children}</div>
			</main>
		</React.Suspense>
	);
}

export default function UserMenue() {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const { isDarkMode, handleThemeChange } = useThemeContext();
	const history = useHistory();

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Button onClick={handleClick}>
				<AppContext.Consumer>
					{(context) => <Avatar src={context.user?.profilePicture} />}
				</AppContext.Consumer>
			</Button>

			<AppContext.Consumer>
				{(context) => (
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<StyledMenuItem
							onClick={() => {
								handleClose();
							}}
						>
							<Button
								style={{
									height: 40,
								}}
								variant="contained"
								color="secondary"
								startIcon={<Brightness4Icon />}
							>
								<FormControlLabel
									control={
										<Switch
											checked={isDarkMode}
											onChange={() => handleThemeChange()}
											inputProps={{
												"aria-label": "toggle theme",
											}}
										/>
									}
									label="dark mode"
									style={{
										textTransform: "none",
									}}
								/>
							</Button>
						</StyledMenuItem>
						<StyledMenuItem
							onClick={() => {
								window.open(
									"https://image-viewer-int.web.app/",
									"_black"
								);
							}}
						>
							<ListItemIcon>
								<OpenInNewIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText primary="Int Link" />
						</StyledMenuItem>
						<StyledMenuItem
							onClick={() => {
								history.push(RouterConstants.ORGANIZATION);
							}}
						>
							<ListItemIcon>
								<Business fontSize="small" />
							</ListItemIcon>
							<ListItemText primary="Organization" />
						</StyledMenuItem>
						<StyledMenuItem
							onClick={() => {
								window.open("https://briapi.redoc.ly/");
							}}
						>
							<ListItemIcon>
								<Description fontSize="small" />
							</ListItemIcon>
							<ListItemText primary="BriAPI" />
						</StyledMenuItem>
						<StyledMenuItem
							onClick={() => {
								handleClose();
								context.logOut();
							}}
						>
							<ListItemIcon>
								<SendIcon fontSize="small" />
							</ListItemIcon>
							<ListItemText primary="LOG OUT" />
						</StyledMenuItem>
					</StyledMenu>
				)}
			</AppContext.Consumer>
		</div>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
		minHeight: "calc(100% - 20px)",
		maxWidth: "100vw",
	},
	content: {
		flexGrow: 1,
		overflow: "auto",
		marginTop: 64,
	},
	toolbar: theme.mixins.toolbar,
}));
