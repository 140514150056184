import { useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { CREATE_LIVELINESS_FACE } from "../../GraphQL/mutations";
import { GET_LIVELINESS_FACES_LIST } from "../../GraphQL/queries";
import { getLivelinessFacesListVariables } from "./types/getLivelinessFacesList";
import {
	GetLivelinessFacesList,
	GetLivelinessFacesList_imageLiveliness_liveFaces,
} from "../../GraphQL/types/getLivelinessFacesList";
import {
	convertSnakeCaseToText,
	renameDuplicateItems,
} from "../../Helpers/string";
import {
	CreateLivelinessFace,
	CreateLivelinessFaceVariables,
} from "../../GraphQL/types/createLivelinessFace";
import { SelectedPointInterface } from "./utilities";

export interface LiveFaces
	extends GetLivelinessFacesList_imageLiveliness_liveFaces {
	title?: string;
}

const useLiveFaces = (
	visualHash: string,
	selectedFace: SelectedPointInterface | null
) => {
	const [
		getLivelinessFaceList,
		{ loading: loadingLiveFaces, error: errorLiveFaces, data },
	] = useLazyQuery<GetLivelinessFacesList, getLivelinessFacesListVariables>(
		GET_LIVELINESS_FACES_LIST
	);

	const [createLiveFaceMutation, { loading: createLiveFaceMutationLoading }] =
		useMutation<CreateLivelinessFace, CreateLivelinessFaceVariables>(
			CREATE_LIVELINESS_FACE
		);

	useEffect(() => {
		if (selectedFace) {
			getLivelinessFaceList({
				variables: {
					visualHash: visualHash,
					face: selectedFace.rect,
				},
			});
		}
	}, [visualHash, selectedFace, getLivelinessFaceList]);

	let liveFaces: LiveFaces[] =
		data?.imageLiveliness?.liveFaces?.sort(
			(a, b) =>
				(a.videoInfo?.errDataDict?.avgPixelErr ?? 0) -
				(b.videoInfo?.errDataDict?.avgPixelErr ?? 0)
		) ?? [];
	const renamedTitles = renameDuplicateItems(
		liveFaces.map((el) => convertSnakeCaseToText(el?.propertyName || ""))
	);

	liveFaces = liveFaces?.map((el, i) => ({
		...el,
		title: renamedTitles[i],
	}));

	return {
		liveFaces: liveFaces,
		loadingLiveFaces,
		errorLiveFaces,
		createLiveFaceMutationLoading,
		createLiveFaceMutation,
	};
};

export default useLiveFaces;
