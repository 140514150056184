import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const MinimizeIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 65 65" {...props}>
			<circle cx="32.5" cy="32.5" r="32.5" fill="white" opacity="0.8" />
			<path
				d="M48.3892 26.7196L38.2781 26.7196M38.2781 26.7196V16.6085M38.2781 26.7196L49.8337 15.1641M26.7225 48.3863L26.7225 38.2752M26.7225 38.2752L16.6114 38.2752M26.7225 38.2752L15.167 49.8307"
				stroke="#5F5F5F"
			/>
		</SvgIcon>
	);
};

export default MinimizeIcon;
