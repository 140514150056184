import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function SlidersIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 14 14" {...props} fill="none">
			<path
				d="M13.7109 3.59466L6.34427 3.59466M6.34427 3.59466C6.34427 2.63737 5.56823 1.86133 4.61094 1.86133C3.65364 1.86133 2.8776 2.63737 2.8776 3.59466M6.34427 3.59466C6.34427 4.55195 5.56823 5.32799 4.61094 5.32799C3.65364 5.32799 2.8776 4.55195 2.8776 3.59466M2.8776 3.59466L0.710937 3.59466M13.7109 10.528L11.5443 10.528M11.5443 10.528C11.5443 9.5707 10.7682 8.79466 9.81094 8.79466C8.85364 8.79466 8.0776 9.5707 8.0776 10.528M11.5443 10.528C11.5443 11.4853 10.7682 12.2613 9.81094 12.2613C8.85364 12.2613 8.0776 11.4853 8.0776 10.528M8.0776 10.528L0.710938 10.528"
				stroke="inherit"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default SlidersIcon;
