import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function ResetIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 16 17" {...props}>
			<path d="M0.533203 8C0.533203 3.87627 3.87614 0.533333 7.99987 0.533333C10.3796 0.533333 12.4992 1.64658 13.8665 3.3807M15.4665 8C15.4665 12.1237 12.1236 15.4667 7.99987 15.4667C5.62017 15.4667 3.50049 14.3534 2.1332 12.6193M5.3332 12.2667H1.59987V16M14.3999 0V3.73333L10.6665 3.73333" />
		</SvgIcon>
	);
}

export default ResetIcon;
