import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { getAuth } from "firebase/auth";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import { useLoginService } from "../hooks/useLoginService";
import Layout from "../Components/Login/Layout";
import EmailIcon from "../assets/icons/EmailIcon";
import BriaButton from "../Components/UI/BriaButton";
import Context from "../Context/Context";
import RouterConstants from "../Constants/RouterConstants";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { toast } from "react-toastify";

interface IProps {}

const VerifyAccountPage: React.FC<IProps> = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { sendEmailVerificationFunction } = useLoginService();
	const context = React.useContext(Context);

	const email = getAuth().currentUser?.email;

	const handleSubmit = async (values: any) => {
		sendEmailVerificationFunction();
		toast.dark("The verification email is on its way");
	};

	return (
		<Layout>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				width="100%"
			>
				<Typography className={classes.typo1}>
					{t("emailSent")}
				</Typography>
			</Box>

			<CheckCircleIcon className={classes.checkIcon} />

			<EmailIcon className={classes.icon} />

			<BriaButton
				variant="contained"
				color="secondary"
				onClick={handleSubmit}
				className={classes.button}
			>
				{t("sendVerificationAgain")}
			</BriaButton>

			<Box textAlign="center" padding="10px">
				<Typography className={classes.typo2}>
					{t("anEmailHasBeenSentTo")}
					<span className={classes.boldFont}>{email}</span>
				</Typography>

				<Typography className={classes.typo2}>
					{t("pleaseConfirmYourEmailOr")}
					<Link
						to={{
							pathname: RouterConstants.LOGIN,
							search: window.location.search,
						}}
						onClick={context.logOut}
						className={classes.link}
					>
						{t("useDifferentAccount")}
					</Link>
				</Typography>
			</Box>
		</Layout>
	);
};

const useStyles = makeStyles((theme) => ({
	button: {
		borderRadius: 20,
		width: "100%",
		maxWidth: 440,
		height: 40,
	},
	icon: {
		fontSize: 308,
	},
	typo1: {
		fontSize: 34,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.text.primary,
		// marginBottom: theme.spacing(7),
	},
	typo2: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.primary,
	},
	boldFont: {
		fontWeight: theme.typography.fontWeightBold,
	},
	link: {
		color: theme.palette.primary.light,
	},
	checkIcon: {
		fontSize: 64,
		fill: theme.palette.primary.main,
	},
}));

export default VerifyAccountPage;
