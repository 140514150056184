import { useLazyQuery, useMutation } from "@apollo/client";
import { useState } from "react";
import { CREATE_EXTREME_EXPRESSIONS } from "../../GraphQL/mutations";
import { GET_VALID_EXPRESSIONS } from "../../GraphQL/queries";
import {
	CreateExtremeExpression,
	CreateExtremeExpressionVariables,
} from "../../GraphQL/types/createExtremeExpression";

export const useExtremeExpression = () => {
	const [selectedExpressionValue, setSelectedExpressionValue] =
		useState<number>(0);
	const [selectedExpressionName, setSelectedExpressionName] =
		useState<string>("");

	const [getValidExpressions, { data }] = useLazyQuery(GET_VALID_EXPRESSIONS);

	const [
		createExtremeExpressionMutation,
		{
			loading: createExtremeExpressionMutationLoading,
			error: createExtremeExpressionMutationError,
		},
	] = useMutation<CreateExtremeExpression, CreateExtremeExpressionVariables>(
		CREATE_EXTREME_EXPRESSIONS
	);

	const setExtremeExpression = (emotion: string, value: number) => {
		setSelectedExpressionName(emotion);
		setSelectedExpressionValue(value);
	};

	const resetExtremeExpression = () => {
		setExtremeExpression("", 0);
	};

	return {
		getValidExpressions,
		createExtremeExpressionMutation,
		setExtremeExpression,
		resetExtremeExpression,
		selectedExpressionValue,
		selectedExpressionName,
		createExtremeExpressionMutationLoading,
		createExtremeExpressionMutationError,
		validExpressions: data?.getValidExtremeExpression?.data ?? [],
	};
};
