import React from "react";
import client from "../GraphQL/client";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { CircularProgress, Button } from "@material-ui/core";
import {
	getGCPInstanceMetadata,
	getAWSInstanceMetadata,
} from "../GraphQL/queries";
import {
	runGCPInstanceCommand,
	runAWSInstanceCommand,
} from "../GraphQL/mutations";
import { awsInstanceMetadata_awsInstanceMetadata as InstanceMetadata } from "../GraphQL/types/awsInstanceMetadata";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {
	PlatformName,
	GCPInstanceAction,
	AWSInstanceAction,
} from "../types/graphql-global-types";
import AppBar from "@material-ui/core/AppBar";
import Analytics from "../Models/Analytics";
import Divider from "@material-ui/core/Divider";
import {
	getRemoteConfig,
	getString,
	fetchAndActivate,
} from "firebase/remote-config";

enum InstanceAction {
	start = "start",
	stop = "stop",
}

class Instance {
	platformName: PlatformName;
	name: string;
	zone: string;
	status: string;
	lastStartTimestampUnix: number;
	vcpuNum: number | null;
	instanceId: string | null;
	instanceType: string | null;
	gpuType: string | null;
	isChecked: boolean;

	constructor(
		platformName: PlatformName,
		name: string,
		zone: string,
		status: string,
		lastStartTimestampUnix: number,
		vcpuNum: number | null,
		instanceId: string | null,
		instanceType: string | null,
		gpuType: string | null
	) {
		this.platformName = platformName;
		this.name = name;
		this.zone = zone;
		this.status = status;
		this.lastStartTimestampUnix = lastStartTimestampUnix;
		this.vcpuNum = vcpuNum;
		this.instanceId = instanceId;
		this.instanceType = instanceType;
		this.gpuType = gpuType;
		this.isChecked = false;
	}
}

interface IState {
	backendInstances: Instance[];
	personalInstances: Instance[];
	gcpInstancesToRunCommands: string[];
	awsInstancesToRunCommands: string[];
	isLoading: boolean;
	isEditMode: boolean;
	instancesInterval: any | null;
	backendInstancesIds: string[];
}

class InstanceManagement extends React.Component<any, IState> {
	state: IState = {
		backendInstances: [],
		personalInstances: [],
		gcpInstancesToRunCommands: [],
		awsInstancesToRunCommands: [],
		isLoading: false,
		isEditMode: false,
		instancesInterval: null,
		backendInstancesIds: [],
	};

	async componentDidMount() {
		const interval: any = setInterval(
			this.updateInstances.bind(this),
			15000
		);
		const defaultRemoteConfig = getRemoteConfig();
		await fetchAndActivate(defaultRemoteConfig);
		this.setState({
			backendInstancesIds: JSON.parse(
				getString(defaultRemoteConfig, "backend_instances")
			).ids,
		});
		this.setState({ isLoading: true, instancesInterval: interval });
		const localAWSInstanceMetadata: InstanceMetadata[] =
			await getAWSInstanceMetadata(client);
		const localPersonalInstances: Instance[] = [];
		const localBackendInstances: Instance[] = [];
		localAWSInstanceMetadata.forEach(
			(instanceMetadata: InstanceMetadata) => {
				let instance: Instance = new Instance(
					instanceMetadata.platformName,
					instanceMetadata.name,
					instanceMetadata.zone,
					instanceMetadata.status,
					instanceMetadata.lastStartTimestampUnix,
					instanceMetadata.vcpuNum,
					instanceMetadata.instanceId,
					instanceMetadata.instanceType,
					instanceMetadata.gpuType
				);
				this.state.backendInstancesIds.includes(
					instance.instanceId ?? ""
				)
					? localBackendInstances.push(instance)
					: localPersonalInstances.push(instance);
			}
		);
		this.setState(
			{
				personalInstances: localPersonalInstances,
				backendInstances: localBackendInstances,
				isLoading: false,
			},
			async () => {
				const localGCPInstanceMetadata: InstanceMetadata[] =
					await getGCPInstanceMetadata(client);
				const localPersonalInstances: Instance[] =
					this.state.personalInstances;
				localGCPInstanceMetadata.forEach(
					(instanceMetadata: InstanceMetadata) => {
						localPersonalInstances.push(
							new Instance(
								instanceMetadata.platformName,
								instanceMetadata.name,
								instanceMetadata.zone,
								instanceMetadata.status,
								instanceMetadata.lastStartTimestampUnix,
								instanceMetadata.vcpuNum,
								instanceMetadata.instanceId,
								instanceMetadata.instanceType,
								instanceMetadata.gpuType
							)
						);
					}
				);
				this.setState({ personalInstances: localPersonalInstances });
			}
		);
	}

	async componentWillUnmount() {
		if (this.state.instancesInterval !== null) {
			clearInterval(this.state.instancesInterval);
		}
		client.stop();
	}

	async updateInstances() {
		const localAWSInstanceMetadata: InstanceMetadata[] =
			await getAWSInstanceMetadata(client);
		const localGCPInstanceMetadata: InstanceMetadata[] =
			await getGCPInstanceMetadata(client);
		const localInstanceMetadata: InstanceMetadata[] =
			localAWSInstanceMetadata.concat(localGCPInstanceMetadata);
		const curPersonalInstances: Instance[] = [];
		const curBackendInstances: Instance[] = [];
		let statusJSON = new Map<string, string>();
		for (let instance of localInstanceMetadata) {
			statusJSON.set(
				instance.instanceId ?? instance.name,
				instance.status
			);
		}
		for (let backendInstance of this.state.backendInstances) {
			let key: string =
				backendInstance.instanceId ?? backendInstance.name;
			backendInstance.status = statusJSON.get(key) ?? "";
			curBackendInstances.push(backendInstance);
		}
		for (let personalInstance of this.state.personalInstances) {
			let key: string =
				personalInstance.instanceId ?? personalInstance.name;
			personalInstance.status = statusJSON.get(key) ?? "";
			curPersonalInstances.push(personalInstance);
		}

		this.setState({
			personalInstances: curPersonalInstances,
			backendInstances: curBackendInstances,
		});
	}

	getDateFromUnixTimestamp(unixTimestamp: number): string {
		const date: Date = new Date(unixTimestamp * 1000);
		const dateString: string = date.toDateString();
		let hours: string = date.getHours().toString();
		if (hours.length === 1) {
			hours = "0" + hours;
		}
		let minutes: string = date.getMinutes().toString();
		if (minutes.length === 1) {
			minutes = "0" + minutes;
		}
		return `${dateString}, ${hours}:${minutes}`;
	}

	async runInstanceAction(actionEnum: InstanceAction) {
		let gcpInstanceNames: string[] = [];
		let awsInstanceIds: string[] = [];
		const localPersonalInstances: Instance[] = this.state.personalInstances;
		localPersonalInstances.forEach((instance: Instance) => {
			if (instance.isChecked) {
				if (instance.platformName === PlatformName.GCP) {
					gcpInstanceNames.push(instance.name);
				} else if (
					instance.platformName === PlatformName.AWS &&
					instance.instanceId !== null
				) {
					awsInstanceIds.push(instance.instanceId);
				}
				instance.isChecked = false;
			}
		});
		if (actionEnum === InstanceAction.start) {
			if (gcpInstanceNames.length > 0) {
				runGCPInstanceCommand(
					gcpInstanceNames,
					GCPInstanceAction.START,
					client
				);
				Analytics.post("Started GCP machines");
			}
			if (awsInstanceIds.length > 0) {
				runAWSInstanceCommand(
					awsInstanceIds,
					AWSInstanceAction.START,
					client
				);
				Analytics.post("Started AWS machines");
			}
		} else if (actionEnum === InstanceAction.stop) {
			if (gcpInstanceNames.length > 0) {
				runGCPInstanceCommand(
					gcpInstanceNames,
					GCPInstanceAction.STOP,
					client
				);
				Analytics.post("Stopped GCP machines");
			}
			if (awsInstanceIds.length > 0) {
				runAWSInstanceCommand(
					awsInstanceIds,
					AWSInstanceAction.STOP,
					client
				);
				Analytics.post("Stopped AWS machines");
			}
		}
		this.setState({ personalInstances: localPersonalInstances });
	}

	renderTableHeaders(editableInstances: boolean): JSX.Element {
		const boldFont: any = { fontWeight: "bold" };
		return (
			<TableHead>
				<TableRow>
					{this.state.isEditMode && editableInstances && (
						<TableCell size="medium" style={boldFont}>
							{""}
						</TableCell>
					)}
					<TableCell style={boldFont}>{"Platform"}</TableCell>
					<TableCell style={boldFont}>{"Instance Name"}</TableCell>
					<TableCell style={boldFont}>{"Zone"}</TableCell>
					<TableCell style={boldFont}>{"Status"}</TableCell>
					<TableCell style={boldFont}>{"vCPU"}</TableCell>
					<TableCell style={boldFont}>{"GPU Type"}</TableCell>
					<TableCell style={boldFont}>{"Instance Type"}</TableCell>
					<TableCell style={boldFont}>{"Instance ID"}</TableCell>
					<TableCell style={boldFont}>
						{"Last Time Started"}
					</TableCell>
				</TableRow>
			</TableHead>
		);
	}

	renderBackendMachines(): JSX.Element {
		const { classes }: any = this.props;
		const editableInstances: boolean = false;
		return (
			<Paper className={classes.paper}>
				<Typography
					component="h2"
					variant="h6"
					color="primary"
					gutterBottom
				>
					{"Backend Machines"}
				</Typography>
				<Table size="medium" style={{ marginTop: "1rem" }}>
					{this.renderTableHeaders(editableInstances)}
					<TableBody>
						{this.state.backendInstances.map(
							(instance: Instance, index: number) =>
								this.renderSingleRow(instance, index)
						)}
					</TableBody>
				</Table>
			</Paper>
		);
	}

	renderPersonalMachines(): JSX.Element {
		const { classes }: any = this.props;
		const editableInstances: boolean = true;
		return (
			<Paper className={classes.paper}>
				<Typography
					component="h2"
					variant="h6"
					color="primary"
					gutterBottom
				>
					{"Personal Instances"}
				</Typography>
				<Table size="medium" style={{ marginTop: "1rem" }}>
					{this.renderTableHeaders(editableInstances)}
					<TableBody>
						{this.state.personalInstances.map(
							(instance: Instance, index: number) =>
								this.renderSingleRow(instance, index)
						)}
					</TableBody>
				</Table>
			</Paper>
		);
	}

	renderChooseInstance(index: number): JSX.Element {
		return (
			<TableCell size="medium" style={{ width: "fit-content" }}>
				<FormControlLabel
					control={
						<Checkbox
							color="primary"
							name="checkInstance"
							checked={
								this.state.personalInstances[index].isChecked
							}
						/>
					}
					label=""
					onChange={() => {
						let localInstances: Instance[] =
							this.state.personalInstances;
						localInstances[index].isChecked =
							!localInstances[index].isChecked;
						this.setState({ personalInstances: localInstances });
					}}
				/>
			</TableCell>
		);
	}

	renderSingleRow(instance: Instance, index: number): JSX.Element {
		const { classes }: any = this.props;
		let cellClass: any;
		if (instance.status == "Stopped") {
			cellClass = classes.stoppedInstance;
		} else if (instance.status == "Running") {
			cellClass = classes.runningInstance;
		} else {
			cellClass = classes.inBetweenInstance;
		}
		return (
			<TableRow key={instance.name}>
				{this.state.isEditMode &&
					!this.state.backendInstancesIds.includes(
						instance.instanceId ?? ""
					) &&
					this.renderChooseInstance(index)}
				<TableCell>{instance.platformName}</TableCell>
				<TableCell>{instance.name}</TableCell>
				<TableCell>{instance.zone}</TableCell>
				<TableCell className={cellClass}>{instance.status}</TableCell>
				<TableCell>{instance.vcpuNum}</TableCell>
				<TableCell>{instance.gpuType}</TableCell>
				<TableCell>{instance.instanceType}</TableCell>
				<TableCell>{instance.instanceId}</TableCell>
				<TableCell>
					{this.getDateFromUnixTimestamp(
						instance.lastStartTimestampUnix
					)}{" "}
				</TableCell>
			</TableRow>
		);
	}
	public render() {
		const { classes } = this.props;
		const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
		if (this.state.isLoading) {
			return <CircularProgress />;
		}
		let checkedInstanceExists: boolean = this.state.personalInstances.some(
			(instance: Instance) => {
				return instance.isChecked;
			}
		);
		return (
			<Container maxWidth="lg" className={classes.container}>
				<Grid container spacing={3}>
					<AppBar position="fixed" className={classes.appBar}>
						{this.state.isEditMode ? (
							<Grid className={classes.grid}>
								<Button
									onClick={() => {
										this.runInstanceAction(
											InstanceAction.start
										);
									}}
									disabled={!checkedInstanceExists}
									className={classes.startButton}
									variant="contained"
								>
									{"Start Instances"}
								</Button>
								<Button
									onClick={() => {
										this.runInstanceAction(
											InstanceAction.stop
										);
									}}
									disabled={!checkedInstanceExists}
									className={classes.stopButton}
									variant="contained"
								>
									{"Stop Instances"}
								</Button>
								<Button
									onClick={() => {
										const localPersonalInstances: Instance[] =
											this.state.personalInstances;
										localPersonalInstances.forEach(
											(instance: Instance) => {
												instance.isChecked = false;
											}
										);
										this.setState({
											personalInstances:
												localPersonalInstances,
											isEditMode: false,
										});
									}}
									className={classes.button}
									variant="contained"
									color="secondary"
								>
									{"Cancel"}
								</Button>
							</Grid>
						) : (
							<Grid className={classes.grid}>
								<Button
									onClick={() => {
										this.setState({ isEditMode: true });
									}}
									style={{ textTransform: "none" }}
									className={classes.button}
									variant="contained"
									color="secondary"
								>
									{"Edit"}
								</Button>
							</Grid>
						)}
					</AppBar>
					<Grid
						xs={12}
						container
						style={{ marginTop: "4rem", width: "19rem" }}
						spacing={3}
					>
						{this.renderPersonalMachines()}
						<Divider />
						{this.renderBackendMachines()}
					</Grid>
				</Grid>
			</Container>
		);
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			margin: 40,
		},
		table: {
			minWidth: 650,
		},
		paper: {
			padding: theme.spacing(0.75),
			display: "flex",
			overflow: "auto",
			flexDirection: "column",
		},
		fixedHeight: {
			height: "3rem",
		},
		container: {
			maxHeight: "90vh",
			overflow: "scroll",
			marginTop: "4rem",
		},
		button: {
			textTransform: "none",
			fontWeight: "bold",
			margin: "0 1rem",
			width: "10rem",
		},
		grid: {
			display: "flex",
			justifyContent: "center",
			flexWrap: "wrap",
			paddingTop: "1rem",
		},

		startButton: {
			textTransform: "none",
			fontWeight: "bold",
			margin: "0 1rem",
			width: "10rem",
			backgroundColor: theme.palette.startButtonBackground.main,
			color: theme.palette.buttonTextColor.main,
		},
		stopButton: {
			textTransform: "none",
			fontWeight: "bold",
			margin: "0 1rem",
			width: "10rem",
			backgroundColor: theme.palette.stopButtonBackground.main,
			color: theme.palette.buttonTextColor.main,
		},
		stoppedInstance: {
			color: "gray",
			fontWeight: "bold",
		},
		runningInstance: {
			color: "green",
			fontWeight: "bold",
		},
		inBetweenInstance: {
			color: "orange",
			fontWeight: "bold",
		},
		appBar: {
			marginTop: "4rem",
			backgroundColor: theme.palette.background.paper,
		},
		tableHeader: {
			fontWeight: "bold",
		},
	});

export default withStyles(styles)(InstanceManagement);
