import React from "react";
import { makeStyles, withStyles } from "@material-ui/core";
import Button, { ButtonProps } from "@material-ui/core/Button";

const CustomizedButton = withStyles({
	root: {
		borderRadius: 20,
	},
	label: {
		textTransform: "capitalize",
	},
})(Button);

const useStyles = makeStyles((theme) => ({
	root: {
		textTransform: "capitalize",
	},
}));

interface IProps {
	capitalize?: boolean;
}

const BriaButton: React.FC<IProps & ButtonProps> = ({
	capitalize,
	children,
	...rest
}) => {
	const classes = useStyles(capitalize);
	return (
		<CustomizedButton className={classes.root} {...rest}>
			{children}
		</CustomizedButton>
	);
};

export default BriaButton;
