import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_LIVELINESS_BACKGROUNDS_LIST } from "../../GraphQL/queries";
import { CREATE_LIVE_BACKGROUND } from "../../GraphQL/mutations";
import Analytics from "../../Models/Analytics";
import {
	GetBackgroundLivenessList,
	GetBackgroundLivenessListVariables,
	GetBackgroundLivenessList_backgroundThumbnails,
} from "../../GraphQL/types/GetBackgroundLivenessList";
import {
	CreateLiveBackground,
	CreateLiveBackgroundVariables,
} from "../../GraphQL/types/createLiveBackground";
import { BriaObjectType, SelectedPointInterface } from "./utilities";
import {
	camelCaseToText,
	camelToSnakeCase,
	capitalizeFirstLetter,
} from "../../Helpers/string";

interface IProps {
	selectedPoint: SelectedPointInterface | null;
	visualHash: string;
	selectedImageUrl: string;
}

const useLiveBackgrounds = ({
	selectedPoint,
	visualHash,
	selectedImageUrl,
}: IProps) => {
	const [
		createLiveBackgroundMutation,
		{ loading: createLiveBackgroundLoading },
	] = useMutation<CreateLiveBackground, CreateLiveBackgroundVariables>(
		CREATE_LIVE_BACKGROUND
	);

	const [
		getLiveBackgroundList,
		{ loading: loadLiveBackgroung, error: errorLiveBackground, data },
	] = useLazyQuery<
		GetBackgroundLivenessList,
		GetBackgroundLivenessListVariables
	>(GET_LIVELINESS_BACKGROUNDS_LIST);

	useEffect(() => {
		if (selectedPoint?.objectType === BriaObjectType.background) {
			getLiveBackgroundList({
				variables: {
					imgUrl: selectedImageUrl,
					vHash: visualHash,
				},
			});
		}
	}, [getLiveBackgroundList, selectedImageUrl, selectedPoint, visualHash]);

	const backgroundList = (
		Object.keys(data?.backgroundThumbnails ?? {}) as Array<
			keyof GetBackgroundLivenessList_backgroundThumbnails
		>
	)
		.filter((key) => key !== "__typename")
		.map((key) => {
			return {
				id: key,
				propertyName: key,
				title: capitalizeFirstLetter(camelCaseToText(key)),
				videoUrl: data?.backgroundThumbnails
					? data?.backgroundThumbnails[key]
					: "",
			};
		});

	return {
		getLiveBackgroundList,
		createLiveBackgroundMutation,
		loadLiveBackgroung,
		errorLiveBackground,
		liveBackgroundsList: backgroundList,
		createLiveBackgroundLoading,
	};
};

export default useLiveBackgrounds;
