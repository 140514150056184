import React, { useContext } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { GoogleAuthProvider } from "firebase/auth";
import Context from "../Context/Context";
import { Form, Formik, FormikErrors } from "formik";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Google from "../assets/icons/Google";
import RouterConstants from "../Constants/RouterConstants";
import { useLoginService } from "../hooks/useLoginService";
import { Link, useLocation } from "react-router-dom";
import Layout from "../Components/Login/Layout";
import BriaInput from "../Components/UI/BriaInput";
import BriaCheckbox from "../Components/UI/BriaCheckbox";
import ErrorIcon from "../assets/icons/ErrorIcon";
import Alert from "@material-ui/lab/Alert";

interface IProps {}

interface FormValues {
	email: string;
	password: string;
}

const SignIn: React.FC<IProps> = () => {
	const classes = useStyles();
	const context = useContext(Context);
	const { t } = useTranslation();
	const { loginWithEmail, errorMessage } = useLoginService();
	const location = useLocation<any>();
	const redirect = new URLSearchParams(window.location.search).get(
		"redirect"
	);

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const initialValues = {
		email: "",
		password: "",
	};

	const handleSubmit = (values: any) => {
		loginWithEmail(values.email, values.password);
	};

	return (
		<Layout>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				width="100%"
			>
				<Typography className={classes.typo1}>
					{t("welcomeBack")}
				</Typography>
				{location?.state?.passwordReset && (
					<Alert severity="success">Password has been reset</Alert>
				)}

				<Button
					variant="outlined"
					startIcon={<Google className={classes.icon} />}
					onClick={() =>
						isLoading
							? null
							: context.registerViaAuthProvider(
									new GoogleAuthProvider()
							  )
					}
					className={classes.button}
				>
					{t("signInWithGoogle")}
				</Button>

				<Typography className={classes.typo}>{t("or")}</Typography>

				<Formik
					initialValues={initialValues}
					validateOnMount
					validate={(values) => {
						const errors: FormikErrors<FormValues> = {};
						if (!values.email) {
							errors.email = "Email is Required";
						} else if (
							!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
								values.email
							)
						) {
							errors.email = "Invalid email address";
						} else if (!values.password) {
							errors.password = "Password is Required";
						}
						return errors;
					}}
					onSubmit={handleSubmit}
				>
					{({ values, errors, touched, handleChange, isValid }) => (
						<Form className={classes.form}>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<BriaInput
									id="email"
									label="Email"
									onChange={handleChange}
									error={
										(Boolean(errors.email) &&
											touched.email) as boolean
									}
									helperText={errors.email}
									className={classes.textField}
								/>
								<BriaInput
									id="password"
									label="Password"
									type="password"
									autoComplete="current-password"
									onChange={handleChange}
									error={
										(Boolean(errors.password) &&
											touched.password) as boolean
									}
									helperText={errors.password}
									className={classes.textField}
								/>

								{errorMessage && (
									<Typography
										className={classes.error}
										color="secondary"
									>
										<ErrorIcon
											className={classes.errorIcon}
										/>
										{t(`${errorMessage}`)}
									</Typography>
								)}

								<Box
									display="flex"
									alignItems="center"
									justifyContent="space-between"
									width={440}
									height={17}
									mt="2px"
									mb={3}
								>
									<Box ml="18px">
										<BriaCheckbox
											style={{ display: "inline-block" }}
										/>
										<Typography className={classes.typo3}>
											Remember me
										</Typography>
									</Box>
									<Link
										to={RouterConstants.FORGOTPASSWORD}
										className={classes.link}
									>
										Forgot your password?
									</Link>
								</Box>
								<Button
									type="submit"
									variant="contained"
									color="secondary"
									disabled={!isValid}
									className={classes.button}
								>
									{t("signIn")}
								</Button>
							</Box>
						</Form>
					)}
				</Formik>
			</Box>

			<Typography className={classes.typo2}>
				{t("isThisYourFirstTime?")}
				<Link
					to={{
						pathname: redirect
							? RouterConstants.SIGNUP
							: RouterConstants.SIGNUPVIEWERS,
						search: window.location.search,
					}}
					className={classes.signup}
				>
					{t("signUp")}
				</Link>
			</Typography>
		</Layout>
	);
};

const useStyles = makeStyles((theme) => ({
	form: {
		width: "100%",
		height: 207,
		marginTop: 12,
	},
	button: {
		borderRadius: 20,
		width: "100%",
		maxWidth: 440,
		height: 40,
		marginTop: theme.spacing(1),
	},
	icon: {
		fontSize: 20,
	},
	signup: {
		color: theme.palette.primary.light,
		textDecoration: "none",
	},
	typo: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.text.primary,
		opacity: 0.5,
		marginTop: 26,
		marginBottom: 10,
	},
	typo1: {
		fontSize: 34,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.text.primary,
		marginBottom: theme.spacing(1),
	},
	typo2: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.primary,
	},
	typo3: {
		fontSize: 14,
		color: "#979797",
		display: "inline-block",
		marginLeft: -9,
	},
	link: {
		color: theme.palette.primary.light,
		opacity: 0.5,
		textDecoration: "none",
		marginRight: 22,
	},
	textField: {
		marginBottom: theme.spacing(3),
		width: "100%",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			minWidth: "unset",
		},
	},
	error: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightBold,
		maxWidth: 440,
		marginBottom: 15,
		marginLeft: 12,
	},
	errorIcon: {
		fontSize: 16,
		marginRight: 4,
		verticalAlign: "middle",
	},
}));

export default SignIn;
