import { WebOperations } from "../types/graphql-global-types";

export default class Metric implements WebOperations {
	duration: number;
	operationName: string;
	tags?: string | null;
	constructor(duration: number, operationName: string, tags?: string | null) {
		this.duration = duration;
		this.operationName = operationName;
		this.tags = tags;
	}
}
