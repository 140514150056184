import React from "react";
import Box, { BoxProps } from "@material-ui/core/Box";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "inline-block",
		cursor: "pointer",
		height: "10px",
		width: "10px",
		backgroundColor: "white",
		border: "3px solid white",
		boxShadow: "0px 0px 0px 7px #7400FA",
		borderRadius: "50%",
	},
	active: {
		border: "none",
		display: "inline-block",
		cursor: "pointer",
		height: "10px",
		width: "10px",
		background: "#7400FA",
		boxShadow: "0px 0px 0px 7px #7400FA",
		borderRadius: "50%",
	},
}));
interface IProps extends BoxProps {
	active: boolean;
}
function ObjectPoint({ active, ...props }: IProps) {
	const classes = useStyles();
	return (
		<Box
			className={clsx(classes.root, { [classes.active]: active })}
			position="absolute"
			{...props}
		>
			{/* <Box className={clsx()}></Box> */}
		</Box>
	);
}

export default ObjectPoint;
