import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const FullscreenIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 24 24" {...props}>
			<circle cx="12" cy="12" r="12" fill="white" opacity="0.8" />
			<path
				fill="none"
				d="M14.6666 5.59961H18.4M18.4 5.59961V9.33294M18.4 5.59961L14.1333 9.86628M5.59998 14.6663V18.3996M5.59998 18.3996H9.33331M5.59998 18.3996L9.86664 14.1329"
				stroke="#5F5F5F"
			/>
		</SvgIcon>
	);
};

export default FullscreenIcon;
