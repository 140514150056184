import { withStyles } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";

const StyledMenu = withStyles({
	paper: {
		borderRadius: 2,
		width: 120,
		minHeight: 95,
		// maxHeight: 151,
	},
})(Menu);

export default StyledMenu;
