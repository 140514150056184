import client from "./client";
import { ApolloClient } from "@apollo/client";
import { NormalizedCacheObject } from "@apollo/client/cache";
import gql from "graphql-tag";
import {
	deleteMongoItem,
	deleteMongoItemVariables,
} from "./types/deleteMongoItem";
import { deleteByPath, deleteByPathVariables } from "./types/deleteByPath";
import { renameFolder, renameFolderVariables } from "./types/renameFolder";
import { moveImages, moveImagesVariables } from "./types/moveImages";
import { addLabels, addLabelsVariables } from "./types/addLabels";
import {
	uploadImageToVdr,
	uploadImageToVdrVariables,
} from "./types/uploadImageToVdr";
import {
	uploadImageToResearch,
	uploadImageToResearchVariables,
} from "./types/uploadImageToResearch";
import {
	runGcpInstanceCommand,
	runGcpInstanceCommandVariables,
} from "./types/runGcpInstanceCommand";
import {
	runAwsInstanceCommand,
	runAwsInstanceCommandVariables,
} from "./types/runAwsInstanceCommand";
import {
	DBName,
	GCPInstanceAction,
	AWSInstanceAction,
	VDRUploadParameters,
	WebOperations,
} from "../types/graphql-global-types";
import {
	sendMetricsToGrafanaVariables,
	sendMetricsToGrafana_sendMetrics,
} from "./types/sendMetricsToGrafana";
import {
	updateImageScore,
	updateImageScoreVariables,
} from "./types/updateImageScore";
import {
	createExtremeExpression,
	createExtremeExpressionVariables,
} from "../Pages/Tool/types/createExtremeExpression";

export async function deleteMongoImage(
	dbName: DBName,
	mongoId: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<
			deleteMongoItem,
			deleteMongoItemVariables
		>({
			query: gql`
				mutation deleteMongoItem($dbName: DBName!, $mongoId: String!) {
					deleteMongoItem(mongoId: $mongoId, dbName: $dbName) {
						ok
					}
				}
			`,
			variables: {
				dbName: dbName,
				mongoId: mongoId,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function deleteFolder(
	dbName: DBName,
	path: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<
			deleteByPath,
			deleteByPathVariables
		>({
			query: gql`
				mutation deleteByPath($dbName: DBName!, $path: String!) {
					deleteByPath(path: $path, dbName: $dbName) {
						ok
					}
				}
			`,
			variables: {
				dbName: dbName,
				path: path,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function changeFoldername(
	dbName: DBName,
	curFolderPath: string,
	newFolderName: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<string> {
	try {
		const res = await localClient.query<
			renameFolder,
			renameFolderVariables
		>({
			query: gql`
				mutation renameFolder(
					$dbName: DBName!
					$curFolderPath: String!
					$newFolderName: String!
				) {
					renameFolder(
						curFolderPath: $curFolderPath
						dbName: $dbName
						newFolderName: $newFolderName
					) {
						newFolderPath
					}
				}
			`,
			variables: {
				dbName: dbName,
				curFolderPath: curFolderPath,
				newFolderName: newFolderName,
			},
		});
		if (
			res.data.renameFolder?.newFolderPath === undefined ||
			res.data.renameFolder?.newFolderPath === null
		) {
			alert("Renaming folder has failed!");
			return Promise.reject();
		}
		return Promise.resolve(res.data.renameFolder?.newFolderPath);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function moveImagesToFolder(
	dbName: DBName,
	mongoIds: string[],
	newPath: string,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<moveImages, moveImagesVariables>({
			query: gql`
				mutation moveImages(
					$dbName: DBName!
					$mongoIds: [String!]!
					$newPath: String!
				) {
					moveImages(
						mongoIds: $mongoIds
						dbName: $dbName
						newPath: $newPath
					) {
						ok
					}
				}
			`,
			variables: {
				dbName: dbName,
				mongoIds: mongoIds,
				newPath: newPath,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function addLabelsToImages(
	dbName: DBName,
	mongoIds: string[],
	labels: string[],
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<addLabels, addLabelsVariables>({
			query: gql`
				mutation addLabels(
					$dbName: DBName!
					$mongoIds: [String!]!
					$labels: [String!]!
				) {
					addLabels(
						mongoIds: $mongoIds
						dbName: $dbName
						labels: $labels
					) {
						ok
					}
				}
			`,
			variables: {
				dbName: dbName,
				mongoIds: mongoIds,
				labels: labels,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function runGCPInstanceCommand(
	instanceNames: string[],
	action: GCPInstanceAction,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<
			runGcpInstanceCommand,
			runGcpInstanceCommandVariables
		>({
			query: gql`
				mutation runGcpInstanceCommand(
					$instanceNames: [String!]!
					$action: GCPInstanceAction!
				) {
					runGcpInstanceCommand(
						instanceNames: $instanceNames
						action: $action
					) {
						ok
					}
				}
			`,
			variables: {
				instanceNames: instanceNames,
				action: action,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function runAWSInstanceCommand(
	instanceIds: string[],
	action: AWSInstanceAction,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<
			runAwsInstanceCommand,
			runAwsInstanceCommandVariables
		>({
			query: gql`
				mutation runAwsInstanceCommand(
					$instanceIds: [String!]!
					$action: AWSInstanceAction!
				) {
					runAwsInstanceCommand(
						instanceIds: $instanceIds
						action: $action
					) {
						ok
					}
				}
			`,
			variables: {
				instanceIds: instanceIds,
				action: action,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export const UPLOAD_IMAGE_TO_GALLERY = gql`
	mutation uploadImageToGallery(
		$filePath: String!
		$uploadParameters: VDRUploadParameters!
	) {
		uploadImageToGallery(
			filePath: $filePath
			uploadParameters: $uploadParameters
		) {
			visualHash
		}
	}
`;

export async function addImageToVDR(
	binaryStr: string,
	uploadParameters: VDRUploadParameters,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<
			uploadImageToVdr,
			uploadImageToVdrVariables
		>({
			query: gql`
				mutation uploadImageToVdr(
					$file: Upload!
					$uploadParameters: VDRUploadParameters!
				) {
					uploadImageToVdr(
						file: $file
						uploadParameters: $uploadParameters
					) {
						ok
					}
				}
			`,
			variables: {
				file: { base_64_string: binaryStr },
				uploadParameters: uploadParameters,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export async function addImageToResearch(
	binaryStr: string,
	folder: string,
	subfolder: string,
	project: string,
	source: string,
	labels: string[] | null,
	displayName: string | null,
	localClient: ApolloClient<NormalizedCacheObject> = client
): Promise<boolean> {
	try {
		const res = await localClient.query<
			uploadImageToResearch,
			uploadImageToResearchVariables
		>({
			query: gql`
				mutation uploadImageToResearch(
					$file: Upload!
					$displayName: String
					$labels: [String!]
					$folder: String!
					$subfolder: String!
					$project: String!
					$source: String!
				) {
					uploadImageToResearch(
						file: $file
						displayName: $displayName
						labels: $labels
						folder: $folder
						subfolder: $subfolder
						project: $project
						source: $source
					) {
						ok
					}
				}
			`,
			variables: {
				file: { base_64_string: binaryStr },
				labels: labels,
				folder: folder,
				subfolder: subfolder,
				project: project,
				source: source,
				displayName: displayName,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
}

export const ADD_SEMANTICS = gql`
	mutation AddSemantics(
		$ethnicity: String!
		$ethnicityValue: Float!
		$uid: String!
		$rects: [Rect!]
		$semantics: [ImageSemantic!]
		$pipelineSettings: [PipelineSetting!]
		$visualHash: String!
		$inputImage: String
	) {
		addSemantics(
			ethnicity: $ethnicity
			ethnicityValue: $ethnicityValue
			uid: $uid
			rects: $rects
			inputImage: $inputImage
			semantics: $semantics
			pipelineSettings: $pipelineSettings
			visualHash: $visualHash
		) {
			newImage {
				url
				imageNoBgUrl
			}
			layersUrl
		}
	}
`;

export const ADD_OBJECT_TYPE_SEMANTICS = gql`
	mutation ChangeObjectTypeSemantics(
		$ethnicity: String!
		$ethnicityValue: Float!
		$uid: String!
		$rects: [Rect!]
		$semantics: [ImageSemantic!]
		$pipelineSettings: [PipelineSetting!]
		$visualHash: String!
		$inputImage: String
		$objectType: String
	) {
		objectTypeSemantics(
			ethnicity: $ethnicity
			ethnicityValue: $ethnicityValue
			uid: $uid
			rects: $rects
			inputImage: $inputImage
			semantics: $semantics
			pipelineSettings: $pipelineSettings
			visualHash: $visualHash
			objectType: $objectType
		) {
			newImage {
				url
			}
			layersUrl
		}
	}
`;

export const ADD_SEMANTIC_COMBINATION = gql`
	mutation AddSemanticCombination(
		$ethnicity: String!
		$ethnicityValue: Float!
		$uid: String!
		$rects: [Rect!]
		$pipelineSettings: [PipelineSetting!]
		$visualHash: String!
		$thumbnails: [ThumbnailData]
		$scaleDownFactor: Int!
	) {
		addSemanticCombination(
			thumbnails: $thumbnails
			scaleDownFactor: $scaleDownFactor
			ethnicity: $ethnicity
			ethnicityValue: $ethnicityValue
			uid: $uid
			rects: $rects
			pipelineSettings: $pipelineSettings
			visualHash: $visualHash
		) {
			thumbnailImages
		}
	}
`;

export const CREATE_LIVELINESS_FACE = gql`
	mutation CreateLivelinessFace(
		$visualHash: String!
		$videoInfo: VideoInfoParameters!
		$face: Rect!
		$imageURL: String!
	) {
		createLiveliness(
			vhash: $visualHash
			videoInfo: $videoInfo
			face: $face
			imageURL: $imageURL
		) {
			videoUrl
		}
	}
`;

export const CREATE_LIVE_BACKGROUND = gql`
	mutation CreateLiveBackground(
		$vhash: String!
		$fileNameInBucket: String!
		$imgUrl: String!
		$config: Config!
	) {
		createLiveBackground(
			vhash: $vhash
			config: $config
			fileNameInBucket: $fileNameInBucket
			imgUrl: $imgUrl
		) {
			videoUrl
		}
	}
`;

export const CHANGE_ETHNICITY = gql`
	mutation ChangeEthnicity(
		$ethnicity: String!
		$ethnicityValue: Float!
		$uid: String!
		$rects: [Rect!]
		$semantics: [ImageSemantic!]
		$pipelineSettings: [PipelineSetting!]
		$visualHash: String!
		$inputImage: String
	) {
		chnageEthnicity(
			ethnicity: $ethnicity
			ethnicityValue: $ethnicityValue
			uid: $uid
			rects: $rects
			inputImage: $inputImage
			semantics: $semantics
			pipelineSettings: $pipelineSettings
			visualHash: $visualHash
		) {
			newImage {
				url
				imageNoBgUrl
			}
			layersUrl
		}
	}
`;

export const sendMetricsToGrafana = async (metricList: WebOperations[]) => {
	try {
		await client.query<
			sendMetricsToGrafana_sendMetrics,
			sendMetricsToGrafanaVariables
		>({
			query: gql`
				mutation sendMetricsToGrafana($metricList: [WebOperations]) {
					sendMetrics(metricList: $metricList) {
						tracked
					}
				}
			`,
			variables: {
				metricList: metricList,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const updateImageScoreByvHash = async (vHash: string, score: number) => {
	try {
		await client.query<updateImageScore, updateImageScoreVariables>({
			query: gql`
				mutation updateImageScore($vHash: String!, $score: Int!) {
					updateImageScore(vHash: $vHash, score: $score) {
						ok
					}
				}
			`,
			variables: {
				vHash: vHash,
				score: score,
			},
		});
		return Promise.resolve(true);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const CREATE_EXTREME_EXPRESSIONS = gql`
	mutation CreateExtremeExpression(
		$ethnicity: String!
		$ethnicityValue: Float
		$inputImage: String
		$objectType: String
		$pipelineSettings: [PipelineSetting]
		$rects: [Rect]
		$semantics: [ImageSemantic]
		$uid: String!
		$visualHash: String!
		$emotion: String!
		$sliderScale: Float!
	) {
		addExtremeExpression(
			ethnicity: $ethnicity
			ethnicityValue: $ethnicityValue
			objectType: $objectType
			semantics: $semantics
			inputImage: $inputImage
			rects: $rects
			uid: $uid
			visualHash: $visualHash
			pipelineSettings: $pipelineSettings
			emotion: $emotion
			sliderScale: $sliderScale
		) {
			newImage {
				url
				imageNoBgUrl
			}
			layersUrl
		}
	}
`;
export const APPEND_ASK_ALAN_RECORD = gql`
	mutation AppendAskAlanRecord($list: [String]) {
		appendAskAlanRecord(list: $list) {
			ok
		}
	}
`;
