import { ThemeOptions } from "@material-ui/core/styles";

export const darkTheme: ThemeOptions = {
	palette: {
		type: "dark",
		common: {
			white: "#FFFFFF",
			black: "#495057",
		},
		primary: {
			light: "#D80067",
			main: "#8800FF",
			dark: "#5300C9",
		},
		secondary: {
			main: "#B80065",
		},
		text: {
			primary: "#FFFFFF",
			secondary: "#A6A6A6",
		},
		error: {
			main: "#DC3545",
		},
		warning: {
			main: "#FFC107",
		},
		success: {
			main: "#28A745",
		},
		background: {
			default:
				"radial-gradient(111.11% 111.11% at 50% 41.11%, #403748 21.88%, #07010C 100%)",
			paper: "#000000",
		},
		buttonTextColor: {
			main: "white",
		},
		startButtonBackground: {
			main: "green",
		},
		stopButtonBackground: {
			main: "orange",
		},
		menuItemBackground: {
			main: "#5300C980",
		},
		tools: {
			main: "#D80067",
		},
		cards: {
			main: "#D80067",
		},
		cardText: {
			main: "#FFFFFF",
		},
		cardTextSecondary: {
			main: "#A6A6A6",
		},
		uploadCardBackground: {
			main: "#2C2738",
		},
		textColor: {
			main: "#495057",
		},
		shadow: {
			main: "rgba(255, 255, 255, 0.75)",
		},
	},
	typography: {
		fontFamily: "Montserrat",
	},
	shape: {
		borderRadius: 10,
	},
};
