import React from "react";
import { CssBaseline, createMuiTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { lightTheme } from "../Constants/LightTheme";
import { darkTheme } from "../Constants/DarkTheme";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

interface BriaThemeInterface {
	isDarkMode: boolean;
	handleThemeChange: () => void;
}

const defaultValues: BriaThemeInterface = {
	isDarkMode: false,
	handleThemeChange: () => {},
};

const BriaThemeContext = React.createContext<BriaThemeInterface>(defaultValues);

export const useThemeContext = () => {
	const { isDarkMode, handleThemeChange } =
		React.useContext(BriaThemeContext);
	if (isDarkMode === undefined || !handleThemeChange)
		throw new Error(
			"useThemeContext should be used under BriaThemeContext"
		);
	return { isDarkMode, handleThemeChange };
};

const BriaThemeProvider = ({ children }: React.PropsWithChildren<{}>) => {
	const { i18n } = useTranslation();
	const [isDarkMode, setIsDarkMode] = React.useState<boolean>(() => {
		// const item = localStorage.getItem("isDarkMode");
		// return item ? JSON.parse(item) : false;
		return false;
	});

	const themeType = isDarkMode ? darkTheme : lightTheme;
	themeType.direction = i18n.dir();
	const theme = createMuiTheme(themeType);
	document.body.classList.toggle("dark", isDarkMode);
	document.body.classList.toggle("light", !isDarkMode);

	const handleThemeChange = () => {
		localStorage.setItem("isDarkMode", JSON.stringify(!isDarkMode));
		setIsDarkMode(!isDarkMode);
	};
	const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

	return (
		<BriaThemeContext.Provider value={{ isDarkMode, handleThemeChange }}>
			<ThemeProvider theme={theme}>
				<StylesProvider jss={jss}>
					<CssBaseline />
					{children}
				</StylesProvider>
			</ThemeProvider>
		</BriaThemeContext.Provider>
	);
};

export { BriaThemeProvider };
