import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const PreviousIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 22 42" {...props}>
			<path
				fill="none"
				d="M21 1L1.00002 21L21 41"
				stroke="#5300C9"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="bevel"
			/>
		</SvgIcon>
	);
};

export default PreviousIcon;
