import { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import RouterConstants from "../../Constants/RouterConstants";
import { ReactComponent as Logo } from "../../assets/bria-logo.svg";
import GalleryIcon from "../../assets/icons/GalleryIcon";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import UserMenu from "../UserMenu";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useTranslation } from "react-i18next";
import { ImageEditorData_metaData_settings } from "../../GraphQL/types/imageEditorData";
import Context from "../../Context/Context";
import InfoIcon from "@material-ui/icons/Info";
import { useQuery } from "@apollo/client";
import { GET_GPU_MACHINE_STATUS } from "../../GraphQL/queries";
import { GetGPUMachineStatus } from "../../GraphQL/types/GetGPUMachineStatus";
import BriaButton from "../UI/BriaButton";
import ContactUsIcon from "../../assets/icons/ContactUsIcon";
import LinksConst from "../../Constants/LinksConst";

const useStyles = makeStyles((theme: Theme) => ({
	actionsContainer: {
		flexGrow: 1,
	},
	appBar: {
		background: theme.palette.background.paper,
		borderBottom: `1px solid #E9ECEF`,
	},
	breadcrumbs: {
		marginLeft: theme.spacing(6),
		color: theme.palette.text.primary,
		fontSize: theme.typography.pxToRem(16),
	},
	gallery: {
		cursor: "pointer",
		color: theme.palette.text.primary,
		fontSize: theme.typography.pxToRem(16),
	},
	imageName: {
		color: theme.palette.text.primary,
		fontSize: theme.typography.pxToRem(16),
		fontWeight: theme.typography.fontWeightBold,
	},
	icon: {
		fontSize: 19,
		paddingRight: 6,
		paddingTop: 6,
	},
	beta: {
		fontSize: 13,
		color: theme.palette.cardTextSecondary.main,
		textTransform: "uppercase",
		fontWeight: 600,
		position: "absolute",
		bottom: 11,
		left: 92,
	},
	contactUsIcon: {
		fontSize: 23,
	},
	button: {
		padding: "5px 27px",
		fontWeight: theme.typography.fontWeightMedium,
	},
}));

interface IProps {
	imageName?: string;
	children?: JSX.Element;
	pipelineSettings?: ImageEditorData_metaData_settings[];
	showSettings?: boolean;
	handleChangePipelineSettings?: (index: number, value: boolean) => void;
}

function ToolHeader({
	children,
	imageName,
	pipelineSettings,
	handleChangePipelineSettings,
	showSettings,
}: IProps) {
	const history = useHistory();
	const { t } = useTranslation();
	const classes = useStyles();
	const context = useContext(Context);

	const { data: isMachineOn } = useQuery<GetGPUMachineStatus>(
		GET_GPU_MACHINE_STATUS,
		{
			pollInterval: 60 * 1000,
		}
	);

	return (
		<>
			<AppBar position="sticky" elevation={0} className={classes.appBar}>
				<Toolbar>
					<IconButton
						edge="start"
						aria-label="home page"
						disableRipple
						disableFocusRipple
						onClick={() => history.push(RouterConstants.BASE)}
						disabled={!context.user?.isAdmin()}
						style={{ marginLeft: 3 }}
					>
						<Logo height={50} />
						<Typography className={classes.beta}>Beta</Typography>
					</IconButton>
					{(context.user?.isAdmin() || context.user?.isViewer()) &&
						imageName && (
							<Breadcrumbs
								separator={
									<NavigateNextIcon fontSize="small" />
								}
								aria-label="breadcrumb"
								className={classes.breadcrumbs}
							>
								<Link
									onClick={() =>
										context.user?.isViewer()
											? history.push(
													RouterConstants.GALLERY
											  )
											: history.push(
													RouterConstants.TOOLS
											  )
									}
									className={classes.gallery}
								>
									<GalleryIcon className={classes.icon} />
									{t("myGallery")}
								</Link>
								<Typography className={classes.imageName}>
									{imageName}
								</Typography>
							</Breadcrumbs>
						)}

					<Box className={classes.actionsContainer}>{children}</Box>
					{context.user?.isViewer() && (
						<>
							<BriaButton
								variant="text"
								color="secondary"
								onClick={() => {
									window.open(LinksConst.BRIA_API);
								}}
								className={classes.button}
							>
								{t("BriaAPI")}
							</BriaButton>
							<BriaButton
								variant="outlined"
								color="secondary"
								startIcon={
									<ContactUsIcon
										className={classes.contactUsIcon}
									/>
								}
								onClick={() => {
									window.open(
										LinksConst.CONTACT_US_FORM_LINK
									);
								}}
								className={classes.button}
							>
								{t("contactUs")}
							</BriaButton>
						</>
					)}
					<UserMenu
						pipelineSettings={pipelineSettings}
						handleChangePipelineSettings={
							handleChangePipelineSettings
						}
						showSettings={showSettings}
					/>
				</Toolbar>
			</AppBar>
			{isMachineOn?.getGpuMachineStatus === false && (
				<Box
					height={50}
					bgcolor="black"
					color="white"
					padding={2}
					display="flex"
					alignItems="center"
					justifyContent="space-between"
				>
					<Box display="flex" flexDirection="row">
						<Box paddingRight={1}>
							<InfoIcon color="secondary" />
						</Box>
						<Typography color="inherit">
							Something went
							<Typography color="secondary" display="inline">
								<strong>wrong!</strong>
							</Typography>
						</Typography>
					</Box>

					<Box
						display="flex"
						flexDirection="row"
						justifyContent="center"
					>
						<Typography color="inherit">
							We are working hard to try fix this.
						</Typography>
					</Box>
				</Box>
			)}
		</>
	);
}

export default ToolHeader;
