import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
	root: {
		height: 200,
		margin: 15,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		background: theme.palette.uploadCardBackground.main,
		overflow: "hidden",
		position: "relative",
		cursor: "pointer",
		border: "3px solid transparent",
		borderRadius: 2,
		marginBottom: theme.spacing(3),
		boxSizing: "border-box",
		"&:hover": {
			border: `3px solid ${theme.palette.common.white}`,
			boxShadow: theme.shadows[8],
		},
	},
	innerCard: {
		width: "50%",
		height: 101,
		border: "1px dashed #495057",
		borderRadius: 2,
		background: theme.palette.common.white,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		textAlign: "center",
		flexDirection: "column",
		padding: 5,
	},
	addIcon: {
		width: 30,
		height: 27,
		fontSize: 30,
		color: theme.palette.common.black,
	},
	typo: {
		fontSize: 12,
		color: theme.palette.common.black,
	},
}));

interface IProps {
	onClick: () => void;
}

const UploadVersion: React.FC<IProps> = ({ onClick }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Box className={classes.root} onClick={onClick}>
			<Box className={classes.innerCard}>
				<Box>
					<Add className={classes.addIcon} />
				</Box>
				<Typography className={classes.typo}>
					{t("newVersion")}
				</Typography>
			</Box>
		</Box>
	);
};

export default UploadVersion;
