import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function BackGroundButton(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props} >
            <path d="M10 12.5C11.7259 12.5 13.125 11.1009 13.125 9.375C13.125 7.64911 11.7259 6.25 10 6.25C8.27411 6.25 6.875 7.64911 6.875 9.375C6.875 11.1009 8.27411 12.5 10 12.5Z" fill="none" stroke="inherit" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.875 3.75H3.125C2.77982 3.75 2.5 4.02982 2.5 4.375V15.625C2.5 15.9702 2.77982 16.25 3.125 16.25H16.875C17.2202 16.25 17.5 15.9702 17.5 15.625V4.375C17.5 4.02982 17.2202 3.75 16.875 3.75Z" fill="#979797" stroke="inherit" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.69531 16.2496C5.08349 15.153 5.80202 14.2035 6.75201 13.5321C7.702 12.8606 8.83676 12.5 10.0001 12.5C11.1635 12.5 12.2982 12.8606 13.2482 13.5321C14.1982 14.2035 14.9167 15.153 15.3049 16.2496" fill="white" stroke="inherit" />
            <path d="M4.69531 16.2496C5.08349 15.153 5.80202 14.2035 6.75201 13.5321C7.702 12.8606 8.83676 12.5 10.0001 12.5C11.1635 12.5 12.2982 12.8606 13.2482 13.5321C14.1982 14.2035 14.9167 15.153 15.3049 16.2496" fill="white" stroke="inherit" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.125 12.25C11.8509 12.25 13.25 10.8509 13.25 9.125C13.25 7.39911 11.8509 6 10.125 6C8.39911 6 7 7.39911 7 9.125C7 10.8509 8.39911 12.25 10.125 12.25Z" fill="white" stroke="inherit" stroke-linecap="round" stroke-linejoin="round" />
        </SvgIcon>
    );
}

export default BackGroundButton;
