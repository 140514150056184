import React, { ReactElement } from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import { isDevelopment } from "../Config/Env";
import app from "../Config/Firebase";

const myIframe: ReactElement = React.createElement("iframe");
const myURL = isDevelopment
	? "http://localhost:5000/graphql/"
	: "https://labs.bria.ai:5000/graphql/";

async function asyncPopulate() {
	const authorization: string =
		"eyJhbGciOiJSUzI1NiIsImtpZCI6ImFiMGNiMTk5Zjg3MGYyOGUyOTg5YWI0ODFjYzJlNDdlMGUyY2MxOWQiLCJ0eXAiOiJKV1QifQ.eyJuYW1lIjoiUm9ueSBMZXZ5IiwicGljdHVyZSI6Imh0dHBzOi8vbGg1Lmdvb2dsZXVzZXJjb250ZW50LmNvbS8tWTd0aWE2aFJMMXMvQUFBQUFBQUFBQUkvQUFBQUFBQUFBQUEvQU1adXVjbHhRUlpCOEk0WEFYWFNMUnJVUjMyXzN5RHBjdy9zOTYtYy9waG90by5qcGciLCJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vaW1hZ2Utdmlld2VyLTEyMGNiIiwiYXVkIjoiaW1hZ2Utdmlld2VyLTEyMGNiIiwiYXV0aF90aW1lIjoxNjE2NjYyMzUwLCJ1c2VyX2lkIjoiak5RWUQ4dXRIamUyVXhKMHhMc08wVUQyeXp0MSIsInN1YiI6ImpOUVlEOHV0SGplMlV4SjB4THNPMFVEMnl6dDEiLCJpYXQiOjE2MjI5NzY4OTgsImV4cCI6MTYyMjk4MDQ5OCwiZW1haWwiOiJyb255QGJyaWEuYWkiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiZmlyZWJhc2UiOnsiaWRlbnRpdGllcyI6eyJnb29nbGUuY29tIjpbIjEwMjMwMTgwMDc2OTk5NDIxNDgyNCJdLCJlbWFpbCI6WyJyb255QGJyaWEuYWkiXX0sInNpZ25faW5fcHJvdmlkZXIiOiJnb29nbGUuY29tIn19.QWiu_jSFwO5stQAOFQow3FJpPBbI7Sg8fjYJfaLW7khX5z2TrMZ-QqS40IkyzYIIYOGZYmEYeZxkfo0eS0TJ3fpzNuDhGz8olRYQ45TVBL7HojeBoDbggQaihC7X3x54clPTexWqI_B70WgcZr1neElqAuAn5fj8MmB3WrX_j_nH746tdB-lcTj5Xgy3TiziHp82V3TUxZNs5sY5TNoNGP0xok3WLNtFyh9Ur18unsteDJDQ-dkaPnjYNvrUiBhW2yBYGv8VbiSRXQEKTgGb7WV3aqoFgp0hXRueXj2RDFIib9epZStQIAPmC_S2MRx_l1-5sodaPSLvD0JpY4qUqA";
	const clientUid = localStorage.getItem("client_uid") || "";
	populateIframe(myIframe, myURL, [["Authorization", authorization]]);
}

// asyncPopulate()

function populateIframe(iframe: any, url: string, headers: [[string, any]]) {
	function handler(this: XMLHttpRequest) {
		if (this.readyState === this.DONE) {
			if (this.status === 200) {
				iframe.src = URL.createObjectURL(this.response);
			} else {
				console.error("Request failed", this);
			}
		}
	}

	var xhr = new XMLHttpRequest();
	xhr.open("GET", url);
	xhr.onreadystatechange = handler;
	xhr.responseType = "blob";
	headers.forEach(function (header) {
		xhr.setRequestHeader(header[0], header[1]);
	});
	xhr.send();
}

class GraphQlPlayground extends React.Component<any, any> {
	public render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				{/* {myIframe} */}
				<iframe
					title={"graphQL"}
					width="100%"
					height="100%"
					src={
						isDevelopment
							? "http://localhost:5000/graphql/"
							: "https://labs.bria.ai:5000/graphql/"
					}
				/>
			</div>
		);
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: theme.palette.background.paper,
			height: "100vh",
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "space-around",
			overflow: "hidden",
		},
	});

export default withStyles(styles)(GraphQlPlayground);
