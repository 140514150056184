import React from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import SlidersIcon from "../../assets/icons/SlidersIcon";
import ThumbnailsIcon from "../../assets/icons/ThumbnailsIcon";
import clsx from "clsx";
import { ToolsBackGroundMode, ToolsViewerMode } from "../Tools/utilities";
import Tooltip from "./Tooltip";
import BackGroundButton from "../../assets/icons/BackGroundButton";
import RemoveBackGroundButton from "../../assets/icons/RemoveBackGroundButton";

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.common.white,
		width: 60,
		height: 30,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(4),
		borderRadius: 25,
		overflow: "hidden",
	},
	toggleGroup: {
		height: "100%",
		width: "100%",
		padding: 1,
	},
	toggleButton: {
		height: "100%",
		width: "50%",
		stroke: "#A4A4A4",
		borderRadius: 15,
	},
	icon1: {
		marginLeft: 3,
		fontSize: 22,
	},
	icon2: {
		marginRight: 6,
		fontSize: 22,
	},
	activeToggleButton: {
		backgroundColor: `${theme.palette.primary.dark} !important`,
		stroke: "white",
		"&:disabled": {
			backgroundColor: `${theme.palette.grey[400]} !important`,
		},
	},
}));

interface IProps {
	selectedStyle: ToolsBackGroundMode;
	onStyleChange: (style: ToolsBackGroundMode) => void;
	disabled: boolean;
}

const RemoveBackGroundSwitch: React.FC<IProps> = ({
	selectedStyle,
	onStyleChange,
	disabled,
}) => {
	const classes = useStyles();

	const handleStyle = (
		event: React.MouseEvent<HTMLElement>,
		newStyle: ToolsBackGroundMode
	) => {
		if (newStyle !== null) {
			onStyleChange(newStyle);
		}
	};

	return (
		<Box className={classes.root}>
			<ToggleButtonGroup
				className={classes.toggleGroup}
				value={selectedStyle}
				exclusive
				onChange={handleStyle}
			>
				<Tooltip title="With Background" arrow>
					<ToggleButton
						className={clsx({
							[classes.toggleButton]: true,
							[classes.activeToggleButton]:
								selectedStyle === ToolsBackGroundMode.withBg,
						})}
						disabled={disabled}
						value={ToolsBackGroundMode.withBg}
					>
						<RemoveBackGroundButton className={classes.icon1} />
					</ToggleButton>
				</Tooltip>
				<Tooltip title="Without Background" arrow>
					<ToggleButton
						className={clsx({
							[classes.toggleButton]: true,
							[classes.activeToggleButton]:
								selectedStyle === ToolsBackGroundMode.noBg,
						})}
						value={ToolsBackGroundMode.noBg}
						disabled={disabled}
					>
						<BackGroundButton className={classes.icon2} />
					</ToggleButton>
				</Tooltip>
			</ToggleButtonGroup>
		</Box>
	);
};

export default RemoveBackGroundSwitch;
