import React from "react";
import { useTranslation } from "react-i18next";
import { Redirect, useLocation } from "react-router-dom";
import ResetPasswordPage from "./ResetPasswordPage";
import FirebaseConst from "../Constants/FirebaseConst";
import VerifyingPage from "./VerifyingPage";
import RouterConstants from "../Constants/RouterConstants";

interface IProps {}

const AuthPage: React.FC<IProps> = () => {
	const { t } = useTranslation();
	const search = useLocation().search;
	const action = new URLSearchParams(search).get("mode");

	if (action === FirebaseConst.RESET_PASSWORD) {
		return <ResetPasswordPage />;
	} else if (action === FirebaseConst.VERIFY_ACCOUNT) {
		return <VerifyingPage />;
	}

	return <Redirect to={RouterConstants.BASE} />;
};

export default AuthPage;
