class RouterConstants {
	public static readonly BASE: string = "/";
	public static readonly TOOLS: string = "/tools/";
	public static readonly GALLERY: string = "/gallery/";
	public static readonly INSTANCES: string = "/instances/";
	public static readonly DEMO: string = "/demo/";
	public static readonly DATASETS: string = "/datasets/";
	public static readonly RESEARCH: string = "/research/";
	public static readonly BOARD: string = "/tensor-board/";
	public static readonly GRAPHANA: string = "/graphana/";
	public static readonly USERS: string = "/users/";
	public static readonly LOGIN: string = "/login/";
	public static readonly SIGNUP: string = "/sign-up/";
	public static readonly SIGNUPVIEWERS: string = "/sign-up-page/";
	public static readonly FORGOTPASSWORD: string = "/forgot-password/";
	public static readonly RESETPASSWORD: string = "/reset-password/";
	public static readonly PENDING: string = "/pending/";
	public static readonly VERIFY: string = "/verify-user/";
	public static readonly VERIFYING: string = "/verifying/";
	public static readonly AUTH: string = "/auth/";
	public static readonly SETTINGS: string = "/settings/";
	public static readonly VDR: string = "/vdr/";
	public static readonly GRAPHQLPLAYGROUND: string = "/graphqlplayground/";
	public static readonly BRIAPI: string = "/briapi/";
	public static readonly ORGANIZATION: string = "/organization/";
	public static readonly TERMS_OF_USE: string = "/terms-of-service/";
}

export default RouterConstants;
