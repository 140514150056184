import React from 'react';
import i18n from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { isDevelopment } from '../Config/Env';

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'ar', 'fr', 'he'],
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
    },
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
    },
    debug: isDevelopment,
  });

function I18nProvider({ children }: React.PropsWithChildren<React.ReactNode>) {
  i18n.on('languageChanged', function (lng) {
    document.body.dir = i18n.dir();
  });

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

export default I18nProvider;
