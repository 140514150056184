import React, { useRef, useState, ReactElement } from "react";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import clsx from "clsx";

import useOutsideClick from "../../hooks/useOutsideClick";
import { ToolsThumbnails } from "../../Models/ToolsConfig";
import { Skeleton } from "@material-ui/lab";
import CustomizedTooltip from "./Tooltip";
import ErrorIcon from "@material-ui/icons/Error";
const thumbnailsSize = 120;
const useStyles = makeStyles((theme) => ({
	root: {
		width: thumbnailsSize,
		margin: `auto ${theme.spacing(1)}px`,
		background: "transparent",
		position: "relative",
		marginBottom: theme.spacing(4),
		"&:hover $moreIconWrapper": {
			display: "flex",
		},
	},
	container: {
		width: ({ expanded }: any) =>
			expanded ? thumbnailsSize : thumbnailsSize + 10,
		height: ({ expanded }: any) =>
			expanded ? thumbnailsSize : thumbnailsSize + 10,
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		border: ({ expanded }: any) =>
			expanded ? "none" : "5px solid transparent",
		overflow: "hidden",
		"&:hover": {
			border: ({ expanded }: any) =>
				expanded ? "none" : "5px solid #FFF",
			boxShadow: ({ expanded }: any) =>
				expanded ? theme.shadows[0] : theme.shadows[8],
		},
	},
	shadowedWrapper: {
		"&:before": {
			content: "''",
			position: "absolute",
			width: "100%",
			height: "25px",
			background:
				"radial-gradient(50% 50% at 50.07% 50%, rgba(162, 162, 162, 0.97) 0%, rgba(245, 245, 245, 0) 100%)",
			left: "50%",
			bottom: " -40px",
			zIndex: -1,
			transform: "translate(-50%,-50%)",
		},
	},
	title: {
		fontSize: 14,
		color: ({ expanded }: any) =>
			expanded ? theme.palette.tools.main : theme.palette.text.primary,
		position: ({ expanded }: any) => (expanded ? "absolute" : "relative"),
		top: ({ expanded }: any) => (expanded ? 13 : 0),
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
	},
	imgWrapper: {
		width: thumbnailsSize,
		height: thumbnailsSize,
		borderRadius: theme.shape.borderRadius,
		overflow: "hidden",
		margin: 0,
	},
	img: {
		width: "100%",
		height: "100%",
		margin: 0,
		padding: 0,
		cursor: "pointer",
		borderRadius: theme.shape.borderRadius,
	},
	slidersWrapper: {
		paddingTop: "5px",
	},
	moreIconWrapper: {
		color: ({ expanded }: any) =>
			!expanded ? theme.palette.cards.main : theme.palette.grey[700],
		zIndex: theme.zIndex.modal,
		position: "absolute",
		cursor: "pointer",
		backgroundColor: theme.palette.common.white,
		opacity: 0.7,
		display: ({ expanded }: any) => (expanded ? "flex" : "none"),
		borderRadius: "50%",
		height: "30px",
		width: "30px",
		justifyContent: "center",
		alignItems: "center",
		top: ({ expanded }: any) => (expanded ? 10 : theme.spacing(4.5)),
		right: ({ expanded }: any) => (expanded ? theme.spacing(1) : 0),
		"&:hover": {
			backgroundColor: theme.palette.common.white,
			opacity: 1,
		},
	},
	expandedImage: {
		width: 180,
		height: 180,
		margin: 10,
		marginTop: 50,
		padding: 30,
		borderRadius: theme.shape.borderRadius,
		background: "rgba(233, 236, 239, 0.35)",
	},
}));

interface IProps {
	thumbnail: ToolsThumbnails;
	onClick?: () => void;
	children: ReactElement;
	onAddSemanticCombination: (
		operationVariables: any
	) => Promise<string> | string;
	personalizerValues?: { [key: string]: number };
	warrning?: boolean;
	error?: boolean;
}

const ThumbnailCardNew: React.FC<IProps> = ({
	thumbnail,
	children,
	onClick,
	onAddSemanticCombination,
	personalizerValues,
	warrning,
	error,
}) => {
	const controllerRef = useRef(new window.AbortController());
	const containerRef = useRef(null);
	const [expanded, setExpanded] = useState<boolean>(false);
	const ref = React.useRef<HTMLDivElement>(null);
	const [url, setURL] = useState<string>("");

	async function getImageURL() {
		controllerRef.current && controllerRef.current.abort();
		controllerRef.current = new window.AbortController();
		if (thumbnail) {
			const operationVariables = {
				context: {
					fetchOptions: {
						signal: controllerRef.current.signal,
					},
				},
			};
			let imageURL = await onAddSemanticCombination?.(operationVariables);
			setURL(imageURL ?? "");
		}
	}

	React.useEffect(() => {
		getImageURL();
		return () => {
			controllerRef.current && controllerRef.current.abort();
		};
	}, [thumbnail, personalizerValues]);

	const classes = useStyles({
		expanded,
	});

	useOutsideClick(ref, () => {
		if (expanded) {
			setExpanded(false);
		}
	});
	const toggleExpanded = (event: any) => {
		setExpanded(!expanded);
	};

	const handleClose = () => {
		setExpanded(false);
	};

	const thumbnailContainer = (
		<>
			<Box
				className={clsx(classes.moreIconWrapper)}
				onClick={toggleExpanded}
			>
				{expanded ? <CloseIcon fontSize="small" /> : <MoreHorizIcon />}
			</Box>

			<Box display="flex" justifyContent="center">
				<Typography
					className={classes.title}
					gutterBottom
					align="center"
					variant="subtitle2"
				>
					{thumbnail.title}
				</Typography>
			</Box>
			<Box className={classes.container}>
				<img
					src={url ?? ""}
					className={classes.img}
					onClick={() => {
						onClick && onClick();
					}}
					alt={thumbnail.title}
				/>
				{warrning && (
					<CustomizedTooltip
						arrow
						title={`Lower accuracy is expected.`}
						placement="bottom-start"
					>
						<ErrorIcon
							fontSize="small"
							style={{
								position: "absolute",
								bottom: 7,
								left: 7,
								color: error ? "#D80067" : "#FFC107",
							}}
						/>
					</CustomizedTooltip>
				)}
			</Box>
		</>
	);

	if (url === "") {
		return (
			<Box
				display="flex"
				justifyContent="center"
				my={2}
				className={classes.root}
			>
				<Skeleton variant="rect" width={120} height={120} />
			</Box>
		);
	}

	return (
		<>
			<Paper
				className={clsx(
					classes.root,
					expanded && classes.shadowedWrapper
				)}
				elevation={0}
				ref={containerRef}
			>
				{thumbnailContainer}
				<Popover
					open={expanded}
					anchorEl={containerRef.current}
					onClose={handleClose}
					TransitionComponent={Collapse}
					PaperProps={{
						elevation: 1,
					}}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					style={{ marginLeft: 5 }}
				>
					<Box
						className={clsx(classes.moreIconWrapper)}
						onClick={toggleExpanded}
					>
						{expanded ? (
							<CloseIcon fontSize="small" />
						) : (
							<MoreHorizIcon />
						)}
					</Box>

					<Box
						className="thumbnail-popover"
						width={thumbnailsSize + 80}
					>
						<Box className={classes.expandedImage}>
							{thumbnailContainer}
						</Box>
						<Box p="10px">{children}</Box>
					</Box>
				</Popover>
			</Paper>
		</>
	);
};

export default ThumbnailCardNew;
