import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const HappyIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.7578 19.4756C8.43717 19.4756 6.21157 18.5537 4.57063 16.9128C2.92969 15.2718 2.00781 13.0462 2.00781 10.7256C2.00781 8.40494 2.92969 6.17934 4.57063 4.5384C6.21157 2.89746 8.43717 1.97559 10.7578 1.97559C13.0785 1.97559 15.3041 2.89746 16.945 4.5384C18.5859 6.17934 19.5078 8.40494 19.5078 10.7256C19.5078 13.0462 18.5859 15.2718 16.945 16.9128C15.3041 18.5537 13.0785 19.4756 10.7578 19.4756ZM10.7578 20.7256C8.10565 20.7256 5.56211 19.672 3.68674 17.7967C1.81138 15.9213 0.757812 13.3778 0.757812 10.7256C0.757812 8.07342 1.81138 5.52988 3.68674 3.65452C5.56211 1.77915 8.10565 0.725586 10.7578 0.725586C13.41 0.725586 15.9535 1.77915 17.8289 3.65452C19.7042 5.52988 20.7578 8.07342 20.7578 10.7256C20.7578 13.3778 19.7042 15.9213 17.8289 17.7967C15.9535 19.672 13.41 20.7256 10.7578 20.7256Z"
				fill="#8800FF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.4024 12.685C15.2588 12.6021 15.0882 12.5797 14.9281 12.6226C14.768 12.6655 14.6315 12.7702 14.5486 12.9137C14.1647 13.5792 13.6123 14.1317 12.947 14.5157C12.2816 14.8997 11.5268 15.1017 10.7586 15.1012C9.9904 15.1017 9.23562 14.8997 8.57027 14.5157C7.90493 14.1317 7.3525 13.5792 6.96861 12.9137C6.92787 12.8421 6.87336 12.7792 6.80822 12.7287C6.74308 12.6782 6.66859 12.6411 6.58904 12.6195C6.5095 12.5979 6.42647 12.5922 6.34473 12.6029C6.26299 12.6135 6.18416 12.6402 6.11278 12.6814C6.0414 12.7226 5.97888 12.7775 5.92881 12.843C5.87874 12.9085 5.84212 12.9832 5.82105 13.0629C5.79998 13.1426 5.79488 13.2257 5.80605 13.3073C5.81722 13.389 5.84443 13.4676 5.88611 13.5387C6.37971 14.3942 7.08993 15.1045 7.94529 15.5983C8.80065 16.092 9.77098 16.3517 10.7586 16.3512C11.7462 16.3517 12.7166 16.092 13.5719 15.5983C14.4273 15.1045 15.1375 14.3942 15.6311 13.5387C15.714 13.3952 15.7364 13.2246 15.6935 13.0645C15.6506 12.9044 15.5459 12.7679 15.4024 12.685Z"
				fill="#8800FF"
			/>
			<path
				d="M12.0078 8.85059C12.0078 9.88559 12.5678 10.7256 13.2578 10.7256C13.9478 10.7256 14.5078 9.88559 14.5078 8.85059C14.5078 7.81559 13.9478 6.97559 13.2578 6.97559C12.5678 6.97559 12.0078 7.81559 12.0078 8.85059ZM7.00781 8.85059C7.00781 9.88559 7.56781 10.7256 8.25781 10.7256C8.94781 10.7256 9.50781 9.88559 9.50781 8.85059C9.50781 7.81559 8.94781 6.97559 8.25781 6.97559C7.56781 6.97559 7.00781 7.81559 7.00781 8.85059Z"
				fill="#8800FF"
			/>
		</SvgIcon>
	);
};

export default HappyIcon;
