import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Logo } from "../../assets/bria_logo.svg";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

interface IProps {}

const Layout: React.FC<IProps> = ({ children }) => {
	const classes = useStyles();

	return (
		<Container
			component="main"
			maxWidth={false}
			className={classes.background}
		>
			<Box className={classes.header} />

			<Box className={classes.credentials_content}>
				<Box position="relative">
					<Logo className={classes.logo} />
					<Typography className={classes.beta}>Beta</Typography>
				</Box>
				{children}
			</Box>
		</Container>
	);
};

const useStyles = makeStyles((theme) => ({
	logo: {
		width: 167,
		height: 106,
	},
	credentials_content: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-evenly",
		// width: 600,
		// minWidth: 600,
		height: "100vh",
		position: "absolute",
		top: 0,
		left: 0,
		background: "white",
		padding: "20px",
		boxShadow:
			"0px 12px 24px rgba(44, 39, 56, 0.02), 0px 32px 64px rgba(44, 39, 56, 0.04)",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
		},
	},
	background: {
		backgroundColor: "black",
		height: "100%",
		width: "100%",
		zIndex: -1,
		padding: 0,
	},
	beta: {
		fontSize: 13,
		color: theme.palette.cardTextSecondary.main,
		textTransform: "uppercase",
		fontWeight: 600,
		position: "absolute",
		bottom: 12,
		left: 159,
	},
	header: {
		backgroundImage:
			"url('https://bria-image-repository.s3.amazonaws.com/web-assets/headerImage2.webp')",
		height: "100%",
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
	},
}));

export default Layout;
