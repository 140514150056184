import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

const CustomizedTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: theme.palette.common.black,
		borderRadius: 0,
		maxWidth: 110,
	},
	arrow: {
		color: theme.palette.common.black,
	},
	tooltipPlacementBottom: {
		margin: theme.spacing(1, 0),
	},
}))(Tooltip);

export default CustomizedTooltip;
