import Button from "@material-ui/core/Button";
import { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import DropDownMenu from "./DropDownMenu";
import Dialog from "@material-ui/core/Dialog";

const RegisterDetails = (props: { onClick(): void }) => {
	const classes = useStyles();
	const onClick = props.onClick;

	const placeholder = "choose main thing";
	const [userTypeChoice, setUserTypeChoice] = useState<String>(placeholder);
	const [jobChoice, setJobChoice] = useState<String>(placeholder);
	const [usingForChoice, setUsingForChoice] = useState<String>(placeholder);
	const [disabledButton, setDisabledButton] = useState<boolean>(true);
	const userTypes = ["Work", "Personal", "School"];
	const jobs = [
		"Business Owner",
		"Team Leader",
		"Team Member",
		"Freelancer",
		"Director / C-Level /VP",
	];

	async function handleUserTypeChoice(value: string) {
		setUserTypeChoice(value);
	}

	const handleJobChoice = (value: string) => {
		setJobChoice(value);
	};

	const handleUsingForChoice = (value: string) => {
		setUsingForChoice(value);
	};

	useEffect(() => {
		console.log(userTypeChoice);
		console.log(jobChoice);
		console.log(usingForChoice);
		if (
			userTypeChoice !== placeholder &&
			jobChoice !== placeholder &&
			usingForChoice !== placeholder
		) {
			setDisabledButton(false);
		}
	}, [userTypeChoice, jobChoice, usingForChoice]);

	return (
		<Dialog
			aria-labelledby="simple-dialog-title"
			open={true}
			classes={{ paper: classes.paper }}
		>
			<main className={classes.root}>
				<div className={classes.container}>
					<div className={classes.title}>
						Let's create the best experience for you
					</div>
					<div className={classes.subtitle}>
						Let us know a bit more so we can help you get started
					</div>
					<div className={classes.flex}>
						<div className={classes.text}>I'm here for</div>
						<DropDownMenu
							listItems={userTypes}
							handleChoice={handleUserTypeChoice}
						/>
						<div className={classes.text}>and I'm a</div>
						<DropDownMenu
							listItems={jobs}
							handleChoice={handleJobChoice}
						/>
						<div className={classes.text}>
							I'd like to use monday.com for
						</div>
						<DropDownMenu
							listItems={jobs}
							handleChoice={handleUsingForChoice}
						/>
					</div>
					<Button
						variant="contained"
						className={classes.button}
						disabled={disabledButton}
						onClick={onClick}
					>
						Continue
					</Button>
				</div>
			</main>
		</Dialog>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			boxShadow: "0 2px 8px rgba(0, 0, 0, 0.25)",
			borderRadius: "14px",
			height: "440px",
			backgroundColor: "white",
		},
		container: {
			display: "flex",
			flexDirection: "column",
			paddingTop: "6%",
			paddingLeft: "8%",
		},
		title: {
			fontWeight: "bold",
			fontSize: 20,
			paddingBottom: "1%",
		},
		subtitle: {
			color: "#979797",
		},
		text: {
			fontSize: 27,
			font: "small-caption",
			paddingRight: "1%",
			fontWeight: 100,
			color: "#495057",
		},
		flex: {
			display: "flex",
			alignItems: "center",
			flexWrap: "wrap",
			marginTop: "8%",
		},

		button: {
			width: "20%",
			borderRadius: "unset",
			marginTop: "15%",
			marginLeft: "75%",
			textTransform: "none",
			fontWeight: 500,
		},
		paper: { minWidth: "58%" },
	})
);
export default RegisterDetails;
