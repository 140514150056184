import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Layout from "../Components/Login/Layout";
import BriaInput from "../Components/UI/BriaInput";
import { useLoginService } from "../hooks/useLoginService";
import { useHistory, useLocation } from "react-router-dom";
import ErrorIcon from "../assets/icons/ErrorIcon";
import * as Yup from "yup";
import RouterConstants from "../Constants/RouterConstants";

interface IProps {}

const ResetPassword: React.FC<IProps> = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const history = useHistory();
	const { confirmPasswordResetFunction, errorMessage } = useLoginService();
	const search = useLocation().search;
	const oobCode = new URLSearchParams(search).get("oobCode");

	const initialValues = {
		newPassword: "",
		confirmPassword: "",
	};

	const handleSubmit = async (values: any) => {
		await confirmPasswordResetFunction(oobCode ?? "", values.newPassword);
		history.push(RouterConstants.LOGIN, {
			passwordReset: true,
		});
	};

	return (
		<Layout>
			<Box display="flex" flexDirection="column" alignItems="center">
				<Typography className={classes.typo1}>
					{t("resetPassword")}
				</Typography>

				<Formik
					initialValues={initialValues}
					validateOnMount
					validationSchema={Yup.object({
						newPassword: Yup.string().required(
							"Password is required"
						),
						confirmPassword: Yup.string().test(
							"passwords-match",
							"Passwords must match",
							function (value) {
								return this.parent.newPassword === value;
							}
						),
					})}
					onSubmit={handleSubmit}
				>
					{({ values, errors, touched, handleChange, isValid }) => (
						<Form className={classes.form}>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<BriaInput
									id="newPassword"
									label="New Password"
									type="password"
									autoComplete="current-password"
									name="newPassword"
									onChange={handleChange}
									value={values.newPassword}
									error={
										(!!errors.newPassword &&
											touched.newPassword) as boolean
									}
									helperText={errors.newPassword}
									className={classes.textField}
								/>
								<BriaInput
									id="confirmPassword"
									label="Re-type New Password"
									type="password"
									autoComplete="current-password"
									name="confirmPassword"
									value={values.confirmPassword}
									onChange={handleChange}
									error={
										(!!errors.confirmPassword &&
											touched.confirmPassword) as boolean
									}
									helperText={errors.confirmPassword}
									className={classes.textField}
								/>

								{errorMessage && (
									<Typography
										className={classes.error}
										color="secondary"
									>
										<ErrorIcon
											className={classes.errorIcon}
										/>
										{t(`${errorMessage}`)}
									</Typography>
								)}

								<Button
									type="submit"
									variant="contained"
									color="secondary"
									disabled={!isValid}
									className={classes.button}
								>
									{t("resetPassword")}
								</Button>
							</Box>
						</Form>
					)}
				</Formik>
			</Box>
			<Box />
		</Layout>
	);
};

const useStyles = makeStyles((theme) => ({
	form: {
		// width: 440,
		height: 200,
	},
	button: {
		borderRadius: 20,
		width: 440,
		height: 40,
	},
	icon: {
		fontSize: 58,
	},
	signup: {
		color: theme.palette.primary.light,
	},
	typo1: {
		fontSize: 34,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.text.primary,
		marginBottom: 162,
	},
	typo2: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.primary,
		margin: theme.spacing(5),
	},
	typo3: {
		fontSize: 14,
		color: "#979797",
		display: "inline-block",
		marginLeft: -9,
	},
	textField: {
		marginBottom: 22,
	},
	error: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightBold,
		maxWidth: 440,
		marginBottom: 15,
		marginLeft: 12,
		alignSelf: "start",
	},
	errorIcon: {
		fontSize: 16,
		marginRight: 4,
		verticalAlign: "middle",
	},
}));

export default ResetPassword;
