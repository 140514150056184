import React, { useContext } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { GoogleAuthProvider } from "firebase/auth";
import Context from "../Context/Context";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Google from "../assets/icons/Google";
import RouterConstants from "../Constants/RouterConstants";
import { Link as RouterLink } from "react-router-dom";
import BriaCheckbox from "../Components/UI/BriaCheckbox";
import Link from "@material-ui/core/Link";
import Layout from "../Components/Login/Layout";
import BriaInput from "../Components/UI/BriaInput";
import * as Yup from "yup";
import ErrorIcon from "../assets/icons/ErrorIcon";
import { useLoginService } from "../hooks/useLoginService";

interface IProps {}

const Register: React.FC<IProps> = () => {
	const classes = useStyles();
	const context = useContext(Context);
	const { t } = useTranslation();
	const { sendEmailVerificationFunction } = useLoginService();
	const [isLoading, setIsLoading] = React.useState<boolean>(false);
	const [agreement, setAgreement] = React.useState<boolean>(false);
	const [errorMessage, setErrorMessage] =
		React.useState<boolean | string>(false);

	const initialValues = {
		username: "",
		email: "",
		password: "",
	};

	const handleSubmit = (values: any) => {
		context
			.registerWithEmail(values.username, values.email, values.password)
			.then(() => {
				sendEmailVerificationFunction();
			})
			.catch((error) => {
				setErrorMessage(error.code);
			});
	};

	return (
		<Layout>
			<Box display="flex" flexDirection="column" alignItems="center">
				<Typography className={classes.typo1}>{t("signUp")}</Typography>
				<Button
					variant="outlined"
					startIcon={<Google className={classes.icon} />}
					onClick={() =>
						isLoading
							? null
							: context.registerViaAuthProvider(
									new GoogleAuthProvider()
							  )
					}
					className={classes.button}
				>
					{t("signUpWithGoogle")}
				</Button>

				<Typography className={classes.typo}>{t("or")}</Typography>

				<Formik
					initialValues={initialValues}
					validateOnMount
					validationSchema={Yup.object().shape({
						username: Yup.string().required("Username is Required"),
						email: Yup.string()
							.email("Invalid email")
							.required("Email is Required"),
						password: Yup.string()
							.min(6, "Must be 6 or more characters")
							.required("Password is Required"),
					})}
					onSubmit={handleSubmit}
				>
					{({ values, errors, touched, handleChange, isValid }) => (
						<Form className={classes.form}>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<BriaInput
									id="username"
									label="Username"
									onChange={handleChange}
									value={values.username}
									error={!!touched.username}
									helperText={errors.username}
									className={classes.textField}
								/>
								<BriaInput
									id="email"
									label="Email"
									onChange={handleChange}
									value={values.email}
									error={!!errors.email}
									helperText={errors.email}
									className={classes.textField}
								/>
								<BriaInput
									id="password"
									label="Password"
									type="password"
									autoComplete="current-password"
									value={values.password}
									onChange={handleChange}
									error={!!errors.password}
									helperText={errors.password}
									className={classes.textField}
								/>

								{errorMessage && (
									<Typography
										className={classes.error}
										color="secondary"
									>
										<ErrorIcon
											className={classes.errorIcon}
										/>
										{t(`${errorMessage}`)}
									</Typography>
								)}

								<Box mt={-2} mb="6px" width="100%">
									<BriaCheckbox
										style={{ display: "inline-block" }}
										value={agreement}
										onChange={(
											event: React.ChangeEvent<HTMLInputElement>
										) => {
											setAgreement(event.target.checked);
										}}
									/>
									<Typography className={classes.typo3}>
										{t("IAgreeToOur")}
										<Link
											onClick={(
												e: React.MouseEvent<any>
											) => {
												window.open(
													`${window.location.origin}${RouterConstants.TERMS_OF_USE}`,
													"_blank"
												);
											}}
											className={classes.link}
										>
											{t("termsOfService")}
										</Link>
									</Typography>
								</Box>
								<Button
									type="submit"
									variant="contained"
									color="secondary"
									disabled={!isValid || !agreement}
									className={classes.button}
								>
									{t("register")}
								</Button>
							</Box>
						</Form>
					)}
				</Formik>
			</Box>

			<Typography className={classes.typo2}>
				{t("alreadyHaveAnAccount?")}
				<RouterLink
					to={{
						pathname: RouterConstants.LOGIN,
						search: window.location.search,
					}}
					className={classes.signin}
				>
					{t("signIn")}
				</RouterLink>
			</Typography>
		</Layout>
	);
};

const useStyles = makeStyles((theme) => ({
	form: {
		width: "100%",
		height: 280,
	},
	button: {
		borderRadius: 20,
		width: "100%",
		maxWidth: 440,
		height: 40,
	},
	icon: {
		fontSize: 20,
	},
	signin: {
		color: theme.palette.primary.light,
		textDecoration: "none",
	},
	typo: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.text.primary,
		opacity: 0.5,
		marginTop: 26,
		marginBottom: 22,
	},
	typo1: {
		fontSize: 34,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.text.primary,
		marginBottom: 53,
		textTransform: "capitalize",
	},
	typo2: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.primary,
	},
	typo3: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightRegular,
		color: "#979797",
		display: "inline-block",
	},
	link: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.primary,
		textDecoration: "none",
		cursor: "pointer",

		"&:hover": {
			textDecoration: "none",
		},
	},
	textField: {
		marginBottom: theme.spacing(3),
		width: "100%",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			minWidth: "unset",
		},
	},
	dialogTitle: { padding: 0 },
	dialogContent: { padding: "0 40px 40px 40px" },
	title: {
		fontSize: 24,
		fontWeight: theme.typography.fontWeightBold,
	},
	close: {
		fontSize: 16,
		cursor: "pointer",
	},
	error: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightBold,
		maxWidth: 440,
		marginBottom: 15,
		marginLeft: 12,
	},
	errorIcon: {
		fontSize: 16,
		marginRight: 4,
		verticalAlign: "middle",
	},
}));

export default Register;
