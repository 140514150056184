import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import client from "../GraphQL/client";
import gql from "graphql-tag";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface User {
	email: string;
	uid: string;
	role: string;
}

interface IState {
	organizationUsers: User[] | [User];
	orgUid: string;
}

type TParams = { organizationID: string };

class OrganizationUsers extends React.Component<
	any & RouteComponentProps<TParams>,
	any
> {
	state: IState = {
		organizationUsers: [],
		orgUid: this.props.match.params.organizationID,
	};

	componentDidMount() {
		const orgUid: string = this.state.orgUid;
		this.getOrganizationUsers(orgUid);
	}

	async getOrganizationUsers(orgUid: string) {
		try {
			const res = await client.query<any, any>({
				query: gql`
					query organizationUsers($orgUid: String) {
						organizationUsers(orgUid: $orgUid) {
							email
							uid
							role
						}
					}
				`,
				variables: {
					orgUid: orgUid,
				},
			});
			this.setState({
				organizationUsers: res.data.organizationUsers ?? [],
			});
		} catch (error) {
			// alert(error.message);
		}
	}

	public render() {
		const { classes } = this.props;
		return (
			<div className={classes.root}>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell>User email</TableCell>
								<TableCell>User ID</TableCell>
								<TableCell align="right">User role</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.organizationUsers.map(
								(user: User, index: number) => {
									return (
										<TableRow key={index}>
											<TableCell>{user.email}</TableCell>
											<TableCell
												component="th"
												scope="row"
											>
												{user.uid}
											</TableCell>
											<TableCell align="right">
												{user.role}
											</TableCell>
										</TableRow>
									);
								}
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	}
}

const styles = () =>
	createStyles({
		root: {
			display: "flex",
			margin: 40,
		},
		table: {
			minWidth: 650,
		},
	});

export default withStyles(styles)(withRouter(OrganizationUsers));
