import React, { useContext } from "react";
import clsx from "clsx";
import { makeStyles, fade, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";

import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NavigationItems from "../Components/ListItems";
import SelectedPage from "../Models/SelectedPage";
import UserMenue from "../Components/UserMenue";
import Logo from "../assets/icons/BriaLogo";
import EngineMenue from "../Components/EngineMenue";
import AppContext from "../Context/Context";
import { useTranslation } from "react-i18next";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";

interface IProps {
	title: string;
}

function AppHeader({ title }: IProps) {
	const classes: any = useStyles();
	const { i18n } = useTranslation();
	const [open, setOpen] = React.useState(false);
	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};
	const context = useContext(AppContext);
	const pageName: string = title.toLowerCase();
	const theme = useTheme();

	const themeColor =
		title === SelectedPage.DATASET
			? "rgba(82, 0, 200, 0.9)"
			: title === SelectedPage.RESEARCH
			? "rgba(202, 204, 206, 0.9)"
			: "rgba(25, 96, 21, 0.9)";
	const curDrawerWidth: string | number = !open
		? context.pageMenuWidth
		: drawerWidth;
	context.pageMenuWidth = curDrawerWidth;
	return (
		<React.Fragment>
			<AppBar
				position="absolute"
				style={{
					backgroundColor: themeColor,
					flexWrap: "nowrap",
					overflow: "hidden",
				}}
				className={clsx(classes.appBar, open && classes.appBarShift)}
			>
				<Toolbar className={classes.toolbar}>
					<IconButton
						edge="start"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						className={clsx(
							classes.menuButton,
							open && classes.menuButtonHidden
						)}
					>
						<MenuIcon />
					</IconButton>
					<Grid container alignItems="center">
						<Grid item>
							<Typography
								component="h1"
								variant="h6"
								noWrap
								className={classes.title}
							>
								<Logo className={classes.logo} />
							</Typography>
						</Grid>
						<Grid item>
							<Typography
								variant="h6"
								style={{
									color: theme.palette.text.primary,
									fontSize: "1rem",
									margin: "0 0.5rem",
									textTransform:
										title === SelectedPage.IMAGEREPOSITORY
											? "uppercase"
											: "capitalize",
								}}
							>
								{pageName}
							</Typography>
						</Grid>
					</Grid>
					{context.user?.isAdmin() && <EngineMenue />}
					<Select
						value={i18n.language}
						onChange={(event) => {
							i18n.changeLanguage(event.target.value as string);
						}}
					>
						<MenuItem value="en">English</MenuItem>
						<MenuItem value="fr">français</MenuItem>
						<MenuItem value="ar">العربية</MenuItem>
						<MenuItem value="he">עִברִית</MenuItem>
					</Select>
					<UserMenue />
				</Toolbar>
			</AppBar>
			<nav className={classes.drawer}>
				<Drawer
					variant="permanent"
					classes={{
						paper:
							title === SelectedPage.BRIATOR_V2
								? clsx(
										classes.drawerPaper,
										!open && classes.drawerPaperClose,
										classes.drawerPaperCstm
								  )
								: clsx(
										classes.drawerPaper,
										!open && classes.drawerPaperClose
								  ),
					}}
					open={open}
				>
					<div className={classes.toolbarIcon}>
						<IconButton onClick={handleDrawerClose}>
							<ChevronLeftIcon
								color="secondary"
								className={classes.menueIcon}
							/>
						</IconButton>
					</div>
					<Divider />
					<List>
						<NavigationItems />
					</List>
				</Drawer>
			</nav>
		</React.Fragment>
	);
}

const drawerWidth = "15rem";
const useStyles = makeStyles((theme) => ({
	root: {
		color: theme.palette.text.primary,
		display: "flex",
		maxHeight: "100vh",
		maxWidth: "100vw",
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	logo: {
		paddingTop: theme.spacing(2),
		cursor: "pointer",
		height: 55,
		width: 63,
	},
	toolbarIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},
	menueIcon: {},
	appBar: {
		background: theme.palette.background.default,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		height: "4rem",
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth})`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: "none",
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		background: theme.palette.background.default,
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9),
		},
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		//height: '100vh',
		overflow: "auto",
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	fixedHeight: {
		height: 240,
	},
	searchContainer: {
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: "auto",
		},
	},
	search: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		"&:hover": {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
	},
	searchIcon: {
		width: theme.spacing(7),
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	inputRoot: {
		color: theme.palette.text.primary,
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 7),
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("md")]: {
			width: 200,
		},
	},
	button: {
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
		background: "#FE6B8B",
		borderRadius: 3,
		border: 0,
		color: "white",
		height: 40,
		padding: "0 30px",
		boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
	},
	appBarCstm: {
		display: "none",
	},
	drawerPaperCstm: {
		display: "none",
		borderRadius: 3,
	},
	appBarSpacerCstm: {
		minHeight: "initial",
	},
	sitelogoCstm: {
		position: "absolute",
		top: 2,
		left: 26,
		zIndex: 999999,
	},
	drawer: {
		flexShrink: 0,
	},
}));

export default AppHeader;
