import React, {
	ReactChildren,
	ReactChild,
	ReactElement,
	useRef,
	useState,
} from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import HoverVideoPlayer from "react-hover-video-player";
import { Skeleton } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import Collapse from "@material-ui/core/Collapse";

const videoSize = 120;
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: videoSize,
		margin: `auto ${theme.spacing(1)}px`,
		background: "transparent",
		position: "relative",
		marginBottom: theme.spacing(4),
		"&:hover $moreIconWrapper": {
			display: "flex",
		},
	},
	container: {
		width: videoSize + 10,
		height: videoSize + 10,
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		border: ({ expanded }: any) =>
			expanded ? "none" : "5px solid transparent",
		overflow: "hidden",
		"&:hover": {
			border: ({ expanded }: any) =>
				expanded ? "none" : "5px solid #FFF",
			boxShadow: ({ expanded }: any) =>
				expanded ? theme.shadows[0] : theme.shadows[8],
		},
	},
	shadowedWrapper: {
		"&:before": {
			content: "''",
			position: "absolute",
			width: "90%",
			height: "30px",
			background:
				"radial-gradient(50% 50% at 50.07% 50%, rgba(162, 162, 162, 0.97) 0%, rgba(245, 245, 245, 0) 100%)",
			left: "50%",
			bottom: " -50px",
			zIndex: -1,
			transform: "translate(-50%,-50%)",
		},
	},
	videoContainer: {
		height: videoSize,
		width: videoSize,
		borderRadius: theme.shape.borderRadius,
		overflow: "hidden",
		margin: 0,
	},
	errRate: {
		position: "absolute",
		padding: "2px 5px",
		bottom: "5px",
		left: "5px",
		backgroundColor: "#ededed70",
	},
	slidersWrapper: {
		paddingTop: "5px",
	},
	moreIconWrapper: {
		color: theme.palette.cards.main,
		zIndex: theme.zIndex.modal,
		position: "absolute",
		cursor: "pointer",
		background: "rgba(255, 255, 255, 0.7)",
		borderRadius: "50%",
		height: "30px",
		width: "30px",
		display: ({ expanded }: any) => (expanded ? "flex" : "none"),
		justifyContent: "center",
		alignItems: "center",
		// top: ({ showTitle, expanded, title }: any) =>
		// 	expanded ? "10px" : showTitle && title ? theme.spacing(4) : "10px",
		top: ({ expanded }: any) => (expanded ? "10px" : theme.spacing(4)),
		right: ({ expanded }: any) => (expanded ? theme.spacing(1) : 0),
		"&:hover": {
			background: "rgba(255, 255, 255)",
		},
	},
	expandedImage: {
		width: 180,
		height: 180,
		margin: 10,
		marginTop: 50,
		padding: 30,
		borderRadius: theme.shape.borderRadius,
		background: "rgba(233, 236, 239, 0.35)",
	},
	title: {
		fontSize: 14,
		color: ({ expanded }: any) =>
			expanded ? theme.palette.tools.main : theme.palette.text.primary,
		position: ({ expanded }: any) => (expanded ? "absolute" : "relative"),
		top: ({ expanded }: any) => (expanded ? 13 : 0),
		overflow: "hidden",
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
	},
}));

interface IProps {
	title: string;
	videoURL: string;
	error?: number | null;
	readyOnly?: boolean;
	// showTitle?: boolean;
	small?: boolean;
	children?(
		callback: () => void
	):
		| ReactChild
		| ReactChild[]
		| ReactChildren
		| ReactChildren[]
		| ReactElement
		| ReactElement[];
}

const LiveVideoItem: React.FC<IProps> = ({
	children,
	title,
	// showTitle,
	videoURL,
	error,
	readyOnly,
}) => {
	const containerRef = useRef(null);
	const [expanded, setExpanded] = useState<boolean>(false);
	const classes = useStyles({
		expanded,
		// showTitle,
		title,
	});

	const toggleExpanded = () => {
		setExpanded(!expanded);
	};

	const handleClose = () => {
		setExpanded(false);
	};

	const videoContainer = (
		<>
			{children && (
				<Box
					className={clsx(classes.moreIconWrapper)}
					onClick={toggleExpanded}
				>
					{expanded ? (
						<CloseIcon fontSize="small" />
					) : (
						<MoreHorizIcon />
					)}
				</Box>
			)}
			{/* {showTitle && ( */}
			<Box display="flex" justifyContent="center">
				<Typography
					align="center"
					variant="subtitle2"
					className={classes.title}
				>
					{title}
				</Typography>
			</Box>
			{/* )} */}
			<Box className={classes.container}>
				<HoverVideoPlayer
					className={classes.videoContainer}
					videoSrc={videoURL}
					videoStyle={{
						height: "100%",
					}}
					loadingOverlay={
						<Skeleton
							variant="rect"
							animation="wave"
							height="100%"
							width="100%"
						/>
					}
					restartOnPaused
				/>
				{error && (
					<Typography variant="caption" className={classes.errRate}>
						{+error.toFixed(2)}
					</Typography>
				)}
			</Box>
		</>
	);

	return (
		<>
			<Paper
				className={clsx(
					classes.root,
					expanded && classes.shadowedWrapper
				)}
				elevation={0}
				ref={containerRef}
			>
				{videoContainer}
				<Popover
					open={expanded}
					anchorEl={containerRef.current}
					PaperProps={{
						elevation: 1,
					}}
					onClose={handleClose}
					TransitionComponent={Collapse}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					transformOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					style={{ marginLeft: 5 }}
				>
					{children && (
						<Box
							className={clsx(classes.moreIconWrapper)}
							onClick={toggleExpanded}
						>
							{expanded ? (
								<CloseIcon fontSize="small" />
							) : (
								<MoreHorizIcon />
							)}
						</Box>
					)}
					<Box className="video-popover" width={videoSize + 80}>
						<Box className={classes.expandedImage}>
							{videoContainer}
						</Box>
						<Box p="10px">{children && children(handleClose)}</Box>
					</Box>
				</Popover>
			</Paper>
		</>
	);
};

export default LiveVideoItem;
