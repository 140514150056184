import { useReducer, useCallback } from "react";
import { useEventListener } from "./useEventListener";

enum ActionType {
	Set = "SET",
	Undo = "UNDO",
	Redo = "REDO",
	Reset = "RESET",
}
export interface Actions {
	set: (payload: OperationDetails) => void;
	undo: () => void;
	redo: () => void;
	reset: () => void;
	canUndo: boolean;
	canRedo: boolean;
}

interface OperationDetails {
	undo: () => void;
	redo: () => void;
}

interface Action {
	type: ActionType;
	payload?: OperationDetails;
}

export interface State {
	past: OperationDetails[];
	future: OperationDetails[];
}

const initialState: State = {
	past: [],
	future: [],
};

function undoRedoReducer(state: State, action: Action): State {
	const { past, future } = state;

	switch (action.type) {
		case ActionType.Undo: {
			const previous = past[0];
			const newPast = past.slice(1);
			previous.undo();
			return {
				past: newPast,
				future: [previous, ...future],
			};
		}
		case ActionType.Redo: {
			const newPast = future[0];
			const newFuture = future.slice(1);
			newPast.redo();
			return {
				past: [newPast, ...past],
				future: newFuture,
			};
		}

		case ActionType.Set: {
			if (action.payload === undefined) {
				throw Error("Invalid argument");
			}
			return {
				past: [action.payload, ...past],
				future: [],
			};
		}

		case ActionType.Reset: {
			return {
				past: [],
				future: [],
			};
		}
		default:
			return state;
	}
}

export const useUndoRedu = (): Actions => {
	const [state, dispatch] = useReducer(undoRedoReducer, initialState);

	const canUndo = state.past.length !== 0;
	const canRedo = state.future.length !== 0;
	const undo = useCallback(() => {
		if (canUndo) {
			dispatch({ type: ActionType.Undo });
		}
	}, [canUndo]);
	const redo = useCallback(() => {
		if (canRedo) {
			dispatch({ type: ActionType.Redo });
		}
	}, [canRedo]);
	const set = useCallback(
		(payload: OperationDetails) =>
			dispatch({ type: ActionType.Set, payload }),
		[]
	);

	const keyboardEventsHanlder = useCallback(
		(event) => {
			if (event.ctrlKey && event.key === "z") {
				undo();
			}
			if (event.ctrlKey && event.key === "y") {
				redo();
			}
		},
		[undo, redo]
	);

	const reset = useCallback(() => dispatch({ type: ActionType.Reset }), []);

	useEventListener({
		type: "keydown",
		listener: keyboardEventsHanlder,
	});

	return { set, reset, undo, redo, canUndo, canRedo };
};
