import React, { useContext } from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import { createBrowserHistory } from "history";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import PrivateRoute from "./Components/Common/PrivateRoute";
import PublicRoute from "./Components/Common/PublicRoute";
import RouterConstants from "./Constants/RouterConstants";
import AppContext from "./Context/Context";
import Analytics from "./Models/Analytics";
import SelectedPage from "./Models/SelectedPage";
import { DBName } from "./types/graphql-global-types";
import GraphQLPlayground from "./Pages/GraphQLPlayground";

import Copyright from "./Components/Copyright";
import TermsOfUse from "./Pages/TermsOfUse";

import LoginPage from "./Pages/LoginPage";

import RegisterPage from "./Pages/RegisterPage";

import RegisterPageViewers from "./Pages/RegisterPageViewers";

import ForgotPasswordPage from "./Pages/ForgotPasswordPage";
import VerifyAccountPage from "./Pages/VerifyAccountPage";

import Authentication from "./Pages/Auth";

import AppHeader from "./Components/AppHeader";

import SearchForImage from "./Pages/Tool/SearchForImage";
import SearchForImageViewers from "./Pages/Tool/SearchForImageViewers";

import ImageEditTool from "./Pages/Tool/ImageEditor";

import ImageReview from "./Pages/ImageReview";

import InstanceManagement from "./Pages/InstanceManagement";

import ToolHeader from "./Components/Tools/ToolHeader";

import ImageFiltersDemo from "./Pages/ImageFiltersDemo";

import ImageViewer from "./Pages/ImageViewer";

import Organization from "./Pages/Organization";

import OrganizationUsers from "./Pages/OrganizationUsers";

import TensorBoardPage from "./Pages/TensorBoardPage";
import GrafanaDashboard from "./Pages/GrafanaDashboard";
import UserManagement from "./Pages/UserManagement";

import UploadFile from "./Components/UploadFile";

const history = createBrowserHistory();
history.listen(() => {
	Analytics.setCurrentScreen(
		window.location.pathname + window.location.search
	);
});

export enum ROLES {
	ADMIN = "admin",
	USER = "view",
	EXTERNAL = "external",
	NON = "non",
}

const ALL_USERS = [ROLES.ADMIN, ROLES.USER, ROLES.EXTERNAL];

const NON_VIEWERS_USERS = [ROLES.ADMIN, ROLES.EXTERNAL];

const VIEWERS_USERS = [ROLES.ADMIN, ROLES.USER];

export enum PAGE_TYPES {
	PRIVATE = "PRIVATE",
	PUBLIC = "PUBLIC",
}

const AdminRole = () => [ROLES.ADMIN];

const ViewerRole = () => [ROLES.USER];

function PageContianer({ children }: React.PropsWithChildren<any>) {
	const classes = useStyles();
	return (
		<React.Suspense
			fallback={
				<Box
					width="100vw"
					display="flex"
					justifyContent="center"
					alignItems="center"
				>
					<CircularProgress />
				</Box>
			}
		>
			<main className={classes.content}>
				<div className={clsx(classes.toolbar)}>{children}</div>
			</main>
		</React.Suspense>
	);
}

function Routes() {
	const classes = useStyles();
	return (
		<Router history={history}>
			<div className={classes.root}>
				<UploadFile />

				<Switch>
					<Route path={RouterConstants.VERIFY} exact>
						<Box width="100%">
							<VerifyAccountPage />
						</Box>
					</Route>

					<Route path={RouterConstants.AUTH} exact>
						<Box width="100%">
							<Authentication />
						</Box>
					</Route>

					<Route path={RouterConstants.TERMS_OF_USE}>
						<Box width="100%">
							<TermsOfUse />
						</Box>
					</Route>

					<PublicRoute path={RouterConstants.LOGIN} exact>
						<Box width="100%">
							<LoginPage />
						</Box>
					</PublicRoute>

					<PublicRoute path={RouterConstants.SIGNUP} exact>
						<Box width="100%">
							<RegisterPage />
						</Box>
					</PublicRoute>

					<PublicRoute path={RouterConstants.SIGNUPVIEWERS} exact>
						<Box width="100%">
							<RegisterPageViewers />
						</Box>
					</PublicRoute>

					<PublicRoute path={RouterConstants.FORGOTPASSWORD} exact>
						<Box width="100%">
							<ForgotPasswordPage />
						</Box>
					</PublicRoute>

					<PrivateRoute
						exact
						path={`${RouterConstants.VDR}:mongoId`}
						roles={AdminRole()}
					>
						<AppHeader title={SelectedPage.IMAGEREPOSITORY} />
						<PageContianer>
							<Box>
								<ImageReview dbName={DBName.VDR} />
							</Box>
						</PageContianer>
						{/* <Copyright /> */}
					</PrivateRoute>

					<PrivateRoute
						path={RouterConstants.BASE}
						exact
						roles={AdminRole()}
					>
						<AppHeader title={SelectedPage.IMAGEREPOSITORY} />
						<PageContianer>
							<ImageViewer dbName={DBName.VDR} />
						</PageContianer>
						{/* <Copyright /> */}
					</PrivateRoute>

					<PrivateRoute
						path={`${RouterConstants.TOOLS}:visual_hash`}
						exact
						roles={NON_VIEWERS_USERS}
					>
						<Box height="100%" width="100vw">
							<ImageEditTool />
						</Box>
					</PrivateRoute>

					<PrivateRoute
						path={`${RouterConstants.GALLERY}:visual_hash`}
						exact
						roles={VIEWERS_USERS}
					>
						<Box height="100%" width="100vw">
							<ImageEditTool />
						</Box>
					</PrivateRoute>

					<PrivateRoute
						exact
						path={RouterConstants.TOOLS}
						roles={NON_VIEWERS_USERS}
					>
						<Box
							display="grid"
							minHeight="100vh"
							width="100vw"
							gridTemplateRows="80px auto"
						>
							<ToolHeader />
							<SearchForImage />
						</Box>
					</PrivateRoute>

					<PrivateRoute
						exact
						path={RouterConstants.GALLERY}
						roles={VIEWERS_USERS}
					>
						<Box
							display="grid"
							minHeight="100vh"
							width="100vw"
							gridTemplateRows="80px auto"
						>
							<ToolHeader />
							<SearchForImageViewers />
						</Box>
					</PrivateRoute>

					<PrivateRoute
						exact
						path={RouterConstants.INSTANCES}
						roles={AdminRole()}
					>
						<AppHeader title={SelectedPage.INSTANCE_MANAGEMENT} />
						<PageContianer>
							<InstanceManagement />
						</PageContianer>
						{/* <Copyright /> */}
					</PrivateRoute>

					<Route exact path={RouterConstants.DEMO}>
						<AppHeader title={SelectedPage.BRIATOR} />
						<PageContianer>
							<ImageFiltersDemo />
						</PageContianer>
						{/* <Copyright /> */}
					</Route>

					<PrivateRoute
						exact
						path={`${RouterConstants.DATASETS}:mongoId`}
						roles={AdminRole()}
					>
						<AppHeader title={SelectedPage.DATASET} />
						<PageContianer>
							<Box>
								<ImageReview dbName={DBName.DATASETS} />
							</Box>
						</PageContianer>
						{/* <Copyright /> */}
					</PrivateRoute>

					<PrivateRoute
						exact
						path={RouterConstants.DATASETS}
						roles={AdminRole()}
					>
						<AppHeader title={SelectedPage.DATASET} />
						<PageContianer>
							<ImageViewer dbName={DBName.DATASETS} />
						</PageContianer>
						{/* <Copyright /> */}
					</PrivateRoute>

					<PrivateRoute
						exact
						path={`${RouterConstants.RESEARCH}:mongoId`}
						roles={AdminRole()}
					>
						<AppHeader title={SelectedPage.RESEARCH} />

						<PageContianer>
							<Box>
								<ImageReview dbName={DBName.RESEARCH} />
							</Box>
						</PageContianer>
						{/* <Copyright /> */}
					</PrivateRoute>

					<PrivateRoute
						exact
						path={RouterConstants.RESEARCH}
						roles={AdminRole()}
					>
						<AppHeader title={SelectedPage.RESEARCH} />
						<PageContianer>
							<ImageViewer dbName={DBName.RESEARCH} />
						</PageContianer>
						{/* <Copyright /> */}
					</PrivateRoute>

					<PrivateRoute
						exact
						path={RouterConstants.ORGANIZATION}
						roles={AdminRole()}
					>
						<AppHeader title={"Organization"} />
						<PageContianer>
							<Organization />
						</PageContianer>
					</PrivateRoute>

					<PrivateRoute
						exact
						path={`${RouterConstants.ORGANIZATION}:organizationID`}
						roles={AdminRole()}
					>
						<AppHeader title={"Organization"} />
						<PageContianer>
							<OrganizationUsers />
						</PageContianer>
					</PrivateRoute>

					<PrivateRoute
						exact
						path={RouterConstants.BOARD}
						roles={AdminRole()}
					>
						<AppHeader title={SelectedPage.TENSORBOARD} />
						<PageContianer>
							<TensorBoardPage />
						</PageContianer>
						{/* <Copyright /> */}
					</PrivateRoute>

					<PrivateRoute
						exact
						path={RouterConstants.GRAPHANA}
						roles={AdminRole()}
					>
						<AppHeader title={SelectedPage.GRAFANA} />
						<PageContianer>
							<GrafanaDashboard />
						</PageContianer>
						{/* <Copyright /> */}
					</PrivateRoute>

					<PrivateRoute
						exact
						path={RouterConstants.USERS}
						roles={AdminRole()}
					>
						<AppHeader title={SelectedPage.USERMAMAGEMENT} />
						<PageContianer>
							<UserManagement />
						</PageContianer>
						{/* <Copyright /> */}
					</PrivateRoute>

					<PrivateRoute
						exact
						path={RouterConstants.GRAPHQLPLAYGROUND}
						roles={AdminRole()}
					>
						<AppHeader title={SelectedPage.GRAPHQLPLAYGROUND} />
						<PageContianer>
							<GraphQLPlayground />
						</PageContianer>
						{/* <Copyright /> */}
					</PrivateRoute>

					<Redirect to={RouterConstants.BASE} />
				</Switch>
			</div>
		</Router>
	);
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
		minHeight: "100vh",
		maxWidth: "100vw",
	},
	content: {
		flexGrow: 1,
		overflow: "auto",
		marginTop: 64,
	},
	toolbar: theme.mixins.toolbar,
}));

export default Routes;
