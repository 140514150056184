import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const NormalIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.7578 19.4756C8.43717 19.4756 6.21157 18.5537 4.57063 16.9128C2.92969 15.2718 2.00781 13.0462 2.00781 10.7256C2.00781 8.40494 2.92969 6.17934 4.57063 4.5384C6.21157 2.89746 8.43717 1.97559 10.7578 1.97559C13.0785 1.97559 15.3041 2.89746 16.945 4.5384C18.5859 6.17934 19.5078 8.40494 19.5078 10.7256C19.5078 13.0462 18.5859 15.2718 16.945 16.9128C15.3041 18.5537 13.0785 19.4756 10.7578 19.4756ZM10.7578 20.7256C8.10565 20.7256 5.56211 19.672 3.68674 17.7967C1.81138 15.9213 0.757812 13.3778 0.757812 10.7256C0.757812 8.07342 1.81138 5.52988 3.68674 3.65452C5.56211 1.77915 8.10565 0.725586 10.7578 0.725586C13.41 0.725586 15.9535 1.77915 17.8289 3.65452C19.7042 5.52988 20.7578 8.07342 20.7578 10.7256C20.7578 13.3778 19.7042 15.9213 17.8289 17.7967C15.9535 19.672 13.41 20.7256 10.7578 20.7256Z"
				fill="#8800FF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.7578 13.8506C15.7578 13.6848 15.692 13.5259 15.5748 13.4086C15.4575 13.2914 15.2986 13.2256 15.1328 13.2256H6.38281C6.21705 13.2256 6.05808 13.2914 5.94087 13.4086C5.82366 13.5259 5.75781 13.6848 5.75781 13.8506C5.75781 14.0163 5.82366 14.1753 5.94087 14.2925C6.05808 14.4097 6.21705 14.4756 6.38281 14.4756H15.1328C15.2986 14.4756 15.4575 14.4097 15.5748 14.2925C15.692 14.1753 15.7578 14.0163 15.7578 13.8506Z"
				fill="#8800FF"
			/>
			<path
				d="M12.0078 8.85059C12.0078 9.88559 12.5678 10.7256 13.2578 10.7256C13.9478 10.7256 14.5078 9.88559 14.5078 8.85059C14.5078 7.81559 13.9478 6.97559 13.2578 6.97559C12.5678 6.97559 12.0078 7.81559 12.0078 8.85059ZM7.00781 8.85059C7.00781 9.88559 7.56781 10.7256 8.25781 10.7256C8.94781 10.7256 9.50781 9.88559 9.50781 8.85059C9.50781 7.81559 8.94781 6.97559 8.25781 6.97559C7.56781 6.97559 7.00781 7.81559 7.00781 8.85059Z"
				fill="#8800FF"
			/>
		</SvgIcon>
	);
};

export default NormalIcon;
