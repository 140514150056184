import React from "react";
import { withStyles } from "@material-ui/core";
import Badge, { BadgeProps } from "@material-ui/core/Badge";

const CustomizedBadge = withStyles({
	badge: {
		height: 17,
		borderRadius: 4,
		top: -6,
		right: -15,
	},
})(Badge);

interface IProps {}

const BriaBadge: React.FC<IProps & BadgeProps> = ({ children, ...rest }) => {
	return <CustomizedBadge {...rest}>{children}</CustomizedBadge>;
};

export default BriaBadge;
