import React, { useEffect } from "react";
import LoadingOverlay from "./Components/Common/LoadingOverlay";
import AppContextProvider from "./Context/AppContextProvider";
import { BriaThemeProvider } from "./Context/BriaThemeProvider";
import AppContext from "./Context/Context";
import I18nProvider from "./Context/I18nProvider";
import { ApolloProvider } from "@apollo/client/react";
import client from "./GraphQL/client";
import Routes from "./Routes";
import { ToastContainer } from "react-toastify";

const App = () => {
	return (
		<ApolloProvider client={client}>
			<I18nProvider>
				<BriaThemeProvider>
					<AppContextProvider>
						<AppContext.Consumer>
							{(context) => {
								return context.authenticationWasVerified ? (
									<Routes />
								) : (
									<LoadingOverlay />
								);
							}}
						</AppContext.Consumer>
						<ToastContainer
							position="bottom-center"
							autoClose={3000}
							hideProgressBar
							closeOnClick
							pauseOnHover={false}
							draggable={false}
						/>
					</AppContextProvider>
				</BriaThemeProvider>
			</I18nProvider>
		</ApolloProvider>
	);
};

export default App;
