import React from "react";
import Box from "@material-ui/core/Box";
import Checkbox from "../../Components/UI/BriaCheckbox";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { images_with_tags_images_images } from "../../GraphQL/types/images_with_tags";

interface IProps {
	selectedImage: images_with_tags_images_images | undefined;
}

const VersionCard = ({ selectedImage }: IProps) => {
	const classes = useStyles();

	const [checked, setChecked] = React.useState(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	return (
		<Box className={classes.imagesContainer}>
			<Checkbox
				checked={checked}
				onChange={handleChange}
				className={classes.checkbox}
			/>
			<img
				id="root_image"
				src={selectedImage?.mediumUrl}
				className={classes.img}
				alt={selectedImage?.displayName ?? selectedImage?.visualHash}
			/>
		</Box>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		imagesContainer: {
			width: "33%",
		},
		img: {
			height: 200,
			borderRadius: 2,
			objectFit: "contain",
			margin: 15,
		},
		checkbox: {
			position: "absolute",
			margin: 3,
		},
	})
);

export default VersionCard;
