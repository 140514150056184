import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function ShowPasswordIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 22 18" {...props}>
			<path
				d="M21.8991 10.8602C20.9082 9.30805 19.5483 7.98787 17.9239 7.00101L20.0086 3.64151C20.1018 3.48898 20.1264 3.30834 20.077 3.13901C20.0276 2.96968 19.9082 2.8254 19.7449 2.73767C19.5817 2.64994 19.3877 2.62587 19.2055 2.67072C19.0232 2.71557 18.8674 2.82569 18.772 2.97706L16.6873 6.33921C15.1384 5.61067 13.4451 5.18638 11.7125 5.09269V1.23086C11.7125 1.05464 11.6373 0.885631 11.5034 0.761021C11.3695 0.636411 11.1879 0.566406 10.9986 0.566406C10.8092 0.566406 10.6276 0.636411 10.4937 0.761021C10.3598 0.885631 10.2846 1.05464 10.2846 1.23086V5.09269C8.55298 5.18636 6.86057 5.61067 5.31268 6.33921L3.2251 2.97706C3.12975 2.82569 2.97394 2.71557 2.79167 2.67072C2.60941 2.62587 2.41548 2.64994 2.25221 2.73767C2.08893 2.8254 1.96958 2.96968 1.92018 3.13901C1.87078 3.30834 1.89536 3.48898 1.98854 3.64151L4.07327 7.00101C2.44965 7.98797 1.09072 9.30816 0.10086 10.8602C0.034872 10.9621 0 11.0788 0 11.1977C0 11.3167 0.034872 11.4334 0.10086 11.5353C1.22034 13.2985 2.81432 14.7596 4.7256 15.7747C6.63688 16.7897 8.79944 17.3235 11 17.3235C13.2006 17.3235 15.3631 16.7897 17.2744 15.7747C19.1857 14.7596 20.7797 13.2985 21.8991 11.5353C21.9651 11.4334 22 11.3167 22 11.1977C22 11.0788 21.9651 10.9621 21.8991 10.8602ZM11.0043 15.9951C9.12685 15.9912 7.28009 15.5517 5.63109 14.7164C3.9821 13.8811 2.58293 12.6763 1.56017 11.211C2.47971 9.88063 3.71085 8.76118 5.16133 7.93656C5.16133 8.00567 5.16133 8.07211 5.16133 8.14122C5.16133 9.58626 5.77813 10.9721 6.87603 11.9939C7.97394 13.0157 9.46303 13.5898 11.0157 13.5898C12.5684 13.5898 14.0575 13.0157 15.1554 11.9939C16.2533 10.9721 16.8701 9.58626 16.8701 8.14122C16.8701 8.07477 16.8701 8.00833 16.8701 7.94188C18.3094 8.76366 19.5314 9.87645 20.4455 11.1977C19.4245 12.6649 18.0265 13.8719 16.378 14.7096C14.7294 15.5473 12.8825 15.9891 11.0043 15.9951Z"
				fill="#495057"
				fill-opacity="0.25"
			/>
		</SvgIcon>
	);
}

export default ShowPasswordIcon;
