class Machine {
	id: string;
	displayName: string;
	heartbeatName: string;
	isOn: boolean;
	lastSeen: number;

	constructor(
		id: string,
		displayName: string,
		heartbeatName: string = "",
		isOn: boolean = false
	) {
		this.id = id;
		this.displayName = displayName;
		this.heartbeatName = heartbeatName;
		this.isOn = isOn;
		this.lastSeen = 0;
	}
}

export default Machine;
