import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function Account(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 17 16" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.678 3.73112C11.678 5.49743 10.2212 6.92903 8.42373 6.92903C6.62631 6.92903 5.16949 5.49743 5.16949 3.73112C5.16949 1.9648 6.62631 0.533203 8.42373 0.533203C10.2212 0.533203 11.678 1.9648 11.678 3.73112Z"
				// stroke="white"
				strokeLinecap="square"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.8475 15.4568H3C3 14.6994 3 13.9789 3 13.3264C3 11.5591 4.45697 10.127 6.25424 10.127H10.5932C12.3905 10.127 13.8475 11.5591 13.8475 13.3264C13.8475 13.9789 13.8475 14.6994 13.8475 15.4568Z"
				// stroke="white"
				strokeLinecap="square"
			/>
		</SvgIcon>
	);
}

export default Account;
