import React from "react";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const AngryIcon = (props: SvgIconProps) => {
	return (
		<SvgIcon
			width="21"
			height="21"
			viewBox="0 0 21 21"
			fill="none"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.7578 19.4756C8.43717 19.4756 6.21157 18.5537 4.57063 16.9128C2.92969 15.2718 2.00781 13.0462 2.00781 10.7256C2.00781 8.40494 2.92969 6.17934 4.57063 4.5384C6.21157 2.89746 8.43717 1.97559 10.7578 1.97559C13.0785 1.97559 15.3041 2.89746 16.945 4.5384C18.5859 6.17934 19.5078 8.40494 19.5078 10.7256C19.5078 13.0462 18.5859 15.2718 16.945 16.9128C15.3041 18.5537 13.0785 19.4756 10.7578 19.4756ZM10.7578 20.7256C8.10565 20.7256 5.56211 19.672 3.68674 17.7967C1.81138 15.9213 0.757812 13.3778 0.757812 10.7256C0.757812 8.07342 1.81138 5.52988 3.68674 3.65452C5.56211 1.77915 8.10565 0.725586 10.7578 0.725586C13.41 0.725586 15.9535 1.77915 17.8289 3.65452C19.7042 5.52988 20.7578 8.07342 20.7578 10.7256C20.7578 13.3778 19.7042 15.9213 17.8289 17.7967C15.9535 19.672 13.41 20.7256 10.7578 20.7256Z"
				fill="#8800FF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.4024 16.2668C15.2588 16.3497 15.0882 16.3722 14.9281 16.3293C14.768 16.2864 14.6315 16.1816 14.5486 16.0381C14.1647 15.3727 13.6123 14.8201 12.947 14.4361C12.2816 14.0521 11.5268 13.8501 10.7586 13.8506C9.13986 13.8506 7.72611 14.7293 6.96861 16.0381C6.88514 16.1805 6.74875 16.2841 6.58919 16.3262C6.42963 16.3684 6.25986 16.3458 6.11693 16.2633C5.974 16.1807 5.86951 16.045 5.82627 15.8858C5.78303 15.7265 5.80454 15.5566 5.88611 15.4131C6.37971 14.5576 7.08993 13.8473 7.94529 13.3536C8.80065 12.8598 9.77098 12.6001 10.7586 12.6006C11.7462 12.6004 12.7164 12.8602 13.5717 13.3539C14.4271 13.8476 15.1373 14.5578 15.6311 15.4131C15.714 15.5566 15.7364 15.7272 15.6935 15.8873C15.6506 16.0474 15.5459 16.184 15.4024 16.2668Z"
				fill="#8800FF"
			/>
			<path
				d="M12.0078 8.85059C12.0078 9.88559 12.5678 10.7256 13.2578 10.7256C13.9478 10.7256 14.5078 9.88559 14.5078 8.85059C14.5078 7.81559 13.9478 6.97559 13.2578 6.97559C12.5678 6.97559 12.0078 7.81559 12.0078 8.85059ZM7.00781 8.85059C7.00781 9.88559 7.56781 10.7256 8.25781 10.7256C8.94781 10.7256 9.50781 9.88559 9.50781 8.85059C9.50781 7.81559 8.94781 6.97559 8.25781 6.97559C7.56781 6.97559 7.00781 7.81559 7.00781 8.85059Z"
				fill="#8800FF"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.6921 6.07006C15.6178 5.9222 15.488 5.80982 15.331 5.75757C15.174 5.70532 15.0027 5.71745 14.8546 5.79131L12.3546 7.04131C12.2786 7.07641 12.2104 7.12642 12.1541 7.18836C12.0979 7.25031 12.0546 7.32293 12.0269 7.40192C11.9992 7.48091 11.9876 7.56465 11.9929 7.64819C11.9982 7.73172 12.0202 7.81334 12.0577 7.88821C12.0951 7.96307 12.1472 8.02966 12.2108 8.08401C12.2745 8.13836 12.3484 8.17937 12.4282 8.20461C12.508 8.22985 12.5921 8.2388 12.6754 8.23093C12.7588 8.22306 12.8397 8.19854 12.9133 8.15881L15.4133 6.90881C15.5612 6.83454 15.6736 6.70468 15.7258 6.54768C15.7781 6.39068 15.7659 6.21937 15.6921 6.07131V6.07006ZM5.82459 6.07006C5.89885 5.9222 6.02872 5.80982 6.18572 5.75757C6.34271 5.70532 6.51402 5.71745 6.66209 5.79131L9.16209 7.04131C9.23807 7.07641 9.30623 7.12642 9.36253 7.18836C9.41882 7.25031 9.4621 7.32293 9.48979 7.40192C9.51748 7.48091 9.52903 7.56465 9.52374 7.64819C9.51845 7.73172 9.49644 7.81334 9.459 7.88821C9.42157 7.96307 9.36948 8.02966 9.30583 8.08401C9.24217 8.13836 9.16825 8.17937 9.08844 8.20461C9.00864 8.22985 8.92457 8.2388 8.84124 8.23093C8.75791 8.22306 8.67701 8.19854 8.60334 8.15881L6.10334 6.90881C5.95548 6.83454 5.8431 6.70468 5.79085 6.54768C5.73859 6.39068 5.75073 6.21937 5.82459 6.07131V6.07006Z"
				fill="#8800FF"
			/>
		</SvgIcon>
	);
};

export default AngryIcon;
