import React from "react";
import { Theme, createStyles } from "@material-ui/core/styles";
import AppContext from "../Context/Context";
import {
	Grid,
	Paper,
	withStyles,
	Typography,
	Button,
	Box,
	Chip,
	Fade,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Crop from "@material-ui/icons/Crop";
import ZoomIn from "@material-ui/icons/ZoomIn";
import ZoomOut from "@material-ui/icons/ZoomOut";
import ColorLens from "@material-ui/icons/ColorLens";
import ArrowBack from "@material-ui/icons/ArrowBack";
import queryString from "query-string";
import Cropper from "react-easy-crop";
import getCroppedImg from "../Helpers/cropImage";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import gql from "graphql-tag";
import client from "../GraphQL/client";
import {
	vdrObject_vdrObject as vdrObject,
	vdrObject as vdrMainObject,
	vdrObject_vdrObject_vdrAwsFaceDetection_faces,
} from "./types/vdrObject";
import { isDevelopment } from "../Config/Env";
import Analytics from "../Models/Analytics";
import Divider from "@material-ui/core/Divider";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { DBName } from "../types/graphql-global-types";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Slider from "@material-ui/core/Slider";
import {
	EmojiPeople,
	FileCopy,
	InsertEmoticon,
	MenuBook,
	Minimize,
	SentimentVeryDissatisfied,
	ThumbDown,
	ThumbUp,
} from "@material-ui/icons";
import Draggable from "react-draggable";
import { vdrObjectRawJsonString } from "../GraphQL/queries";
import { vdrObject_vdrObject_vdrAwsLabelDetection_labels } from "./types/vdrObject";
import { withRouter, RouteComponentProps } from "react-router";
import {
	getImageRepository,
	getFolders,
	getImagesReturnValues,
	getLabelsByMongoId,
} from "../GraphQL/queries";

import { images_images_images as Image } from "../GraphQL/types/images";
import BriaButton from "../Components/UI/BriaButton";
import { downloadImage } from "../Helpers/images";
import { toast } from "react-toastify";
interface IProp {
	dbName: DBName;
	classes: any;
}

type TParams = { mongoId: string };

class ImageReview extends React.Component<
	IProp & RouteComponentProps<TParams>,
	any
	> {
	state = {
		inHoverOverImage: false,
		calculatingPixelData: false,
		movmentInit: false,
		croppedImage: null,
		r: 0,
		g: 0,
		b: 0,
		x: 0,
		y: 0,
		alpah: 0,
		fullSize: false,
		cropEnabled: false,
		crop: { x: 0, y: 0 },
		zoom: 1,
		aspect: 4 / 3,
		croppedAreaPixels: {},
		colorDetection: false,
		setOpen: false,
		isLoading: false,
		VdrObjectData: null,
		rectArrData: [],
		landmarksData: [],
		isToggleFace: false,
		imageUrl: "",
		nextImageUrl: "",
		isMaskEnable: false,
		selectedPage: 0,
		imageSegmentations: [] as Segmentation[],
		classificationMenuToggle: "tools",
		shouldDisplayJSON: false,
		classificationMenuDisplay: true,
		imageData: {
			display_name: this.props.match.params.mongoId,
			visual_hash: "",
		},
		previousFolder: this.props.location.search,
		previousDB: this.props.dbName,
		indexOfCurrentPresentedImage: this.context.presentedImagesArray.indexOf(
			this.props.match.params.mongoId
		),
		currentMongoId: "",
	};

	static contextType = AppContext;

	uploadedFiles: Map<string, any> = new Map<string, any>();

	async getImageData(mongoId: string) {
		const result = JSON.parse(
			await vdrObjectRawJsonString(mongoId, this.props.dbName, client)
		);
		this.getVdrRepositoryImgs(result.visual_hash);
		this.setState({
			imageData: result,
			imageUrl: `${result.full_url}`,
		});
	}

	async fetchAllImagesFromFolder(selectedPage: number, initialPath?: string) {
		const getImagesReturnValues: getImagesReturnValues =
			await getImageRepository(
				this.context.validFacesToggle,
				this.context.mslToggle,
				[],
				[],
				selectedPage,
				500,
				"",
				this.props.dbName,
				this.props.location.search.split("=")[1] || "",
				JSON.stringify({
					$and: [
						{
							$or: [
								{ score: { $gt: 0 } },
								{ score: { $exists: false } },
								{ score: null },
							],
						},
					],
				}),
				this.context.selectedLabels,
				this.context.selectedText,
				client
			);
		this.context.addToPresentedImagesArray(
			getImagesReturnValues.images.map((image: Image) => image.mongoId)
		);
	}

	navigateImages(
		event: React.KeyboardEvent<HTMLDivElement>,
		presentedImagesArray: string[]
	) {
		const { history } = this.props;
		if (
			event.key === "ArrowLeft" &&
			this.state.indexOfCurrentPresentedImage > 0
		) {
			this.setState(
				{
					indexOfCurrentPresentedImage:
						this.state.indexOfCurrentPresentedImage - 1,
				},
				() => {
					const previousMongoId: string =
						presentedImagesArray[
						this.state.indexOfCurrentPresentedImage
						];
					const curFolder = queryString.parse(
						this.props.location.search
					).folder;
					if (!curFolder) {
						history.push(`${previousMongoId}`);
					} else
						history.push(
							`${previousMongoId}?folder=${curFolder}`
						);
					this.getImageData(previousMongoId);
				}
			);
		} else if (
			event.key === "ArrowRight" &&
			this.state.indexOfCurrentPresentedImage !==
			presentedImagesArray.length - 1
		) {
			this.setState(
				{
					indexOfCurrentPresentedImage:
						this.state.indexOfCurrentPresentedImage + 1,
				},
				() => {
					const nextmongoId: string =
						presentedImagesArray[
						this.state.indexOfCurrentPresentedImage
						];
					const curFolder = queryString.parse(
						this.props.location.search
					).folder;
					if (!curFolder) {
						history.push(`${nextmongoId}`);
					} else
						history.push(`${nextmongoId}?folder=${curFolder}`);
					this.getImageData(nextmongoId);
				}
			);
		}
	}

	showCroppedImage = async () => {
		try {
			const croppedImage = await getCroppedImg(
				this.state.imageUrl,
				this.state.croppedAreaPixels
			);
			this.setState({
				cropEnabled: false,
				croppedImage: croppedImage.image_src,
			});
		} catch (e) { }
	};

	onCropChange = (crop: any) => {
		this.setState({ crop });
	};

	onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
		this.setState({
			croppedAreaPixels: croppedAreaPixels,
		});
	};

	onZoomChange = (zoom: any) => {
		this.setState({ zoom });
	};

	handleDeleteForever = async () => {
		let filename = decodeURI(this.state.imageUrl.replace(/^.*[\\/]/, ""));
		filename = filename.replace("%2F", "/");
		try {
			this.setState({ isLoading: true });
			this.setState({ setOpen: false, isLoading: false });
			this.props.history.goBack();
		} catch (error) {
			this.setState({ setOpen: false, isLoading: false });
		}
	};

	onMouseOverEvent(e: any) {
		if (!this.state.colorDetection) {
			return;
		}
		if (this.state.calculatingPixelData) {
			return;
		}
		var img: any = document.getElementById("root_image");
		var element: any = img.parentNode;
		var style = window.getComputedStyle(element);
		var matrix = new WebKitCSSMatrix(style.transform);
		img.setAttribute("crossOrigin", "anonymous");
		img.src = this.state.croppedImage || this.state.imageUrl + "?t=12";
		var canvas = document.createElement("canvas");
		canvas.width = img.width;
		canvas.height = img.height;
		var context: any = canvas.getContext("2d");
		context.translate(matrix.m41, matrix.m42);
		context.scale(matrix.a, matrix.d);
		context.drawImage(img, 0, 0, img.width, img.height);
		var bounds = e.target.getBoundingClientRect();
		var x = e.clientX - (bounds.left + Math.abs(matrix.m41));
		var y = e.clientY - (bounds.top + Math.abs(matrix.m42));

		this.setState({ calculatingPixelData: true });
		if (this.state.movmentInit === false) {
			this.setState({ inHoverOverImage: true });
		}
		setTimeout(() => {
			var data = (canvas.getContext("2d") as any).getImageData(
				x,
				y,
				img.width,
				img.height
			).data;
			this.setState({
				r: data[0],
				g: data[1],
				b: data[2],
				alpah: data[3],
				x: x,
				y: y,
				movmentInit: true,
				calculatingPixelData: false,
			});
		}, 200);
	}

	componentDidMount() {
		this.getImageData(this.props.match.params.mongoId);
		this.fetchAllImagesFromFolder(1);
	}

	componentWillUnmount() {
		client.stop();
	}

	async getVdrRepositoryImgs(visualHashId: string): Promise<void> {
		try {
			const res = await client.query<vdrMainObject>({
				query: gql`
					query vdrObject($visualHash: String) {
						vdrObject(visualHash: $visualHash) {
							visualHash
							url
							width
							height
							imageLabels
							vdrSegmentation {
								primarySegmentation {
									maskUrlExternal
									maskUrlInternal
									algoName
									algoVersion
									overlap
								}
								allSegmentations {
									maskUrlExternal
									maskUrlInternal
									algoName
									algoVersion
									overlap
								}
							}
							validForTraining
							isInternal
							insertionDate
							vdrAwsLabelDetection {
								algoName
								algoVersion
								labels {
									name
									confidence
								}
							}
							vdrAwsFaceDetection {
								algoName
								algoVersion
								faces {
									faceSentiments {
										name
										confidence
									}
									ageRange {
										high
										low
									}
									faceAttributes {
										name
										value
										confidence
									}
									landmarks {
										x
										y
									}
									gender {
										value
										confidence
									}
									confidence
									sharpness
									brightness
									rect {
										top
										left
										width
										height
									}
								}
							}
						}
					}
				`,
				variables: {
					visualHash: visualHashId,
				},
			});
			if (res.data.vdrObject !== null) {
				(res.data.vdrObject.vdrAwsFaceDetection?.faces ?? []).map(
					(items: any) => {
						items.isChecked = false;
						return items;
					}
				);
			}
			const allSegmentations: Segmentation[] | undefined =
				res.data.vdrObject?.vdrSegmentation?.allSegmentations.map(
					(segmentation) =>
						new Segmentation(
							segmentation.maskUrlExternal,
							segmentation.maskUrlInternal,
							segmentation.algoName,
							segmentation.algoVersion,
							segmentation.overlap
						)
				);
			const primarySegmentation: Segmentation = res.data.vdrObject
				?.vdrSegmentation
				?.primarySegmentation as unknown as Segmentation;
			allSegmentations?.push(primarySegmentation);
			this.setState({
				imageSegmentations: allSegmentations ?? [],
				VdrObjectData: res.data.vdrObject,
			});
			return Promise.resolve();
		} catch (error) {
			console.log(error);
		}
	}

	calculate = (num: number, operation: string, xy?: number) => {
		let image = document.getElementById("root_image") as HTMLImageElement;
		let containertWidth = image.clientWidth;
		let imageWidth = image.naturalWidth;
		let imageHeight = image.naturalHeight;
		let image_height = imageHeight / (imageWidth / containertWidth);
		let image_height_ratio = imageHeight / image_height;
		let offsetLength = image.offsetLeft;
		let offsetTopLength = image.offsetTop;

		switch (operation) {
			case "x":
				return offsetLength + num / (imageWidth / containertWidth);
			case "y":
				return offsetTopLength + num / image_height_ratio;
			case "width":
				if (xy) return num / (imageWidth / containertWidth);
				break;
			case "height":
				if (xy) return num / image_height_ratio;
				break;
			default:
				return 0;
		}
		return 0;
	};

	handleChange = (event: any) => {
		const isMaskEnable: boolean = event.target.checked;
		if (isMaskEnable) {
			Analytics.post(`view mask clicked`);
		}
		this.setState({ isMaskEnable: isMaskEnable });
	};

	handleCreatePlot = (e: any, rect: any, landmarks: any, index: any) => {
		let checked = e.target.checked;
		this.setState({ isToggleFace: true });
		this.setState((prevState: any) => {
			const VdrObjectData = prevState.VdrObjectData as vdrObject | null;
			(VdrObjectData?.vdrAwsFaceDetection?.faces ?? []).map(
				(item: any, indexs: any) => {
					if (index === indexs) {
						item.isChecked = checked;
					}
					return item;
				}
			);
			return VdrObjectData;
		});

		let rectArrData = this.state.rectArrData;
		let landmarksData = this.state.landmarksData;
		if (e.target.checked) {
			let obj: any = { id: index };
			let response = { ...obj, ...rect };
			let rectArr: any = [];
			rectArr.push(response);
			let objData: any = {
				id: index,
				landmarks: landmarks,
			};
			let landmarksData: any = [];
			landmarksData.push(objData);
			this.setState({
				rectArrData: this.state.rectArrData.concat(rectArr),
				landmarksData: this.state.landmarksData.concat(landmarksData),
			});
		} else {
			rectArrData.splice(
				rectArrData.findIndex(function (i: any) {
					return i.id === index;
				}),
				1
			);
			landmarksData.splice(
				landmarksData.findIndex(function (i: any) {
					return i.id === index;
				}),
				1
			);
			this.setState({
				rectArrData: rectArrData,
				landmarksData: landmarksData,
			});
		}
	};

	handleChangeToggle = (event: any) => {
		if (!event.target.checked) {
			this.setState({ isToggleFace: false });
		} else {
			this.setState({ isToggleFace: true });
		}
	};

	handleClassificationMenuToggleChange = (
		event: React.MouseEvent<HTMLElement>,
		newToggle: string | null
	) => {
		this.setState({ classificationMenuToggle: newToggle });
	};

	toggleButtons() {
		const { classes } = this.props;
		return (
			<ToggleButtonGroup
				value={this.state.classificationMenuToggle}
				exclusive
				onChange={(e: any) =>
					this.handleClassificationMenuToggleChange(
						e,
						e.currentTarget.value
					)
				}
				aria-label="text alignment"
				classes={{ root: classes.toggleButtonGroupRoot }}
			>
				<ToggleButton
					classes={{
						root: classes.toggleButtonRoot,
						selected: classes.toggleButtonSelected,
					}}
					value="tools"
					aria-label="left aligned"
				>
					<Typography
						variant="body2"
						color="textPrimary"
						display="block"
						style={{ textTransform: "none", fontWeight: "bold" }}
					>
						Masks
					</Typography>
				</ToggleButton>
				<ToggleButton
					classes={{
						root: classes.toggleButtonRoot,
						selected: classes.toggleButtonSelected,
					}}
					value="sentiments"
					aria-label="centered"
				>
					<Typography
						variant="button"
						color="textPrimary"
						display="block"
						style={{ textTransform: "none", fontWeight: "bold" }}
					>
						Sentiments
					</Typography>
				</ToggleButton>
				<ToggleButton
					classes={{
						root: classes.toggleButtonRoot,
						selected: classes.toggleButtonSelected,
					}}
					value="faceDetection"
					aria-label="right aligned"
				>
					<Typography
						variant="button"
						color="textPrimary"
						display="block"
						style={{ textTransform: "none", fontWeight: "bold" }}
					>
						Face Detection
					</Typography>
				</ToggleButton>
				<ToggleButton
					classes={{
						root: classes.toggleButtonRoot,
						selected: classes.toggleButtonSelected,
					}}
					value="visualDetections"
					aria-label="right aligned"
				>
					<Typography
						variant="button"
						color="textPrimary"
						display="block"
						style={{ textTransform: "none", fontWeight: "bold" }}
					>
						Visuals Detection
					</Typography>
				</ToggleButton>
				<ToggleButton
					classes={{
						root: classes.toggleButtonRoot,
						selected: classes.toggleButtonSelected,
					}}
					value="briaLabels"
					aria-label="right aligned"
				>
					<Typography
						variant="button"
						color="textPrimary"
						display="block"
						style={{ textTransform: "none", fontWeight: "bold" }}
					>
						Bria Labels
					</Typography>
				</ToggleButton>
				<ToggleButton
					classes={{
						root: classes.toggleButtonRoot,
						selected: classes.toggleButtonSelected,
					}}
					value="imageInfo"
					aria-label="right aligned"
				>
					<Typography
						variant="button"
						color="textPrimary"
						display="block"
						style={{ textTransform: "none", fontWeight: "bold" }}
					>
						Image Info
					</Typography>
				</ToggleButton>
			</ToggleButtonGroup>
		);
	}

	iconSelection(emotion: string): JSX.Element {
		switch (emotion) {
			case "HAPPY":
				return <InsertEmoticon />;
			case "CALM":
				return <InsertEmoticon />;
			case "ANGRY":
				return <InsertEmoticon />;
			case "SURPRISED":
				return <InsertEmoticon />;
			case "FEAR":
				return <InsertEmoticon />;
			case "SAD":
				return <SentimentVeryDissatisfied />;
			case "CONFUSED":
				return <InsertEmoticon />;
			case "DISGUSTED":
				return <InsertEmoticon />;
			default:
				return <div></div>;
		}
	}

	visualDetections(): JSX.Element {
		const { VdrObjectData } = this.state;
		const VdrData = VdrObjectData as vdrObject | null;
		const { classes } = this.props;
		if (VdrData === null) {
			return <div />;
		}

		const labelDetection = VdrData.vdrAwsLabelDetection;

		if (!labelDetection) {
			return <div />;
		}

		const awsLabels:
			| vdrObject_vdrObject_vdrAwsLabelDetection_labels[]
			| null = labelDetection.labels;

		if (!awsLabels) {
			return <div />;
		}

		return (
			<TableContainer style={{ width: "80%", margin: "0 auto" }}>
				<Table className={classes.table} aria-label="customized table">
					<TableHead style={{ background: "rgba(38, 38, 38, 0.8)" }}>
						<TableRow>
							<TableCell style={{ fontWeight: "bold" }}>
								Detected Labels
							</TableCell>
							<TableCell
								align="right"
								style={{ fontWeight: "bold" }}
							>
								Confidence
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{awsLabels.map((label: any, index: number) => (
							<TableRow
								key={index}
								style={{
									background:
										index % 2 === 0
											? "none"
											: "rgba(82, 0, 200, 0.2)",
								}}
							>
								<TableCell component="th" scope="row">
									{label.name}
								</TableCell>
								<TableCell align="right">
									{Math.round(label.confidence)}%
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		);
	}

	renderAgeRange(detectedPerson: any): JSX.Element {
		const { classes } = this.props;
		const ageRange: { high: number; low: number } = detectedPerson.ageRange;
		const ageLow: number = ageRange.low;
		const ageHigh: number = ageRange.high;
		const ageRangeArray: number[] = [ageLow, ageHigh];

		if (!ageRange || !ageLow || !ageHigh) {
			return <div />;
		}

		const marks: any[] = [
			{ value: 0, label: "0" },
			{ value: 100, label: "100" },
		];

		marks.push(
			{ value: ageLow, label: `${ageLow}` },
			{ value: ageHigh, label: `${ageHigh}` }
		);

		return (
			<Grid container spacing={2} justify="flex-start">
				<Grid item xs={4}>
					<Typography
						id="input-slider"
						gutterBottom
						variant="body2"
						color="textPrimary"
						style={{ textAlign: "left", marginLeft: "0.5rem" }}
					>
						Age Range
					</Typography>
				</Grid>
				<Grid item xs={7}>
					<Slider
						classes={{
							markLabel: classes.markLabel,
							markLabelActive: classes.markLabelActive,
						}}
						value={ageRangeArray}
						aria-labelledby="range-slider"
						marks={marks}
						min={0}
						max={100}
					/>
				</Grid>
			</Grid>
		);
	}

	renderGender(detectedPerson: any): JSX.Element {
		const genderRecognition: { value: string; confidence: number } =
			detectedPerson.gender;
		const genderValue: string = genderRecognition.value;
		const genderConfidence: number = genderRecognition.confidence;

		if (!genderRecognition || !genderValue || !genderConfidence) {
			return <div />;
		}

		return (
			<Grid
				container
				spacing={2}
				alignItems="center"
				justify="flex-start"
			>
				<Grid item xs={3}>
					<Typography
						id="input-slider"
						gutterBottom
						variant="body2"
						color="textPrimary"
						style={{ textAlign: "left", marginLeft: "0.5rem" }}
					>
						Gender
					</Typography>
				</Grid>
				<Grid item xs={2}>
					<Typography
						id="input-slider"
						gutterBottom
						variant="body2"
						color="textSecondary"
						style={{ textAlign: "left" }}
					>
						{genderValue === "Male" ? (
							<EmojiPeople />
						) : (
								<EmojiPeople />
							)}
					</Typography>
				</Grid>
				<Grid item xs={2}>
					<Typography
						id="input-slider"
						gutterBottom
						variant="body2"
						color="textPrimary"
						style={{ textAlign: "left" }}
					>
						{genderValue}
					</Typography>
				</Grid>
				<Grid item xs={3}>
					<Typography
						id="input-slider"
						gutterBottom
						variant="body2"
						color="textPrimary"
						style={{ textAlign: "left" }}
					>
						Confidence:
					</Typography>
				</Grid>
				<Grid item xs={2}>
					<Typography
						id="input-slider"
						gutterBottom
						variant="body2"
						color="textPrimary"
						style={{ textAlign: "left" }}
					>
						{`${Math.round(genderConfidence)}%`}
					</Typography>
				</Grid>
			</Grid>
		);
	}

	renderQuality(detectedPerson: any): JSX.Element {
		const brightness: number = detectedPerson.brightness;
		const sharpness: number = detectedPerson.sharpness;

		if (!brightness || !sharpness) {
			return <div />;
		}

		return (
			<Grid
				container
				spacing={2}
				alignItems="center"
				justify="center"
				style={{ marginBottom: "1rem" }}
			>
				<Grid item xs={3}>
					<Typography
						id="input-slider"
						gutterBottom
						variant="body2"
						color="textPrimary"
					>
						Brightness: {Math.round(brightness)}%
					</Typography>
				</Grid>
				<Grid item xs={3}>
					<Typography
						id="input-slider"
						gutterBottom
						variant="body2"
						color="textPrimary"
					>
						Sharpness: {Math.round(sharpness)}%
					</Typography>
				</Grid>
			</Grid>
		);
	}

	renderConfidence(detectedPerson: any): JSX.Element {
		const confidence: number | undefined = Math.round(
			detectedPerson.confidence
		);

		if (!confidence) {
			return <div />;
		}

		const marks = [
			{
				value: confidence,
				label: `${confidence}%`,
			},
		];

		return (
			<Grid container spacing={2} justify="center">
				<Grid item xs={3}>
					<Typography
						id="input-slider"
						gutterBottom
						variant="button"
						style={{
							textTransform: "none",
							fontWeight: "bold",
							textAlign: "left",
						}}
						color="textPrimary"
					>
						Confidence
					</Typography>
				</Grid>
				<Grid item xs={3}>
					<Slider
						value={confidence}
						valueLabelDisplay="auto"
						aria-labelledby="continuous-slider"
						marks={marks}
					/>
				</Grid>
			</Grid>
		);
	}

	imageBasics(detectedPerson: any): JSX.Element {
		return (
			<div>
				{this.renderAgeRange(detectedPerson)}
				{this.renderGender(detectedPerson)}
				{this.renderQuality(detectedPerson)}
				{this.renderConfidence(detectedPerson)}
			</div>
		);
	}

	imageInfo(): JSX.Element {
		const { VdrObjectData } = this.state;
		const VdrData = VdrObjectData as vdrObject | null;

		if (!VdrData) {
			return <div />;
		}

		const url: string = VdrData.url;
		const insertionDate: number = VdrData.insertionDate;
		const readableInsertionDate: string = new Date(
			insertionDate * 1000
		).toDateString();

		return (
			<div>
				<Grid container spacing={2} style={{ marginTop: "1rem" }}>
					<Grid item xs={3} style={{ textAlign: "left" }}>
						<Typography
							id="input-slider"
							gutterBottom
							variant="button"
							style={{
								textTransform: "none",
								fontWeight: "bold",
							}}
							color="textPrimary"
						>
							URL path:
						</Typography>
					</Grid>
					<Grid item xs={9} style={{ position: "relative" }}>
						<Typography
							id="input-slider"
							gutterBottom
							variant="button"
							style={{
								textTransform: "none",
								fontWeight: "bold",
							}}
							color="textPrimary"
						>
							{url}
						</Typography>
						<IconButton
							size="small"
							style={{
								position: "absolute",
								right: "0.5rem",
								padding: "0",
								bottom: "8px",
							}}
						>
							<FileCopy
								onClick={() => {
									navigator.clipboard.writeText(url);
								}}
							/>
						</IconButton>
					</Grid>
				</Grid>
				<Divider />
				<Grid container spacing={2} style={{ marginTop: "1rem" }}>
					<Grid item xs={3} style={{ textAlign: "left" }}>
						<Typography
							id="input-slider"
							gutterBottom
							variant="button"
							style={{
								textTransform: "none",
								fontWeight: "bold",
							}}
							color="textPrimary"
						>
							Insertion date:
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<Typography
							id="input-slider"
							gutterBottom
							variant="button"
							style={{
								textTransform: "none",
								fontWeight: "bold",
							}}
							color="textPrimary"
						>
							{readableInsertionDate}
						</Typography>
					</Grid>
				</Grid>
				<Divider />
				<Grid container spacing={2} style={{ marginTop: "1rem" }}>
					<Grid item xs={3} style={{ textAlign: "left" }}>
						<Typography
							id="input-slider"
							gutterBottom
							variant="button"
							style={{
								textTransform: "none",
								fontWeight: "bold",
							}}
							color="textPrimary"
						>
							Width
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<Typography
							id="input-slider"
							gutterBottom
							variant="button"
							style={{
								textTransform: "none",
								fontWeight: "bold",
							}}
							color="textPrimary"
						>
							{VdrData.width}px
						</Typography>
					</Grid>
				</Grid>
				<Divider />
				<Grid container spacing={2} style={{ marginTop: "1rem" }}>
					<Grid item xs={3} style={{ textAlign: "left" }}>
						<Typography
							id="input-slider"
							gutterBottom
							variant="button"
							style={{
								textTransform: "none",
								fontWeight: "bold",
							}}
							color="textPrimary"
						>
							Height
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<Typography
							id="input-slider"
							gutterBottom
							variant="button"
							style={{
								textTransform: "none",
								fontWeight: "bold",
							}}
							color="textPrimary"
						>
							{VdrData.height}px
						</Typography>
					</Grid>
				</Grid>
				<Divider />
				<Grid container spacing={2} style={{ marginTop: "1rem" }}>
					<Grid item xs={3} style={{ textAlign: "left" }}>
						<Typography
							id="input-slider"
							gutterBottom
							variant="button"
							style={{
								textTransform: "none",
								fontWeight: "bold",
							}}
							color="textPrimary"
						>
							Internal image
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<Typography
							id="input-slider"
							gutterBottom
							variant="button"
							style={{
								textTransform: "none",
								fontWeight: "bold",
							}}
							color="textPrimary"
						>
							{VdrData.isInternal ? "Yes" : "No"}
						</Typography>
					</Grid>
				</Grid>
				<Divider />
				<Grid container spacing={2} style={{ marginTop: "1rem" }}>
					<Grid item xs={3} style={{ textAlign: "left" }}>
						<Typography
							id="input-slider"
							gutterBottom
							variant="button"
							style={{
								textTransform: "none",
								fontWeight: "bold",
							}}
							color="textPrimary"
						>
							Valid for training
						</Typography>
					</Grid>
					<Grid item xs={9}>
						<Typography
							id="input-slider"
							gutterBottom
							variant="button"
							style={{
								textTransform: "none",
								fontWeight: "bold",
							}}
							color="textPrimary"
						>
							{VdrData.validForTraining ? "Yes" : "No"}
						</Typography>
					</Grid>
				</Grid>
				<Divider />
			</div>
		);
	}

	faceDetection(): JSX.Element {
		const { VdrObjectData } = this.state;
		const VdrData = VdrObjectData as vdrObject | null;

		if (VdrData === null) {
			return <div />;
		}

		const faceDetection = VdrData.vdrAwsFaceDetection;

		if (!faceDetection) {
			return <div />;
		}

		const awsFaces: vdrObject_vdrObject_vdrAwsFaceDetection_faces[] | null =
			faceDetection.faces;

		if (!awsFaces) {
			return <div />;
		}

		return (
			<div>
				{this.faceRectangles()}
				{awsFaces.map((detectedPerson: any, index: number) => {
					const sortedRecognitions: any[] = [];
					detectedPerson.faceAttributes.map((faceAttribute: any) => {
						sortedRecognitions.push(faceAttribute);
					});
					sortedRecognitions.sort((recognitionA, recognitionB) =>
						recognitionA.confidence > recognitionB.confidence
							? -1
							: 1
					);
					return (
						<div
							style={{
								padding: "0.5rem",
								border: "1px solid white",
								marginBottom: "0.5rem",
							}}
						>
							<Typography
								variant="h6"
								style={{
									textTransform: "none",
									fontWeight: "bold",
								}}
							>
								Person {index + 1}
							</Typography>
							<Paper
								style={{
									marginBottom: "1rem",
									padding: "0.5rem",
								}}
							>
								{this.imageBasics(detectedPerson)}
							</Paper>
							<TableContainer>
								<Table aria-label="customized table">
									<TableHead
										style={{
											background: "rgba(38, 38, 38, 0.8)",
										}}
									>
										<TableRow>
											<TableCell
												style={{ fontWeight: "bold" }}
											>
												Searched element
											</TableCell>
											<TableCell
												align="right"
												style={{ fontWeight: "bold" }}
											>
												Found?
											</TableCell>
											<TableCell
												align="right"
												style={{ fontWeight: "bold" }}
											></TableCell>
											<TableCell
												align="right"
												style={{ fontWeight: "bold" }}
											>
												Confidence
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{sortedRecognitions.map(
											(
												recognition: any,
												index: number
											) => {
												return (
													<TableRow
														key={index}
														style={{
															background:
																index % 2 === 0
																	? "none"
																	: "rgba(82, 0, 200, 0.2)",
														}}
													>
														<TableCell
															component="th"
															scope="row"
														>
															{recognition.name}
														</TableCell>
														<TableCell align="right">
															{recognition.value ===
																true
																? "Yes"
																: "No"}
														</TableCell>
														<TableCell align="right">
															{recognition.value ===
																true ? (
																	<ThumbUp />
																) : (
																	<ThumbDown />
																)}
														</TableCell>
														<TableCell align="right">{`${Math.round(
															recognition.confidence
														)}%`}</TableCell>
													</TableRow>
												);
											}
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</div>
					);
				})}
			</div>
		);
	}

	imageSentiments(): JSX.Element[] | JSX.Element {
		const { VdrObjectData } = this.state;
		const VdrData = VdrObjectData as vdrObject | null;

		if (VdrData === null) {
			return <div />;
		}

		const detectedPeople = VdrData.vdrAwsFaceDetection?.faces;

		if (!detectedPeople) {
			return <div />;
		}

		return detectedPeople.map((detectedPerson: any, index: number) => {
			return (
				<Paper style={{ marginBottom: "1rem", padding: "0.5rem" }}>
					<Typography
						variant="h6"
						style={{ textTransform: "none", fontWeight: "bold" }}
					>
						Person {index + 1}
					</Typography>
					{detectedPerson.faceSentiments.map((emotion: any) => {
						const lowerCaseEmotionName = emotion.name.toLowerCase();
						return (
							<Grid
								container
								spacing={2}
								alignItems="center"
								justify="flex-start"
							>
								<Grid item xs={1}>
									{this.iconSelection(emotion.name)}
								</Grid>
								<Grid item xs={3}>
									<Typography
										id="input-slider"
										gutterBottom
										variant="body2"
										color="textSecondary"
										style={{
											textAlign: "left",
											textTransform: "capitalize",
										}}
									>
										{lowerCaseEmotionName}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<Slider
										value={Math.round(emotion.confidence)}
										aria-labelledby="input-slider"
										disabled
									/>
								</Grid>
								<Grid item xs={2}>
									<Typography
										id="input-slider"
										gutterBottom
										variant="body2"
										color="textPrimary"
										style={{ textAlign: "left" }}
									>
										{`${Math.round(emotion.confidence)}%`}
									</Typography>
								</Grid>
							</Grid>
						);
					})}
				</Paper>
			);
		});
	}

	briaLabels(): JSX.Element {
		const { classes } = this.props;
		const { VdrObjectData } = this.state;
		const VdrData = VdrObjectData as vdrObject | null;

		if (VdrData === null) {
			return <div />;
		}

		const briaLabels: (string | null)[] = VdrData.imageLabels ?? [];
		if (!briaLabels) {
			return (
				<Typography>No labels added by Bria for this image</Typography>
			);
		}

		let overlap: boolean = false;

		for (let i = 0; i < this.state.imageSegmentations.length; i++) {
			if (this.state.imageSegmentations[i].overlap) {
				overlap = true;
				break;
			}
		}

		return (
			<Grid className={classes.chipsContainer} container>
				{briaLabels.map(
					(label: any, index: number) =>
						label !== "" && (
							<Chip
								key={`${index}${label}`}
								label={label}
								size="medium"
								style={{
									margin: "5px",
									fontSize: "1rem",
									padding: "0.25rem",
								}}
								clickable
								color="primary"
							/>
						)
				)}
				{overlap && (
					<Chip
						key={"overlap"}
						label={"Overlap"}
						size="medium"
						style={{
							margin: "5px",
							fontSize: "1rem",
							padding: "0.25rem",
						}}
						clickable
						color="primary"
					/>
				)}
			</Grid>
		);
	}

	bodySegmentation(): JSX.Element {
		const { VdrObjectData } = this.state;
		const VdrData = VdrObjectData as vdrObject | null;
		const { classes } = this.props;

		if (!VdrData) {
			return <div />;
		}

		const externalMasks = this.state.imageSegmentations.filter(
			(mask) => mask.maskUrlExternal !== null
		);
		const internalMasks = this.state.imageSegmentations.filter(
			(mask) => mask.maskUrlInternal !== null
		);

		return VdrData?.visualHash != undefined ? (
			<div>
				{externalMasks.map((segmentation: Segmentation) => {
					let algoName: string = segmentation.algoName;
					if (segmentation.algoName === "manual_segmentation") {
						algoName = "MSL_semantic_segmentation";
					}
					return (
						<Grid container>
							<Grid item>
								<h3>{algoName}</h3>
							</Grid>
							<Grid item xs={2}>
								<h4>{segmentation.algoVersion}</h4>
							</Grid>
							<Grid
								item
								xs={2}
								style={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<span>
									<Switch
										classes={{
											root: classes.switchRoot,
										}}
										name="checkedA"
										onChange={(event: any) => {
											const isMaskEnabled: boolean =
												event.target.checked;
											if (isMaskEnabled) {
												Analytics.post(
													`view mask clicked`
												);
											}
											segmentation.showExternalMask =
												isMaskEnabled;
										}}
										checked={segmentation.showExternalMask}
									/>
								</span>
							</Grid>
						</Grid>
					);
				})}
				<Divider />
				<Typography align="left">
					{internalMasks.length === 0
						? "No internal masks for this visual"
						: "Internal Masks"}
				</Typography>
				{internalMasks?.map((segmentation: Segmentation) => {
					let algoName: string = segmentation.algoName;
					if (segmentation.algoName === "manual_segmentation") {
						algoName = "MSL_semantic_segmentation";
					}
					if (algoName !== "msl") {
						return (
							<Grid container>
								<Grid item>
									<h3>{algoName}_internal</h3>
								</Grid>
								<Grid item xs={2}>
									<h4>{segmentation.algoVersion}</h4>
								</Grid>
								<Grid
									item
									xs={2}
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<span>
										<Switch
											classes={{
												root: classes.switchRoot,
											}}
											name="checkedA"
											onChange={(event: any) => {
												const isMaskEnabled: boolean =
													event.target.checked;
												if (isMaskEnabled) {
													Analytics.post(
														`view mask clicked`
													);
												}
												segmentation.showInternalMask =
													isMaskEnabled;
											}}
											checked={
												segmentation.showInternalMask
											}
										/>
									</span>
								</Grid>
							</Grid>
						);
					}
				})}
			</div>
		) : (
				<div />
			);
	}

	faceRectangles(): JSX.Element {
		const { VdrObjectData } = this.state;
		const VdrData = VdrObjectData as vdrObject | null;
		const { classes } = this.props;

		return VdrData === null ? (
			<div></div>
		) : VdrData?.visualHash != undefined ? (
			<Paper style={{ padding: "0.5rem", marginBottom: "1rem" }}>
				<Box display="flex" justifyContent="center" alignItems="center">
					<Box p={1}>
						<h4>Face Detection V1.0.0</h4>
					</Box>
					<Box p={1}>
						<Switch
							classes={{ root: classes.switchRoot }}
							name="checkedA"
							onChange={this.handleChangeToggle}
							checked={this.state.isToggleFace}
						/>
					</Box>
				</Box>
				{(VdrData?.vdrAwsFaceDetection?.faces ?? []).map(
					(item: any, index: any) => (
						<Box
							display="flex"
							justifyContent="flex-start"
							alignItems="center"
						>
							<Box p={1}>
								<Typography
									variant="button"
									color="textPrimary"
									display="block"
									style={{
										textTransform: "none",
										fontWeight: "bold",
									}}
								>
									Person {index + 1}:{" "}
								</Typography>
							</Box>
							<Box p={1}>
								<FormControlLabel
									control={
										<Checkbox
											name="gilad1"
											checked={
												item.isChecked &&
												this.state.isToggleFace
											}
											onChange={(e) =>
												this.handleCreatePlot(
													e,
													item.rect,
													item.landmarks,
													index
												)
											}
										/>
									}
									label={`x:${Math.floor(
										item.rect.left
									)}, y:${Math.floor(
										item.rect.top
									)}, w:${Math.floor(
										item.rect.width
									)}, h:${Math.floor(item.rect.height)}`}
								/>
							</Box>
						</Box>
					)
				)}
			</Paper>
		) : (
					<div />
				);
	}

	showJSON(): JSX.Element {
		const { shouldDisplayJSON, VdrObjectData } = this.state;
		const VdrData = VdrObjectData as vdrObject | null;

		if (VdrData === null) {
			return <div />;
		}

		return (
			<div>
				<Grid container>
					<Grid item xs={8}></Grid>
				</Grid>
				<div
					style={{
						maxHeight: "8rem",
						maxWidth: "100%",
						display: shouldDisplayJSON ? "flex" : "none",
						flexWrap: "wrap",
						border: "1px solid white",
						overflow: "scroll",
						backgroundColor: "rgba(52, 52, 52, 0.5)",
					}}
				>
					<pre>{JSON.stringify(VdrData, null, 1)}</pre>
				</div>
			</div>
		);
	}

	handleMenuDisplay() {
		this.setState({
			classificationMenuDisplay: !this.state.classificationMenuDisplay,
		});
	}

	minimizeButton(): JSX.Element {
		return (
			<IconButton
				style={{
					position: "absolute",
					top: "-8px",
					right: "18px",
					padding: "0",
				}}
				onClick={() => {
					this.handleMenuDisplay();
				}}
			>
				<Minimize />
			</IconButton>
		);
	}

	maximizeButton(): JSX.Element {
		return (
			<IconButton
				style={{
					display: this.state.classificationMenuDisplay
						? "none"
						: "block",
				}}
				onClick={() => {
					this.handleMenuDisplay();
				}}
			>
				<MenuBook />
			</IconButton>
		);
	}

	classificationMenu(): JSX.Element {
		const { VdrObjectData } = this.state;
		const VdrData = VdrObjectData as vdrObject | null;
		const { classes } = this.props;
		if (VdrData === null) {
			return <div />;
		}

		return (
			<Fade in={this.state.classificationMenuDisplay}>
				<div
					style={{
						display: this.state.classificationMenuDisplay
							? "block"
							: "none",
					}}
				>
					<Draggable
						positionOffset={{ x: 0.59 * window.innerWidth, y: "0" }}
					>
						<Grid item className={classes.classificationMenu}>
							<Paper className={classes.paper}>
								{this.minimizeButton()}
								{this.toggleButtons()}
								<div
									style={{
										height: "auto",
										maxHeight: "31rem",
										overflow: "scroll",
										marginBottom: "1rem",
									}}
								>
									{this.state.classificationMenuToggle ===
										"tools" ? (
											this.bodySegmentation()
										) : this.state.classificationMenuToggle ===
											"sentiments" ? (
												this.imageSentiments()
											) : this.state.classificationMenuToggle ===
												"faceDetection" ? (
													this.faceDetection()
												) : this.state.classificationMenuToggle ===
													"visualDetections" ? (
														this.visualDetections()
													) : this.state.classificationMenuToggle ===
														"briaLabels" ? (
															this.briaLabels()
														) : this.state.classificationMenuToggle ===
															"imageInfo" ? (
																this.imageInfo()
															) : (
																<div />
															)}
								</div>
								{this.showJSON()}
							</Paper>
						</Grid>
					</Draggable>
				</div>
			</Fade>
		);
	}

	content(): JSX.Element {
		const { rectArrData, landmarksData } = this.state;
		const { classes } = this.props;
		const imageSrc = this.state.croppedImage ?? this.state.imageUrl;
		if (this.state.cropEnabled) {
			return (
				<div>
					<Cropper
						image={this.state.imageUrl}
						crop={this.state.crop}
						zoom={this.state.zoom}
						aspect={this.state.aspect}
						onCropChange={this.onCropChange}
						onCropComplete={this.onCropComplete}
						onZoomChange={this.onZoomChange}
					/>
					<Button
						onClick={this.showCroppedImage}
						variant="contained"
						color="primary"
						classes={{ root: classes.cropButton }}
					>
						Done
					</Button>
				</div>
			);
		}
		return (
			<div className={classes.imgbox} style={{ width: "initial" }}>
				<Box position="absolute" zIndex="tooltip">
					{this.classificationMenu()}
				</Box>
				<Box
					position="relative"
					margin="auto"
					onMouseMove={(e: any) => {
						this.onMouseOverEvent(e);
					}}
					onMouseLeave={(_: any) =>
						this.setState({ inHoverOverImage: false })
					}
					onMouseEnter={(_: any) =>
						this.setState({ inHoverOverImage: true })
					}
				>
					<TransformWrapper
						options={{ centerContent: true, limitToBounds: false }}
						wheel={{
							step:
								this.props.dbName !== DBName.RESEARCH ? 10 : 40,
						}}
					>
						<TransformComponent>
							<img
								onMouseMove={(e) => {
									this.onMouseOverEvent(e);
								}}
								onMouseLeave={(_) =>
									this.setState({ inHoverOverImage: false })
								}
								onMouseEnter={(_) =>
									this.setState({ inHoverOverImage: true })
								}
								id="root_image"
								style={{
									//position: "absolute",
									maxHeight: this.state.fullSize
										? undefined
										: "75vh",
									width:
										this.props.dbName !== DBName.RESEARCH
											? "auto"
											: "100%",
								}}
								src={imageSrc}
								alt={imageSrc}
							/>
							{
								<div>
									{this.state.imageSegmentations.map(
										(segmentation: Segmentation) => {
											let maskUrl = `https://bria-image-repository.s3.amazonaws.com/${isDevelopment ? "" : ""
												}${segmentation.showExternalMask
													? segmentation.maskUrlExternal
													: segmentation.maskUrlInternal
												}`;
											return (
												(segmentation.showExternalMask ||
													segmentation.showInternalMask) && (
													<img
														src={maskUrl}
														alt={maskUrl}
														style={{
															position:
																"absolute",
															opacity: 0.7,
															maxHeight: this
																.state.fullSize
																? undefined
																: "100vh",
															margin: "auto",
															height: "100%",
															top: 0,
															left: 0,
															right: 0,
														}}
													/>
												)
											);
										}
									)}
									<div>
										{this.state.isToggleFace &&
											landmarksData.length > 0 &&
											landmarksData.map((items: any) =>
												items.landmarks.map(
													(item: any, _: any) => (
														<Box
															component="span"
															className={
																classes.imageMask1
															}
															style={{
																left: this.calculate(
																	item.x,
																	"x"
																),
																top: this.calculate(
																	item.y,
																	"y"
																),
															}}
														></Box>
													)
												)
											)}
										{this.state.isToggleFace &&
											rectArrData.length > 0 &&
											rectArrData.map((item: any) => (
												<Box
													component="span"
													className={
														classes.imageRect
													}
													style={{
														left: this.calculate(
															item.left,
															"x"
														),
														top: this.calculate(
															item.top,
															"y"
														),
														width: this.calculate(
															item.width,
															"width",
															item.left
														),
														height: this.calculate(
															item.height,
															"height",
															item.top
														),
													}}
												></Box>
											))}
									</div>
								</div>
							}
						</TransformComponent>
					</TransformWrapper>
				</Box>
			</div>
		);
	}

	render() {
		const { VdrObjectData } = this.state;
		const { classes, history } = this.props;
		try {
		} catch { }
		const showColorDetection =
			this.state.inHoverOverImage && this.state.colorDetection;

		const handleClose = () => {
			this.setState({ setOpen: false });
		};
		return (
			<React.Fragment>
				<AppContext.Consumer>
					{(context) => {
						const hideEditButtons =
							this.props.dbName !== DBName.RESEARCH;
						return (
							<div
								className="uploadPic"
								style={{ width: "100%", textAlign: "center" }}
								onKeyDown={(event) =>
									this.navigateImages(
										event,
										context.presentedImagesArray
									)
								}
								tabIndex={0}
							>
								<AppBar
									position="relative"
									className={classes.appBar}
								>
									<Toolbar>
										<IconButton
											onClick={() => {
												const folder =
													queryString.parse(
														this.props.location
															.search
													).folder;
												const dbName =
													this.props.location.pathname.split(
														"/"
													)[1];
												history.replace(``);
												if (folder !== undefined) {
													if (dbName === "vdr") {
														history.replace(
															`?folder=${folder}`
														);
													} else {
														history.replace(
															`${dbName}/?folder=${folder}`
														);
													}
												} else {
													history.replace(
														`${dbName}`
													);
												}
											}}
											className={classes.backButton}
											edge="start"
											aria-label="open drawer"
										>
											<ArrowBack />
										</IconButton>
										<Typography variant="h4" align="center">
											<div>
												{this.props.dbName ===
													DBName.VDR
													? this.state.imageData
														.visual_hash
													: this.state.imageData
														?.display_name ??
													this.props.match.params
														.mongoId}
											</div>
										</Typography>
										<div className={classes.grow} />
										{this.maximizeButton()}
										<IconButton
											onClick={() => {
												this.setState({
													fullSize:
														!this.state.fullSize,
												});
											}}
											edge="end"
											color="inherit"
										>
											{this.state.fullSize ? (
												<ZoomOut />
											) : (
													<ZoomIn />
												)}
										</IconButton>
										{hideEditButtons ? (
											<div />
										) : (
												<div>
													<IconButton
														onClick={() => {
															this.setState({
																setOpen: true,
															});
														}}
														edge="end"
														color="inherit"
													>
														<DeleteIcon />
													</IconButton>
													<IconButton
														edge="end"
														color="inherit"
													>
														<ColorLens
															onClick={() => {
																this.setState({
																	colorDetection:
																		!this.state
																			.colorDetection,
																});
															}}
														/>
														{showColorDetection ? (
															`R:${this.state.r} G:${this.state.g} B:${this.state.b} A:${this.state.alpah}`
														) : (
																<div />
															)}
														{showColorDetection ? (
															<Paper
																style={{
																	background: `rgb(${this.state.r},${this.state.g},${this.state.b})`,
																}}
																className={
																	classes.colorCell
																}
															/>
														) : (
																<div />
															)}
														{showColorDetection ? (
															`x:${this.state.x} y:${this.state.y}`
														) : (
																<div />
															)}
													</IconButton>
													<IconButton
														onClick={() => {
															this.setState({
																cropEnabled:
																	!this.state
																		.cropEnabled,
															});
														}}
														edge="end"
														color="inherit"
													>
														<Crop />
													</IconButton>
												</div>
											)}
									</Toolbar>
								</AppBar>
								{this.content()}
								<BriaButton
									style={{ margin: "1rem" }}
									variant="contained"
									color="secondary"
									onClick={async () => {
										await downloadImage(this.state.imageUrl, `${this.state.imageData.visual_hash}.jpg`);
										toast.dark(
											<Typography>
												Image was <strong>downloaded</strong>
											</Typography>
										);
									}}
									className={classes.button}
								>
									{"download"}
								</BriaButton>
								<div>
									<Dialog
										open={this.state.setOpen}
										onClose={handleClose}
										aria-labelledby="alert-dialog-title"
										aria-describedby="alert-dialog-description"
									>
										<DialogTitle id="alert-dialog-title">
											{"Do you want to delete ?"}
										</DialogTitle>
										<DialogContent>
											<DialogContentText id="alert-dialog-description">
												If you delete this file your
												will lost it.
											</DialogContentText>
										</DialogContent>
										<DialogActions>
											<Button
												onClick={
													this.handleDeleteForever
												}
												color="secondary"
											>
												Delete forever
											</Button>
											<Button
												onClick={handleClose}
												color="primary"
												autoFocus
											>
												Cancel
											</Button>
											{this.state.isLoading ? (
												<CircularProgress />
											) : (
													""
												)}
										</DialogActions>
									</Dialog>
								</div>
							</div>
						);
					}}
				</AppContext.Consumer>
			</React.Fragment>
		);
	}
}

const styles = (theme: Theme) =>
	createStyles({
		switchRoot: {
			margin: "0 auto",
		},
		root: {
			display: "flex",
			flexWrap: "wrap",
			overflow: "hidden",
			alignItems: "center",
			justifyContent: "center",
		},
		gridListRoot: {
			alignItems: "center",
			justifyContent: "center",
			display: "flex",
			overflow: "hidden",
			backgroundColor: theme.palette.background.paper,
		},
		gridList: {
			width: "100%",
			height: "100%",
		},
		container: {
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
		},
		pageControll: {
			paddingTop: theme.spacing(4),
			paddingBottom: theme.spacing(4),
		},
		backButton: {
			paddingLeft: theme.spacing(10),
		},
		paper: {
			width: "36rem",
			padding: theme.spacing(2),
			margin: theme.spacing(2),
			display: "flex",
			overflow: "scroll",
			flexDirection: "column",
			backgroundColor: theme.palette.background.paper, //TODO add alpha 0.7
		},
		appBar: {
			background: theme.palette.background.paper,
			position: "sticky",
			color: theme.palette.text.primary,
			// top: 'auto',
			// bottom: 0,
		},
		dividerColor: {
			backgroundColor: "black",
		},
		grow: {
			flexGrow: 1,
		},
		fabButton: {
			position: "absolute",
			zIndex: 1,
			top: -30,
			left: 0,
			right: 0,
			margin: "0 auto",
		},
		colorCell: {
			height: 30,
			width: 35,
		},
		paddingGroup: {
			flexDirection: "row",
			// margin: theme.spacing(2),
		},
		maskImage: {
			opacity: 0.5,
			position: "absolute",
			maxwidth: "100%",
			maxHeight: "100vh",
			margin: "auto",
		},
		img: {
			position: "absolute",
			maxwidth: "100%",
			maxHeight: "100vh",
			margin: "auto",
		},
		imgbox: {
			display: "grid",
			height: "100%",
			alignSelf: "center",
		},
		imageRect: {
			position: "absolute",
			border: "5px solid black",
		},
		imageMask1: {
			position: "absolute",
			color: "yellow",
			height: "5px",
			fontSize: "45px",
			width: "5px",
			backgroundColor: "yellow",
			display: "inline-block",
		},
		classificationMenu: {},
		main: {
			height: "initial !important",
		},

		markLabel: {
			color: "inherit",
		},
		markLabelActive: {
			fontWeight: "bold",
		},
		chipsContainer: {
			display: "flex",
			justifyContent: "center",
			flexWrap: "wrap",
		},
		toggleButtonRoot: {
			border: "none",
		},
		toggleButtonSelected: {
			borderBottom: `3px solid rgb(55, 160, 133)`,
		},
		toggleButtonGroupRoot: {
			width: "100%",
			marginBottom: "2rem",
			borderBottom: "1px solid white",
			borderRadius: "0",
		},
	});

class Segmentation {
	maskUrlExternal: string | null;
	maskUrlInternal: string | null;
	algoName: string;
	algoVersion: string;
	showInternalMask: boolean;
	showExternalMask: boolean;
	overlap: boolean | null;
	constructor(
		maskUrlExternal: string | null,
		maskUrlInternal: string | null,
		algoName: string,
		algoVersion: string,
		overlap: boolean | null
	) {
		this.maskUrlExternal = maskUrlExternal;
		this.maskUrlInternal = maskUrlInternal;
		this.algoName = algoName;
		this.algoVersion = algoVersion;
		this.showInternalMask = false;
		this.showExternalMask = false;
		this.overlap = overlap;
	}
}

class S3Paths {
	disparityMap: string;
	pointCloud: string;
	depthMap: string;
	constructor(disparityMap: string, pointCloud: string, depthMap: string) {
		this.disparityMap = disparityMap;
		this.pointCloud = pointCloud;
		this.depthMap = depthMap;
	}
}

export default withRouter(withStyles(styles)(ImageReview));
