import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function BothImagesSlider(props: SvgIconProps) {
    return (
        <SvgIcon viewBox="0 0 20 20" {...props} fill="none">
            <path d="M14.3438 4.1875H2.65625C2.36285 4.1875 2.125 4.42535 2.125 4.71875V14.2812C2.125 14.5747 2.36285 14.8125 2.65625 14.8125H14.3438C14.6372 14.8125 14.875 14.5747 14.875 14.2812V4.71875C14.875 4.42535 14.6372 4.1875 14.3438 4.1875Z" fill="none" stroke="inherit" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.125 12.1569L5.46809 8.8138C5.51742 8.76447 5.57599 8.72534 5.64044 8.69864C5.70489 8.67194 5.77398 8.6582 5.84374 8.6582C5.91351 8.6582 5.98259 8.67194 6.04704 8.69864C6.1115 8.72534 6.17006 8.76447 6.21939 8.8138L9.18684 11.7813C9.23617 11.8306 9.29474 11.8697 9.35919 11.8964C9.42364 11.9231 9.49273 11.9369 9.56249 11.9369C9.63225 11.9369 9.70134 11.9231 9.76579 11.8964C9.83025 11.8697 9.88881 11.8306 9.93814 11.7813L11.3118 10.4076C11.3612 10.3582 11.4197 10.3191 11.4842 10.2924C11.5486 10.2657 11.6177 10.252 11.6875 10.252C11.7573 10.252 11.8263 10.2657 11.8908 10.2924C11.9552 10.3191 12.0138 10.3582 12.0631 10.4076L14.875 13.2194" fill="none" stroke="inherit" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.3594 8.4375C10.7995 8.4375 11.1562 8.08073 11.1562 7.64062C11.1562 7.20052 10.7995 6.84375 10.3594 6.84375C9.91927 6.84375 9.5625 7.20052 9.5625 7.64062C9.5625 8.08073 9.91927 8.4375 10.3594 8.4375Z" fill="none" stroke="inherit" />
            <line x1="8.5" y1="-2.3006e-08" x2="8.5" y2="19" fill="none" stroke="inherit" />
        </SvgIcon>
    );
}

export default BothImagesSlider;
