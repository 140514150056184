import React from "react";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Button, Box, FormControl, FormHelperText, InputLabel, Select, Menu } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import { VDRUploadParameters } from "../../types/graphql-global-types";
interface IProps {
	finishedForm: (uploadParameters: VDRUploadParameters) => void;
	formNotEmpty: (formEmpty: boolean) => void;
	activeStepCallback: (activeStep: number) => void;
	numberOfImages: number;
	projects: string[];
}

interface IState {
	curQueryName: string;
	curProjectId: string;
	curDescription: string;
	curSendToSemSeg: boolean;
	curInternalUse: boolean;
	curValidForTraining: boolean;
	activeStep: number;
	steps: string[];
	formEmpty: boolean;
	projectIdAnchorEl: null | HTMLElement;
	showProjects: boolean;
	shouldShowErrorProjectId: boolean;
	customProjectMenuOpen: boolean
}

class UploadToVDRParameters implements VDRUploadParameters {
	query: string;
	description: string | null;
	projectId: string;
	sendToSemSeg: boolean;
	validForTraining: boolean;
	internalUse: boolean;
	imageRefUrl: string;

	constructor(
		query: string,
		description: string | null,
		projectId: string,
		sendToSemSeg: boolean,
		validForTraining: boolean,
		internalUse: boolean
	) {
		this.validForTraining = validForTraining;
		this.query = query;
		this.description = description;
		this.projectId = projectId;
		this.sendToSemSeg = sendToSemSeg;
		this.internalUse = internalUse;
		this.imageRefUrl = "image_upload/1/2/3";
	}
}

class VDRUploadForm extends React.Component<IProps, IState> {
	state: IState = {
		curQueryName: "",
		curProjectId: "",
		curDescription: "",
		curSendToSemSeg: false,
		curInternalUse: true,
		curValidForTraining: true,
		activeStep: 0,
		steps: ["Data", "Data Pipeline", "Review your upload request"],
		formEmpty: true,
		projectIdAnchorEl: null,
		showProjects: false,
		shouldShowErrorProjectId: false,
		customProjectMenuOpen: false
	};

	async componentDidMount() {
		this.props.formNotEmpty.bind(this);
		this.handleFormUpdating = this.handleFormUpdating.bind(this);
	}

	async isFormEmpty() {
		let localFormEmpty: boolean =
			this.state.curProjectId === "" &&
			this.state.curDescription === "" &&
			this.state.curQueryName == "";
		this.setState({ formEmpty: localFormEmpty });
	}

	handleCloseProjectIDMenu = () => {
		this.setState({
			showProjects: false,
			shouldShowErrorProjectId: false,
		});
	};

	handleCloseCustomProjectIDMenu = () => {
		this.setState({
			projectIdAnchorEl: null,
			showProjects: false,
			shouldShowErrorProjectId: false,
			customProjectMenuOpen: false
		});
	};

	getStepContent(step: number) {
		switch (step) {
			case 0:
				return this.DataForm();
			case 1:
				return this.DataPipelineForm();
			case 2:
				return this.Review();
			default:
				throw new Error("Unknown step");
		}
	}

	changeActiveStep() {
		this.setState({ activeStep: this.state.activeStep - 1 }, () => {
			this.props.activeStepCallback(this.state.activeStep);
		});
	}

	handleNext() {
		let shouldGoNext: Boolean = true;
		if (this.state.curProjectId === "") {
			this.setState({ shouldShowErrorProjectId: true });
			shouldGoNext = false;
		} else {
			this.setState({ shouldShowErrorProjectId: false });
		}
		if (!shouldGoNext) {
			return;
		}
		if (this.state.activeStep + 1 === this.state.steps.length) {
			const uploadParameters = new UploadToVDRParameters(
				this.state.curQueryName,
				this.state.curDescription,
				this.state.curProjectId,
				this.state.curSendToSemSeg,
				this.state.curValidForTraining,
				this.state.curInternalUse
			);
			this.props.finishedForm(uploadParameters);
		} else {
			this.setState({ activeStep: this.state.activeStep + 1 }, () => {
				this.props.activeStepCallback(this.state.activeStep);
			});
		}
	}

	getUploadImageText(): string {
		const uploadImagetext: string =
			this.props.numberOfImages === 1
				? "Upload 1 image"
				: "Upload " + this.props.numberOfImages + " images";
		return uploadImagetext;
	}

	render() {
		const { classes }: any = this.props;
		return (
			<React.Fragment>
				{this.getStepContent(this.state.activeStep)}
				<div className={classes.buttons}>
					{this.state.activeStep !== 0 && (
						<Button
							onClick={this.changeActiveStep.bind(this)}
							className={classes.button}
						>
							{" Back"}
						</Button>
					)}
					<Button
						variant="contained"
						color="primary"
						onClick={this.handleNext.bind(this)}
						className={classes.button}
						style={{ textTransform: "none" }}
					>
						{this.state.activeStep === this.state.steps.length - 1
							? this.getUploadImageText()
							: "Next"}
					</Button>
				</div>
			</React.Fragment>
		);
	}

	setProjectId(projectId: string) {
		this.setState({ curProjectId: projectId }, () =>
			this.handleCloseProjectIDMenu()
		);
		this.handleFormUpdating();
	}

	async handleFormUpdating() {
		await this.isFormEmpty();
		this.props.formNotEmpty(this.state.formEmpty);
	}

	DataForm(): JSX.Element {
		return (
			<React.Fragment>
				<Typography variant="h6" gutterBottom>
					{this.props.numberOfImages === 1
						? "Tell us about the image"
						: "Tell us about the images"}
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<TextField
							id="queryName"
							name="queryName"
							label="Tag Name"
							fullWidth
							autoComplete="query-name"
							defaultValue={this.state.curQueryName}
							onChange={(event: any) => {
								this.setState(
									{ curQueryName: event.target.value },
									async () => {
										await this.handleFormUpdating();
									}
								);
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						{this.getProjectIDGrid()}
					</Grid>
					<Grid item xs={12}>
						<TextField
							id="description"
							name="description"
							label="Description"
							helperText="Tell us what appears in the image, e.g. a girl with a red dress"
							fullWidth
							autoComplete="description"
							defaultValue={this.state.curDescription}
							onChange={(event: any) => {
								this.setState(
									{ curDescription: event.target.value },
									async () => {
										await this.handleFormUpdating();
									}
								);
							}}
						/>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}

	setCustomProjectId(event: any) {
		if (event.key === "Enter") {
			this.handleCloseCustomProjectIDMenu()
		}
		console.log(event.target.value)
		this.setState({ curProjectId: event.target.value }, async () => {
			await this.handleFormUpdating();
		});
	}

	getProjectIDGrid(): JSX.Element {
		const { classes }: any = this.props;
		return (
			<FormControl className={classes.formControl}>
				<InputLabel id="demo-simple-select-autowidth-label">Project ID</InputLabel>
				<Select
					labelId="demo-simple-select-autowidth-label"
					id="demo-simple-select-autowidth"
					value={this.state.curProjectId}
					onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.setProjectId(event?.target.value as string)}
					autoWidth
					onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
						this.setState({
							projectIdAnchorEl: event.currentTarget
						});
					}}
				>
					{this.props.projects.map((projectName: string) => {
						if (projectName == "Custom") {
							return (
								<MenuItem
									value={this.state.curProjectId}
									onClick={() => {
										this.setState({ customProjectMenuOpen: true });
									}}
									style={{ fontWeight: "bold", color: "blue" }}
								>{this.state.curProjectId || "* Custom Project ID"}
								</MenuItem>
							)
						} else {
							return (
								<MenuItem value={projectName}>{projectName}</MenuItem>
							)
						}
					})}
				</Select>
				<Menu
					id="simple-menu"
					anchorEl={this.state.projectIdAnchorEl}
					keepMounted
					open={this.state.customProjectMenuOpen}
					onClose={this.handleCloseCustomProjectIDMenu}
				>
					<MenuItem>
						<TextField
							label="* Write your custom project ID"
							fullWidth
							onKeyUp={this.setCustomProjectId.bind(
								this
							)}
						>
							{this.state.curProjectId}
						</TextField>
					</MenuItem>
				</Menu>
			</FormControl>
		);
	}

	DataPipelineForm() {
		return (
			<React.Fragment>
				<Typography variant="h6" gutterBottom>
					{"Tell us what we should do with the image"}
				</Typography>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									name="sendToSemSeg"
									checked={this.state.curSendToSemSeg}
								/>
							}
							label="Should we send it to manual semantic segmentation?"
							onChange={() => {
								this.setState({
									curSendToSemSeg:
										!this.state.curSendToSemSeg,
								});
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									name="validForTraining"
									checked={this.state.curValidForTraining}
								/>
							}
							label="Should we use it for training?"
							onChange={() => {
								this.setState({
									curValidForTraining:
										!this.state.curValidForTraining,
								});
							}}
						/>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									name="internalUse"
									checked={this.state.curInternalUse}
								/>
							}
							label="Should we only use it internally?"
							onChange={() => {
								this.setState({
									curInternalUse: !this.state.curInternalUse,
								});
							}}
						/>
					</Grid>
				</Grid>
			</React.Fragment>
		);
	}

	Review() {
		const { classes }: any = this.props;
		return (
			<React.Fragment>
				<Typography variant="h6" gutterBottom>
					{"Upload summary"}
				</Typography>
				<List disablePadding>
					<ListItem className={classes.listItem}>
						<ListItemText primary="Tag Name" />
						<Typography
							variant="subtitle1"
							className={classes.total}
						>
							{this.state.curQueryName}
						</Typography>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText primary="Project ID" />
						<Typography
							variant="subtitle1"
							className={classes.total}
						>
							{this.state.curProjectId}
						</Typography>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText primary="Description" />
						<Typography
							variant="subtitle1"
							className={classes.total}
						>
							{this.state.curDescription}
						</Typography>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText primary="Send to manual semantic segmentation" />
						<Typography
							variant="subtitle1"
							className={classes.total}
						>
							<Checkbox
								color="primary"
								name="sendToSemSeg"
								checked={this.state.curSendToSemSeg}
							/>
						</Typography>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText primary="Valid for training" />
						<Typography
							variant="subtitle1"
							className={classes.total}
						>
							<Checkbox
								color="primary"
								name="validForTraining"
								checked={this.state.curValidForTraining}
							/>
						</Typography>
					</ListItem>
					<ListItem className={classes.listItem}>
						<ListItemText primary="Internal use" />
						<Typography
							variant="subtitle1"
							className={classes.total}
						>
							<Checkbox
								color="primary"
								name="internalUse"
								checked={this.state.curInternalUse}
							/>
						</Typography>
					</ListItem>
				</List>
			</React.Fragment>
		);
	}
}
const styles = (theme: Theme) =>
	createStyles({
		modalPaper: {
			position: "absolute",
			width: 800,
			backgroundColor: theme.palette.background.paper,
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		formControl: {
			minWidth: "100%",
		},
		listItem: {
			padding: theme.spacing(1, 0),
		},
		total: {
			fontWeight: 2048,
		},
		title: {
			marginTop: theme.spacing(2),
		},
		appBar: {
			position: "relative",
		},
		layout: {
			width: "auto",
			marginLeft: theme.spacing(2),
			marginRight: theme.spacing(2),
			[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
				width: 600,
				marginLeft: "auto",
				marginRight: "auto",
			},
		},
		formPaper: {
			marginTop: theme.spacing(3),
			marginBottom: theme.spacing(3),
			padding: theme.spacing(2),
			[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
				marginTop: theme.spacing(6),
				marginBottom: theme.spacing(6),
				padding: theme.spacing(3),
			},
		},
		stepper: {
			padding: theme.spacing(3, 0, 5),
		},
		buttons: {
			display: "flex",
			justifyContent: "flex-end",
		},
		button: {
			marginTop: theme.spacing(3),
			marginLeft: theme.spacing(1),
			textTransform: "none",
		},
	});

export default withStyles(styles)(VDRUploadForm);
