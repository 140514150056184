import React from "react";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import MUIDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface IProps {
    shouldOpen: boolean;
    onClose: () => void;
    dialogTitle: string;
    dialogContentText: string;
    renderDialogContent: () => JSX.Element | void;
    onClickAffirmative: () => void;
    affirmativeButtonText: string;
    onClickNegative: () => void;
    negativeButtonText: string;
}

interface IState {}
class Dialog extends React.Component<IProps, IState> {
    render() {
        return (
            <MUIDialog
                open={this.props.shouldOpen}
                onClose={this.props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {this.props.dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-content-text">
                        {this.props.dialogContentText}
                    </DialogContentText>
                    {this.props.renderDialogContent()}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.props.onClickAffirmative}
                        color="primary"
                        style={{ textTransform: "none" }}
                    >
                        {this.props.affirmativeButtonText}
                    </Button>
                    <Button
                        onClick={this.props.onClickNegative}
                        color="primary"
                        autoFocus
                        style={{ textTransform: "none" }}
                    >
                        {this.props.negativeButtonText}
                    </Button>
                </DialogActions>
            </MUIDialog>
        );
    }
}

const styles = (theme: Theme) => createStyles({});

export default withStyles(styles)(Dialog);
