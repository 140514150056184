import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik, FormikErrors } from "formik";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { useLoginService } from "../hooks/useLoginService";
import Layout from "../Components/Login/Layout";
import BriaInput from "../Components/UI/BriaInput";
import ErrorIcon from "../assets/icons/ErrorIcon";
import ResetPasswordConfirmation from "./ResetPasswordConfirmation";

interface IProps {}

interface FormValues {
	email: string;
}

const ForgotPassword: React.FC<IProps> = () => {
	const classes = useStyles();
	const { t } = useTranslation();
	const { resetPassword, errorMessage } = useLoginService();
	const [sendResetEmail, setSendResetEmail] = React.useState<{
		isEmailSent: boolean;
		userEmail: string;
	}>({ isEmailSent: false, userEmail: "" });

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const initialValues = {
		email: "",
	};

	const handleSubmit = async (values: any) => {
		resetPassword(values.email)
			.then(() => {
				setSendResetEmail({
					isEmailSent: true,
					userEmail: values.email,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	if (sendResetEmail.isEmailSent && !errorMessage) {
		return <ResetPasswordConfirmation email={sendResetEmail.userEmail} />;
	}

	return (
		<Layout>
			<Box display="flex" flexDirection="column" alignItems="center">
				<Typography className={classes.typo1}>
					{t("forgotPassword")}
				</Typography>
				<Typography className={classes.typo2}>
					{t("pleaseEnterYourEmailToReceiveAPasswordResetLink")}
				</Typography>

				<Formik
					initialValues={initialValues}
					validate={(values) => {
						const errors: FormikErrors<FormValues> = {};
						if (!values.email) {
							errors.email = "Required";
						} else if (
							!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
								values.email
							)
						) {
							errors.email = "Invalid email address";
						}
						return errors;
					}}
					onSubmit={handleSubmit}
				>
					{({ values, errors, touched, handleChange, isValid }) => (
						<Form className={classes.form}>
							<Box
								display="flex"
								flexDirection="column"
								alignItems="center"
							>
								<BriaInput
									id="email"
									label="Email"
									onChange={handleChange}
									error={
										(Boolean(errors.email) &&
											touched.email) as boolean
									}
									helperText={errors.email}
									className={classes.textField}
								/>

								{errorMessage && (
									<Typography
										className={classes.error}
										color="secondary"
									>
										<ErrorIcon
											className={classes.errorIcon}
										/>
										{errorMessage}
									</Typography>
								)}

								<Button
									type="submit"
									variant="contained"
									color="secondary"
									disabled={!isValid}
									className={classes.button}
								>
									{t("sendLink")}
								</Button>
							</Box>
						</Form>
					)}
				</Formik>
			</Box>
			<Box />
		</Layout>
	);
};

const useStyles = makeStyles((theme) => ({
	form: {
		width: 440,
		height: 200,
		marginTop: 90,
	},
	button: {
		borderRadius: 20,
		width: 440,
		height: 40,
		marginTop: 16,
	},
	icon: {
		fontSize: 58,
	},
	signup: {
		color: theme.palette.primary.light,
	},
	typo1: {
		fontSize: 34,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.text.primary,
		marginBottom: 125,
	},
	typo2: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.primary,
	},
	textField: {
		marginTop: 15,
		marginBottom: 15,
	},
	error: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightBold,
		marginLeft: 12,
	},
	errorIcon: {
		fontSize: 16,
		marginRight: 4,
		verticalAlign: "middle",
	},
}));

export default ForgotPassword;
