import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function Search(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 19 19" {...props}>
			<path
				d="M7.44593 13.894C6.17092 13.8938 4.92468 13.5156 3.86463 12.8071C2.80458 12.0986 1.97844 11.0917 1.49061 9.9137C1.00278 8.7357 0.875121 7.4395 1.12391 6.189C1.3727 4.9385 1.98675 3.78985 2.88832 2.88828C3.78988 1.98671 4.93859 1.37273 6.1891 1.12394C7.4396 0.875144 8.73571 1.00271 9.91371 1.49054C11.0917 1.97838 12.0985 2.80455 12.807 3.8646C13.5155 4.92465 13.8937 6.17098 13.8939 7.44599C13.8921 9.15553 13.2121 10.7945 12.0033 12.0034C10.7945 13.2122 9.15548 13.8921 7.44593 13.894ZM7.44593 1.74399C6.31858 1.74399 5.21655 2.0783 4.27918 2.70462C3.34182 3.33095 2.61124 4.22116 2.17982 5.2627C1.7484 6.30424 1.63567 7.45031 1.8556 8.556C2.07554 9.66169 2.61826 10.6773 3.41542 11.4745C4.21257 12.2716 5.22818 12.8145 6.33387 13.0345C7.43956 13.2544 8.58579 13.1415 9.62733 12.7101C10.6689 12.2787 11.5591 11.5481 12.1854 10.6108C12.8118 9.67339 13.1459 8.57136 13.1459 7.444C13.1432 5.93308 12.5419 4.4848 11.4735 3.41642C10.4051 2.34803 8.95685 1.74664 7.44593 1.74399Z"
				fill="#ABB5BE"
				// stroke="#495057"
				strokeWidth="0.5"
			/>
			<path
				d="M17.582 17.8916C17.5332 17.8916 17.4848 17.882 17.4397 17.8633C17.3946 17.8446 17.3536 17.8172 17.3191 17.7826L11.5959 12.0596C11.5613 12.0251 11.5339 11.9841 11.5151 11.9389C11.4964 11.8938 11.4869 11.8454 11.4868 11.7965C11.4868 11.7476 11.4962 11.6991 11.5149 11.654C11.5336 11.6088 11.5609 11.5677 11.5955 11.5331C11.63 11.4985 11.6712 11.4711 11.7163 11.4523C11.7615 11.4335 11.8098 11.4239 11.8586 11.4238C11.9075 11.4238 11.956 11.4334 12.0012 11.452C12.0464 11.4707 12.0875 11.4981 12.1221 11.5326L17.845 17.2556C17.8973 17.3076 17.9332 17.3739 17.9478 17.4463C17.9623 17.5186 17.9549 17.5936 17.9268 17.6618C17.8986 17.7299 17.8507 17.7882 17.7893 17.8291C17.7279 17.8701 17.6558 17.8918 17.582 17.8916Z"
				fill="#ABB5BE"
				// stroke="#495057"
				strokeWidth="0.5"
			/>
		</SvgIcon>
	);
}

export default Search;
