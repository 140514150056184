import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import clsx from "clsx";
import Tooltip from "./Tooltip";

const useStyles = makeStyles((theme) => ({
	root: {
		background: theme.palette.common.white,
		width: 60,
		height: 30,
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(4),
		borderRadius: 25,
		overflow: "hidden",
	},
	toggleGroup: {
		height: "100%",
		width: "100%",
		padding: 1,
	},
	toggleButton: {
		height: "100%",
		width: "50%",
		stroke: "#A4A4A4",
		borderRadius: 15,
	},
	icon1: {
		marginLeft: 3,
		fontSize: 22,
	},
	icon2: {
		marginRight: 6,
		fontSize: 22,
	},
	activeToggleButton: {
		backgroundColor: `${theme.palette.primary.dark} !important`,
		stroke: "white",
		"&:disabled": {
			backgroundColor: `${theme.palette.grey[400]} !important`,
		},
	},
}));

interface IProps {
	selectedValue: number;
	onChange: (value: number) => void;
	disabled?: boolean;
	rightIcon: ReactElement;
	leftIcon: ReactElement;
}

const TwoStateSwitch: React.FC<IProps> = ({
	selectedValue,
	onChange,
	disabled = false,
	rightIcon,
	leftIcon,
}) => {
	const classes = useStyles();

	const handleStyle = (
		event: React.MouseEvent<HTMLElement>,
		newStyle: number
	) => {
		if (newStyle !== null) {
			onChange(newStyle);
		}
	};

	const rightIconView = React.cloneElement(rightIcon, {
		className: classes.icon1,
	});

	const leftIconView = React.cloneElement(leftIcon, {
		className: classes.icon2,
	});

	return (
		<Box className={classes.root}>
			<ToggleButtonGroup
				className={classes.toggleGroup}
				value={selectedValue}
				exclusive
				onChange={handleStyle}
			>
				{/* <Tooltip arrow> */}
				<ToggleButton
					className={clsx({
						[classes.toggleButton]: true,
						[classes.activeToggleButton]: selectedValue === 1,
					})}
					disabled={disabled}
					value={1}
				>
					{rightIconView}
				</ToggleButton>
				{/* </Tooltip> */}
				{/* <Tooltip title="" arrow> */}
				<ToggleButton
					className={clsx({
						[classes.toggleButton]: true,
						[classes.activeToggleButton]: selectedValue === 2,
					})}
					value={2}
					disabled={disabled}
				>
					{leftIconView}
				</ToggleButton>
				{/* </Tooltip> */}
			</ToggleButtonGroup>
		</Box>
	);
};

export default TwoStateSwitch;
