import React, { useContext } from "react";
import AppContext from "../../Context/Context";
import { Redirect, Route, RouteProps } from "react-router-dom";
import RouterConstants from "../../Constants/RouterConstants";

const PublicRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
	const { user } = useContext(AppContext);
	const path = new URLSearchParams(window.location.search).get("redirect");
	const redirect = path === "/" ? null : `?redirect=${path}`;
	const emailVerified = user?.isEmailVerified();

	return (
		<Route
			{...rest}
			render={() => {
				return !user && !emailVerified ? (
					<>{children}</>
				) : !!user && !emailVerified ? (
					<Redirect to={`${RouterConstants.VERIFY}${redirect}`} />
				) : !!redirect ? (
					<Redirect to={`${path}`} />
				) : (
					<Redirect to={`${RouterConstants.BASE}${redirect}`} />
				);
			}}
		></Route>
	);
};

export default PublicRoute;
