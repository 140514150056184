import React, { Dispatch, SetStateAction } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Switch from "@material-ui/core/Switch";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { ImageEditorData_metaData_settings } from "../GraphQL/types/imageEditorData";

interface IProps {
	openSettingsDialog: boolean;
	setOpenSettingsDialog: Dispatch<SetStateAction<boolean>>;
	pipelineSettings?: ImageEditorData_metaData_settings[];
	handleChangePipelineSettings?: (index: number, value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
			maxWidth: 360,
			backgroundColor: theme.palette.background.paper,
		},
	})
);

export default function Settings({
	openSettingsDialog,
	setOpenSettingsDialog,
	pipelineSettings: settingsList,
	handleChangePipelineSettings,
}: IProps) {
	const classes = useStyles();

	const handleCloseSettingsDialog = () => {
		setOpenSettingsDialog(false);
	};

	return (
		<Dialog
			open={openSettingsDialog}
			onClose={handleCloseSettingsDialog}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{"Settings"}
				<IconButton
					edge="end"
					color="inherit"
					aria-label="close"
					onClick={handleCloseSettingsDialog}
					style={{ float: "right" }}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			<DialogContent>
				<List className={classes.root}>
					{settingsList?.map((setting: any, index: any) => {
						return (
							<ListItem key={index}>
								<ListItemText
									id="switch-list-label"
									primary={setting.name}
									secondary={setting.description}
								/>
								<ListItemSecondaryAction>
									<Switch
										edge="end"
										onChange={(
											event: React.ChangeEvent<HTMLInputElement>
										) => {
											handleChangePipelineSettings?.(
												index,
												event.target.checked
											);
										}}
										checked={setting.isOn}
										inputProps={{
											"aria-labelledby":
												"switch-list-label",
										}}
									/>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}
				</List>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleCloseSettingsDialog}
					color="primary"
					autoFocus
				>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
}
