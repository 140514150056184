import React from "react";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {
	Avatar,
	Button,
	CircularProgress,
	Container,
	Menu,
	MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import client from "../GraphQL/client";
import gql from "graphql-tag";
import { users, users_users } from "./types/users";
import {
	chnageUserRole,
	chnageUserRoleVariables,
} from "./types/chnageUserRole";
import { ROLES } from "../Routes";

interface IState {
	users: users_users[] | [users_users];
	isLoading: boolean;
	anchorEl: any;
	selectedUserId: string | null;
}

class UserManagement extends React.Component<any, IState> {
	state: IState = {
		users: [],
		isLoading: false,
		anchorEl: null,
		selectedUserId: null,
	};

	componentDidMount() {
		this.getUsers();
	}

	componentWillUnmount() {
		client.stop();
	}

	async getUsers() {
		this.setState({ isLoading: true });
		try {
			const res = await client.query<users, any>({
				query: gql`
					query users {
						users {
							email
							role
							profilePicture
							uid
						}
					}
				`,
			});
			this.setState({ users: res.data.users ?? [] });
		} catch (error) {
			// alert(error.message);
		}
		this.setState({ isLoading: false });
	}

	async chnageUserRole(newRole: ROLES) {
		this.handleClose();
		try {
			const res = await client.mutate<
				chnageUserRole,
				chnageUserRoleVariables
			>({
				mutation: gql`
					mutation chnageUserRole($role: String!, $uid: String!) {
						chnageUserRole(role: $role, uid: $uid) {
							ok
						}
					}
				`,
				variables: {
					role: newRole,
					uid: this.state.selectedUserId ?? "",
				},
			});
			if (res.data?.chnageUserRole?.ok) {
				this.setState({ selectedUserId: null }, () => {
					this.getUsers();
				});
			}
		} catch (error) {
			// alert(error.message);
		}
	}

	handleClose = () => {
		this.setState({ anchorEl: null });
	};

	handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	public render() {
		const { classes } = this.props;
		if (this.state.isLoading) {
			return <CircularProgress />;
		}
		return (
			<div className={classes.root}>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell>Email</TableCell>
								<TableCell align="right">Chnage Role</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{this.state.users.map(
								(user: users_users, index: number) => {
									const userUID = user.uid;
									return (
										<TableRow key={index}>
											<TableCell>
												<Avatar
													alt={user.email}
													src={user.profilePicture}
												/>
											</TableCell>
											<TableCell
												component="th"
												scope="row"
											>
												{user.email}
											</TableCell>
											<TableCell align="right">
												<Button
													variant={
														user.role == "admin"
															? "contained"
															: "outlined"
													}
													color={
														user.role == "non"
															? "secondary"
															: undefined
													}
													aria-controls="simple-menu"
													aria-haspopup="true"
													onClick={(
														event: React.MouseEvent<
															HTMLButtonElement,
															MouseEvent
														>
													) => {
														this.handleClick(event);
														this.setState({
															selectedUserId:
																userUID,
														});
													}}
													// onClick={event => {
													// this.setState({ selectedUserId: userUID }, () => {
													//     this.handleClick(event)
													// })
													// }}
												>
													{user.role}
												</Button>
												<Menu
													id="simple-menu"
													anchorEl={
														this.state.anchorEl
													}
													keepMounted
													open={Boolean(
														this.state.anchorEl
													)}
													onClose={this.handleClose}
													anchorOrigin={{
														vertical: "bottom",
														horizontal: "center",
													}}
													transformOrigin={{
														vertical: "top",
														horizontal: "center",
													}}
												>
													<MenuItem
														onClick={() =>
															this.chnageUserRole(
																ROLES.ADMIN
															)
														}
													>
														Admin
													</MenuItem>
													<MenuItem
														onClick={() =>
															this.chnageUserRole(
																ROLES.USER
															)
														}
													>
														Viewer
													</MenuItem>
													<MenuItem
														onClick={() =>
															this.chnageUserRole(
																ROLES.EXTERNAL
															)
														}
													>
														External
													</MenuItem>
													<MenuItem
														onClick={() =>
															this.chnageUserRole(
																ROLES.NON
															)
														}
													>
														Disable
													</MenuItem>
												</Menu>
											</TableCell>
										</TableRow>
									);
								}
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		);
	}
}

const styles = (theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			margin: 40,
		},
		table: {
			minWidth: 650,
		},
	});

export default withStyles(styles)(UserManagement);
