import React, { useContext } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";
import Context from "../../Context/Context";
import { getAuth } from "firebase/auth";

export default function useFeedback() {
	const [ratingValue, setRatingValue] = React.useState<number | null>(5);
	const [value, setValue] = React.useState<string>("");
	const context = useContext(Context);

	const dsn =
		"https://84b28882ee0d47dea87581329c5c0bc2@o417868.ingest.sentry.io/5884174";

	const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		setValue(event.target.value);
	};

	const handleSubmit = async () => {
		const eventId = Sentry.captureMessage("User feedback");

		const body = new FormData();
		Object.entries({
			name: context.user?.userName ?? "bria",
			email: getAuth().currentUser?.email ?? "bria@bria.com",
			comments: JSON.stringify(
				{
					feedback: value,
					rating: ratingValue?.toString() ?? "",
				},
				null,
				"\t"
			),
			event_id: eventId,
		}).map(([k, v]) => body.append(k, v));

		const query = Object.entries({ dsn, eventId })
			.map(([k, v]) => `${k}=${v}`)
			.join("&");

		await fetch(`https://sentry.io/api/embed/error-page/?${query}`, {
			method: "POST",
			body,
		});

		setRatingValue(5);
		setValue("");
	};

	return {
		ratingValue,
		setRatingValue,
		value,
		setValue,
		handleChange,
		handleSubmit,
	};
}
