import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function ContactUsIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 23 23" {...props}>
			<path
				d="M4.08149 15.9028C3.00989 14.0963 2.63461 11.9608 3.02611 9.89722C3.41762 7.83365 4.54897 5.98397 6.20774 4.69552C7.86651 3.40707 9.93858 2.76847 12.0349 2.89965C14.1311 3.03083 16.1074 3.92276 17.5926 5.40795C19.0778 6.89315 19.9698 8.86943 20.101 10.9657C20.2322 13.062 19.5936 15.1341 18.3051 16.7928C17.0167 18.4516 15.167 19.583 13.1034 19.9745C11.0399 20.366 8.90435 19.9907 7.09788 18.9191L7.0979 18.9191L4.11899 19.7702C3.99575 19.8054 3.86532 19.807 3.74124 19.7748C3.61716 19.7427 3.50394 19.6779 3.4133 19.5873C3.32266 19.4967 3.25791 19.3834 3.22576 19.2594C3.19361 19.1353 3.19522 19.0049 3.23044 18.8816L4.08156 15.9027L4.08149 15.9028Z"
				stroke="#D80067"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
}

export default ContactUsIcon;
