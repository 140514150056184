import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import Layout from "../Components/Login/Layout";
import EmailIcon from "../assets/icons/EmailIcon";

interface IProps {
	email: string;
}

const ResetPasswordConfirmation: React.FC<IProps> = ({ email }) => {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<Layout>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				width="100%"
			>
				<Typography className={classes.typo1}>
					{t("emailSent")}
				</Typography>
			</Box>

			<EmailIcon className={classes.icon} />

			<Box textAlign="center" padding="10px">
				<Typography className={classes.typo2}>
					{t("weSentAPasswordResetLinkTo")}
					<span className={classes.boldFont}>{email}</span>
				</Typography>
			</Box>
		</Layout>
	);
};

const useStyles = makeStyles((theme) => ({
	icon: {
		fontSize: 308,
	},
	typo1: {
		fontSize: 34,
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.text.primary,
		// marginBottom: theme.spacing(7),
	},
	typo2: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.text.primary,
	},
	boldFont: {
		fontWeight: theme.typography.fontWeightBold,
	},
}));

export default ResetPasswordConfirmation;
