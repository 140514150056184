import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function Settings(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 17 16" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.73592 0.533203L6.64264 0.999023L6.28576 2.70352C5.71518 2.91778 5.19775 3.2216 4.73023 3.5819L2.98596 3.0084L2.51735 2.86556L2.27437 3.27702L1.07355 5.25117L0.830566 5.6627L1.18637 5.96862L2.53579 7.09746C2.48698 7.39273 2.42407 7.68479 2.42407 7.99499C2.42407 8.30518 2.48698 8.5973 2.53579 8.89258L1.18637 10.0214L0.830566 10.3273L1.07355 10.7388L2.27437 12.713L2.51735 13.1255L2.98596 12.9816L4.73023 12.4081C5.19775 12.7684 5.71518 13.0722 6.28576 13.2865L6.64264 14.9909L6.73592 15.4568H10.1106L10.2049 14.9909L10.5607 13.2865C11.1313 13.0722 11.6487 12.7684 12.1163 12.4081L13.8605 12.9816L14.3291 13.1255L14.5732 12.713L15.7729 10.7388L16.017 10.3273L15.6601 10.0214L14.3107 8.89258C14.3606 8.5973 14.4224 8.30518 14.4224 7.99499C14.4224 7.68479 14.3606 7.39273 14.3107 7.09746L15.6601 5.96862L16.017 5.6627L15.7729 5.25117L14.5732 3.27702L14.3291 2.86556L13.8605 3.0084L12.1163 3.5819C11.6487 3.2216 11.1313 2.91778 10.5607 2.70352L10.2049 0.999023L10.1106 0.533203H6.73592Z"
				// stroke="#495057"
				strokeLinecap="square"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.5929 7.99499C10.5929 9.17182 9.62095 10.127 8.42339 10.127C7.22583 10.127 6.2539 9.17182 6.2539 7.99499C6.2539 6.81815 7.22583 5.86309 8.42339 5.86309C9.62095 5.86309 10.5929 6.81815 10.5929 7.99499Z"
				// stroke="#495057"
				strokeLinecap="square"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
}

export default Settings;
