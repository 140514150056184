import React from "react";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

function EmailIcon(props: SvgIconProps) {
	return (
		<SvgIcon viewBox="0 0 308 308" {...props}>
			<circle cx="154" cy="154" r="154" fill="#EDEDED" />
			<rect x="64" y="91" width="181" height="126" fill="white" />
			<line
				y1="-3"
				x2="122.296"
				y2="-3"
				transform="matrix(0.756363 0.654152 -0.468707 0.883354 64 91)"
				stroke="#EDEDED"
				strokeWidth="6"
			/>
			<line
				y1="-3"
				x2="122.296"
				y2="-3"
				transform="matrix(-0.756363 0.654152 0.468707 0.883354 249 91)"
				stroke="#EDEDED"
				strokeWidth="6"
			/>
		</SvgIcon>
	);
}

export default EmailIcon;
